/* Copyright */
import React from "react";
import CircuitIndicator from "./circuit-indicator";
import { getSigmaDataStatus } from "../../../data/utils/Utils";

interface Props {
  value: string;
}

/* Renders short circuit value on the left side of slash and earth circuit value on the right side.
The value titles in the cell on the same row of the table have to follow the same ordering. */
class SigmaDCell extends React.Component<Props> {
  public render(): JSX.Element {
    const { value } = this.props;
    return (
      <div style={{ display: "flex", alignItems: "center", position: "relative", right: "3px" }}>
        <CircuitIndicator status={getSigmaDataStatus(parseInt(value)).shortCircuit} type="shortCircuit"/>
        <span style={{ marginLeft: "0.2rem", marginRight: "0.3rem", fontSize: "1rem" }}>/</span>
        <CircuitIndicator status={getSigmaDataStatus(parseInt(value)).earthCircuit} type="earthCircuit"/>
      </div>);
  }
}

export default SigmaDCell;

/* Copyright */
import React from "react";
import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableMeta, MUIDataTableOptions, SelectableRows } from "mui-datatables";
import ExpandLessOutlinedIcon from "@material-ui/icons/ExpandLessOutlined";
import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";
import { ClientProperties } from "../../../data/clientSpecific/ClientProperties";
import { Maybe, Nullable } from "../../../types/aliases";
import { Data } from "../../../data/data/Data";
import { convertTimestampToString, DateTimeFormatTarget, parseDoorDataValue } from "../../../data/utils/Utils";
import { LatestDataTableDictionary } from "../../../types/utu-types";
import { latestDataTableDictionary, constantSensorDataDictionary } from "../../../data/utu-dictionary-data/UtuDictionaryData";
import { translations } from "../../../generated/translationHelper";
import Device from "../../../data/device/Device";
import LatestData, { LatestDataObserver } from "../../../data/data/LatestData";
import SigmaDCell from "./sigmaD-cell";

interface Props {
  device: Device;
  showOnlyActiveEvents: boolean;
}

interface State {
  open: boolean;
  latestData?: LatestData;
  tableData?: Data;
}

type LatestDataTableCell = Nullable<string | number | boolean | undefined | JSX.Element>;
type LatestDataTableRow = LatestDataTableCell[];

class LatestDataTable extends React.Component<Props, State> implements LatestDataObserver {

  private readonly tableOptions: MUIDataTableOptions = {
    filterType: "checkbox",
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    customToolbarSelect: (_selectedRows: any, _displayData: Array<{ data: any[]; dataIndex: number }>, _setSelectedRows: any): React.ReactNode => {
      // TODO: This is not supported yet.
      return null;
    },
    onRowsDelete: (_rowsDeleted: { lookup: { [dataIndex: number]: boolean }; data: { index: number; dataIndex: number }[] }) => void{ // eslint-disable-line @typescript-eslint/no-explicit-any
      // TODO: This is not supported yet.
    },
    selectableRows: "none" as SelectableRows,
    selectableRowsOnClick: true,
    rowsPerPage: 15,
    rowsPerPageOptions: [15, 100],
    sortOrder: {
      name: "sensorName",
      direction: "asc",
    },
    textLabels: {
      body: {
        noMatch: translations.events.texts.noEventsFound(),
        toolTip: translations.common.texts.sort(),
      },
      pagination: {
        next: translations.common.buttons.nextPage(),
        previous: translations.common.buttons.previousPage(),
        rowsPerPage: translations.common.inputs.rowsPerPage(),
        displayRows: translations.common.texts.of(),
      },
      toolbar: {
        downloadCsv: translations.common.buttons.downloadCsv(),
        filterTable: translations.common.buttons.filterTable(),
        print: translations.common.buttons.print(),
        search: translations.common.buttons.search(),
        viewColumns: translations.common.buttons.viewColumns(),
      },
      filter: {
        reset: translations.common.buttons.reset(),
        title: translations.common.texts.filters().toUpperCase(),
      },
      viewColumns: {
        title: translations.common.texts.showColumns(),
      },
    },
  };

  private readonly tableColumns: MUIDataTableColumnDef[] = [
    {
      name: "sensorName",
      label: ClientProperties.LATEST_DATA_TABLE_LABELS.sensorName,
      options: {
        filter: true,
      },
    },
    {
      name: "reading",
      label: ClientProperties.LATEST_DATA_TABLE_LABELS.reading,
      options: {
        filter: true,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        customBodyRender: (_value: any, tableMeta: MUIDataTableMeta, _updateValue: any): Nullable<string | JSX.Element> => {
          return this.renderCell(tableMeta);
        },
      },
    },
  ];

  public constructor(props: Props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  public async componentDidMount(): Promise<void> {
    const latestData = await this.props.device.getLatestData();

    if (latestData) {
      // getting data from devicesMeasurementsUpdateFeed needs receiver as a parameter to work
      // receiver is set in parent component so no need to have reveicer adding/removing operations here
      latestData.addObserver(this);
      const tableData = latestData.getData();
      this.setState({ latestData });

      if (tableData) {
        this.setState({ tableData });
      }
    }
  }

  public componentWillUnmount(): void {
    this.state.latestData?.removeObserver(this);
  }

  public onDataUpdate(latestData: LatestData): void {
    this.setState({ tableData: latestData.getData() });
  }

  private getTableData(): LatestDataTableRow[] {
    const rows: LatestDataTableRow[] = [];
    const { tableData } = this.state;

    if (tableData) {
      this.getSensorDataObjectsByIdentifiers(this.getIdentifiersFromData(Object.keys(tableData)), tableData).forEach(dataObj => {
        const row: LatestDataTableRow = [];
        row.push(this.getSensorDataDisplayName(Object.keys(dataObj)));
        row.push(this.getSensorDataReadings(dataObj));
        rows.push(row);
      });
    }
    return rows;
  }

  private getIdentifiersFromData(dataKeys: string[]): Partial<LatestDataTableDictionary> {
    const {
      transf_a,
      transf_o,
      door_lv,
      door_mv,
    } = latestDataTableDictionary;

    const dictionaryEntriesToShowOnOwnRows = [transf_a, transf_o, door_lv, door_mv];

    return dataKeys.reduce<Partial<LatestDataTableDictionary>>((acc, curr) => {

      if (Object.values(latestDataTableDictionary).find(({ name }) => curr.startsWith(name))) {
        let identifier;

        if (dictionaryEntriesToShowOnOwnRows.some(({ name }) => name === curr)) {
          identifier = latestDataTableDictionary[curr];
        } else {
          const prefixId = curr.split("_", 1).toString() as keyof LatestDataTableDictionary;
          identifier = latestDataTableDictionary[prefixId];
        }

        if (acc[identifier.name] === undefined) {
          acc[identifier.name] = identifier;
        }
      }
      return acc;
    }, {});
  }

  // palauttaa esim. listan 0: {hfct_max: 7, hfct_avg: 6}, {cab_rh: 50, cab_t: 23}...
  private getSensorDataObjectsByIdentifiers(identifiers: Partial<LatestDataTableDictionary>, data: Data): Partial<Data>[] {
    const ids = Object.values(identifiers)
      .filter(identifier => identifier !== undefined)
      .map(identifier => this.groupSensorDataByIdentifier(data, identifier!.name));
    return ids;
  }

  // palauttaa esim olion {hfct_max: 7, hfct_avg: 6}
  private groupSensorDataByIdentifier(data: Data, identifier: string): Partial<Data> {
    const dataObject: Partial<Data> = {};
    Object.keys(data).forEach(dataKey => {
      if (dataKey.startsWith(identifier)) {
        dataObject[dataKey] = data[dataKey];
      }
    });
    return dataObject;
  }

  private getSensorDataDisplayName(dataKeys: string[]): Nullable<string> {
    const {
      cab,
      transf_a,
      transf_o,
      hfct,
      mic,
      door_lv,
      door_mv,
    } = latestDataTableDictionary;

    if (dataKeys.some(key => key.startsWith(cab.name))) {
      return cab.displayName;
    } else if (dataKeys.some(key => key.startsWith(hfct.name))) {
      return hfct.displayName;
    } else if (dataKeys.some(key => key.startsWith(mic.name))) {
      return mic.displayName;
    } else if (dataKeys.some(key => key.startsWith("sigD"))) {
      return latestDataTableDictionary[`sigD${this.getSigmaDIndex(dataKeys[0])}`].displayName;
    } else if (dataKeys.includes(transf_a.name)) {
      return transf_a.displayName;
    } else if (dataKeys.includes(transf_o.name)) {
      return transf_o.displayName;
    } else if (dataKeys.includes(door_lv.name)) {
      return door_lv.displayName;
    } else if (dataKeys.includes(door_mv.name)) {
      return door_mv.displayName;
    } else {
      return null;
    }
  }

  private getSigmaDIndex(sigmaDeviceName: string): string {
    return sigmaDeviceName.charAt(4);
  }

  private getSensorDataReadings(dataObj: Partial<Data>): LatestDataTableCell {
    const { cab, transf_a, transf_o, hfct, mic } = latestDataTableDictionary;

    const {
      cab_rh,
      cab_t,
      transf_a: tr_a,
      transf_o: tr_o,
      mic_avg,
      hfct_avg,
      door_lv,
      door_mv,
    } = constantSensorDataDictionary;

    const keys = Object.keys(dataObj);

    if (keys.some(key => key.startsWith(cab.name))) {
      return `${dataObj[cab_t.name]} / ${dataObj[cab_rh.name]}`;
    } else if (keys.some(key => key.startsWith(hfct.name))) {
      return dataObj[hfct_avg.name];
    } else if (keys.some(key => key.startsWith(mic.name))) {
      return dataObj[mic_avg.name];
    } else if (keys.some(key => key.startsWith("sigD"))) {
      const index = this.getSigmaDIndex(keys[0]);
      return `${dataObj[`sigD${index}`]}`;
    } else if (keys.includes(transf_a.name)) {
      return dataObj[tr_a.name] ;
    } else if (keys.includes(transf_o.name)) {
      return dataObj[tr_o.name] ;
    } else if (keys.includes(door_lv.name)) {
      return parseDoorDataValue(dataObj[door_lv.name]) ;
    } else if (keys.includes(door_mv.name)) {
      return parseDoorDataValue(dataObj[door_mv.name]) ;
    } else {
      return null;
    }
  }

  // Quick workaround for a bug(?) which seems to occur only with deviceId 98:F4:AB:3E:23:CE: componentDidMount awaits device.getLatestData()
  // but latestData.getData() returns undefined even though data inside latestData should be available synchronously by then...
  private openTableAndGetTableDataIfNeeded(): void {
    if (!this.state.tableData && this.state.latestData) {
      this.setState({ tableData: this.state.latestData.getData() });
    }
    this.setState({ open: true });
  }

  private renderCell(tableMeta: MUIDataTableMeta): Nullable<string | JSX.Element> {
    if (tableMeta == null) {
      return null;
    }
    const rowTitle: string = tableMeta.rowData[0];

    if (rowTitle.startsWith("Sigma D++")) {
      const value: string = tableMeta.rowData[1];
      return <SigmaDCell value={value}/>;
    } else {
      return tableMeta.rowData[1];
    }
  }

  public renderTable(): Maybe<JSX.Element> {
    if (this.state.tableData) {
      return (
        <MUIDataTable
          title={`${ClientProperties.LATEST_DATA_TABLE_TITLE} (${convertTimestampToString(this.state.tableData.timestamp, DateTimeFormatTarget.StatusTable)})`}
          data={this.getTableData()}
          columns={this.tableColumns}
          options={this.tableOptions}
        />
      );
    }
  }

  public render(): JSX.Element {
    const { open } = this.state;
    return (
      <div className="history-events">
        <div className="history-events-header">
          <div className="history-events-header-text">
            {open ? "" : "Viimeisimmät mittaukset"}
          </div>
          <div className="history-events-header-expand">
            {open
              ? <ExpandLessOutlinedIcon
                onClick={(): void => { this.setState({ open: false });}}/>
              : <ExpandMoreOutlinedIcon
                onClick={(): void => this.openTableAndGetTableDataIfNeeded()}
              />}
          </div>
        </div>
        {open && this.renderTable()}
      </div>
    );
  }
}

export default LatestDataTable;

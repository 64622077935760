/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import { Button, TextField } from "@material-ui/core";
import React, { ChangeEvent, Component, Fragment, ReactNode } from "react";
import Loader from "../../ui/loader";
import { Maybe } from "../../../types/aliases";
import AuthWrapper from "../../../data/auth/AuthWrapper";
import { translations } from "../../../generated/translationHelper";
import { isError } from "../../../data/utils/ErrorUtils";

export interface Props {
}

interface State {
  isLoading: boolean;
  error?: string;
  success?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  userName?: string;
}

export default class AttributeFormUtu extends Component<Props, State> {

  public constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: false,
    };
  }

  public componentDidMount(): void {
    this.getUserDetails();
  }

  private async getUserDetails(): Promise<void> {
    this.setState({ isLoading: true });

    Promise.allSettled([
      AuthWrapper.getCurrentAuthenticatedUsername(),
      AuthWrapper.getGivenName(),
      AuthWrapper.getFamilyName(),
      AuthWrapper.getPhoneNumber(),
    ]).then(results => {
      const userName = AttributeFormUtu.getPromiseResult(results[0]);
      const firstName = AttributeFormUtu.getPromiseResult(results[1]);
      const lastName = AttributeFormUtu.getPromiseResult(results[2]);
      const phoneNumber = AttributeFormUtu.getPromiseResult(results[3]);

      this.setState({
        firstName,
        lastName,
        phoneNumber,
        userName,
      });
    });
    this.setState({ isLoading: false });
  }

  private static getPromiseResult<TResult = string>(result: PromiseSettledResult<Maybe<TResult>>, fallbackResult?: TResult): Maybe<TResult> {
    if (result.status === "rejected") {
      console.error("Failed to settle", result.reason);
    } else {
      return result.value ?? fallbackResult;
    }
  }

  private isSaveButtonEnabled(): boolean {
    if (!this.state.firstName?.length && !this.state.lastName?.length && !this.state.phoneNumber?.length) {
      return false;
    } else {
      return true;
    }
  }

  private handleAttributeSubmit = async (): Promise<void> => {
    try {
      const { firstName, lastName, phoneNumber } = this.state;
      this.setState({ isLoading: true });
      await AuthWrapper.setNameAndPhoneNumber(firstName ?? "", lastName ?? "", phoneNumber ?? "");
      this.setSuccessMessage("Tietojen päivittäminen onnistui");
      this.setState({ firstName, lastName, phoneNumber });
    } catch (error) {
      console.error("handleAttributeSubmit", error);
      if (isError(error)) this.handleErrorCode(error.message);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  private handleErrorCode(code?: string): void {
    switch (code) {
      case "Attribute value for given_name must not be null":
      case "Attribute value for family_name must not be null":
        this.setErrorMessage(translations.user.texts.invalidName());
        break;
      case "Invalid phone number format.":
        this.setErrorMessage(translations.user.texts.invalidPhoneNumberFormat());
        break;
      case "Network error":
        this.setErrorMessage(translations.common.texts.networkError());
        break;
      default:
        this.setErrorMessage(translations.common.texts.unableToPerformAction());
        break;
    }
  }

  private setErrorMessage(error?: string): void {
    this.setState({ error });
  }

  private setSuccessMessage(success: string): void {
    this.setState({ success });
    setTimeout(() => {
      this.setState({ success: undefined });
    }, 4000);
  }

  private renderInputs(): JSX.Element {
    const errorAndSuccessReset: Pick<State, "success" | "error"> = { success: undefined, error: undefined };

    return (
      <div className="utu-user-form">
        <TextField
          className="utu-user-text-field"
          label={translations.user.inputs.firstname()}
          variant="outlined"
          size="small"
          inputProps={{ "data-testid": "first-name-field" }}
          value={this.state.firstName ?? ""}
          onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>): void =>
            this.setState({ firstName: event.target.value, ...errorAndSuccessReset })}
        />
        <TextField
          className="utu-user-text-field"
          label={translations.user.inputs.lastname()}
          variant="outlined"
          size="small"
          inputProps={{ "data-testid": "last-name-field" }}
          value={this.state.lastName ?? ""}
          onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>): void =>
            this.setState({ lastName: event.target.value, ...errorAndSuccessReset })}
        />
        <TextField
          className="utu-user-text-field"
          label={translations.user.inputs.phonenumber()}
          size="small"
          variant="outlined"
          value={this.state.phoneNumber ?? ""}
          onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>): void =>
            this.setState({ phoneNumber: event.target.value, ...errorAndSuccessReset })}
        />
        <Button
          disabled={!this.isSaveButtonEnabled()}
          variant="contained"
          color="primary"
          onClick={this.handleAttributeSubmit}
        >
          {translations.common.buttons.save()}
        </Button>
      </div>
    );
  }

  private renderUserAttributeSubmitMessage(): ReactNode {
    let message;

    if (this.state.error) {
      message = translations.common.texts.errorOccurred({ error: this.state.error });
    } else if (this.state.success){
      message = this.state.success;
    }
    return (
      <span data-testid={this.state.error ? "user-attributes-error" : undefined}>{message}</span>
    );
  }

  private renderLoader = (): Maybe<JSX.Element> => {
    if (this.state.isLoading) {
      return <Loader topBottomPadding="0"/>;
    }
  };

  public render(): JSX.Element {
    return (
      <Fragment>
        <p className="utu-username-display">
          <span style={{ fontWeight: "bold" }}>Käyttäjätunnus: </span> {this.state.userName}
        </p>
        <h3 style={{ textAlign: "center" }}>
          Muokkaa käyttäjätietoja
        </h3>
        {this.renderInputs()}
        <div className="utu-user-loader-container">
          {this.renderLoader()}
          {this.renderUserAttributeSubmitMessage()}
        </div>
      </Fragment>
    );
  }
}

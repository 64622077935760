/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import BaseObservable from "../../observer/BaseObservable";
import { ReceiverObserver } from "../ReceiverObserver";
import AuthListener, { AuthEvent } from "../../auth/AuthListener";
import AuthWrapper from "../../auth/AuthWrapper";

export type ReceiverReplacement = {toRemove?: string[]; toAdd?: string[]};

export default class ReceiverManager extends BaseObservable<ReceiverObserver> {
  private static __instance: ReceiverManager;

  private readonly authListener: AuthListener;
  private standardReceivers: string[] = [];
  private customReceivers = new Set<string>();

  private constructor() {
    super();
    this.authListener = new AuthListener(this.authHandler, true);
  }

  public static get instance(): ReceiverManager {
    if (!ReceiverManager.__instance) {
      ReceiverManager.__instance = new ReceiverManager();
    }
    return ReceiverManager.__instance;
  }

  // override
  public addObserver(observer: ReceiverObserver): void {
    super.addObserver(observer);
    observer.onReceiversChanged(this.getFullReceiverList());
  }

  public addReceivers(...receivers: string[]): void {
    const notify = receivers
      .reduce((result, receiver) => result || this.addCustomReceiver(receiver), false);
    if (notify) this.notifyReceiversChanged();
  }

  public removeReceivers(...receivers: string[]): void {
    const notify = receivers
      .reduce((result, receiver) => result || this.removeCustomReceiver(receiver), false);
    if (notify) this.notifyReceiversChanged();
  }

  public replaceReceivers({ toRemove = [], toAdd = [] }: ReceiverReplacement): void {
    // remove replicated receivers - no need to remove and add them back again
    const add = toAdd.filter(a => !toRemove.includes(a));
    const remove = toRemove.filter(a => !toAdd.includes(a));

    // the order of the OR statements are important due to short-circuit evaluation
    let notify = add.reduce((result, receiver) => this.addCustomReceiver(receiver) || result, false);
    notify = remove.reduce((result, receiver) => this.removeCustomReceiver(receiver) || result, notify);

    if (notify) this.notifyReceiversChanged();
  }

  private addCustomReceiver(receiver: string): boolean {
    if (this.customReceivers.has(receiver)) return false;
    this.customReceivers.add(receiver);
    return true;
  }

  private removeCustomReceiver(receiver: string): boolean {
    return this.customReceivers.delete(receiver);
  }

  private notifyReceiversChanged(): void {
    const receivers = this.getFullReceiverList();
    console.log("Notifying receiver list change to " + receivers.toString());
    this.notifyAction(observer => observer.onReceiversChanged(receivers));
  }

  private authHandler = async (event: AuthEvent): Promise<void> => {
    if (event === "SignedIn") {
      this.standardReceivers = await ReceiverManager.getStandardReceivers();
      this.notifyReceiversChanged();
    } else if (event === "SignedOut") {
      this.standardReceivers = [];
      this.customReceivers = new Set<string>();
      this.notifyReceiversChanged();
    }
  };

  private getFullReceiverList(): string[] {
    return [...this.standardReceivers, ...this.customReceivers];
  }

  private static async getStandardReceivers(): Promise<string[]> {
    const userId = (await AuthWrapper.getCurrentAuthenticatedUserClaims())?.userId;
    if (!userId) console.error("Failed to resolved receiver 'userId'");
    return userId ? [userId.replace("USER/", "")] : [];
  }

  /* use this version, if you want to include user's organizations in the standard receivers
  private static async getStandardReceivers(): Promise<string[]> {
    return (await AuthWrapper.getCurrentAuthenticatedUserClaims())?.canSee ?? [];
  }
  */
}

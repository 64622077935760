/* Copyright */
import React, { Component } from "react";
import { Button, Dialog, Typography, Box } from "@material-ui/core";
import { v4 as uuid } from "uuid";
import { Maybe, Nullable } from "../../../../types/aliases";
import { EventConfig, EventTriggerDbEntry } from "../../../../data/types/eventTypes";
import TriggerPropertySelector from "./trigger-property-selector";
import Loader from "../../../ui/loader";
import { validateNumber, validatePartialNumber } from "../../../../utils/validation";
import { BackendActionStatus } from "../../../../types/utu-types";
import { sensorTypeDisplayNameDictionary, triggerOperatorDictionary, constantSensorDataDictionary } from "../../../../data/utu-dictionary-data/UtuDictionaryData";
import { EventSeverity, EventType } from "../../../../data/clientSpecific/Event";
import { hasKey } from "../../../../utils/functions";

interface Props {
  cancel: () => void;
  open: boolean;
  save: (eventConfig: Maybe<EventConfig>, isNewTrigger: boolean) => Promise<void>;
  selectedEventTrigger: Nullable<EventTriggerDbEntry>;
  backendActionLoading: boolean;
  backendActionStatus: Nullable<BackendActionStatus>;
}

interface State {
  compareValue: string;
  eventName: string;
  operatorDisplayName: string;
  selectedSensorDataTypeDisplayName: string;
  selectedSensorTypeDisplayName: string; // used only on UI to group data types -> is not sent to backend
}

class EventTriggerPopup extends Component<Props, State> {

  public constructor(props: Props) {
    super(props);
    this.state = this.getInitialState();
  }

  private renderContent(): JSX.Element {
    if (this.props.backendActionLoading) {
      return <Loader/>;
    } else if (this.props.backendActionStatus !== null) {
      const message = this.props.backendActionStatus === BackendActionStatus.SUCCESS
        ? "Hälytyskonfiguraatio on nyt tallennettu"
        : "Hälytyskonfiguraatio tallentaminen epäonnistui";
      return <Typography align="center">{message}</Typography>;
    } else {
      return this.renderSelectorList();
    }
  }

  private renderSelectorList(): JSX.Element {
    return (
      <>
        <TriggerPropertySelector
          currentValue={this.state.selectedSensorTypeDisplayName}
          label="Sensori"
          updateValue={(value: string): void => this.handleSelectSensorType(value)}
          values={Object.values(sensorTypeDisplayNameDictionary).map(({ displayName }) => displayName)}
        />
        <TriggerPropertySelector
          currentValue={this.state.selectedSensorDataTypeDisplayName}
          label="Mittaustyyppi"
          updateValue={(value: string): void => this.setState({ selectedSensorDataTypeDisplayName: value })}
          values={this.state.selectedSensorTypeDisplayName !== "" ? this.getSensorTypeSpecificDataTypes() : null}
          disabled={this.state.selectedSensorTypeDisplayName === ""}
        />
        <TriggerPropertySelector
          currentValue={this.state.operatorDisplayName}
          label="Operaattori"
          updateValue={(value: string): void => this.setState({ operatorDisplayName: value })}
          values={Object.values(triggerOperatorDictionary).map(({ displayName }) => displayName)}
          disabled={this.state.selectedSensorDataTypeDisplayName === ""}
        />
        <TriggerPropertySelector
          currentValue={this.state.compareValue}
          label="Vertailuarvo"
          error={!this.validateCompareValue()}
          updateValue={
            (value: string): void => this.setState({ compareValue: validatePartialNumber(value) ? value : "" })}
          values={null}
        />
        <TriggerPropertySelector
          currentValue={this.state.eventName}
          label="Hälytyksen nimi"
          updateValue={(value: string): void => this.setState({ eventName: value })}
          values={null}
        />
      </>
    );
  }

  private renderButtons(): Maybe<JSX.Element> {
    if (this.props.backendActionStatus !== null) {
      return (
        <Button
          size="small"
          variant="contained"
          onClick={(): void => this.props.cancel()}
          color="primary"
        >
        Ok
        </Button>
      );
    } else if (!this.props.backendActionLoading) {
      return (
        <Box display="flex" justifyContent="center">
          <Button
            size="small"
            variant="contained"
            disabled={!this.validateFields()}
            onClick={(): Promise<void> => this.save()}
            color="primary"
            style={{ marginRight: "1rem" }}
          >
            Tallenna
          </Button>
          <Button
            size="small"
            variant="contained"
            onClick={(): void => this.props.cancel()}
            color="primary"
          >
            Peruuta
          </Button>
        </Box>
      );
    }
  }

  public render(): JSX.Element {
    return (
      <Dialog open={this.props.open}>
        <Box p="1rem">
          <Typography variant="h6" align="center">
            {this.props.selectedEventTrigger
              ? "Muokkaa hälytyskonfiguraatiota"
              : "Uusi hälytyskonfiguraatio"}
          </Typography>
          <Box width="18rem" mt="1rem">
            <Box height="19rem" display="flex" flexDirection="column" justifyContent="center">
              {this.renderContent()}
            </Box>
            <Box height="2rem" display="flex" justifyContent="center">
              {this.renderButtons()}
            </Box>
          </Box>
        </Box>
      </Dialog>
    );
  }

  private handleSelectSensorType(value: string): void {
    this.setState({
      selectedSensorTypeDisplayName: value,
      selectedSensorDataTypeDisplayName: "",
    });
  }

  private getInitialState(): Readonly<State> {
    const { selectedEventTrigger } = this.props;

    if (selectedEventTrigger) {
      return {
        compareValue: (selectedEventTrigger.rules[0].conditions.any[0].value as number).toString(),
        eventName: selectedEventTrigger.rules[0].name as string,
        operatorDisplayName: this.getOperatorDisplayName(selectedEventTrigger.rules[0].conditions.any[0].operator as string),
        selectedSensorDataTypeDisplayName: this.getSensorDisplayName(selectedEventTrigger.rules[0].conditions.any[0].fact as string),
        selectedSensorTypeDisplayName: this.getSensorTypeByDataType(selectedEventTrigger.rules[0].conditions.any[0].fact as string),
      };
    } else {
      return {
        compareValue: "",
        eventName: "",
        operatorDisplayName: "",
        selectedSensorDataTypeDisplayName: "",
        selectedSensorTypeDisplayName: "",
      };
    }
  }

  public async save(): Promise<void> {
    const newConfig = this.props.selectedEventTrigger === null;
    const eventConfig = this.createEventConfig(newConfig);
    await this.props.save(eventConfig, newConfig);
  }

  private createEventConfig(newConfig: boolean): Maybe<EventConfig> {
    // assertion: this.props.selectedEventTrigger !== null if newConfig === false
    if (!newConfig && !hasKey(this.props.selectedEventTrigger!.rules[0].event.params, "eventId")) {
      console.error("Trigger to edit is not valid");
      return;
    } else {
      const partialTrigger: Partial<EventTriggerDbEntry> = {
        rules: [{
          conditions: {
            any: [{
              fact: this.getSensorNameByDisplayName(),
              value: parseFloat(this.state.compareValue),
              operator: this.getOperatorByDisplayName(),
            }],
          },
          event: {
            params: {
              sensorName: this.getSensorNameByDisplayName(),
              severity: EventSeverity.High,
            },
            type: EventType.Sensor,
          },
          name: this.state.eventName,
        }],
      };

      // assertion: this.props.selectedEventTrigger !== null if newConfig === false
      if (!newConfig && hasKey(this.props.selectedEventTrigger!.rules[0].event.params, "eventId")) {
        partialTrigger.triggerId = this.props.selectedEventTrigger!.triggerId;
        partialTrigger.rules![0].event.params!.eventId = this.props.selectedEventTrigger!.rules[0].event.params.eventId;
      } else if (newConfig) {
        partialTrigger.triggerId = uuid();
        // assertion: params is guaranteed to be defined here
        partialTrigger.rules![0].event.params!.eventId = uuid();
      }

      return {
        // assertion: partialTrigger has required properties here
        trigger: partialTrigger as EventTriggerDbEntry,
        metadata: {
          eventId: (partialTrigger as EventTriggerDbEntry).rules[0].event.params!.eventId as string,
          description: (partialTrigger as EventTriggerDbEntry).rules[0].name,
        },
      };
    }
  }

  private getSensorTypeSpecificDataTypes(): string[] {
    // sensorTypeDisplayNameKey on esim "mic", jos this.state.selectedSensorType on "Ultraäänimikrofoni"
    const sensorTypeDisplayNameKey = Object.keys(sensorTypeDisplayNameDictionary).find(key => {
      return sensorTypeDisplayNameDictionary[key].displayName === this.state.selectedSensorTypeDisplayName;
    });

    // dataTypes on esim ["mic_avg", "mic_max"], jos sensorTypeDisplayNameKey on "mic"
    const dataTypes = Object.keys(constantSensorDataDictionary).filter(key => key.startsWith(sensorTypeDisplayNameKey!));
    // acc on esim ["Ultraäänimikrofoni", "Ultraäänimikrofoni maksimi"] jos dataTypes on ["mic_avg", "mic_max"]
    return dataTypes.reduce<string[]>((acc, curr) => {
      if (constantSensorDataDictionary[curr] !== undefined) acc.push(constantSensorDataDictionary[curr].displayName);
      return acc;
    }, []);
  }

  private getSensorTypeByDataType(dataType: string): string {
    let sensorType = "";

    for (const key in sensorTypeDisplayNameDictionary) {
      if (dataType.startsWith(key)) sensorType = sensorTypeDisplayNameDictionary[key].displayName;
    }

    if (sensorType === "") {
      console.error("Unknown parameter 'dataType' in EventTriggerPopup.getSensorTypeByDataType");
    }
    return sensorType;
  }

  private getOperatorByDisplayName(): string {
    let operator = "";

    for (const key in triggerOperatorDictionary) {
      if (triggerOperatorDictionary[key].displayName === this.state.operatorDisplayName) operator = key;
    }

    if (operator === "") {
      console.error(`Unknown value ${this.state.operatorDisplayName} in EventTriggerPopup.getOperatorByDisplayName`);
    }
    return operator;
  }

  private getOperatorDisplayName(operator: string): string {
    let displayName = "";

    for (const key in triggerOperatorDictionary) {
      if (key === operator) displayName = triggerOperatorDictionary[key].displayName;
    }

    if (displayName === "") {
      console.error("Unknown parameter 'operator' in EventTriggerPopup.getOperatorDisplayName");
    }
    return displayName;
  }

  private getSensorNameByDisplayName(): string {
    let sensorName = "";

    for (const key in constantSensorDataDictionary) {
      if (constantSensorDataDictionary[key].displayName === this.state.selectedSensorDataTypeDisplayName) sensorName = constantSensorDataDictionary[key].name;
    }

    if (sensorName === "") {
      console.error(`Unknown value ${this.state.selectedSensorDataTypeDisplayName} in EventTriggerPopup.getSensorNameByDisplayName`);
    }
    return sensorName!;
  }

  private getSensorDisplayName(sensorName: string): string {
    let sensorDisplayName = "";

    for (const key in constantSensorDataDictionary) {
      if (constantSensorDataDictionary[key].name === sensorName) sensorDisplayName = constantSensorDataDictionary[key].displayName;
    }

    if (sensorDisplayName === "") {
      console.error("Unknown parameter 'sensorName' in EventTriggerPopup.sensorDisplayName");
    }
    return sensorDisplayName;
  }

  private validateFields(): boolean {
    let check = true;

    if (this.state.compareValue === "" || !this.validateCompareValue()) {
      check = false;
    } else if (check && this.state.eventName === "") {
      check = false;
    } else if (check && this.state.operatorDisplayName === "") {
      check = false;
    } else if (check && this.state.selectedSensorDataTypeDisplayName === "") {
      check = false;
    } else if (check && this.state.selectedSensorTypeDisplayName === "") {
      check = false;
    }
    return check;
  }

  private validateCompareValue(): boolean {
    let check = true;

    if (validateNumber(this.state.compareValue) === null && this.state.compareValue !== "") { // allow empty string as initial value
      check = false;
    } else if (check && parseFloat(this.state.compareValue) < 5) {
      check = false;
    }
    return check;
  }
}

export default EventTriggerPopup;

/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

type TimePeriod = {
  endTimestamp: number;
  startTimestamp: number;
};


export function isTimePeriod(value: unknown): value is TimePeriod {
  return !!(value as Partial<TimePeriod>).startTimestamp && !!(value as Partial<TimePeriod>).endTimestamp;
}

export function timePeriodIsEmpty(timePeriod: Partial<TimePeriod>): boolean {
  return timePeriod.startTimestamp == null && timePeriod.endTimestamp == null;
}

export function timePeriodIsValid(timePeriod: Partial<TimePeriod>): timePeriod is TimePeriod {
  const start = timePeriod.startTimestamp, end = timePeriod.endTimestamp;
  return !!start && !!end && start <= end;
}

export default TimePeriod;

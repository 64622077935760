/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import React, { Component } from "react";
import Device from "../../../data/device/Device";
import { getMapLocation } from "../../../data/utils/Utils";
import { MapLocation } from "../../../types/utu-types";
import HistoryMap from "./history-map";
import HistoryWeather from "./history-weather";

interface Props {
  device: Device;
}

interface State {
  deviceLocation?: MapLocation;
}

class LocationSection extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {};
  }

  public async componentDidMount(): Promise<void> {
    const deviceLocation = await getMapLocation(this.props.device);

    if (deviceLocation) {
      this.setState({ deviceLocation });
    } else {
      console.error("Location was not found for device: ", this.props.device.getId());
    }
  }

  public render(): JSX.Element {
    const { device } = this.props;
    const { deviceLocation } = this.state;
    return (
      <div className="map-container">
        <HistoryMap
          markerLocation={deviceLocation}
          device={device}
        />
        {deviceLocation && <HistoryWeather device={device} location={deviceLocation}/>}
      </div>
    );
  }
}

export default LocationSection;

/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { Nullable } from "../../types/aliases";

export class DeviceStateProperties {
  public readonly deviceId: Nullable<string>;
  public displayName: Nullable<string>;
  public otaId: Nullable<string>;

  public constructor(deviceProperties: Partial<DeviceStateProperties>) {
    this.deviceId = deviceProperties.deviceId ?? null;
    this.displayName = deviceProperties.displayName ?? null;
    this.otaId = deviceProperties.otaId ?? null;
  }
}

/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import AuthListener, { AuthEvent } from "./data/auth/AuthListener";
import BackendFactory from "./data/BackendFactory";
import AuthWrapper from "./data/auth/AuthWrapper";
import { ROOT_DEVICE_GROUP_ORGANIZATION_ID } from "./data/utils/Utils";

export default class ViewAccessMethods {
  private static adminAccess: boolean | undefined;
  private static rootGroupSuperAdminAccess: boolean | undefined;

  private static authCallback = (event: AuthEvent): void => {
    if (event === "SignedOut") {
      ViewAccessMethods.adminAccess = undefined;
      ViewAccessMethods.rootGroupSuperAdminAccess = undefined;
    }
  };

  private static authListener = new AuthListener(ViewAccessMethods.authCallback);

  public static async hasAdminAccess(): Promise<boolean> {
    if (ViewAccessMethods.adminAccess != null) {
      return ViewAccessMethods.adminAccess;
    }
    const organizationBackend = BackendFactory.getOrganizationBackend();

    try {
      const user = await organizationBackend.getCurrentUser();

      if (!user) {
        return false;
      }

      // TODO:  These need to match the PolicyGroups granted by the service - not a very dynamic approach!
      //        Currently these are controlled by users-service's domain-specific hook code
      const hasAdminGroup = await user.hasPolicyGroupNamed("Admin");
      const hasSuperAdmins = await user.hasPolicyGroupNamed("SuperAdmin");

      const claims = await AuthWrapper.getCurrentAuthenticatedUserClaims();

      const hasAllPass = claims?.grants[claims.homeOrganizationId].includes("*") ?? false;

      ViewAccessMethods.adminAccess = hasAdminGroup || hasSuperAdmins || hasAllPass;
    } catch (err) {
      console.error("Failed to check for admin rights", err);
      // user did not have the access rights to retrieve user grants (hasPolicyGroupNamed)
      ViewAccessMethods.adminAccess = false;
    }

    ViewAccessMethods.logPermission("Admin*", ViewAccessMethods.adminAccess);
    return ViewAccessMethods.adminAccess;
  }

  // Sensoan addition
  public static async hasRootGroupSuperAdminAccess(): Promise<boolean> {
    if (ViewAccessMethods.rootGroupSuperAdminAccess != null) {
      return ViewAccessMethods.rootGroupSuperAdminAccess;
    }
    const organizationBackend = BackendFactory.getOrganizationBackend();

    try {
      const user = await organizationBackend.getCurrentUser();

      if (!user) {
        return false;
      }
      const isRootGroupUser = user.getHomeOrganizationId() === ROOT_DEVICE_GROUP_ORGANIZATION_ID;

      const hasSuperAdmins = await user.hasPolicyGroupNamed("SuperAdmin");

      const claims = await AuthWrapper.getCurrentAuthenticatedUserClaims();

      const hasAllPass = claims?.grants[claims.homeOrganizationId].includes("*") ?? false;

      ViewAccessMethods.rootGroupSuperAdminAccess = (hasSuperAdmins && isRootGroupUser) || (hasAllPass && isRootGroupUser);

    } catch (err) {
      console.error("Failed to check for admin rights", err);
      // user did not have the access rights to retrieve user grants (hasPolicyGroupNamed)
      ViewAccessMethods.rootGroupSuperAdminAccess = false;
    }

    ViewAccessMethods.logPermission("RootGroupSuperAdminAccess*", ViewAccessMethods.rootGroupSuperAdminAccess);
    return ViewAccessMethods.rootGroupSuperAdminAccess;
  }

  private static logPermission(name: string, access: boolean): void{
    console.log(`User ${access ? "has" : "does not have"} ${name} access`);
  }
}

/* Copyright */
import {
  AddOnConfigSelectionDictionary,
  ConstantSensorDataDictionary,
  HistoryChartDictionary,
  LatestDataTableDictionary,
  SensorTypeDisplayNameDictionary,
  TriggerOperatorDictionary,
  DoorSensorDataValueDictionary,
  OrganizationLevelDictionary,
} from "../../types/utu-types";

const constantSensorDataDictionary: ConstantSensorDataDictionary = {
  cab_t: {
    name: "cab_t",
    displayName: "Muuntamon lämpötila",
  },
  cab_rh: {
    name: "cab_rh",
    displayName: "Muuntamon kosteus",
  },
  transf_a: {
    name: "transf_a",
    displayName: "Muuntajatilan lämpötila",
  },
  transf_o: {
    name: "transf_o",
    displayName: "Muuntajan lämpötila",
  },
  hfct_avg: {
    name: "hfct_avg",
    displayName: "Osittaispurkaus (virta-anturi)",
  },
  hfct_max: {
    name: "hfct_max",
    displayName: "Osittaispurkaus (virta-anturi) maksimi",
  },
  mic_avg: {
    name: "mic_avg",
    displayName: "Osittaispurkaus (ultraääni)",
  },
  mic_max: {
    name: "mic_max",
    displayName: "Osittaispurkaus (ultraääni) maksimi",
  },
  door_lv: {
    name: "door_lv",
    displayName: "Ovivalvonta (pienjännitepuoli)",
  },
  door_mv: {
    name: "door_mv",
    displayName: "Ovivalvonta (keskijännitepuoli)",
  },
};

const addOnConfigSelectionDictionary: AddOnConfigSelectionDictionary = {
  cab: {
    name: "cab",
    displayName: "Muuntamon kosteus- ja lämpötilamittaus (dhs-anturi)",
  },
  transf: {
    name: "transf",
    displayName: "Muuntajan ja muuntajatilan lämpötilamittaus (fir-anturi)",
  },
  hfct: {
    name: "hfct",
    displayName: "Muuntamon osittaispurkausmittaus (virta-anturi)",
  },
  mic: {
    name: "mic",
    displayName: "Muuntamon osittaispurkausmittaus (ultraääni)",
  },
  door_lv: {
    displayName: "Ovivalvonta (pienjännitepuoli)",
    name: "door_lv",
  },
  door_mv: {
    displayName: "Ovivalvonta (keskijännitepuoli)",
    name: "door_mv",
  },
  sigD1: {
    name: "sigD1",
    displayName: "Kaapelivian valvonta (SigmaD) 1",
  },
  sigD2: {
    name: "sigD2",
    displayName: "Kaapelivian valvonta (SigmaD) 2",
  },
  sigD3: {
    name: "sigD3",
    displayName: "Kaapelivian valvonta (SigmaD) 3",
  },
  sigD4: {
    name: "sigD4",
    displayName: "Kaapelivian valvonta (SigmaD) 4",
  },
  sigD5: {
    name: "sigD5",
    displayName: "Kaapelivian valvonta (SigmaD) 5",
  },
};

const sensorTypeDisplayNameDictionary: SensorTypeDisplayNameDictionary = {
  cab: {
    name: "cab",
    displayName: "Olosuhdeanturi",
  },
  transf: {
    name: "transf",
    displayName: "IR-anturi",
  },
  mic: {
    name: "mic",
    displayName: "Ultraääni",
  },
  hfct: {
    name: "hfct",
    displayName: "Virta-anturi",
  },
};

const triggerOperatorDictionary: TriggerOperatorDictionary = {
  lessThan: {
    name: "lessThan",
    displayName: "Pienempi kuin",
  },
  greaterThan: {
    name: "greaterThan",
    displayName: "Suurempi kuin",
  },
};

const latestDataTableDictionary: LatestDataTableDictionary = {
  cab: {
    name: "cab",
    displayName: "Muuntamon lämpötila / kosteus",
  },
  transf_a: {
    name: "transf_a",
    displayName: "Muuntajatilan lämpötila",
  },
  transf_o: {
    name: "transf_o",
    displayName: "Muuntajan lämpötila",
  },
  mic: {
    name: "mic",
    displayName: "Osittaispurkaus (ultraääni)",
  },
  hfct: {
    name: "hfct",
    displayName: "Osittaispurkaus (virta-anturi)",
  },
  sigD1: {
    name: "sigD1",
    displayName: "Sigma D++ 1 oikosulku / maasulku",
  },
  sigD2: {
    name: "sigD2",
    displayName: "Sigma D++ 2 oikosulku / maasulku",
  },
  sigD3: {
    name: "sigD3",
    displayName: "Sigma D++ 3 oikosulku / maasulku",
  },
  sigD4: {
    name: "sigD4",
    displayName: "Sigma D++ 4 oikosulku / maasulku",
  },
  sigD5: {
    name: "sigD5",
    displayName: "Sigma D++ 5 oikosulku / maasulku",
  },
  door_lv: {
    name: "door_lv",
    displayName: "Ovikytkimet (pienjännitepuoli)",
  },
  door_mv: {
    name: "door_mv",
    displayName: "Ovikytkimet (keskijännitepuoli)",
  },
};

const historyChartDictionary: HistoryChartDictionary = {
  cab: {
    name: "cab",
    displayName: "Muuntamo",
    sensorDataNames: ["cab_t", "cab_rh"],
  },
  transf: {
    name: "transf",
    displayName: "Muuntaja ja muuntajatila",
    sensorDataNames: ["transf_a", "transf_o"],
  },
  mic: {
    name: "mic",
    displayName: "Osittaispurkaus (ultraääni)",
    sensorDataNames: ["mic_avg", "mic_max"],
  },
  hfct: {
    name: "hfct",
    displayName: "Osittaispurkaus (virta-anturi)",
    sensorDataNames: ["hfct_avg", "hfct_max"],
  },
};

const doorSensorDataValueDictionary: DoorSensorDataValueDictionary = {
  0: {
    name: 0,
    displayName: "Kiinni",
  },
  1: {
    name: 1,
    displayName: "Auki",
  },
};

const organizationLevelDictionary: OrganizationLevelDictionary = {
  0: {
    level: 0,
    name: "root",
  },
  1: {
    level: 1,
    name: "powerCompany",
  },
  2: {
    level: 2,
    name: "powerCompanyRegion",
  },
};

export {
  constantSensorDataDictionary,
  addOnConfigSelectionDictionary,
  sensorTypeDisplayNameDictionary,
  triggerOperatorDictionary,
  latestDataTableDictionary,
  historyChartDictionary,
  doorSensorDataValueDictionary,
  organizationLevelDictionary,
};

/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import AWSBackend from "./backend/AWSBackend";
import Backend from "./backend/Backend";
import OrganizationBackend from "./organization/OrganizationBackend";
import { AWSOrganizationBackend } from "./organization/AWSOrganizationBackend";
import AWSDeviceFactory from "./device/AWSDeviceFactory";
import NotificationBackend from "./events/NotificationBackend";
import AWSNotificationBackend from "./events/AWSNotificationBackend";

export default class BackendFactory {
  private static genericBackend: Backend;
  private static organizationBackend: OrganizationBackend;
  private static notificationBackend: NotificationBackend;

  public static getBackend(): Backend {
    if (!BackendFactory.genericBackend) {
      BackendFactory.genericBackend = new AWSBackend(new AWSDeviceFactory());
    }
    return BackendFactory.genericBackend;
  }

  public static getOrganizationBackend(): OrganizationBackend {
    if (!BackendFactory.organizationBackend) {
      BackendFactory.organizationBackend = new AWSOrganizationBackend();
    }
    return BackendFactory.organizationBackend;
  }

  public static getNotificationBackend(): NotificationBackend {
    if (!BackendFactory.notificationBackend) {
      BackendFactory.notificationBackend = new AWSNotificationBackend();
    }
    return BackendFactory.notificationBackend;
  }
}

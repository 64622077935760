/* Copyright */
import React from "react";
import { Box, IconButton, Tooltip } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import SettingsIcon from "@material-ui/icons/Settings";
import Device from "../../../data/device/Device";
import logo from "../../../assets/utu-logo-white.png";
import { Maybe, Nullable } from "../../../types/aliases";
import accessControlled from "../../access-control/access-controlled";
import ViewAccessMethods from "../../../ViewAccessMethods";
import DeviceMetadataDialog from "./device-metadata-dialog";
import DeviceAddOnConfigDialog from "./device-add-on-config-dialog";
import { parseAddOnConf, getDeviceStateAsHyper, stringifyAddOnConf, stateIsHyper, TOOLTIP_DELAY_MS } from "../../../data/utils/Utils";
import Loader from "../../ui/loader";
import { BackendActionStatus, DeviceMetadata, MeasurementBoardConfig } from "../../../types/utu-types";
import { addOnConfigSelectionDictionary } from "../../../data/utu-dictionary-data/UtuDictionaryData";

interface Props {
  selectedDevice: Maybe<Device>;
  triggerDataSetUpdate: () => void;
}

interface State {
  addonConfigDialogOpen: boolean;
  deviceMetadata: Nullable<DeviceMetadata>;
  dialogActionLoading: boolean;
  dialogActionStatus: Nullable<BackendActionStatus>;
  metadataFetchLoading: boolean;
  metadataDialogOpen: boolean;
}

const ACIconButtonContainer = accessControlled(Box, ViewAccessMethods.hasAdminAccess);

class ThingMetadata extends React.Component <Props, State> {

  public constructor(props: Props) {
    super(props);
    this.state = {
      addonConfigDialogOpen: false,
      deviceMetadata: null,
      dialogActionLoading: false,
      dialogActionStatus: null,
      metadataFetchLoading: false,
      metadataDialogOpen: false,
    };
  }

  public async componentDidMount(): Promise<void> {
    if (this.props.selectedDevice) {
      this.setState({ metadataFetchLoading: true });
      const deviceMetadata = await this.props.selectedDevice.getMetadata();
      this.setState({ deviceMetadata });
      this.setState({ metadataFetchLoading: false });
    }
  }

  public async componentDidUpdate(prevProps: Props, prevState: State): Promise<void> {
    if (this.props.selectedDevice && !prevProps.selectedDevice) {
      this.setState({ metadataFetchLoading: true });
      const deviceMetadata = await this.props.selectedDevice.getMetadata();
      this.setState({ deviceMetadata });
      this.setState({ metadataFetchLoading: false });
    }

    if ((!this.state.addonConfigDialogOpen && prevState.addonConfigDialogOpen) ||
      (!this.state.metadataDialogOpen && prevState.metadataDialogOpen)) {
      this.setState({ dialogActionLoading: false, dialogActionStatus: null });
    }

  }

  private renderDeviceMetaDataDialog(): Maybe<JSX.Element> {
    if (!this.props.selectedDevice || !this.state.metadataDialogOpen) {
      return;
    } else {
      return (
        <DeviceMetadataDialog
          actionStatus={this.state.dialogActionStatus}
          close={(): void => this.setState({ metadataDialogOpen: false })}
          deviceId={this.props.selectedDevice.getId()}
          loading={this.state.dialogActionLoading}
          metadata={this.state.deviceMetadata}
          open={this.state.metadataDialogOpen}
          save={this.saveDeviceMetadata}
        />
      );
    }
  }

  private renderAddonConfigDialog(): Maybe<JSX.Element> {
    if (!this.props.selectedDevice || !this.state.addonConfigDialogOpen) {
      return;
    } else {
      return (
        <DeviceAddOnConfigDialog
          actionStatus={this.state.dialogActionStatus}
          addOnConfig={this.getAddOnConfig()}
          close={(): void => this.setState({ addonConfigDialogOpen: false })}
          loading={this.state.dialogActionLoading}
          open={this.state.addonConfigDialogOpen}
          save={this.saveDeviceAddonConfig}
        />
      );
    }
  }

  public render(): JSX.Element {
    return (
      <div className="thing-view">
        <div className="thing-logo">
          <img className="thing-image" src={logo} alt="logo" />
        </div>
        <div className="thing-header-and-data">
          <div className="thing-header" key="header">
            Muuntamon kunnonvalvonta
          </div>
          <div className="thing-data">
            {this.state.metadataFetchLoading
              ? <Loader/>
              :
              this.renderMetadataList()}
          </div>
        </div>
        {this.props.selectedDevice &&
          <ACIconButtonContainer showAccessError={false} position="absolute" height="2rem" right={0} bottom="16px" zIndex={1}>
            <Tooltip
              arrow
              placement="top"
              title="Muokkaa muuntamon tietoja"
              enterDelay={TOOLTIP_DELAY_MS}
              id="edit-transformer-information-tooltip"
            >
              <IconButton onClick={(): void => this.setState({ metadataDialogOpen: true })} >
                <CreateIcon />
              </IconButton>
            </Tooltip>
            <Tooltip
              arrow
              placement="top"
              title="Muokkaa laitteen konfiguraatiota"
              enterDelay={TOOLTIP_DELAY_MS}
              id="edit-device-configuration-tooltip"
            >
              <IconButton onClick={(): void => this.setState({ addonConfigDialogOpen: true })}>
                <SettingsIcon />
              </IconButton>
            </Tooltip>
          </ACIconButtonContainer>}
        {this.renderDeviceMetaDataDialog()}
        {this.renderAddonConfigDialog()}
      </div>
    );
  }

  private renderMetadataList(): Maybe<JSX.Element> {
    const { deviceMetadata } = this.state;

    // if deviceMetadata is defined it will always have at least deviceId defined
    // render list only if deviceMetadata has also a valid value for at least one other property
    if (deviceMetadata && Object.values(deviceMetadata).filter(v => (v !== null && v !== "")).length > 1) {
      const {
        asiakas: a,
        muuntamonNimi: mN,
        muuntamonNumero: mNu,
        toimituspvm: tp,
        tuotantotilaus: tt,
      } = deviceMetadata;
      let key = 0;
      return (
        <ul>
          {(a !== null && a !== "") && <li key={key++}>{a}</li>}
          {(mN !== null && mN !== "") && <li key={key++}>{mN}</li>}
          {(mNu !== null && mNu !== "") && <li key={key++}>{mNu}</li>}
          {(tp !== null && tp !== "") && <li key={key++}>{tp}</li>}
          {(tt !== null && tt !== "") && <li key={key++}>{tt}</li>}
        </ul>
      );
    }
  }

  public saveDeviceMetadata = async (deviceMetadata: DeviceMetadata): Promise<void> => {
    if (this.props.selectedDevice) {
      this.setState({ dialogActionLoading: true });
      const response = await this.props.selectedDevice.setMetadata(deviceMetadata);
      const isSuccess = response === "success";
      this.setState({
        dialogActionLoading: false,
        dialogActionStatus: isSuccess ? BackendActionStatus.SUCCESS : BackendActionStatus.ERROR,
      });

      if (isSuccess) {
        this.setState({ deviceMetadata });
      }
    }
  };

  public saveDeviceAddonConfig = async (config: MeasurementBoardConfig): Promise<void> => {
    const state = this.props.selectedDevice?.getState();

    if (state && stateIsHyper(state)) {
      const oldValues = getDeviceStateAsHyper(state).addOnConf;
      getDeviceStateAsHyper(state).addOnConf = stringifyAddOnConf(config);
      this.setState({ dialogActionLoading: true });
      const response = await this.props.selectedDevice!.getState()!.store();
      this.setState({
        dialogActionLoading: false,
        dialogActionStatus: response === "success" ? BackendActionStatus.SUCCESS : BackendActionStatus.ERROR,
      });
      const newValues = getDeviceStateAsHyper(state).addOnConf;

      if (this.areChartRelatedConfigValuesDifferent(oldValues, newValues)) {
        this.props.triggerDataSetUpdate();
      }
    }
  };

  private areChartRelatedConfigValuesDifferent(oldConfig: Maybe<string>, newConfig: Maybe<string>): boolean {
    if (oldConfig && newConfig) {
      const parsedOldConfig = parseAddOnConf(oldConfig);
      const parsedNewConfig = parseAddOnConf(newConfig);
      const { cab, transf, hfct, mic } = addOnConfigSelectionDictionary;
      const chartRelatedConfigValues = [cab, transf, hfct, mic];
      return chartRelatedConfigValues.some(({ name }) => {
        const keyInOldValues = Object.keys(parsedOldConfig).find((key) => key.startsWith(name));
        const keyInNewValues = Object.keys(parsedNewConfig).find((key) => key.startsWith(name));

        if (!keyInOldValues || !keyInNewValues) {
          console.log(`Parsed parameter(s) ${parsedOldConfig} and/or ${parsedNewConfig} has/have unknown values`);
          return false;
        } else {
          return parsedOldConfig[keyInOldValues] !== parsedNewConfig[keyInNewValues];
        }
      });
    } else {
      console.error(`Parameters ${oldConfig} and ${newConfig} could not be compared`);
      return false;
    }
  }

  private getAddOnConfig(): Nullable<MeasurementBoardConfig> {
    const state = this.props.selectedDevice!.getState();

    if (state !== null && stateIsHyper(state)) {
      const addOnConf = getDeviceStateAsHyper(state).addOnConf;
      return addOnConf ? parseAddOnConf(addOnConf) : null;
    } else {
      return null;
    }
  }
}

export default ThingMetadata;

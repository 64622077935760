/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import SubscriptionControllerFactory, {
  SubscriptionController,
  SubscriptionDocument,
} from "../../SubscriptionControllerFactory";
import { Maybe } from "../../../types/aliases";
import { Service } from "../../backend/AppSyncClientProvider";

export abstract class AbstractSubscriptionManager<TListener, TSubscription> {
  private subscriptionController?: SubscriptionController;

  protected constructor(
      private readonly service: Service,
      private readonly document: SubscriptionDocument<TSubscription>,
  ) {}

  /**
   * Method for registering listeners that react to subscription messages
   * @param listener
   */
  public abstract addListener(listener: TListener): void;

  /**
   * Method for unregistering listeners
   * @param listener
   */
  public abstract removeListener(listener: TListener): void;

  /**
   * Handle subscription result.
   * Call {@link getListener} to convert id in {@code result} into a listener object
   * @param result
   * @protected
   */
  protected abstract subscriptionHandler(result: Maybe<TSubscription>): void;

  protected subscribeOnce(): void {
    if (!this.subscriptionController) {
      this.subscriptionController = SubscriptionControllerFactory.getController(this.service, this.document, this.subscriptionHandler.bind(this));
    }
  }

  protected unsubscribe(): void {
    this.subscriptionController?.terminateSubscription();
    this.subscriptionController = undefined;
  }
}

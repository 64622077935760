/*
 *  Copyright (C) 2021 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */

import { Maybe } from "../../../types/aliases";

type Handler<T> = ((selection?: T) => void) | ((selection?: T) => Promise<void>);

/**
 * Handles selection for components where child components use the same selection handler
 * for selecting some singular thing. This helper tracks all the selected "things" and makes
 * sure that user cannot trigger the selection callback multiple times within a single selection call.
 */
export default class SelectionRecorder<TSelection> {
  private selections: TSelection[] = [];
  private wrappedHandler?: Handler<TSelection>;
  private handler?: Handler<TSelection>;

  public constructor(
      private readonly isCurrentSelection: (selection?: TSelection) => boolean,
  ) { /* empty */ }

  /**
   * Returns a wrapped selection callback, which handles selection callback for multiple components, tracking
   * which ones have already been activated during the controlled-component "feedback loop":
   * - component that is "selected" can call the callback multiple times, causing only one event to be pushed upwards.
   *
   * Generates new wrapper only when the {@code handler} changes (expected to come from component props or state).
   *
   * @param handler
   *    callback to wrap (either from props or state)
   * @param selectionReference
   *    currently selected object; used for figuring out, whether the object cache needs to be flushed
   *    (either from props or state)
   */
  public getHandler(handler?: Handler<TSelection>, selectionReference?: unknown): Maybe<Handler<TSelection>> {
    if (!handler) return;

    if (selectionReference && !this.selections.some(this.isCurrentSelection)) {
      this.selections = [];
    }

    if (handler !== this.wrappedHandler) {
      this.wrappedHandler = handler;

      this.handler = (selection?: TSelection): ReturnType<typeof handler> => {
        if (selection === undefined) return handler(selection);
        else if (!this.isSelected(selection)) {
          this.selections.push(selection);
          return handler(selection);
        }
      };
    }

    return this.handler;
  }

  private isSelected(selection: TSelection): boolean {
    return this.isCurrentSelection(selection) || this.selections.includes(selection);
  }
}

/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { Service } from "../backend/AppSyncClientProvider";
import { EventRule, NotificationSubscription } from "../clientSpecific/Event";
import AppSyncClientFactory from "../backend/AppSyncClientFactory";
import { Maybe, Voidable } from "../../types/aliases";
import {
  ActionsRulesListDocument,
  NotificationsSubscriptionsCreateDocument,
  NotificationsSubscriptionsListDocument,
  NotificationsSubscriptionsRemoveDocument,
  SubscriptionDetails,
} from "../../generated/gqlEvents";
import { prefixlessId } from "../organization/Utils";
import NotificationBackend, {
  CreateNotificationSubscriptionParams,
  NotificationQuery,
  RemoveNotificationSubscriptionParams,
} from "./NotificationBackend";

export default class AWSNotificationBackend implements NotificationBackend {

  public async getUserNotificationSubscriptions(query: NotificationQuery): Promise<NotificationSubscription[]> {
    try {
      const client = AppSyncClientFactory.createProvider().getTypedClient(Service.EVENTS);
      const response = await client.query(
        NotificationsSubscriptionsListDocument,
        {
          userId: prefixlessId(query.userId),
          organizationId: query.owningOrganizationId,
        },
        {
          fetchPolicy: "network-only",
        },
      );
      return response.data.notificationsSubscriptionsList?.subscriptions ?? [];
    } catch (error) {
      console.error("getUserNotificationSubscriptions", error);
      return [];
    }
  }

  public async getRules(eventId?: string): Promise<EventRule[]> {
    const client = AppSyncClientFactory.createProvider().getTypedClient(Service.EVENTS);
    const rules: EventRule[] = [];

    try {
      let nextToken: Voidable<string>;

      do {
        const response = await client.query(
          ActionsRulesListDocument,
          eventId ? {} : { eventId },
          {
            fetchPolicy: "network-only",
          },
        );
        const responseRules = response.data.actionsRulesList?.rules;
        if (responseRules) rules.push(...responseRules);
        nextToken = response.data.actionsRulesList?.nextToken;
      } while (nextToken);
    } catch (error) {
      console.error("getEventRules", error);
    }
    return rules;
  }

  public async createNotificationSubscription(params: CreateNotificationSubscriptionParams): Promise<Maybe<NotificationSubscription>> {
    const subscriptionDetails: SubscriptionDetails = {
      userId: prefixlessId(params.userId),
      type: params.eventType,
      organizationId: params.owningOrganizationId,
      ...params.target,
    };
    const client = AppSyncClientFactory.createProvider().getTypedClient(Service.EVENTS);
    
    try {
      const response = await client.mutate(
        NotificationsSubscriptionsCreateDocument,
        {
          subscriptionDetails,
        },
      );
      return response.data?.notificationsSubscriptionsCreate ?? undefined;
    } catch (error) {
      console.error("createNotificationSubscription", error);
    }
  }

  public async removeNotificationSubscription(params: RemoveNotificationSubscriptionParams): Promise<Maybe<NotificationSubscription>> {
    const client = AppSyncClientFactory.createProvider().getTypedClient(Service.EVENTS);

    try {
      const response = await client.mutate(
        NotificationsSubscriptionsRemoveDocument,
        {
          userId: prefixlessId(params.userId),
          subscriptionId: params.subscriptionId,
        },
      );
      return response.data?.notificationsSubscriptionsRemove?.subscription ?? undefined;
    } catch (error) {
      console.error("removeNotificationSubscription", error);
    }
  }
}

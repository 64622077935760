/* Copyright */
import { Button, FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import React, { ChangeEvent, Component } from "react";
import Device from "../../data/device/Device";
import { translations } from "../../generated/translationHelper";

type TimeRangeDictionaryEntries = "twoHours" | "sixHours" | "oneDay" | "twoDays" | "oneWeek" | "oneMonth" | "threeMonths";

type TimeRangeOption = {
  displayName: string;
  valueInHours: number;
};

type TimeRangeOptionDictionary<T extends string> = Record<T, TimeRangeOption>;

const timeRangeOptionDictionary: TimeRangeOptionDictionary<TimeRangeDictionaryEntries> = {
  twoHours: {
    displayName: "2h",
    valueInHours: 2,
  },
  sixHours: {
    displayName: "6h",
    valueInHours: 6,
  },
  oneDay: {
    displayName: "1pv",
    valueInHours: 24,
  },
  twoDays: {
    displayName: "2pv",
    valueInHours: 48,
  },
  oneWeek: {
    displayName: "1vk",
    valueInHours: 168,
  },
  oneMonth: {
    displayName: "1kk",
    valueInHours: 720,
  },
  threeMonths: {
    displayName: "3kk",
    valueInHours: 2160,
  },
};

const DEFAULT_TIMESPAN_VALUE = timeRangeOptionDictionary.twoHours.valueInHours;

interface Props {
  startTimestamp?: number;
  endTimestamp?: number;
  liveDataIsActivated: boolean;
  selectedDevice: Device;
  onTimeRangeSelect: (startTimestamp: number, endTimestamp: number) => void;
}

interface State {
  startTimestamp?: number;
  endTimestamp?: number;
  selectedTimeSpanValue: number;
}

class UtuTimeRangePicker extends Component<Props, State> {

  public constructor(props: Props) {
    super(props);
    this.state = {
      startTimestamp: this.props.startTimestamp,
      endTimestamp: this.props.endTimestamp,
      selectedTimeSpanValue: DEFAULT_TIMESPAN_VALUE,
    };
  }

  public componentDidUpdate(prevProps: Props): void {
    if (this.props.startTimestamp !== prevProps.startTimestamp ||
            this.props.endTimestamp !== prevProps.endTimestamp) {
      this.setState({
        startTimestamp: this.props.startTimestamp,
        endTimestamp: this.props.endTimestamp,
      });
    }

    if (this.props.selectedDevice !== prevProps.selectedDevice) {
      this.setState({ selectedTimeSpanValue: DEFAULT_TIMESPAN_VALUE });
    }

    if (this.props.liveDataIsActivated && !prevProps.liveDataIsActivated) {
      this.setState({ selectedTimeSpanValue: DEFAULT_TIMESPAN_VALUE });
    }
  }

  private setEndDateTime(date: string): void {
    const timestamp = moment(date).unix() * 1000;
    this.setState({ endTimestamp: timestamp, startTimestamp: (timestamp - (this.state.selectedTimeSpanValue * 1000 * 60 * 60)) });
  }

  private search = (): void => {
    const { endTimestamp, startTimestamp } = this.state;

    if (endTimestamp && startTimestamp && endTimestamp >= startTimestamp) {
      this.props.onTimeRangeSelect(startTimestamp, endTimestamp);
    }
  };

  private handleTimeSpanChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
    const timeSpan: number = event.target.value as number;

    if (this.state.endTimestamp) {
      this.setState({ startTimestamp: (this.state.endTimestamp - (timeSpan * 1000 * 60 * 60)),
        selectedTimeSpanValue: timeSpan,
      });
    } else {
      console.error("no value found for this.state.endTimestamp in UtuTimeRangePicker.handleTimeSpanChange");
    }
  };

  public render(): JSX.Element {
    // REFACTOR: Time and date formatting should be harmonized throughout the app
    // and encapsulated to a single class.
    //       const startDate = moment(this.state.startTimestamp).format(("YYYY-MM-DDTHH:mm"));
    const endDate = moment(this.state.endTimestamp).format(("YYYY-MM-DDTHH:mm"));

    return (
      <form className="time-range-picker-container">
        <TextField
          variant="outlined"
          margin="dense"
          type="datetime-local"
          value={endDate}
          InputProps={{ inputProps: { max: new Date() } }}
          onChange={(event: ChangeEvent<HTMLInputElement>): void =>
            this.setEndDateTime(event.currentTarget.value)}
          disabled={this.props.liveDataIsActivated}
          fullWidth={false}
          InputLabelProps={{
            shrink: true,
          }}
          className="time-range-picker-input"
        />
        <Button
          variant="contained"
          color="primary"
          size="medium"
          onClick={(): void => this.setEndDateTime(moment(Date.now()).format(("YYYY-MM-DDTHH:mm")))}
          disabled={this.props.liveDataIsActivated}
          className="time-range-picker-button"
        >
          {translations.common.inputs.now()}
        </Button>
        <FormControl variant="outlined" margin="dense" className="time-span-picker-input">
          <InputLabel id="time-span-picker-label" shrink={true} >
            Aikajakso
          </InputLabel>
          <Select
            labelId="time-span-picker-label"
            id="time-span-picker"
            value={this.state.selectedTimeSpanValue}
            onChange={this.handleTimeSpanChange}
            label="Aikajakso"
            disabled={this.props.liveDataIsActivated}
          >
            {this.getTimeRangeOptionItems()}
          </Select>
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          size="medium"
          onClick={this.search}
          disabled={this.props.liveDataIsActivated}
          className="time-range-picker-button"
        >
        Näytä
        </Button>
      </form>
    );
  }

  private getTimeRangeOptionItems(): JSX.Element[] {
    return Object.values(timeRangeOptionDictionary).map(({ displayName, valueInHours }) => <MenuItem key={valueInHours} value={valueInHours}>{displayName}</MenuItem>);
  }
}

export default UtuTimeRangePicker;

/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/
// TODO: remove header 

import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Box } from "@material-ui/core";
import Chart from "react-google-charts";
import { ChartAnimation, ChartArea, ChartDataRow, ChartExplorer, ChartLegend } from "../../../types/chartprops";
import { Maybe } from "../../../types/aliases";
import { getDateTimeFormat, DateTimeFormatTarget } from "../../../data/utils/Utils";
import Device from "../../../data/device/Device";
import EventsRepository from "../../../data/events/EventsRepository";
import { EventRepositoryListener } from "../../../data/events/EventRepositoryListener";
import Event from "../../../data/clientSpecific/Event";
import Loader from "../../ui/loader";

interface Props {
  devices: Maybe<Device[]>;
}

interface State {
  chartMode: ChartMode;
  timeCutoff: number;
  chartData: ChartDataRow[] | null;
  maxVerticalAxisValue: number;
}

const CHART_AREA: ChartArea = {
  bottom: 60,
  top: 50,
  height: "70%",
  width: "80%",
};

const EXPLORER: ChartExplorer = {
  actions: ["", ""],
  axis: "horizontal",
  keepInBounds: true,
  maxZoomIn: 100.0,
};

const LEGEND: ChartLegend = {
  display: false,
  position: "none",
};

const ANIMATION: ChartAnimation = {
  startup: true,
  easing: "out",
  duration: 100,
};

enum ChartMode {
  Week,
  OneMonth,
  ThreeMonths,
  SixMonths,
}

interface ChartValues {
  [key: string]: number;
}
export default class StatusAlarmChart extends Component<Props, State> implements EventRepositoryListener {

  public constructor(props: Props) {
    super(props);
    this.state = {
      chartMode: ChartMode.Week,
      timeCutoff: this.calculateTimeCutoff(ChartMode.Week),
      chartData: [],
      maxVerticalAxisValue: 0,
    };
  }

  public componentDidMount(): void {
    EventsRepository.instance.addListener(this);
    this.setChartData();
  }

  public componentWillUnmount(): void {
    EventsRepository.instance.removeListener(this);
  }

  public componentDidUpdate(prevProps: Props, prevState: State): void {
    console.log("componentDidUpdate");

    if (prevProps.devices !== this.props.devices || this.state.chartMode !== prevState.chartMode) {
      this.setChartData();
    }
  }

  private setChartData(): void {
    console.log("setChartData");

    if (this.props.devices != null) {
      const chartData: ChartDataRow[] = [["timestamp"]];
      chartData[0].push("Hälytyksiä");
      const chartValues: ChartValues = { };

      // Fill chart with 0 for each day during selected period
      const date: Date = new Date(this.state.timeCutoff);
      const now = new Date();

      while (date <= now) {
        chartValues[date.toDateString()] = 0;
        date.setDate(date.getDate() + 1);
      }

      const allAlarms = EventsRepository.instance.getAllEvents();

      for (const alarm of allAlarms) {
        const activeDevice: Maybe<Device> = this.props.devices.find((device: Device) => {
          return device.getId() === alarm.deviceId;
        });

        if (activeDevice !== undefined && (Number(alarm.timestamp) > this.state.timeCutoff)) {
          const alarmDate: Date = new Date(Number(alarm.timestamp));

          if (Object.keys(chartValues).includes(String(alarmDate.toDateString()))) {
            chartValues[alarmDate.toDateString()]++;
          }
        }
      }

      let tmpChartData: ChartDataRow[] = [];
      Object.keys(chartValues).forEach((key: string) => {
        const chartDataColumns: ChartDataRow = [new Date(key)];
        chartDataColumns.push(chartValues[key]);
        tmpChartData.push(chartDataColumns);
      });

      if (tmpChartData.length > 0) {
        tmpChartData = this.sortChartData(tmpChartData);
        tmpChartData.forEach((i: ChartDataRow) => {
          chartData.push(i);
        });

        this.setState({
          chartData,
        });
      } else if (this.state.chartData != null) {
        this.setState({ chartData: null });
      }
    } else if (this.state.chartData != null) {
      this.setState({ chartData: null });
    }
  }

  private setChartMode(mode: ChartMode): void {
    console.log("setChartMode " + mode);
    this.setState({ chartMode: mode, timeCutoff: this.calculateTimeCutoff(mode) });
  }

  private calculateTimeCutoff(mode: ChartMode): number {
    const date: Date = new Date();
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    let timeCutoff = 0;

    switch (mode) {
      case ChartMode.Week:
        timeCutoff = date.getTime() - (7 * 24 * 60 * 60 * 1000);
        break;
      case ChartMode.OneMonth:
        date.setMonth(date.getMonth() - 1);
        timeCutoff = date.getTime();
        break;
      case ChartMode.ThreeMonths:
        date.setMonth(date.getMonth() - 3);
        timeCutoff = date.getTime();
        break;
      case ChartMode.SixMonths:
        date.setMonth(date.getMonth() - 6);
        timeCutoff = date.getTime();
        break;
    }
    console.log("Time cutoff is " + new Date(timeCutoff).toLocaleString());
    return timeCutoff;
  }

  private sortChartData(input: ChartDataRow[]): ChartDataRow[] {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const sortedOccurences: any[] = input.map((c: ChartDataRow) => {
      return c[1];
    }).sort();

    let maxValue = 5;

    if (sortedOccurences.length > 0) {
      maxValue = Math.max(sortedOccurences[sortedOccurences.length - 1], maxValue);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const sortedInput = input.sort((a: any, b: any): number => {
      const dateA = a[0] as Date;
      const dateB = b[0] as Date;

      if (dateA === dateB) {
        return 0;
      } else if (dateA < dateB) {
        return -1;
      } else {
        return 1;
      }
    });

    if (this.state.maxVerticalAxisValue !== maxValue) {
      this.setState({ maxVerticalAxisValue: maxValue });
    }
    return sortedInput;
  }

  private isLinkActive(mode: ChartMode): boolean {
    return (this.state.chartMode === mode);
  }

  public onEventsInitDone(): void {
    console.log("onEventsInitDone");

    if (this.props.devices && this.props.devices.length > 0) {
      this.setChartData();
    }
  }

  public onEvent(_event: Event): void {
    console.log("onEventOccurred");

    if (this.props.devices && this.props.devices.length > 0) {
      this.setChartData();
    }
  }

  public onEventStateChanged(_event: Event): void {
    console.log("onEventStateChanged");

    if (this.props.devices && this.props.devices.length > 0) {
      this.setChartData();
    }
  }

  private renderChart(): JSX.Element {
    if (this.props.devices && this.state.chartData && this.state.chartData.length > 0) {
      return (
        <>
          <div className="time-selection-container">
            <NavLink
              className="time-selection-link"
              activeClassName="time-selection-link-active"
              isActive={(): boolean => this.isLinkActive(ChartMode.Week)}
              to="#"
              onClick={(): void => this.setChartMode(ChartMode.Week)}
            >
            Viikko
            </NavLink>
            <NavLink
              className="time-selection-link"
              activeClassName="time-selection-link-active"
              isActive={(): boolean => this.isLinkActive(ChartMode.OneMonth)}
              to="#"
              onClick={(): void => this.setChartMode(ChartMode.OneMonth)}
            >
            1kk
            </NavLink>
            <NavLink
              className="time-selection-link"
              activeClassName="time-selection-link-active"
              isActive={(): boolean => this.isLinkActive(ChartMode.ThreeMonths)}
              to="#"
              onClick={(): void => this.setChartMode(ChartMode.ThreeMonths)}
            >
            3kk
            </NavLink>
            <NavLink
              className="time-selection-link"
              activeClassName="time-selection-link-active"
              isActive={(): boolean => this.isLinkActive(ChartMode.SixMonths)}
              to="#"
              onClick={(): void => this.setChartMode(ChartMode.SixMonths)}
            >
            6kk
            </NavLink>
          </div>
          <div>
            <Chart
              chartType="AreaChart"
              loader={<Loader />}
              data={this.state.chartData!}
              options={{
                chartArea: CHART_AREA,
                colors: ["#00a5df"],
                title: "",
                explorer: EXPLORER,
                hAxis: {
                  format: getDateTimeFormat(DateTimeFormatTarget.AlarmsChart),
                  gridlines: {
                    color: "transparent",
                  },
                },
                vAxis: {
                  format: "0",
                  viewWindow: {
                    min: 0,
                    max: this.state.maxVerticalAxisValue,
                  },
                },
                legend: LEGEND,
                animation: ANIMATION,
                enableInteractivity: true,
              }}
              style={{
                width: "100%",
                height: "100%",
              }}
              formatters={[
                {
                  type: "DateFormat",
                  column: 0,
                  options: {
                    pattern: getDateTimeFormat(DateTimeFormatTarget.AlarmsChartHover),
                  },
                },
              ]}
            />
          </div>
        </>
      );
    } else {
      return (
        // position Loader correctly into chart area
        <Box position="relative" top="5.3rem"> 
          <Loader />
        </Box>
      );
    }
  }

  public render(): JSX.Element {
    return (
      <>
        <div className="headline-text">Hälytysten määrä</div>
        {this.renderChart()}
      </>
    );
  }
}

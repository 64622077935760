/* Copyright */
import { Box, TextField } from "@material-ui/core";
import React from "react";
import { Maybe, Nullable } from "../../../types/aliases";
import { validateStringLength } from "../../../utils/validation";

interface Props {
  label: string;
  onChange: (text: Nullable<string>) => void;
  showTextField: boolean;
  value: string;
}

class SigmaSelector extends React.Component <Props> {

  private renderTextField(): Maybe<JSX.Element> {
    if (this.props.showTextField) {      
      const {
        label,
        onChange,
        value,
      } = this.props;
      return (
        <TextField
          error={value === ""}
          label={label}
          onChange={({ target }): void => onChange(validateStringLength(target.value, 15) ? target.value : null)}
          size="small"
          value={value}
          variant="outlined"
          helperText={this.getHelperText(value)}
        />
      );
    }
  }

  public render(): JSX.Element {
    return (
      <Box mt={this.props.value !== null ? "1rem" : undefined}>
        {this.props.children}
        {this.renderTextField()}
      </Box>
    );
  }

  private getHelperText(value: string): Maybe<string> {
    if (!validateStringLength(value, 14)) {
      return "Maksimipituus on 15 merkkiä";
    } else if (value === "") {
      return "Kenttä ei saa olla tyhjä";
    }
  }

}

export default SigmaSelector;

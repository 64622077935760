/* Copyright */

import { Button, Checkbox, FormControlLabel, withStyles } from "@material-ui/core";

const PowerCompanySelectorButton = withStyles({
  root: {
    color: "white",
    "&:hover": {
      backgroundColor: "#004388",
    },
    width: "100%",
  },
})(Button);

const PowerCompanyResetButton = withStyles({
  root: {
    color: "#004c9a",
    fontSize: "1rem",
    fontWeight: "inherit",
    "&:hover": {
      backgroundColor: "unset",
      textDecoration: "underline",
    },
    padding: 0,
  },
})(Button);

const CheckboxLabel = withStyles({
  root: {
    userSelect: "none",
  },
  label: {
  },
})(FormControlLabel);

const PowerCompanySelectorCheckbox = withStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
})(Checkbox);

export {
  PowerCompanyResetButton,
  PowerCompanySelectorButton,
  CheckboxLabel,
  PowerCompanySelectorCheckbox,
};

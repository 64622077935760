/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { DeviceStateProperties } from "../../../data/device/DeviceStateProperties";
import { Nullable } from "../../../types/aliases";

export class ReferenceHWStateProperties extends DeviceStateProperties {
  public readonly batterySoc: Nullable<number>;
  public readonly chargerConnected: Nullable<boolean>;
  public readonly signalStrength: Nullable<number>;
  public readonly modem: Nullable<string>;
  public readonly gpsFix: Nullable<boolean>;
  public readonly deviceFwVersion: Nullable<string>;
  public readonly powerSupply: Nullable<string>;
  public readonly resetReason: Nullable<number>;
  public readonly resetCount: Nullable<number>;
  public readonly selfTestError: Nullable<string>;
  public readonly gpsDebugData: Nullable<string>;

  public constructor(deviceProperties: Partial<ReferenceHWStateProperties>) {
    super(deviceProperties);
    this.batterySoc = deviceProperties.batterySoc ?? null;
    this.chargerConnected = deviceProperties.chargerConnected ?? null;
    this.signalStrength = deviceProperties.signalStrength ?? null;
    this.modem = deviceProperties.modem ?? null;
    this.gpsFix = deviceProperties.gpsFix ?? null;
    this.deviceFwVersion = deviceProperties.deviceFwVersion ?? null;
    this.powerSupply = deviceProperties.powerSupply ?? null;
    this.resetReason = deviceProperties.resetReason ?? null;
    this.resetCount = deviceProperties.resetCount ?? null;
    this.selfTestError = deviceProperties.selfTestError ?? null;
    this.gpsDebugData = deviceProperties.gpsDebugData ?? null;
  }
}

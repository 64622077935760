/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import PromiseWaitList from "./PromiseWaitList";
import { Maybe, Nullable } from "../../types/aliases";

/**
 * Cache specialized in storing Promise results.
 * 
 * In cases where multiple "clients" required the same cacheable, asynchronous entity,
 * AsyncCache may be used to make sure that only one async call is made and other 
 * requesters receive a promise which will eventually resolve into the entity (or error).
 */
export default class AsyncCache<TObject = unknown> {
  private readonly cache = new Map<string, PromiseWaitList<Nullable<TObject>>>();

  /**
   * Get a thing from cache.
   * If the thing has not been fetched yet, uses the provided {@code objectProvider} to fetch the thing.
   * If the provider throws, the error will be delivered to the other called queued for the fetch result.
   *
   * @param key
   *    Unique key used to store the entity
   * @param objectProvider
   *    Async method which fetches the object on cache miss
   * @returns promise which might resolve into a thing - or undefined
   */
  public async get<TOne extends TObject>(key: string, objectProvider: () => Promise<Maybe<TOne>>): Promise<Maybe<TOne>> {
    const cached = this.cache.get(key);
    
    if (cached) {
      return (await cached.get()) as TOne ?? undefined;
    }
    
    const waitList = new PromiseWaitList<Nullable<TObject>>(`get(${key})`);
    this.cache.set(key, waitList);

    try {
      const thing = await objectProvider();

      if (thing) {
        waitList.set(thing);
      } else {
        waitList.set(null);
      }

      return thing;
    }
    catch (err) {
      waitList.fail(err);
      throw err;
    }
  }

  /**
   * Stores thing to the cache under the key.
   * @param key
   *    Unique key that identifies the thing
   * @param thing
   *    Thing to cache
   * @returns true if stored, false if already stored
   */
  public set(key: string, thing: TObject): boolean {
    if (!this.cache.has(key)) {
      const waitList = new PromiseWaitList<Nullable<TObject>>(`set(${key})`);
      waitList.set(thing);
      this.cache.set(key, waitList);
      return true;
    }
    return false;
  }

  /**
   * Deletes value from the cache, if one exists for the key and
   * returns a promise resolving to the deleted value.
   * @param key
   *    Unique key
   */
  public async delete(key: string): Promise<Maybe<TObject>> {
    const value = this.cache.get(key);

    if (value) {
      this.cache.delete(key);
      return (await value.get()) ?? undefined;
    }
  } 

  /**
   * Does the key have something cached.
   * @param key
   *    Unique key
   */
  public has(key: string): boolean {
    return this.cache.has(key);
  }

  /**
   * Empties the cache.
   * However, living promises will not be invalidated.
   */
  public clear(): void {
    this.cache.clear();
  }
}

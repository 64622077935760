/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import { DeviceChangeType, DeviceRouterProps, parseDeviceAndTime } from "./NavigationUtils";
import DeviceNavigationCache, { DeviceChangeHandler } from "./DeviceNavigationCache";
import { timePeriodIsEmpty } from "../data/utils/TimePeriod";

interface DeviceChangeResultHandlerParams {
  stateResetCallback: () => void;
  deviceChangeCallback: (deviceId: string) => Promise<void>;
}

export class DeviceChangeResultHandler implements DeviceChangeHandler {
  private params: DeviceChangeResultHandlerParams;

  private readonly deviceResolveHandlers: Record<DeviceChangeType, (props: DeviceRouterProps) => Promise<void>> = {
    [DeviceChangeType.ChangedToNone]: async (props) => {
      const info = parseDeviceAndTime(props);
      this.params.stateResetCallback();

      if (!timePeriodIsEmpty(info.timePeriod)) {
        // remove time period
        await DeviceNavigationCache.getInstance().navigateToDeviceAndTime(props);
      }
    },
    [DeviceChangeType.ChangedToNew]: async (props) => {
      if (props.match.params.id) {
        await this.params.deviceChangeCallback(props.match.params.id);
      }
    },
    [DeviceChangeType.StayedNone]: async () => {
      // NO-OP: nothing needs to be done here, since nothing changes
    },
    [DeviceChangeType.StayedSame]: async (_props) => {
      // NO-OP: nothing needs to be done here, since nothing changes
    },
    [DeviceChangeType.WillRestore]: async () => {
      const deviceId = DeviceNavigationCache.getInstance().getSelectedDevice()?.getId();
      if (deviceId) await this.params.deviceChangeCallback(deviceId);
    },
  };

  public constructor(params: DeviceChangeResultHandlerParams) {
    this.params = params;
  }

  public async handle(result: DeviceChangeType, props: DeviceRouterProps): Promise<void> {
    console.log(`handle(${result})`);
    await this.deviceResolveHandlers[result](props);
  }
}

/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import React, { Component, Fragment } from "react";
import { NavLink, RouteComponentProps, withRouter } from "react-router-dom";
import { DevicePathRouterProps } from "../../types/routerprops";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import AuthWrapper from "../../data/auth/AuthWrapper";
import CLOSE_ICON from "../../assets/baseline-close-24px.svg";
import MENU_ICON from "../../assets/baseline-menu-24px.svg";
import HEADER_LOGO_1 from "../../assets/utu_logo0.png";
import HEADER_LOGO_2 from "../../assets/utu_logo1.png";
import ViewAccessMethods from "../../ViewAccessMethods";
import accessControlled from "../access-control/access-controlled";
import Paths, { getPathWithRetainedParams } from "../Paths";
import { Maybe, Nullable } from "../../types/aliases";
import { IconButton, withStyles } from "@material-ui/core";
import UserDropdownMenu from "./components/user-dropdown-menu";
import { translations } from "../../generated/translationHelper";

interface State {
  deviceIdUrlParameter: Nullable<string>;
  firstName?: string;
  headerLogo: Maybe<string>;
  mobileMenuOpen: boolean;
  userMenuOpen: boolean;
  isExtraSmallScreen: Maybe<boolean>;
}

type Props = RouteComponentProps<DevicePathRouterProps>;

const AdminContent = accessControlled(React.Fragment, ViewAccessMethods.hasAdminAccess);

const StyledIconButton = withStyles({
  root: {
    color: "#b3c8db",
    "&:hover": {
      color: "#fff",
      backgroundColor: "transparent",
    },
  },
})(IconButton);

class Header extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      deviceIdUrlParameter: null,
      firstName: "",
      headerLogo: undefined,
      mobileMenuOpen: false,
      userMenuOpen: false,
      isExtraSmallScreen: undefined,
    };
  }
  private mediaQueryList = window.matchMedia("(max-width: 37.4em)");

  public async componentDidMount(): Promise<void> {
    this.mediaQueryList.addEventListener("change", (event: MediaQueryListEvent) => this.handleScreenWidthChange(event));
    this.setState({ headerLogo: this.props.location.pathname === Paths.STATUS ? HEADER_LOGO_1 : HEADER_LOGO_2 });
    await this.initializeFirstname();
  }

  public componentDidUpdate(prevProps: Props, prevState: State): void {
    if (!this.state.deviceIdUrlParameter && prevProps.match.params.id) {
      this.setState({ deviceIdUrlParameter: prevProps.match.params.id });
    }

    if ((this.state.deviceIdUrlParameter && this.props.match.params.id) &&
            (this.state.deviceIdUrlParameter !== this.props.match.params.id)) {
      this.setState({ deviceIdUrlParameter: this.props.match.params.id });
    }

    if ((!this.state.isExtraSmallScreen && prevState.isExtraSmallScreen) && this.state.mobileMenuOpen) {
      this.setState({ mobileMenuOpen: false });
    }

    if ((this.state.isExtraSmallScreen && !prevState.isExtraSmallScreen) && this.state.userMenuOpen) {
      this.setState({ userMenuOpen: false });
    }
  }

  public componentWillUnmount(): void {
    this.mediaQueryList.removeEventListener("change", (event: MediaQueryListEvent) => this.handleScreenWidthChange(event));
  }

  public async initializeFirstname(): Promise<void> {
    try {
      const firstName = await AuthWrapper.getGivenName();
      this.setState({
        firstName,
      });
    } catch (error) {
      console.error("getFirstname", error);
    }
  }

  private handleScreenWidthChange({ matches }: MediaQueryListEvent): void {
    if (matches !== this.state.isExtraSmallScreen) {
      this.setState({ isExtraSmallScreen: matches });
    }
  }

  private logOut = async (): Promise<void> => {
    await AuthWrapper.logOut();
  };

  private getRoute(path: Paths): string {
    return getPathWithRetainedParams(path, this.props);
  }

  private getFirstname(): Maybe<string> {
    const trimmedUsername = this.state.firstName?.trim();
    if (trimmedUsername && trimmedUsername.length > 0) return trimmedUsername;
  }

  private handleLinkClick = (headerLogoNumber = 1): void => {
    let headerLogo: string;

    switch (headerLogoNumber) {
      case 0:
        headerLogo = HEADER_LOGO_1;
        break;
      case 1:
        headerLogo = HEADER_LOGO_2;
        break;
      default:
        headerLogo = HEADER_LOGO_1;
        break;
    }

    this.setState({ headerLogo });

    if (this.state.mobileMenuOpen) {
      this.setState({ mobileMenuOpen: false });
    }

    if (this.state.userMenuOpen) {
      this.setState({ userMenuOpen: false });
    }
  };

  private renderMobileMenuItemsOrUserMenuButton(): JSX.Element {
    return (
      this.state.mobileMenuOpen
        ?
        <Fragment>
          <li className="menu-item">
            <NavLink
              to={Paths.USER}
              onClick={(): void => this.handleLinkClick()}
              activeClassName="active"
              className="menu-item-link"
              data-testid="user-tab"
            >
              {this.getFirstname() ?? translations.common.texts.user()}
            </NavLink>
          </li>
          <AdminContent showLoader={false} showAccessError={false}>
            <li className="menu-item">
              <NavLink
                to={Paths.ADMIN}
                onClick={(): void => this.handleLinkClick()}
                activeClassName="active"
                className="menu-item-link"
              >
                {translations.common.texts.admin()}
              </NavLink>
            </li>
          </AdminContent>
          <li className="menu-item" onClick={this.logOut}>
            <div className="menu-item-link" >
              <span>{translations.common.texts.logOut()}</span>
              <div className="exit-icon">
                <ExitToAppIcon
                  height="100%"
                />
              </div>
            </div>
          </li>
        </Fragment>
        :
        <li className="menu-button-item">
          <div className="menu-item-link">
            <StyledIconButton disableRipple onClick={(): void => this.setState({ userMenuOpen: !this.state.userMenuOpen })}>
              <MoreVertIcon />
            </StyledIconButton>
          </div>
        </li>
    );
  }

  private renderMenu(): JSX.Element {
    return (
      <ul className="menu-list">
        <li className="menu-item">
          <NavLink
            to={this.getRoute(Paths.STATUS)}
            onClick={(): void => this.handleLinkClick(0)}
            activeClassName="active"
            className="menu-item-link"
          >
            {translations.common.texts.status()}
          </NavLink>
        </li>
        <li className="menu-item">
          <NavLink
            to={this.getRoute(Paths.HISTORY)}
            onClick={(): void => this.handleLinkClick()}
            activeClassName="active"
            className="menu-item-link"
          >
            {translations.common.texts.history()}
          </NavLink>
        </li>
        <li className="menu-item">
          <NavLink
            to={this.getRoute(Paths.EVENTS)}
            onClick={(): void => this.handleLinkClick()}
            activeClassName="active"
            className="menu-item-link"
          >
            {translations.common.texts.events()}
          </NavLink>
        </li>
        {this.renderMobileMenuItemsOrUserMenuButton()}
      </ul >
    );
  }

  public render(): JSX.Element {
    return (
      <header className="header-container col-sm-12">
        <div className="mobile-menu-container col-sm-1 col-xsm-2">
          <img
            className="menu-button"
            alt="menu"
            src={this.state.mobileMenuOpen ? CLOSE_ICON : MENU_ICON}
            onClick={(): void => this.setState((prevState: State) => ({ mobileMenuOpen: !prevState.mobileMenuOpen })) }
          />
          <div
            className={this.state.mobileMenuOpen ? "menu-content" : "menu-content hidden"}>
            {this.state.mobileMenuOpen && (this.renderMenu())}
          </div>
        </div>
        <div className="logo-container col-md-4 col-sm-5 col-xsm-10">
          <img className="header-logo" src={this.state.headerLogo} alt="header-logo" />
        </div>
        <div className="menu-container col-md-8 col-sm-7">
          <div className="menu">
            {this.renderMenu()}
          </div>
        </div>
        <UserDropdownMenu
          firstName={this.getFirstname()}
          open={this.state.userMenuOpen}
          onNavLinkClick={this.handleLinkClick}
          logOut={this.logOut}/>
      </header>
    );
  }
}

export default withRouter(Header);

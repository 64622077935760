/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import React, { ReactNode } from "react";

interface State {
  // TODO: Fix any type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref: React.RefObject<any>;
  isScrolling: boolean;
  clientY: number;
  scrollY: number;
}

interface Props {
  rootClass: string;
}

export class ScrollDrag extends React.Component<Props, State> {

  public constructor(props: Props) {
    super(props);
    this.state = {
      ref: React.createRef(),
      isScrolling: false,
      clientY: 0,
      scrollY: 0,
    };
  }

  protected onMouseDown = (event: React.MouseEvent<HTMLElement>): void => {
    this.setState({
      isScrolling: true,
      clientY: event.clientY,
    });
  };

  protected onMouseUp = (): void => {
    this.setState({
      isScrolling: false,
    });
  };

  protected onMouseMove = (event: React.MouseEvent<HTMLElement>): void => {
    const { clientY, scrollY } = this.state;

    if (this.state.isScrolling) {
      // eslint-disable-next-line react/no-direct-mutation-state
      this.state.ref.current.scrollLeft = scrollY + event.clientY - clientY;
      this.setState({
        scrollY: scrollY + event.clientY - clientY,
        clientY: event.clientY,
      });
    }
  };

  public render(): JSX.Element {
    const { ref } = this.state;
    return (
      <div
        ref={ref}
        onMouseDown={this.onMouseDown}
        onMouseUp={this.onMouseUp}
        onMouseMove={this.onMouseMove}
        className={this.props.rootClass}
      >
        {React.Children.map(this.props.children, (child: ReactNode) => React.Children.only(child))}
      </div>
    );
  }
}

export default ScrollDrag;

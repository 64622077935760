/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import WarningIcon from "@material-ui/icons/Warning";
import React, { Component } from "react";
import DeviceGroup from "../../../data/device/DeviceGroup";
import { Maybe, Nullable } from "../../../types/aliases";
import BackendFactory from "../../../data/BackendFactory";
import EventsRepository from "../../../data/events/EventsRepository";
import Event from "../../../data/clientSpecific/Event";
import Loader from "../../ui/loader";
import { EventRepositoryListener } from "../../../data/events/EventRepositoryListener";

interface Props {
  group: DeviceGroup;
  editMode?: boolean;
  onRemoveGroup: (group: DeviceGroup) => void;
  onAddGroup: (parent: DeviceGroup) => void;
  showEventsMode: boolean;
}

interface State {
  hasEvents: boolean;
  loading: boolean;
}

export default class TreeFolder extends Component<Props, State> implements EventRepositoryListener {
  public constructor(props: Props) {
    super(props);
    this.state = {
      hasEvents: false,
      loading: false,
    };
  }

  public async componentDidMount(): Promise<void> {
    EventsRepository.instance.addListener(this);
    this.setState({ loading: true });

    if (this.props.showEventsMode && EventsRepository.instance.isInitialized()) {
      const hasEvents = await this.checkGroupForEventsRecursively();
      this.setState({ hasEvents, loading: false });
    }
  }

  public componentWillUnmount(): void {
    EventsRepository.instance.removeListener(this);
  }

  public async onEventsInitDone(): Promise<void> {
    if (this.props.showEventsMode && this.state.loading) {
      const hasEvents = await this.checkGroupForEventsRecursively();
      this.setState({ hasEvents, loading: false });
    }
  }

  public async onEvent(_event: Event): Promise<void> {
    if (this.props.showEventsMode) {
      this.setState({ loading: true });
      const hasEvents = await this.checkGroupForEventsRecursively();
      this.setState({ hasEvents, loading: false });
    }
  }

  public async onEventStateChanged(_event: Event): Promise<void> {
    if (this.props.showEventsMode) {
      this.setState({ loading: true });
      const hasEvents = await this.checkGroupForEventsRecursively();
      this.setState({ hasEvents, loading: false });
    }
  }

  private handleDragStart = (event: React.DragEvent<HTMLDivElement>): void => {
    event.preventDefault();
  };

  private handleDragOver = (event: React.DragEvent<HTMLDivElement>): void => {
    if (this.props.editMode) {
      event.preventDefault();
      event.stopPropagation();
      event.currentTarget.style.backgroundColor = "#d1d1d1";
      event.currentTarget.style.borderRadius = "3px";
    }
  };

  private handleDragLeave = (event: React.DragEvent<HTMLDivElement>): void => {
    if (this.props.editMode) {
      event.preventDefault();
      event.stopPropagation();
      event.currentTarget.style.backgroundColor = "transparent";
    }
  };

  private handleDrop = async (event: React.DragEvent<HTMLDivElement>): Promise<void> => {
    if (this.props.editMode) {
      event.preventDefault();
      event.stopPropagation();
      event.currentTarget.style.backgroundColor = "transparent";

      const deviceId = event.dataTransfer.getData("device");
      const sourceGroupId = event.dataTransfer.getData("group");

      if (this.props.group.getId() === sourceGroupId) {
        return;
      }

      if (deviceId) {
        const device = await BackendFactory.getBackend().getDevice(deviceId);

        if (device) {
          if (sourceGroupId) {
            const sourceGroup = await BackendFactory.getBackend().getDeviceGroup(sourceGroupId);
            await sourceGroup?.removeDevice(device);
          }
          await this.props.group.addDevice(device);
        }
      }
    }
  };

  private renderGroupIcon(): Maybe<JSX.Element> {
    if (this.state.loading) {
      return (
        <Loader
          leftRightPadding="0"
          topBottomPadding="0"
          size="small"
        />
      );
    } else if (this.state.hasEvents) {
      return (
        <WarningIcon
          height="100%"
          htmlColor="#ff0000"
        />
      );
    }
  }

  private renderContent(): JSX.Element {
    return (
      <>
        <div className="iot-item-link">
          <div className="iot-name col-sm-10 col-xsm-10">
            {this.props.group.getLabel()}
          </div>
        </div>
        <div className="iot-icon col-sm-4 col-xsm-4">
          {this.renderGroupIcon()}
        </div>
      </>
    );
  }

  public render(): JSX.Element {
    return (
      <div
        className="tree-folder"
        onDragStart={this.handleDragStart}
        onDragOver={this.handleDragOver}
        onDragLeave={this.handleDragLeave}
        onDrop={this.handleDrop}
      >
        {this.renderContent()}
      </div>
    );
  }

  private async checkGroupForEventsRecursively(): Promise<boolean> {
    const allActiveEvents = EventsRepository.instance.getAllActiveEvents();

    if (allActiveEvents.length > 0) {
      const groups = await this.props.group.getGroups();
      const eventsInGroups: Event[] = [];

      if (groups.length > 0) {
        await Promise.all(groups.map(async (group): Promise<void> => this.getEventsForSubGroups(group, eventsInGroups, allActiveEvents)));
      } else {
        const eventsForDevice = await this.getEventsForDevicesInGroup(this.props.group, allActiveEvents);

        if (eventsForDevice !== null) {
          eventsInGroups.push(...eventsForDevice);
        }
      }
      return eventsInGroups.length > 0;
    } else {
      return false;
    }
  }

  private async getEventsForDevicesInGroup(group: DeviceGroup, allActiveEvents: Event[]): Promise<Nullable<Event[]>> {
    const devices = await group.getDevices();

    if (devices.length > 0) {
      const eventsForDevices = allActiveEvents.filter(({ deviceId }) => {
        return devices.map(d => d.getId() === deviceId);
      });

      if (eventsForDevices.length > 0) {
        return eventsForDevices;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  private async getEventsForSubGroups(group: DeviceGroup, alarms: Event[], allActiveAlarms: Event[]): Promise<void> {
    const subGroups = await group.getGroups();
    const eventsForDevice = await this.getEventsForDevicesInGroup(group, allActiveAlarms);

    if (eventsForDevice !== null) {
      alarms.push(...eventsForDevice);
    }

    if (alarms.length === 0 && subGroups && subGroups.length > 0) {
      await Promise.all(subGroups.map(async (g): Promise<void> => this.getEventsForSubGroups(g, alarms, allActiveAlarms)));
    }
  }
}

/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { ListItem, ListItemText } from "@material-ui/core";
import React, { FunctionComponent, PropsWithChildren, ReactElement } from "react";

interface Props {
  label: string;
  secondary?: React.ReactNode;
}

const SettingsListItem: FunctionComponent<Props> = (props: PropsWithChildren<Props>): ReactElement => {
  // create dash-separated suffix from label
  const idSuffix = props.label.toLowerCase().split(" ").map((piece: string) => piece.replace(/[^a-z0-9]/, "")).join("-");

  return (
    <div className="setting-item">
      <ListItem alignItems={"flex-start"}>
        <ListItemText id={`switch-list-label-${idSuffix}`} primary={props.label} secondary={props.secondary}/>
        {props.secondary ? undefined : props.children}
      </ListItem>
    </div>
  );
};

export default SettingsListItem;

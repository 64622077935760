/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import Data from "./Data";
import TimeSeriesSet from "../utils/TimeSeriesSet";
import TimePeriod from "../utils/TimePeriod";
import BaseObservable from "../observer/BaseObservable";

export interface DataObserver {
  onDataUpdate(subject: DataSet): void;
}

export abstract class DataSet<TData extends Data = Data> extends BaseObservable<DataObserver> implements TimeSeriesSet<TData> {
  public abstract getId(): string;

  public abstract setData(data: TData[]): void;

  public abstract getData(): TData[];

  public abstract getType(): string;

  public abstract getTimePeriod(): TimePeriod;

  public abstract fetch(): Promise<void>;

  public abstract appendData(data: TData): void;
}

export default DataSet;


/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import Drawer from "@material-ui/core/Drawer";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import React, { Component, ReactNode } from "react";
import DeviceGroup from "../../data/device/DeviceGroup";
import SearchBar from "../ui/search-bar";
import DeviceList from "../device-browsing/device-list";
import DeviceTree from "../device-browsing/device-tree";
import { getCaseInsensitiveSearchFilter } from "../device-browsing/helpers/search-filter";
import { getDisplayName, TOOLTIP_DELAY_MS } from "../../data/utils/Utils";
import BackendFactory from "../../data/BackendFactory";
import Device from "../../data/device/Device";
import AuthWrapper from "../../data/auth/AuthWrapper";
import Loader from "../ui/loader";
import { translations } from "../../generated/translationHelper";
import { Tooltip, IconButton } from "@material-ui/core";

interface Props {
  selectedDeviceId?: string;
  selectedGroupId?: string;
  onDeviceSelect?: (device?: Device) => void;
  onGroupSelect?: (group?: DeviceGroup) => void;
}

interface State {
  searchFilter: string;
  drawerOpen: boolean;
  groups?: DeviceGroup[];
  selectedDisplayName?: string;
}

export default class DeviceDrawer extends Component<Props, State> {

  public constructor(props: Props) {
    super(props);
    this.state = {
      searchFilter: "",
      drawerOpen: false,
    };
  }

  public async componentDidMount(): Promise<void> {
    const promiseFactories: Array<() => Promise<void>> = [
      (): Promise<void> => this.fetchGroups(),
    ];

    const deviceId = this.props.selectedDeviceId;

    if (!deviceId) {
      this.setState({ drawerOpen: true });
    } else {
      promiseFactories.push(async (): Promise<void> => this.fetchDisplayName(deviceId));
    }

    if (await AuthWrapper.isCurrentUserAuthenticated()) {
      await Promise.all(promiseFactories.map(factory => factory()));
    }
  }

  public async componentDidUpdate(prevProps: Readonly<Props>): Promise<void> {
    const currentId = this.props.selectedDeviceId, oldId = prevProps.selectedDeviceId;

    if (currentId && currentId !== oldId) {
      await this.fetchDisplayName(currentId);
      this.setState({ drawerOpen: false });
    } else if (!currentId && currentId !== oldId) {
      this.setState({
        selectedDisplayName: undefined,
      });
    }
  }

  private async fetchGroups(): Promise<void> {
    const groups: DeviceGroup[] = await BackendFactory.getBackend().getRootDeviceGroups();
    console.log(`Found ${groups.length} root groups`);
    this.setState({ groups });
  }

  private async fetchDisplayName(deviceId: string): Promise<void> {
    const device = await BackendFactory.getBackend().getDevice(deviceId);

    if (device) {
      this.setState({ selectedDisplayName: getDisplayName(device) });
    }
  }

  private toggleDrawer = (): void => {
    this.setState((prevState: State) => ({
      drawerOpen: !prevState.drawerOpen,
    }));
  };

  private readonly onSearchTriggered = (searchString: string): void => {
    this.setState({ searchFilter: searchString });
  };

  private renderListBrowserMode(): ReactNode {
    return (
      <DeviceList
        searchFilter={this.state.searchFilter}
        selectedDevice={this.props.selectedDeviceId}
        onDeviceSelect={this.props.onDeviceSelect}
      />
    );
  }

  private renderTreeBrowserMode(): ReactNode {
    if (!this.state.groups) return <Loader />;

    return (
      <DeviceTree
        groups={this.state.groups}
        searchFilter={getCaseInsensitiveSearchFilter(this.state.searchFilter)}
        editMode={false}
        keepSingleRootExpanded={true}
        selectedDevice={this.props.selectedDeviceId}
        selectedGroup={this.props.selectedGroupId}
        onDeviceSelect={this.props.onDeviceSelect}
        onGroupSelect={this.props.onGroupSelect}
        showEventsMode
      />
    );
  }

  public render(): ReactNode {
    const borderStyle: React.CSSProperties = { borderLeft: "2px solid rgba(0, 0, 0, 0.54)" };

    return (
      <div className="iot-device-list-container col-sm-3 col-xsm-12">
        <Tooltip arrow placement="right" title="Avaa muuntamon valinta" enterDelay={TOOLTIP_DELAY_MS}>
          <IconButton
            onClick={this.toggleDrawer}
          >
            <ArrowForwardIcon
              style={borderStyle}
            />
          </IconButton>
        </Tooltip>
        {this.state.selectedDisplayName && <span>{this.state.selectedDisplayName}</span>}
        <Drawer
          open={this.state.drawerOpen}
          classes={{ paper: "col-xlg-3 col-sm-4 col-xsm-9 iot-drawer" }}
          onClose={this.toggleDrawer}
        >
          <div className="iot-drawer-list">
            <div className="iot-drawer-list-header">
              <Tooltip arrow placement="right" title="Sulje" enterDelay={TOOLTIP_DELAY_MS}>
                <IconButton
                  onClick={this.toggleDrawer}
                >
                  <ArrowForwardIcon
                    style={{ ...borderStyle, transform: "rotate(180deg)" }}
                  />
                </IconButton>
              </Tooltip>
              <span className="iot-drawer-list-header-title">{translations.deviceDrawer.texts.title()}</span>
            </div>
            <SearchBar
              searchString={this.state.searchFilter}
              onSearchTriggered={this.onSearchTriggered}
              className="tree-search-bar-container"
            />
            {this.state.searchFilter.startsWith("$") ? this.renderListBrowserMode() : this.renderTreeBrowserMode()}
          </div>
        </Drawer>
      </div>
    );
  }
}

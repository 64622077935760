/* Copyright */
import React, { Fragment } from "react";
import MUIDataTable, { MUIDataTableColumnDef, Display, MUIDataTableMeta, MUIDataTableOptions, SelectableRows, MUIDataTableColumn } from "mui-datatables";
import { Button, IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { EventRepositoryListener } from "../../data/events/EventRepositoryListener";
import EventsRepository from "../../data/events/EventsRepository";
import { convertTimestampToString, DateTimeFormatTarget, getDisplayName, parseDoorDataValue } from "../../data/utils/Utils";
import { ClientProperties } from "../../data/clientSpecific/ClientProperties";
import Event, { EventState } from "../../data/clientSpecific/Event";
import { Maybe, Nullable } from "../../types/aliases";
import Device from "../../data/device/Device";
import CommentPopup from "../history-view/components/comment-popup";
import Loader from "./loader";
import AuthWrapper from "../../data/auth/AuthWrapper";
import Data from "../../data/data/Data";
import { BackendActionStatus } from "../../types/utu-types";
import { constantSensorDataDictionary, sensorTypeDisplayNameDictionary } from "../../data/utu-dictionary-data/UtuDictionaryData";
import { translations } from "../../generated/translationHelper";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { DevicePathRouterProps } from "../../types/routerprops";
import DeviceNavigationCache from "../../utils/DeviceNavigationCache";
import Paths from "../Paths";
import SigmaDCell from "../history-view/components/sigmaD-cell";

interface Props extends RouteComponentProps<DevicePathRouterProps> {
  devices: Maybe<Device[]>;
  showOnlyActiveEvents: boolean;
  latestData?: Data[];
  hideThingColumn?: boolean;
  hideLatestSensorValueColumn?: boolean;
  setHistoryViewEventsTableToMountExpanded?: () => void;
  redirectOnDeactivate?: boolean;
}

export type EventComment = Pick<Event, "commentAuthor" | "commentText" | "commentTimestamp" | "eventId" | "timestamp">;

type EventWithIdentifyingProperties = Pick<Event, "eventId" | "deviceId" | "timestamp" | "updatedTimestamp">;

interface State {
  backendActionLoading: boolean;
  backendActionStatus: Nullable<BackendActionStatus>;
  comment: Nullable<EventComment>;
  commentPopupOpen: boolean;
  eventsToIgnoreInEventStateSorting: EventWithIdentifyingProperties[];
  loading: boolean;
  username: string;
}

type EventTableCell = Nullable<string | number | JSX.Element>;
type EventTableRow = EventTableCell[];

class EventsTable extends React.Component<Props, State> implements EventRepositoryListener {


  public constructor(props: Props) {
    super(props);

    this.state = {
      backendActionLoading: false,
      backendActionStatus: null,
      comment: null,
      commentPopupOpen: false,
      eventsToIgnoreInEventStateSorting: [],
      loading: false,
      username: "",
    };

    this.onCommentCancel = this.onCommentCancel.bind(this);
    this.onCommentSave = this.onCommentSave.bind(this);
  }

  private readonly tableOptions: MUIDataTableOptions = {
    filterType: "checkbox",
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    customToolbarSelect: (_selectedRows: any, _displayData: Array<{ data: any[]; dataIndex: number }>, _setSelectedRows: any): React.ReactNode => {
      // TODO: This is not supported yet.
      return null;
    },
    onRowsDelete: (_rowsDeleted: { lookup: { [dataIndex: number]: boolean }; data: { index: number; dataIndex: number }[] }) => void{ // eslint-disable-line @typescript-eslint/no-explicit-any
      // TODO: This is not supported yet.
    },
    selectableRows: "none" as SelectableRows,
    selectableRowsOnClick: true,
    customSort: (data, colIndex, order) => {
      console.log("customSort, colIndex: ", colIndex);

      if (colIndex === 5) {
        return this.sortSensorValues(data, order, colIndex);
      } else if (colIndex === 4 || colIndex === 10) {
        return this.sortByTimestampAndState(data, order, colIndex);
      } else {
        return data.sort((a, b) => {
          return (a.data[colIndex] < b.data[colIndex] ? -1 : 1) * (order === "desc" ? 1 : -1);
        });
      }
    },
    onColumnSortChange: (_changedColumn: string, _direction: string) => {
      if (this.state.eventsToIgnoreInEventStateSorting.length > 0) {
        this.setState({ eventsToIgnoreInEventStateSorting: [] });
      }
    },
    textLabels: {
      body: {
        noMatch: translations.events.texts.noEventsFound(),
        toolTip: translations.common.texts.sort(),
      },
      pagination: {
        next: translations.common.buttons.nextPage(),
        previous: translations.common.buttons.previousPage(),
        rowsPerPage: translations.common.inputs.rowsPerPage(),
        displayRows: translations.common.texts.of(),
      },
      toolbar: {
        downloadCsv: translations.common.buttons.downloadCsv(),
        filterTable: translations.common.buttons.filterTable(),
        print: translations.common.buttons.print(),
        search: translations.common.buttons.search(),
        viewColumns: translations.common.buttons.viewColumns(),
      },
      filter: {
        reset: translations.common.buttons.reset(),
        title: translations.common.texts.filters().toUpperCase(),
      },
      viewColumns: {
        title: translations.common.texts.showColumns(),
      },
    },
  };

  private readonly tableColumns: MUIDataTableColumnDef[] = [
    {
      name: "id",
      options: {
        filter: false,
        sort: false,
        display: "false" as Display,
        viewColumns: false,
      },
    },
    {
      name: "deviceId",
      options: {
        filter: false,
        sort: false,
        display: "false" as Display,
        viewColumns: false,
      },
    },
    {
      name: "eventState",
      label: translations.events.data.state(),
      options: {
        filter: true,
        sort: true,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        customBodyRender: (_value: any, tableMeta: MUIDataTableMeta, _updateValue: any): Nullable<JSX.Element> => {
          return this.renderAcknowledgementCell(tableMeta);
        },
      },
    },
    {
      name: "eventName",
      label: ClientProperties.EVENT_TABLE_LABELS.eventName,
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "timestamp",
      label: translations.common.data.timestamp(),
      options: {
        sortDirection: "asc",
        filter: true,
        sort: true,
      },
    },
    {
      name: "eventSensorValue",
      label: ClientProperties.EVENT_TABLE_LABELS.eventSensorValue,
      options: {
        filter: true,
        sort: true,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        customBodyRender: (_value: any, tableMeta: MUIDataTableMeta, _updateValue: any): Nullable<JSX.Element> => {
          return this.renderSensorValueCell(tableMeta, 5);
        },
      },
    },
    {
      name: "latestSensorValue",
      label: ClientProperties.EVENT_TABLE_LABELS.latestSensorValue,
      options: {
        filter: true,
        sort: true,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        customBodyRender: (_value: any, tableMeta: MUIDataTableMeta, _updateValue: any): Nullable<JSX.Element> => {
          return this.renderSensorValueCell(tableMeta, 6);
        },
      },
    },
    {
      name: "thingId",
      label: ClientProperties.EVENT_TABLE_LABELS.thingId,
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "commentText",
      label: ClientProperties.EVENT_TABLE_LABELS.commentText,
      options: {
        filter: true,
        sort: true,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        customBodyRender: (_value: any, tableMeta: MUIDataTableMeta, _updateValue: any): Nullable<JSX.Element> => {
          return this.renderCommentCell(tableMeta);
        },
      },
    },
    {
      name: "commentAuthor",
      label: ClientProperties.EVENT_TABLE_LABELS.commentAuthor,
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "commentTimestamp",
      label: ClientProperties.EVENT_TABLE_LABELS.commentTimestamp,
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "eventId",
      options: {
        filter: false,
        sort: false,
        display: "false" as Display,
        viewColumns: false,
      },
    },
    {
      name: "updatedTimestamp",
      options: {
        filter: true,
        sort: true,
        display: "false" as Display,
        viewColumns: false,
      },
    },
  ];

  public async componentDidMount(): Promise<void> {
    if (!EventsRepository.instance.isInitialized()) {
      this.setState({ loading: true });
    }
    EventsRepository.instance.addListener(this);
    await this.getUserName();
  }

  public componentDidUpdate(_prevProps: Props, prevState: State): void {
    if (!this.state.commentPopupOpen && prevState.commentPopupOpen) {

      if (this.state.backendActionStatus !== null) {
        this.setState({ backendActionStatus: null });
      }

      if (this.state.comment !== null) {
        this.setState({ comment: null });
      }
    }

    // set loading to false in case onEventsInitDone is executed before getUserName is resolved
    if ((this.state.username && !prevState.username) && this.state.loading && EventsRepository.instance.isInitialized()) {
      this.setState({ loading: false });
    }
  }

  public componentWillUnmount(): void {
    EventsRepository.instance.removeListener(this);
  }

  public onEventsInitDone(): void {
    if (this.state.username) {
      this.setState({ loading: false });
    }
  }

  public onEvent(event: Event): void {
    console.log("onEvent");

    if (this.props.devices?.map((device: Device): string => device.getId()).includes(event.deviceId)) {
      this.forceUpdate();
    }
  }

  public onEventStateChanged(event: Event): void {
    console.log("onEventStateChanged");

    if (this.props.devices?.map((device: Device): string => device.getId()).includes(event.deviceId)) {
      this.forceUpdate();
    }
  }

  private renderCommentPopup(): Maybe<JSX.Element> {
    if (!this.state.commentPopupOpen) {
      return;
    } else {
      return (
        <CommentPopup
          backendActionLoading={this.state.backendActionLoading}
          backendActionStatus={this.state.backendActionStatus}
          comment={this.state.comment}
          onCancel={this.onCommentCancel}
          onSave={this.onCommentSave}
          open={this.state.commentPopupOpen}
        />
      );
    }
  }

  private renderAcknowledgementCell(tableMeta: MUIDataTableMeta): Nullable<JSX.Element> {
    if (tableMeta == null) {
      return null;
    }
    const timestamp: string = tableMeta.rowData[0];
    const deviceId: string = tableMeta.rowData[1];
    const event = EventsRepository.instance.getAllEvents().find((event) => {
      return event.deviceId === deviceId && event.timestamp === timestamp;
    });

    if (event) {

      if (event.eventState === EventState.Active) {
        const eventComment: EventComment = {
          eventId: tableMeta.rowData[this.getAdjustableIndex(11, this.getAdjustmentFactor())],
          timestamp: tableMeta.rowData[0],
          commentText: tableMeta.rowData[this.getAdjustableIndex(8, this.getAdjustmentFactor())],
          commentAuthor: this.state.username,
          commentTimestamp: null,
        };
        return (
          <Button
            color="secondary"
            variant="contained"
            onClick={(): void => this.handleAcknowledgeButtonClick(eventComment, deviceId)}
          >
            {translations.events.buttons.acknowledge()}
          </Button>
        );
      } else {
        return (
          <Button
            variant="contained"
            disabled={true}
          >
            Kuitattu
          </Button>
        );
      }
    }
    return null;
  }

  private renderCommentCell(tableMeta: MUIDataTableMeta): Nullable<JSX.Element> {
    const eventComment: EventComment = {
      eventId: tableMeta.rowData[this.getAdjustableIndex(11, this.getAdjustmentFactor())],
      timestamp: tableMeta.rowData[0],
      commentText: tableMeta.rowData[this.getAdjustableIndex(8, this.getAdjustmentFactor())],
      commentAuthor: this.state.username,
      commentTimestamp: null,
    };
    const timestamp: string = tableMeta.rowData[0];
    const deviceId: string = tableMeta.rowData[1];
    const event = EventsRepository.instance.getAllEvents().find(event => {
      return event.deviceId === deviceId && event.timestamp === timestamp;
    });

    if (event) {
      return (
        <div className="comment-cell-container">
          <div className="comment-cell-text">{eventComment.commentText}</div>
          {event.eventState !== EventState.Active
            ?
            <IconButton
              className="comment-cell-icon"
              type="submit"
              aria-label="edit"
              onClick={(): void => this.openCommentDialog(eventComment)}
            >
              <EditIcon />
            </IconButton>
            : null}
        </div>
      );
    } else {
      return null;
    }
  }

  private renderSensorValueCell(tableMeta: MUIDataTableMeta, valueColIndex: 5 | 6): Nullable<JSX.Element> {
    if (tableMeta == null) {
      return null;
    }
    const eventIdColIndex = this.getAdjustableIndex(11, this.getAdjustmentFactor());
    const eventId = tableMeta.rowData[eventIdColIndex];

    // TODO: read this type of values from a constant
    if (eventId.startsWith("sigD")) {
      const value: string = tableMeta.rowData[valueColIndex];
      return <SigmaDCell value={value}/>;
    } else {
      return tableMeta.rowData[valueColIndex];
    }
  }

  private renderEventsTable(): JSX.Element {
    if (this.state.loading) {
      return <Loader/>;
    } else {
      return (
        <MUIDataTable
          title={translations.events.texts.events()}
          data={this.getTableData()}
          columns={this.getTableColumns()}
          options={this.tableOptions}
        />
      );
    }
  }

  private getTableColumns(): MUIDataTableColumnDef[] {
    const { hideThingColumn, hideLatestSensorValueColumn } = this.props;
    let columns = this.tableColumns;
    if (hideThingColumn) columns = columns.filter(column => (column as MUIDataTableColumn).label !== ClientProperties.EVENT_TABLE_LABELS.thingId);
    if (hideLatestSensorValueColumn) columns = columns.filter(column => (column as MUIDataTableColumn).label !== ClientProperties.EVENT_TABLE_LABELS.latestSensorValue);
    return columns;
  }

  private navigateToHistoryViewToDeactivateEvent = async (deviceId: string): Promise<void> => {
    this.props.setHistoryViewEventsTableToMountExpanded?.();
    await DeviceNavigationCache.getInstance().navigateToDevice(this.props, deviceId);
    DeviceNavigationCache.getInstance().navigateToPath(this.props, Paths.HISTORY);
  };

  private handleAcknowledgeButtonClick = (eventComment: EventComment, deviceId: string): void => {
    if (this.props.redirectOnDeactivate) {
      this.navigateToHistoryViewToDeactivateEvent(deviceId);
    } else {
      this.openCommentDialog(eventComment);
    }
  };

  public render(): JSX.Element {
    return (
      <Fragment>
        {this.renderEventsTable()}
        {this.renderCommentPopup()}
      </Fragment>
    );
  }

  private getTableData(): EventTableRow[] {
    const { devices, hideLatestSensorValueColumn, hideThingColumn, showOnlyActiveEvents } = this.props;
    const rows: EventTableRow[] = [];

    if (devices) {
      const deviceIds: string[] = devices.map((device: Device): string => device.getId());
      EventsRepository.instance.getAllEvents().forEach((event: Event) => {
        if ((!showOnlyActiveEvents || event.eventState === EventState.Active) && deviceIds.includes(event.deviceId)) {
          const row: EventTableRow = [];
          row.push(event.timestamp);
          row.push(event.deviceId);
          row.push(event.eventState ?? null);
          row.push(this.getEventNameRowValue(event));
          row.push(convertTimestampToString(Number(event.timestamp), DateTimeFormatTarget.EventsTable));
          row.push(this.getFormattedSensorValue(event));
          !hideLatestSensorValueColumn && row.push(this.getLatestSensorValue(event));
          !hideThingColumn && row.push(this.getDeviceDisplayName(event.deviceId) ?? null);
          row.push(event.commentText ?? null);
          row.push(event.commentAuthor ?? null);
          row.push(event.commentTimestamp ? convertTimestampToString(Number(event.commentTimestamp), DateTimeFormatTarget.EventsTable) : null);
          row.push(event.eventId ?? null);
          row.push(event.updatedTimestamp ?? null);
          rows.push(row);
        }
      });
    }
    return rows;
  }

  private getEventNameRowValue({ eventId, metadata }: Event): Nullable<string> {
    // getEventDescription returns eventId if description is not found - we don't want to show eventId to the user
    if (eventId && EventsRepository.instance.getEventDescription(eventId) !== eventId) {
      return EventsRepository.instance.getEventDescription(eventId);
    } else if (metadata) {
      return metadata;
    } else {
      return null;
    }
  }

  private getLatestSensorValue({ sensorName, deviceId }: Event): EventTableCell {
    if (this.props.latestData) {
      const deviceLatestData = this.props.latestData.find(data => data.deviceId === deviceId);

      if (deviceLatestData && sensorName) {
        let latestSensorValue = deviceLatestData[sensorName];
        const { door_lv, door_mv } = constantSensorDataDictionary;

        if (sensorName === door_lv.name || sensorName === door_mv.name) {
          latestSensorValue = parseDoorDataValue(latestSensorValue);
        }

        return (latestSensorValue && (typeof latestSensorValue === "number" || typeof latestSensorValue === "string")) ? latestSensorValue : null;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  private getAdjustableIndex(defaultIndex: number, adjustmentFactor?: 1 | 2): number {
    if (!adjustmentFactor) {
      return defaultIndex;
    } else {
      return defaultIndex - adjustmentFactor;
    }
  }

  private getAdjustmentFactor(): Maybe<1 | 2> {
    const { hideThingColumn, hideLatestSensorValueColumn } = this.props;

    if (hideThingColumn && hideLatestSensorValueColumn) {
      return 2;
    } else if (hideThingColumn || hideLatestSensorValueColumn) {
      return 1;
    }
  }

  private getFormattedSensorValue({ sensorName, sensorValue }: Event): EventTableCell {
    const { cab, transf, mic, hfct } = sensorTypeDisplayNameDictionary;
    const { door_lv, door_mv } = constantSensorDataDictionary;

    if (sensorName && sensorValue) {
      if (sensorName.includes(cab.name) || sensorName.includes(transf.name)) {
        return sensorValue.toFixed(1);
      } else if (sensorName.includes(mic.name) || sensorName.includes(hfct.name)){
        return Math.round(sensorValue);
      } else if (sensorName === door_lv.name || sensorName === door_mv.name){
        return parseDoorDataValue(sensorValue) ?? null;
      } else {
        return sensorValue;
      }
    } else {
      return null;
    }
  }

  private getDeviceDisplayName(deviceId: string): Maybe<string> {
    if (this.props.devices) {
      for (const device of this.props.devices) {
        if (device.getId() === deviceId) {
          return getDisplayName(device);
        }
      }
      return deviceId; // should never get here
    }
  }

  private openCommentDialog(comment: EventComment): void {
    this.setState({ comment, commentPopupOpen: true });
  }

  private onCommentCancel(): void {
    this.setState({ commentPopupOpen: false });
  }

  private async onCommentSave(comment: EventComment): Promise<void> {
    const event = EventsRepository.instance.getAllEvents().find(({ eventId, timestamp }) => {
      return eventId === comment.eventId && timestamp === comment.timestamp;
    });

    if (event) {
      event.commentAuthor = comment.commentAuthor;
      event.commentText = comment.commentText;
      event.commentTimestamp = comment.commentTimestamp;

      if (event.eventState === EventState.Active) {
        const eventToAddToList = {
          deviceId: event.deviceId,
          eventId: event.eventId,
          timestamp: event.timestamp,
          updatedTimestamp: event.updatedTimestamp,
        };
        this.setState({ eventsToIgnoreInEventStateSorting: [...this.state.eventsToIgnoreInEventStateSorting, eventToAddToList] });
      }
      this.setState({
        backendActionLoading: true,
      });
      await EventsRepository.instance.deactivateEvent(event);
      const response = await EventsRepository.instance.deactivateEvent(event);
      const isSuccess = response === "success";
      this.setState({
        backendActionLoading: false,
        backendActionStatus: isSuccess ? BackendActionStatus.SUCCESS : BackendActionStatus.ERROR,
      });
    } else {
      console.log(`event with eventId ${comment.eventId} and timestamp ${comment.timestamp} was not found in repository in EventsTable.onCommentSave`);
    }
  }

  private async getUserName(): Promise<void> {
    const userName = await AuthWrapper.getCurrentAuthenticatedUsername();
    const fName = await AuthWrapper.getFamilyName();
    const familyName = (fName ?? "").trim();
    let username = await AuthWrapper.getGivenName();
    username = (username ?? "").trim();

    if (username.length > 0 && familyName.length > 0) {
      username = username + " " + familyName;
    } else if (familyName.length > 0) {
      username = familyName;
    }

    if (username.length < 2) {
      username = userName;
    }
    this.setState({ username });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private sortByTimestampAndState(data: any[], order: string, colIndex: number): any[] {
    return data.sort((a, b) => {
      function parse(x: string): string {
        return (
          x.substring(6, 10) + // year
            x.substring(3, 5) + // month
            x.substring(0, 2) + // day
            x.substring(11, 13) + // hour
            x.substring(14, 16) + // min
            x.substring(17, 19)   // sec
        );
      }
      const aParsed = (a.data[colIndex] !== null && a.data[colIndex].length > 1) ? parse(a.data[colIndex]) : "";
      const bParsed = (b.data[colIndex] !== null && b.data[colIndex].length > 1) ? parse(b.data[colIndex]) : "";
      const stateColIndex = 2;

      if (!this.isItemInIgnoreList(a.data) && !this.isItemInIgnoreList(b.data)) {
        if (a.data[stateColIndex] < b.data[stateColIndex]) {
          return -1 * (order === "asc" ? 1 : -1);
        }

        if (a.data[stateColIndex] > b.data[stateColIndex]) {
          return 1 * (order === "asc" ? 1 : -1);
        }
      }

      if (aParsed < bParsed) {
        return -1 * (order === "desc" ? 1 : -1);
      } else {
        return 1 * (order === "desc" ? 1 : -1);
      }
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private isItemInIgnoreList(item: any): boolean {
    const deviceIdColIndex = 1;
    const timestampColIndex = 4;
    const eventIdColIndex = this.getAdjustableIndex(11, this.getAdjustmentFactor());
    const updatedTimestampColIndex = this.getAdjustableIndex(12, this.getAdjustmentFactor());
    const isDeviceIdMatch = this.state.eventsToIgnoreInEventStateSorting.some(({ deviceId }) => item[deviceIdColIndex] === deviceId);
    const isEventIdMatch = item[eventIdColIndex] !== null && this.state.eventsToIgnoreInEventStateSorting.some(({ eventId }) => item[eventIdColIndex] === eventId);
    const isTimestampMatch = this.state.eventsToIgnoreInEventStateSorting.some(({ timestamp }) => item[timestampColIndex] === convertTimestampToString(Number(timestamp), DateTimeFormatTarget.EventsTable));
    const isUpdatedTimestampMatch = item[updatedTimestampColIndex] !== null && this.state.eventsToIgnoreInEventStateSorting.some(({ updatedTimestamp }) => item[updatedTimestampColIndex] === updatedTimestamp);

    if (isDeviceIdMatch && isEventIdMatch && isTimestampMatch && isUpdatedTimestampMatch) {
      return true;
    } else {
      return false;
    }
  }

  //TODO: handle non-numeric values in sorting
  private sortSensorValues(data: any[], order: string, colIndex: number): any[] { // eslint-disable-line @typescript-eslint/no-explicit-any
    return data.sort((a, b) => {
      return ((order === "desc" ? b : a).data[colIndex] - (order === "desc" ? a : b).data[colIndex]);
    });
  }

}

export default withRouter(EventsTable);

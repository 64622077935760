/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import RuuviIcon from "../../../assets/iot-item-24px.svg";
import { RuuviTagHWState } from "./RuuviTagHWState";
import { RuuviTagHWStateProperties } from "./RuuviTagHWStateProperties";
import AWSThing from "../../../data/device/AWSThing";
import { RuuviTagHWData } from "./RuuviTagHWData";
import AWSBackend from "../../../data/backend/AWSBackend";
import { DeviceParameters } from "../../../data/device/Device";

export class RuuviTagHW extends AWSThing<RuuviTagHWData, RuuviTagHWState> {
  public static type = "RuuviTag";

  public constructor(backend: AWSBackend, params: DeviceParameters) {
    super(RuuviTagHW.type, backend, params);
  }

  public getIcon(): string {
    return RuuviIcon;
  }

  public createState(timestamp?: number, reported?: Partial<RuuviTagHWStateProperties>, desired?: Partial<RuuviTagHWStateProperties>):
  RuuviTagHWState {
    return new RuuviTagHWState(this.getId(), new RuuviTagHWStateProperties(reported ?? {}), new RuuviTagHWStateProperties(desired ?? {}), timestamp);
  }
}

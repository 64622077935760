/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/
// TODO: remove header

import React, { Component, ReactNode } from "react";
import { Box } from "@material-ui/core";
import { PieChart } from "react-minimal-pie-chart";
import Device from "../../../data/device/Device";
import { EventRepositoryListener } from "../../../data/events/EventRepositoryListener";
import Event from "../../../data/clientSpecific/Event";
import EventsRepository from "../../../data/events/EventsRepository";
import { Maybe, Nullable } from "../../../types/aliases";
import Loader from "../../ui/loader";

interface Props {
  devices: Maybe<Device[]>;
}

interface State {
  activeAlarms: Nullable<number>;
}

export default class CircularIndicator extends Component<Props, State> implements EventRepositoryListener {

  public constructor(props: Props) {
    super(props);
    this.state = {
      activeAlarms: null,
    };
  }

  public componentDidMount(): void {
    EventsRepository.instance.addListener(this);

    if (this.props.devices && EventsRepository.instance.isInitialized()) {
      this.setState({ activeAlarms: this.countAlarmsForDevices() });
    }

  }

  public componentDidUpdate(prevProps: Props): void {
    if (this.props.devices && prevProps.devices !== this.props.devices &&
      EventsRepository.instance.isInitialized()) {
      this.setState({ activeAlarms: this.countAlarmsForDevices() });
    }
  }

  public componentWillUnmount(): void {
    EventsRepository.instance.removeListener(this);
  }

  public onEventsInitDone(): void {
    if (this.props.devices) {
      this.setState({ activeAlarms: this.countAlarmsForDevices() });
    }
  }

  public onEvent(_event: Event): void {
    if (this.props.devices) {
      this.setState({ activeAlarms: this.countAlarmsForDevices() });
    }
  }

  public onEventStateChanged(_event: Event): void {
    if (this.props.devices) {
      this.setState({ activeAlarms: this.countAlarmsForDevices() });
    }
  }

  private countAlarmsForDevices(): number {
    const allActiveAlarms = EventsRepository.instance.getAllActiveEvents();
    const deviceIdsWithActiveAlarm: string[] = [];

    // non-null assertion: method only called if this.props.devices !== undefined
    for (const device of this.props.devices!) {
      if (allActiveAlarms.filter(({ deviceId }) => deviceId === device.getId()).length > 0) {
        deviceIdsWithActiveAlarm.push(device.getId());
      }
    }
    return deviceIdsWithActiveAlarm.length;
  }

  private renderRatioText(): Maybe<JSX.Element> {
    if (this.props.devices && this.state.activeAlarms !== null) {
      const percent: string = (100 - (this.state.activeAlarms * 100 / this.props.devices.length)).toFixed();
      return (
        <>
          <div className="ratio-text-1">{percent}%</div>
          <div className="ratio-text-2">OK</div>
        </>
      );
    }
  }

  private renderPieChart(): JSX.Element {
    if (this.props.devices && this.state.activeAlarms !== null) {
      return (
        <div className="indicator">
          <PieChart
            data={[
              {
                color: "#E53935",
                title: "Hälytys",
                value: this.state.activeAlarms!,
              },
              {
                color: "#A1D3C2",
                title: "Tila OK",
                value: this.props.devices.length - this.state.activeAlarms,
              },
            ]}
            labelPosition={50}
            lengthAngle={360}
            lineWidth={26}
            paddingAngle={0}
            radius={26}
            rounded={false}
            startAngle={90}
            viewBoxSize={[
              100,
              100,
            ]}
          />
        </div>
      );
    } else {
      return (
        <Box position="absolute" top="4rem" width="468px">
          <Loader/>
        </Box>
      );
    }
  }

  public render(): ReactNode {
    const dot = "\u25CF";
    return (
      <div className="circular-indicator-container">
        <div className="headline-text">Muuntamojen tila</div>
        {this.renderRatioText()}
        <div className="legend-container">
          <div className="legend-dot-ok">{dot}</div>
          <div className="legend-text">Tila OK</div>
          <div className="legend-dot-alarm">{dot}</div>
          <div className="legend-text">Hälytys</div>
        </div>
        {this.renderPieChart()}
        <hr />
      </div>
    );
  }
}

/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import Data from "./Data";
import BaseObservable from "../observer/BaseObservable";
import { Maybe } from "../../types/aliases";

export interface LatestDataObserver {
  onDataUpdate(subject: LatestData): void;
}

export abstract class LatestData<TData extends Data = Data> extends BaseObservable<LatestDataObserver> {
  public abstract getId(): string;

  public abstract getData(): Maybe<TData>;

  public abstract fetch(): Promise<void>;

  public abstract setData(data: TData): void;
}

export default LatestData;

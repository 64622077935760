/* Copyright */
import { Box, Button, Dialog, DialogActions, FormControl, FormGroup, Mark, Typography } from "@material-ui/core";
import React from "react";
import { addOnConfigSelectionDictionary } from "../../../data/utu-dictionary-data/UtuDictionaryData";
import { Fallible, Nullable } from "../../../types/aliases";
import { BackendActionStatus, MeasurementBoardConfig } from "../../../types/utu-types";
import Loader from "../../ui/loader";
import AddOnConfigSelector from "./add-on-config-selector";
import GainSelector from "./gain-selector";
import SigmaDSelection from "./sigmaD-selection";

interface Props {
  actionStatus: Nullable<BackendActionStatus>;
  addOnConfig: Nullable<MeasurementBoardConfig>;
  close: () => void;
  loading: boolean;
  open: boolean;
  save: (config: MeasurementBoardConfig) => Promise<void>;
}

interface State {
  cab: boolean;
  transf: boolean;
  hfct_g: Fallible<number>;
  mic_g: Fallible<number>;
  door_lv: boolean;
  door_mv: boolean;
  sigD1: Fallible<string>;
  sigD2: Fallible<string>;
  sigD3: Fallible<string>;
  sigD4: Fallible<string>;
  sigD5: Fallible<string>;
}

// TODO: move to global type files?
interface PDGainValueMap {
  readonly [index: number]: number;
  1: number;
  2: number;
  4: number;
  5: number;
  8: number;
  10: number;
  16: number;
  32: number;
}

const pdGainMap: PDGainValueMap = {
  1: 0,
  2: 1,
  4: 2,
  5: 3,
  8: 4,
  10: 5,
  16: 6,
  32: 7,
};

const DEFAULT_PD_GAIN_VALUE = 1;

class DeviceAddOnConfigDialog extends React.Component <Props, State> {

  public constructor(props: Props) {
    super(props);
    this.state = this.getInitialState();
    this.save = this.save.bind(this);
  }

  private renderLabel(): JSX.Element {
    if (this.props.actionStatus !== null || this.props.loading) {
      return (
        <div className="details-edit-label">
        Valitse konfiguraatio laitteelle
        </div>
      );
    } else {
      return (
        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
          <div className="details-edit-label" style={{ width: "auto", marginBottom: 0 }}>
          Valitse konfiguraatio laitteelle
          </div>
          <div className="buttons-container" style={{ width: "auto" }}>
            <DialogActions style={{ justifyContent: "center" }}>
              <Button
                onClick={(): void => this.props.close()}
                size="small"
                variant="contained"
                color="primary"
              >
            Peruuta
              </Button>
              <Button
                disabled={!this.validateSigmaInput()}
                size="small"
                variant="contained"
                onClick={this.save}
                color="primary"
              >
            Tallenna
              </Button>
            </DialogActions>
          </div>
        </Box>
      );
    }
  }

  private renderConfirmBody(): JSX.Element {
    const message = this.props.actionStatus === BackendActionStatus.SUCCESS
      ? "Laitekonfiguraatio on nyt tallennettu"
      : "Laitekonfiguraation tallentaminen epäonnistui";
    return (
      <>
        <div className="message-or-loader-container">
          <Typography>{message}</Typography>
        </div>
        <div className="buttons-container">
          <DialogActions style={{ justifyContent: "center" }}>
            <Button
              color="primary"
              onClick={(): void => this.props.close()}
              size="small"
              variant="contained"
            >
            OK
            </Button>
          </DialogActions>
        </div>
      </>
    );
  }

  private renderEditBody(): JSX.Element {
    return (
      <div className="details-edit-3-3-container">
        <FormControl component="fieldset" style={{ width: "100%" }}>
          <FormGroup>
            <AddOnConfigSelector
              checked={this.state.cab}
              label={addOnConfigSelectionDictionary.cab.displayName}
              name={addOnConfigSelectionDictionary.cab.displayName}
              onChange={({ target }): void => this.setState({ cab: target.checked })}
            />
            <AddOnConfigSelector
              checked={this.state.transf}
              label={addOnConfigSelectionDictionary.transf.displayName}
              name={addOnConfigSelectionDictionary.transf.displayName}
              onChange={({ target }): void => this.setState({ transf: target.checked })}
            />
            <GainSelector
              showSlider={this.state.hfct_g !== false}
              onSliderChange={(_event, value: number | number[]): void => this.setState({ hfct_g: value as number })}
              //cast: GainSelector uses currentSliderValue only when this.state.hfct_g !== false
              currentSliderValue={this.state.hfct_g as number}
              sliderDefaultValue={DEFAULT_PD_GAIN_VALUE}
              sliderMarks={this.getGainSelectorMarks()}
            >
              <AddOnConfigSelector
                checked={this.state.hfct_g !== false}
                label={addOnConfigSelectionDictionary.hfct.displayName}
                name={addOnConfigSelectionDictionary.hfct.displayName}
                onChange={({ target }): void => this.setState({ hfct_g: target.checked ? DEFAULT_PD_GAIN_VALUE : false })}
              />
            </GainSelector>
            <GainSelector
              showSlider={this.state.mic_g !== false}
              onSliderChange={(_event, value: number | number[]): void => this.setState({ mic_g: value as number })}
              //cast: GainSelector uses currentSliderValue only when this.state.mic_g !== false
              currentSliderValue={this.state.mic_g as number}
              sliderDefaultValue={DEFAULT_PD_GAIN_VALUE}
              sliderMarks={this.getGainSelectorMarks()}
            >
              <AddOnConfigSelector
                checked={this.state.mic_g !== false}
                label={addOnConfigSelectionDictionary.mic.displayName}
                name={addOnConfigSelectionDictionary.mic.displayName}
                onChange={({ target }): void => this.setState({ mic_g: target.checked ? DEFAULT_PD_GAIN_VALUE : false })}
              />
            </GainSelector>
            <AddOnConfigSelector
              checked={this.state.door_lv}
              label={addOnConfigSelectionDictionary.door_lv.displayName}
              name={addOnConfigSelectionDictionary.door_lv.displayName}
              onChange={({ target }): void => this.setState({ door_lv: target.checked })}
            />
            <AddOnConfigSelector
              checked={this.state.door_mv}
              label={addOnConfigSelectionDictionary.door_mv.displayName}
              name={addOnConfigSelectionDictionary.door_mv.displayName}
              onChange={({ target }): void => this.setState({ door_mv: target.checked })}
            />
            <SigmaDSelection
              sigD1={this.state.sigD1}
              sigD2={this.state.sigD2}
              sigD3={this.state.sigD3}
              sigD4={this.state.sigD4}
              sigD5={this.state.sigD5}
              onSigD1Change={(value: Fallible<string>): void => this.setState({ sigD1: value })}
              onSigD2Change={(value: Fallible<string>): void => this.setState({ sigD2: value })}
              onSigD3Change={(value: Fallible<string>): void => this.setState({ sigD3: value })}
              onSigD4Change={(value: Fallible<string>): void => this.setState({ sigD4: value })}
              onSigD5Change={(value: Fallible<string>): void => this.setState({ sigD5: value })}
            />
          </FormGroup>
        </FormControl>
      </div>
    );
  }

  private renderBody(): JSX.Element {
    let body: Nullable<JSX.Element> = null;

    if (this.props.loading) {
      body = (
        <div className="message-or-loader-container">
          <Loader/>
        </div>
      );
    } else if (this.props.actionStatus !== null) {
      body = this.renderConfirmBody();
    } else {
      body = this.renderEditBody();
    }
    return body;
  }

  public render(): JSX.Element {
    return (
      <Dialog
        open={this.props.open}
        className="details-edit-dialog-body"
      >
        <div className="details-edit-dialog" style={{ height: this.props.actionStatus !== null || this.props.loading ? "19rem" : "38rem" }}>
          {this.renderLabel()}
          {this.renderBody()}
        </div>
      </Dialog>
    );
  }

  private getInitialState(): Readonly<State> {
    if (this.props.addOnConfig) {
      return this.getStateFromProps();
    } else {
      return this.getEmptyState();
    }
  }

  private getStateFromProps(): Readonly<State> {
    const {
      cab_t,
      cab_rh,
      transf_a,
      transf_o,
      hfct_g,
      mic_g,
      door_lv,
      door_mv,
      sigD1,
      sigD2,
      sigD3,
      sigD4,
      sigD5,
    } = this.props.addOnConfig!;

    return {
      cab: !!cab_t && !!cab_rh,
      transf: !!transf_a && !!transf_o,
      hfct_g: hfct_g !== false ? this.getPdGainMapKeyWithValue(hfct_g) : false,
      mic_g: mic_g !== false ? this.getPdGainMapKeyWithValue(mic_g) : false,
      door_lv: door_lv,
      door_mv: door_mv,
      sigD1: sigD1 ?? false,
      sigD2: sigD2 ?? false,
      sigD3: sigD3 ?? false,
      sigD4: sigD4 ?? false,
      sigD5: sigD5 ?? false,
    };
  }

  private getEmptyState(): Readonly<State> {
    return {
      cab: false,
      transf: false,
      hfct_g: false,
      mic_g: false,
      door_lv: false,
      door_mv: false,
      sigD1: false,
      sigD2: false,
      sigD3: false,
      sigD4: false,
      sigD5: false,
    };
  }

  private validateSigmaInput(): boolean {
    let check = true;
    const {
      sigD1,
      sigD2,
      sigD3,
      sigD4,
      sigD5,
    } = this.state;

    if (sigD1 !== false && sigD1 === "") {
      check = false;
    } else if (sigD2 !== false && sigD2 === "") {
      check = false;
    } else if (sigD3 !== false && sigD3 === "") {
      check = false;
    } else if (sigD4 !== false && sigD4 === "") {
      check = false;
    } else if (sigD5 !== false && sigD5 === "") {
      check = false;
    }
    return check;
  }

  private getGainSelectorMarks(): Mark[] {
    return Object.keys(pdGainMap).reduce<Mark[]>((acc, curr, currIndex) => {
      // create objects with value from 1 to 8 on linear scale and
      // label from 1 to 32 on non-linear scale, for example: [{value: 4, label: 5}, {value: 5, label: 8}]
      acc.push({ value: currIndex + 1, label: parseInt(curr) });
      return acc;
    }, []);
  }

  public save(): void {
    this.props.save(this.createMeasurementBoardConfig());
  }

  private createMeasurementBoardConfig(): MeasurementBoardConfig {
    return {
      cab_t: this.state.cab,
      cab_rh: this.state.cab,
      transf_a: this.state.transf,
      transf_o: this.state.transf,
      hfct_g: this.state.hfct_g && pdGainMap[this.state.hfct_g],
      mic_g: this.state.mic_g && pdGainMap[this.state.mic_g],
      door_lv: this.state.door_lv,
      door_mv: this.state.door_mv,
      sigD1: this.state.sigD1,
      sigD2: this.state.sigD2,
      sigD3: this.state.sigD3,
      sigD4: this.state.sigD4,
      sigD5: this.state.sigD5,
    };
  }

  private getPdGainMapKeyWithValue(valueInConfig: number): Fallible<keyof PDGainValueMap> {
    const key = Object.keys(pdGainMap).find(key => pdGainMap[parseInt(key)] === valueInConfig);

    if (key !== undefined) {
      return parseInt(key);
    } else {
      console.error("Parameter 'valueInConfig' not found from pdGainMap");
      return false;
    }
  }
}

export default DeviceAddOnConfigDialog;

/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import React, { Component, Fragment, ReactNode } from "react";
import DeviceGroup from "../../../data/device/DeviceGroup";
import ConfirmationDialog from "../../ui/confirmation-dialog";
import ErrorDialog from "../../ui/error-dialog";
import { translations } from "../../../generated/translationHelper";

interface Props {
  group: DeviceGroup;
  open: boolean;
  onClose: (success: boolean) => void;
}

interface State {
  loading: boolean;
  errorMsg?: string;
}

export class DeleteGroupPopup extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  private deleteGroup = async (): Promise<void> => {
    if (!this.props.group) {
      return;
    }
    this.setState({ loading: true });

    try {
      await this.props.group.delete();
      this.props.onClose(true);
    } catch (error) {
      console.error("Failed to remove device group", error);
      this.setState({ errorMsg: translations.admin.texts.failedToRemoveGroup() });
    } finally {
      this.setState({ loading: false });
    }
  };

  public render(): ReactNode {
    if (!this.props.open) {
      return null;
    }

    return (
      <Fragment>
        <ErrorDialog
          onClose={(): void => {
            this.setState({ errorMsg: undefined });
            this.props.onClose(false);
          }}
          errorMsg={this.state.errorMsg}
        />
        <ConfirmationDialog
          title={translations.admin.texts.delete()}
          message={translations.admin.texts.removeGroup({ group: this.props.group.getLabel() })}
          onConfirm={this.deleteGroup}
          onCancel={(): void => this.props.onClose(false)}
          showLoader={this.state.loading}
        />
      </Fragment>
    );
  }
}

/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import React, { ReactNode } from "react";

interface Props {
  children?: ReactNode;
}

export default function InlineBox(props: Props): JSX.Element {
  return (
    <div className="inline-box">
      {props.children}
    </div>
  );
}

/* Copyright */
import { Button, Dialog, DialogActions, TextField, Typography } from "@material-ui/core";
import React from "react";
import { Nullable } from "../../../types/aliases";
import Loader from "../../ui/loader";
import { BackendActionStatus, DeviceMetadata } from "../../../types/utu-types";

interface Props {
  actionStatus: Nullable<BackendActionStatus>;
  close: () => void;
  deviceId: string;
  loading: boolean;
  metadata: Nullable<DeviceMetadata>;
  open: boolean;
  save: (metadata: DeviceMetadata) => Promise<void>;
}

interface State {
  deviceId: string;
  asiakas: Nullable<string>;
  muuntamonNimi: Nullable<string>;
  muuntamonNumero: Nullable<string>;
  toimituspvm: Nullable<string>;
  tuotantotilaus: Nullable<string>;
}

export default class DeviceMetadataDialog extends React.Component <Props, State> {

  public constructor(props: Props) {
    super(props);
    this.state = {
      deviceId: props.deviceId,
      asiakas: null,
      muuntamonNimi: null,
      muuntamonNumero: null,
      toimituspvm: null,
      tuotantotilaus: null,
    };
    this.save = this.save.bind(this);
  }

  public componentDidMount(): void {
    if (this.props.metadata !== null) {
      this.setState({
        deviceId: this.props.deviceId,
        asiakas: this.props.metadata.asiakas,
        muuntamonNimi: this.props.metadata.muuntamonNimi,
        muuntamonNumero: this.props.metadata.muuntamonNumero,
        tuotantotilaus: this.props.metadata.tuotantotilaus,
        toimituspvm: this.props.metadata.toimituspvm,
      });
    }
  }

  private renderConfirmBody(): JSX.Element {
    const message = this.props.actionStatus === BackendActionStatus.SUCCESS
      ? "Tiedot on nyt tallennettu"
      : "Tietojen tallentaminen epäonnistui";
    return (
      <>
        <div className="message-or-loader-container">
          <Typography>{message}</Typography>
        </div>
        <div className="buttons-container">
          <DialogActions style={{ justifyContent: "center" }}>
            <Button
              color="primary"
              onClick={(): void => this.props.close()}
              size="small"
              variant="contained"
            >
              OK
            </Button>
          </DialogActions>
        </div>
      </>
    );
  }

  private renderEditBody(): JSX.Element {
    return (
      <>
        <div className="details-edit-2-3-container">
          <TextField
            fullWidth={true}
            label="Asiakas"
            onChange={({ target }): void => this.setState({ asiakas: target.value })}
            size="small"
            value={this.state.asiakas !== null ? this.state.asiakas : ""}
            variant="outlined"
          />
        </div>
        <div className="details-edit-1-3-container">
          <TextField
            fullWidth={true}
            label="Muuntamon nimi"
            onChange={({ target }): void => this.setState({ muuntamonNimi: target.value })}
            size="small"
            value={this.state.muuntamonNimi !== null ? this.state.muuntamonNimi : ""}
            variant="outlined"
          />
        </div>
        <div className="details-edit-1-3-container">
          <TextField
            fullWidth={true}
            label="Muuntamon numero"
            onChange={({ target }): void => this.setState({ muuntamonNumero: target.value })}
            size="small"
            value={this.state.muuntamonNumero !== null ? this.state.muuntamonNumero : ""}
            variant="outlined"
          />
        </div>
        <div className="details-edit-1-3-container">
          <TextField
            fullWidth={true}
            label="Toimitus pvm."
            onChange={({ target }): void => this.setState({ toimituspvm: target.value })}
            size="small"
            value={this.state.toimituspvm !== null ? this.state.toimituspvm : ""}
            variant="outlined"
          />
        </div>
        <div className="details-edit-1-3-container">
          <TextField
            fullWidth={true}
            label="Tuotantotilaus nro."
            onChange={({ target }): void => this.setState({ tuotantotilaus: target.value })}
            size="small"
            value={this.state.tuotantotilaus !== null ? this.state.tuotantotilaus : ""}
            variant="outlined"
          />
        </div>
        <div className="buttons-container">
          <DialogActions style={{ justifyContent: "center" }}>
            <Button
              onClick={(): void => this.props.close()}
              size="small"
              variant="contained"
              color="primary"
            >
            Peruuta
            </Button>
            <Button
              size="small"
              variant="contained"
              onClick={this.save}
              color="primary"
            >
            Tallenna
            </Button>
          </DialogActions>
        </div>
      </>
    );
  }

  private renderBody(): JSX.Element {
    let body: Nullable<JSX.Element> = null;

    if (this.props.loading) {
      body = (
        <div className="message-or-loader-container">
          <Loader/>
        </div>
      );
    } else if (this.props.actionStatus !== null) {
      body = this.renderConfirmBody();
    } else {
      body = this.renderEditBody();
    }
    return body;
  }

  public render(): JSX.Element {
    return (
      <Dialog
        open={this.props.open}
        className="details-edit-dialog-body"
      >
        <div className="details-edit-dialog">
          <div className="details-edit-label" >
            Muokkaa muuntamon tietoja
          </div>
          {this.renderBody()}
        </div>
      </Dialog>
    );
  }

  public save(): void {
    const {
      asiakas: a,
      muuntamonNimi: mN,
      muuntamonNumero: mNu,
      toimituspvm: tp,
      tuotantotilaus: tt,
    } = this.state;

    this.props.save({
      deviceId: this.state.deviceId,
      asiakas: a !== "" ? a : null,
      muuntamonNimi: mN !== "" ? mN : null,
      muuntamonNumero: mNu !== "" ? mNu : null,
      toimituspvm: tp !== "" ? tp : null,
      tuotantotilaus: tt !== "" ? tt : null,
    });
  }
}

/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import ReceiverObserverSubscription, { SubscriptionDocument, SubscriptionHandler } from "./utils/receivers/ReceiverObserverSubscription";
import { Service } from "./backend/AppSyncClientProvider";
import AppSyncClientFactory from "./backend/AppSyncClientFactory";
import ReceiverManager from "./utils/receivers/ReceiverManager";

export interface SubscriptionController {
  terminateSubscription: () => void;
}

export type { SubscriptionDocument } from "./utils/receivers/ReceiverObserverSubscription";
export type { SubscriptionHandler } from "./utils/receivers/ReceiverObserverSubscription";

export default class SubscriptionControllerFactory {
  
  public static getController<TResult>(
      service: Service,
      document: SubscriptionDocument<TResult>,
      handler: SubscriptionHandler<TResult>,
  ): SubscriptionController {
    const receiverSubscription = new ReceiverObserverSubscription(
      () => AppSyncClientFactory.createProvider().getTypedClient(service),
      document,
      handler,
    );

    ReceiverManager.instance.addObserver(receiverSubscription);
    
    return {
      terminateSubscription: (): void => {
        ReceiverManager.instance.removeObserver(receiverSubscription);
        receiverSubscription.terminateSubscriptions();
      },
    };
  }
}

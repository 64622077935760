/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import React, { Component, ReactNode } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Gatekeeper from "./components/access-control/gatekeeper";
import { createTheme, MuiThemeProvider } from "@material-ui/core";
import Content from "./components/content";
import LoginView from "./components/login-view";
import Paths from "./components/Paths";

const theme = createTheme(
  {
    palette: {
      primary: { main: "#004388" },
      secondary: { main: "#E53935" },
    },
    overrides: {
      MuiButton: {
        root: {
          textTransform: "none",
        },
      },
      MuiTooltip: {
        tooltip: {
          fontSize: "0.9rem",
          backgroundColor: "#262626",
          padding: "0.33rem 0.88rem",
          border: "1px solid #262626",
        },
        arrow: {
          color: "#262626",
        },
      },
    },
  });

class App extends Component {

  private renderWithGatekeeper(authenticatedComponent: ReactNode, redirectPath: Paths, redirectIfAuthenticated?: boolean): JSX.Element {
    return (
      <Gatekeeper
        redirectIfAuthenticated={redirectIfAuthenticated}
        redirectPath={redirectPath}
      >
        {authenticatedComponent}
      </Gatekeeper>
    );
  }

  private printVersionString(): void {
    console.log("Starting UI with version string", process.env.REACT_APP_VERSION_STRING);
  }

  public render(): ReactNode {
    this.printVersionString();
    return (
      <BrowserRouter>
        <MuiThemeProvider theme={theme}>
          <Switch>
            <Route
              path={Paths.LOGIN}
              component={(): JSX.Element => this.renderWithGatekeeper(<LoginView />, Paths.ROOT, true)}
            />
            <Route
              path={Paths.ROOT}
              render={(): JSX.Element => this.renderWithGatekeeper(<Content />, Paths.LOGIN)}
            />
          </Switch>
        </MuiThemeProvider>
      </BrowserRouter>
    );
  }
}

export default App;

/*
* Copyright
*/

import BaseObservable from "../observer/BaseObservable";
import { Nullable } from "../../types/aliases";
import { HAxisViewWindow } from "../../components/history-view/components/history-chart";


export interface ChartZoomObserver {
  onZoomChanged: (zoom: Nullable<HAxisViewWindow>) => void;
}

export default class ChartZoomManager extends BaseObservable<ChartZoomObserver> {
  private static __instance: ChartZoomManager;
  private _currentMasterChartIndex: Nullable<number> = null;
  private _zoom: Nullable<HAxisViewWindow> = null;

  private constructor() {
    super();
  }

  public static get instance(): ChartZoomManager {
    if (!ChartZoomManager.__instance) {
      ChartZoomManager.__instance = new ChartZoomManager();
    }
    return ChartZoomManager.__instance;
  }

  public get masterChartId(): Nullable<number> {
    return this._currentMasterChartIndex;
  }

  public set masterChartId(value: Nullable<number>) {
    this._currentMasterChartIndex = value;
  }

  public get zoom(): Nullable<HAxisViewWindow> {
    return this._zoom;
  }

  public set zoom(value: Nullable<HAxisViewWindow>) {
    this._zoom = value;
    this.notifyZoomChanged();
  }

  public resetZoom(): void {
    this.zoom = null;
  }

  private notifyZoomChanged(): void {
    this.notifyAction(observer => observer.onZoomChanged(this.zoom));
  }
}

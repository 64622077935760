/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { Button, Dialog, Grid, Typography } from "@material-ui/core";
import React, { Component, ReactNode } from "react";
import Loader from "./loader";
import { translations } from "../../generated/translationHelper";

interface Props {
  title: string;
  message: string;
  onConfirm: () => void;
  onCancel?: () => void;
  showLoader?: boolean;
}

export default class ConfirmationDialog extends Component<Props> {

  private renderButtons(): ReactNode {
    return (
      <Grid container={true} spacing={2} justifyContent="center">
        <Grid item={true}>
          <Button
            variant="contained"
            color="primary"
            onClick={this.props.onConfirm}
            data-testid="confirm-button"
          >
            {translations.common.buttons.confirm()}
          </Button>
        </Grid>
        {this.props.onCancel &&
        <Grid item={true}>
          <Button
            variant="contained"
            color="primary"
            onClick={this.props.onCancel}
          >
            {translations.common.buttons.cancel()}
          </Button>
        </Grid>
        }
      </Grid>
    );
  }

  public render(): JSX.Element {
    return (
      <Dialog
        onClose={this.props.onCancel}
        open={true}
      >
        <div className="popup-inner" data-testid="confirmation-dialog">
          <Typography variant="h6">{this.props.title}</Typography>
          <div className="popup-body">
            <Grid container direction="column" spacing={2}>
              <Grid item={true}>
                <Typography variant="subtitle1">{this.props.message}</Typography>
              </Grid>
              <Grid item={true}>
                {!this.props.showLoader ? this.renderButtons() : <Loader />}
              </Grid>
            </Grid>
          </div>
        </div>
      </Dialog>
    );
  }
}

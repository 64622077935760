/* Copyright */
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import * as condition from "ol/events/condition";
import Interaction from "ol/interaction/Interaction";
import Select from "ol/interaction/Select";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import Map from "ol/Map";
import Overlay from "ol/Overlay";
import OSM from "ol/source/OSM";
import VectorSource from "ol/source/Vector";
import Icon from "ol/style/Icon";
import Style from "ol/style/Style";
import View from "ol/View";
import BaseEvent from "ol/events/Event";
import OverlayPositioning from "ol/OverlayPositioning";
import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import Marker from "../../../assets/ic_muuntamo_circle.svg";
import EventMarker from "../../../assets/ic_warning_circle.svg";
import StatusPopupUtu from "./status-popup-utu";
import Event from "../../../data/clientSpecific/Event";
import { EventRepositoryListener } from "../../../data/events/EventRepositoryListener";
import { Maybe, Nullable } from "../../../types/aliases";
import EventsRepository from "../../../data/events/EventsRepository";
import { isDefined } from "../../../utils/types";
import StatusHover from "./status-hover";
import DeviceNavigationCache from "../../../utils/DeviceNavigationCache";
import { MapLocationWithDeviceProperties } from "../../../types/utu-types";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const proj = require("ol/proj");

const DEFAULT_CENTER: [number, number] = [24.04, 61.59];
const ZOOM_MIN = 2;
const ZOOM_DEFAULT = 6.85;
const MARKER_ANCHOR: [number, number] = [0.5, 0.5];
const OVERLAY_OFFSET: [number, number] = [0, 37]; // 37px alaspäin ikonista ja sivusuunnassa keskellä ikonia

interface Props extends RouteComponentProps {
  markerData: MapLocationWithDeviceProperties[];
  showOnlyEvents: boolean;
}

interface State {
  clickedPopupId: Nullable<string>;
  mouseOverId: Nullable<string>;
  popupOpen: boolean;
}

class StatusMapUtu extends Component<Props, State> implements EventRepositoryListener {
  private map!: Map;
  private popup: Nullable<Overlay> = null;
  private statusMapPointsLayer: Nullable<VectorLayer> = null;
  private hover: Nullable<Overlay>= null;

  public constructor(props: Props) {
    super(props);
    this.state = {
      clickedPopupId: null,
      mouseOverId: null,
      popupOpen: false,
    };
  }

  public async componentDidMount(): Promise<void> {
    EventsRepository.instance.addListener(this);
    this.renderMap();

    if (this.props.markerData && EventsRepository.instance.isInitialized()) {
      this.renderMarkers();
    }
  }

  public componentWillUnmount(): void {
    EventsRepository.instance.removeListener(this);
  }

  public componentDidUpdate(prevProps: Props): void {
    if (this.props.markerData && (prevProps.markerData !== this.props.markerData) &&
    EventsRepository.instance.isInitialized()) {
      const deviceIds: string[] = this.props.markerData.map(data => data.device.getId());
      console.log("Mapdata changed " + deviceIds);
      this.renderMarkers();
    }
  }

  public onEventsInitDone(): void {
    if (this.props.markerData.length > 0) {
      this.renderMarkers();
    }
  }

  public onEvent(_event: Event): void {
    if (this.props.markerData.length > 0) {
      this.renderMarkers();
    }
  }

  public onEventStateChanged(_event: Event): void {
    if (this.props.markerData.length > 0) {
      this.renderMarkers();
    }
  }

  private openHover = (feature: Feature): void => {
    const statusMapData = this.props.markerData.find(({ device }) => device.getId() === feature.getId());

    if (statusMapData) {
      const { lat, lng } = statusMapData;

      if (lng != null && lat != null) {
        const coordinates = proj.fromLonLat([lng, lat]);
        // Assertion: hover is initialized when map is rendered
        (this.hover as Overlay).setPosition(coordinates);
        // Assertion: id of type string is explicitly set on this.hover when map is rendered
        const mouseOverId = feature.getId() as string;
        this.setState({ mouseOverId });
      }
    }
  };

  private initializeHoverOverlay = (): void => {
    if (!this.hover) {
      const overlayElement = document.getElementById("status-hover");
      this.hover = new Overlay({
        id: "hover",
        element: overlayElement || undefined,
        offset: OVERLAY_OFFSET,
        positioning: OverlayPositioning.BOTTOM_LEFT,
        stopEvent: false,
      });
    }
    this.map.addOverlay(this.hover);
  };

  private initializeOverlay = (): void => {
    if (!this.popup) {
      const overlayElement = document.getElementById("status-popup");
      this.popup = new Overlay({
        id: "popup",
        element: overlayElement || undefined,
        offset: OVERLAY_OFFSET,
        stopEvent: false,
        positioning: OverlayPositioning.TOP_CENTER,
      });
    }
    this.map.addOverlay(this.popup);
  };

  private openPopup = async (feature: any): Promise<void> => { // eslint-disable-line @typescript-eslint/no-explicit-any
    const statusMapDataList = this.props.markerData.filter(({ device }) => {
      return (device.getId() === feature.getId());
    });

    if (statusMapDataList.length > 0) {
      const { lng, lat } = statusMapDataList[0];

      if (lng != null && lat != null) {
        const coordinates = proj.fromLonLat([lng, lat]);

        if (this.popup !== null) {
          await DeviceNavigationCache.getInstance().navigateToDevice(this.props, feature.values_.properties.deviceId);
          this.popup.setPosition(coordinates);
          this.setState({ clickedPopupId: feature.getId(), popupOpen: true });
        }
      }
    }
  };

  private closePopup = async (): Promise<void> => {
    this.popup?.setPosition(undefined);
    this.setState({ popupOpen: false });
    await DeviceNavigationCache.getInstance().navigateToDevice(this.props);
  };

  private setCursorStyle(setStyleToPointer?: boolean): void {
    const mapElement = document.getElementById("status-map");

    if (mapElement) {
      const { style } = mapElement;

      if (setStyleToPointer) {
        style.cursor = "pointer";
      } else {
        mapElement.removeAttribute("style");
      }
    } else {
      console.error("No Element found with id 'status-map'");
    }
  }

  private renderOverlayContent(): Maybe<JSX.Element> {
    if (this.popup) {
      const popupData = this.props.markerData.find(({ device }) => device.getId() === this.state.clickedPopupId);

      if (popupData) {
        const DOMElement = this.popup.getElement();

        if (DOMElement) {
          DOMElement.className = "";
        }
        const deviceEvents = EventsRepository.instance.getAllActiveEvents().filter(({ deviceId }) => deviceId === this.state.clickedPopupId);

        if (this.state.popupOpen) {
          return (
            <StatusPopupUtu
              popupData={popupData}
              onClose={this.closePopup}
              events={deviceEvents}
            />
          );
        }
      }
    }
  }

  private renderHoverContent(): Maybe<JSX.Element> {
    if (this.hover) {
      const markerData = this.props.markerData.find(({ device }) => device.getId() === this.state.mouseOverId);

      if (markerData) {
        const DOMElement = this.hover.getElement();

        if (DOMElement) {
          DOMElement.className = "";
        }
        return (
          <StatusHover
            selectedDevice={markerData.device}
          />
        );
      }
    }
  }

  private renderMarkers(): void {
    const iconStyle = new Style({
      image: new Icon({
        anchor: MARKER_ANCHOR,
        src: Marker,
      }),
    });

    const eventIconStyle = new Style({
      image: new Icon({
        anchor: MARKER_ANCHOR,
        src: EventMarker,
      }),
    });

    const allActiveEvents = EventsRepository.instance.getAllActiveEvents();
    const markers: Feature[] = [];
    this.props.markerData.forEach(({ device, lat, lng }) => {
      if (isDefined(lat) && isDefined(lng)) {
        const deviceId = device.getId();
        const iconFeature = new Feature({
          geometry: new Point(proj.fromLonLat(
            [lng, lat])),
          properties: {
            deviceId,
            // timestamp,
          },
          type: "marker",
        });

        iconFeature.setId(deviceId);

        const deviceHasEvents = allActiveEvents.some(event => event.deviceId === deviceId);

        iconFeature.setStyle(deviceHasEvents ? eventIconStyle : iconStyle);

        if (!this.props.showOnlyEvents || deviceHasEvents) {
          markers.push(iconFeature);
        }
      }
    });
    this.renderLayer(markers);
  }

  private renderLayer(features: Feature[]): void {
    if (this.map !== null) {
      const vectorSource = new VectorSource({
        features,
      });
      const vectorLayer = new VectorLayer({
        source: vectorSource,
      });

      if (this.statusMapPointsLayer) {
        this.map.removeLayer(this.statusMapPointsLayer);
        this.statusMapPointsLayer = null;
      }
      this.statusMapPointsLayer = vectorLayer;
      this.map.addLayer(vectorLayer);
    }
  }

  private renderMap(): void {
    const tileLayer = new TileLayer({
      source: new OSM(),
    });
    this.map = new Map({
      layers: [tileLayer],
      target: "status-map",
      view: new View({
        center: proj.fromLonLat(DEFAULT_CENTER),
        extent: proj.transformExtent([180, -90, -180, 90], "EPSG:4326", "EPSG:3857"),
        minZoom: ZOOM_MIN,
        zoom: ZOOM_DEFAULT,
      }),
    });
    const selectSingleClick: Select = new Select();
    const select: Interaction = selectSingleClick;
    const hover: Select = new Select({
      condition: condition.pointerMove,
    });
    this.map.addInteraction(hover);

    hover.on("select", ({ target }) => {
      const feature: Feature = target.getFeatures().array_[0];

      if (feature) {
        this.setCursorStyle(true);
        this.openHover(feature);
      } else {
        this.setCursorStyle();
        // Assertion: hover is initialized when map is rendered
        (this.hover as Overlay).setPosition(undefined);
      }
    });

    this.map.addInteraction(select);
    select.on("select", (event: BaseEvent) => {
      if (event !== null) {
        const feature = event.target.getFeatures().array_[0];

        if (feature) {
          this.openPopup(feature);
        }
      }
    });
    this.initializeOverlay();
    this.initializeHoverOverlay();
  }

  public render(): JSX.Element {
    return (
      <div id="status-map">
        <div id="status-popup" className="hidden">
          {this.renderOverlayContent()}
        </div>
        <div id="status-hover" className="hidden">
          {this.renderHoverContent()}
        </div>
      </div>
    );
  }
}

export default withRouter(StatusMapUtu);

/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import BackendFactory from "../../data/BackendFactory";
import AuthWrapper from "../../data/auth/AuthWrapper";
import OtaManager from "../../data/ota/OtaManager";
import DeviceGroup from "../../data/device/DeviceGroup";
import Device from "../../data/device/Device";
import EventsRepository from "../../data/events/EventsRepository";
// import DeviceDrawer from "../../components/drawers/device-drawer";
import EventView from "../../components/event-view";
import AdminView from "../../components/admin-view";
import HistoryView from "../../components/history-view";
import StatusView from "../../components/status-view";
import Paths from "../Paths";
import UserViewUtu from "../user-view-utu";

interface Props {}

interface State {
  groups: DeviceGroup[];
  mountHistoryViewEventsTableExpanded: boolean;
  devices?: Device[];
}

export default class ContentController extends Component<Props, State> {

  public constructor(props: Props) {
    super(props);
    this.state = {
      mountHistoryViewEventsTableExpanded: false,
      groups: [],
    };
  }

  public async componentDidMount(): Promise<void> {
    if (await AuthWrapper.isCurrentUserAuthenticated()) {
      await this.getGroups();
      await this.getDevices();
      await EventsRepository.instance.init();
      await OtaManager.getInstance().init();
    }
  }

  public componentWillUnmount(): void {
    EventsRepository.instance.uninit();
    OtaManager.getInstance().uninit();
    this.setState({
      devices: undefined,
      groups: [],
    });
  }

  private async getSubGroups(group: DeviceGroup, allGroups: DeviceGroup[]): Promise<void> {
    const subGroups = await group.getGroups();

    if (subGroups && subGroups.length > 0) {
      allGroups.push(...subGroups);
      await Promise.all(subGroups.map(async (g): Promise<void> => this.getSubGroups(g, allGroups)));
    }
  }

  private async getGroups(): Promise<void> {
    const groups: DeviceGroup[] = await BackendFactory.getBackend().getRootDeviceGroups();
    this.setState({ groups });

  }

  private async getDevices(): Promise<void> {
    if (this.state.groups && this.state.groups.length > 0) {
      // TODO: ignore unassigned_devices?
      const allGroups: DeviceGroup[] = [...this.state.groups];
      await Promise.all(allGroups.map(async (g): Promise<void> => this.getSubGroups(g, allGroups)));
      const allGroupsDevices = await Promise.all(allGroups.map(group => group.getDevices()));
      // combine new devices and old devices into single device array
      const devices = new Set([...allGroupsDevices.flat(), ...(this.state.devices ?? [])]);
      this.setState(
        {
          devices: [...devices],
        },
      );
    } else {
      this.setState({ devices: [] });
    }
  }


  private triggerGroupsUpdate = async (): Promise<void> => {
    await this.getGroups();
    await this.getDevices();
  };

  private renderHistoryView = (): JSX.Element => {
    return (
      <HistoryView
        mountEventsTableExpanded={this.state.mountHistoryViewEventsTableExpanded}
        setHistoryViewEventsTableToMountCollapsed={(): void => this.setState({ mountHistoryViewEventsTableExpanded: false })}
      />
    );
  };

  private renderStatusView = (): JSX.Element => {
    return (
      <StatusView
        groups={this.state.groups}
        devices={this.state.devices}
      />
    );
  };

  private renderEventsView = (): JSX.Element => {
    return (
      <EventView
        groups={this.state.groups}
        devices={this.state.devices}
        setHistoryViewEventsTableToMountExpanded={(): void => this.setState({ mountHistoryViewEventsTableExpanded: true })}
      />
    );
  };

  private renderUserView = (): JSX.Element => {
    return (
      <UserViewUtu />
    );
  };

  private renderAdminView = (): JSX.Element => {
    return (
      <AdminView
        groups={this.state.groups}
        legacyBrowserMode={false}
        triggerGroupsUpdate={this.triggerGroupsUpdate}
      />
    );
  };

  private redirectToRoot = (): JSX.Element => {
    return <Redirect to={Paths.STATUS} />;
  };

  public render(): JSX.Element {
    return (
      <section className="body-container col-sm-12 col-xsm-12">
        <Switch>
          <Route
            exact={true}
            path={Paths.HISTORY + "/:id?"}
            render={this.renderHistoryView}
          />
          <Route
            exact={true}
            path={Paths.STATUS + "/:id?"}
            render={this.renderStatusView}
          />
          <Route
            exact={true}
            path={Paths.EVENTS + "/:id?"}
            render={this.renderEventsView}
          />
          <Route
            exact={true}
            path={Paths.USER}
            render={this.renderUserView}
          />
          <Route
            exact={true}
            path={Paths.ADMIN}
            render={this.renderAdminView}
          />
          <Route
            exact={false}
            path={Paths.ROOT}
            render={this.redirectToRoot}
          />
        </Switch>
      </section >
    );
  }
}

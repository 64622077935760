/* Copyright */
import React from "react";
import ExpandLessOutlinedIcon from "@material-ui/icons/ExpandLessOutlined";
import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";
import Device from "../../../data/device/Device";
import EventsTable from "../../ui/events-table";
import EventsCounter from "./events-counter";
import { Maybe } from "../../../types/aliases";
import LatestData, { LatestDataObserver } from "../../../data/data/LatestData";
import Data from "../../../data/data/Data";

interface Props {
  device: Device;
  mountTableExpanded: boolean;
  setHistoryViewEventsTableToMountCollapsed: () => void;
}

interface State {
  tableExpanded: boolean;
  latestData?: LatestData;
  latestDataValues?: Data;
}

class EventsSection extends React.Component <Props, State> implements LatestDataObserver {
  public constructor(props: Props) {
    super(props);

    this.state = {
      tableExpanded: false,
    };
  }

  public async componentDidMount(): Promise<void> {
    if (this.props.mountTableExpanded) this.setState({ tableExpanded: true });
    const latestData = await this.props.device.getLatestData();

    if (latestData) {
      // getting data from devicesMeasurementsUpdateFeed needs receiver as a parameter to work
      // receiver is set in parent component so no need to have reveicer adding/removing operations here
      latestData.addObserver(this);
      this.setState({ latestData, latestDataValues: latestData.getData() });
    }
  }

  public componentWillUnmount(): void {
    this.state.latestData?.removeObserver(this);
    this.props.setHistoryViewEventsTableToMountCollapsed();
  }

  public onDataUpdate(latestData: LatestData): void {
    this.setState({ latestDataValues: latestData.getData() });
  }

  public renderTable(): Maybe<JSX.Element> {
    if (this.props.device) {
      return (
        <EventsTable
          devices={[this.props.device]}
          showOnlyActiveEvents={false}
          latestData={this.state.latestDataValues ? [this.state.latestDataValues] : undefined}
          hideThingColumn
        />
      );
    }
  }

  public render(): JSX.Element {
    const { device } = this.props;
    const { tableExpanded } = this.state;

    return (
      <div className="history-events">
        <div className="history-events-header">
          <EventsCounter device={device} />
          <div className="history-events-header-text">
            {tableExpanded ? "" : "Hälytykset"}
          </div>
          <div className="history-events-header-expand">
            {tableExpanded
              ? <ExpandLessOutlinedIcon
                onClick={(): void => { this.setState({ tableExpanded: false });}}/>
              : <ExpandMoreOutlinedIcon
                onClick={(): void => { this.setState({ tableExpanded: true });}}
              />}
          </div>
        </div>
        {tableExpanded && this.renderTable()}
      </div>
    );
  }
}

export default EventsSection;

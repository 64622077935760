/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import React, { Component } from "react";
import { Chart } from "react-google-charts";
import { Data } from "../../../data/data/Data";
import Loader from "../../ui/loader";
import {
  ChartAnimation,
  ChartArea,
  ChartDataRow,
  ChartExplorer,
  ChartLegend,
  ChartSeries,
  GoogleChartWrapperGetChartReturnType,
  ExtendedGoogleChartWrapperGetChartReturnType,
} from "../../../types/chartprops";
import { DateTimeFormatTarget, getDateTimeFormat } from "../../../data/utils/Utils";
import { Maybe, Nullable } from "../../../types/aliases";
import { constantSensorDataDictionary } from "../../../data/utu-dictionary-data/UtuDictionaryData";
import { translations } from "../../../generated/translationHelper";
import { ReactGoogleChartEvent } from "react-google-charts/dist/types";
import ChartZoomManager, { ChartZoomObserver } from "../../../data/utils/ChartZoomManager";
import { hasKey } from "../../../utils/functions";

interface Props {
  data: Data[];
  sensorDataPair: string[];
  chartTitle?: string;
  chartIndex: number;
}

interface State {
  sensorDataNames: string[];
  chartData?: ChartDataRow[];
  hAxisViewWindow: Nullable<HAxisViewWindow>;
}

interface MinAndMaxValue {
  minValue: number;
  maxValue: number;
}

export type HAxisViewWindow = {
  min: Date;
  max: Date;
};

const CHART_AREA: ChartArea = {
  bottom: 60,
  top: 50,
  height: "70%",
  width: "80%",
};

const EXPLORER: ChartExplorer = {
  actions: ["dragToZoom", "rightClickToReset"],
  axis: "horizontal",
  keepInBounds: true,
  maxZoomIn: 100.0,
};

const LEGEND: ChartLegend = {
  display: true,
  position: "bottom",
};

const ANIMATION: ChartAnimation = {
  startup: true,
  easing: "out",
  duration: 1500,
};

export default class HistoryChart extends Component<Props, State> implements ChartZoomObserver {
  public constructor(props: Props) {
    super(props);
    this.state = {
      sensorDataNames: [],
      hAxisViewWindow: null,
    };
  }

  public componentDidMount(): void {
    this.setChartData();
    ChartZoomManager.instance.addObserver(this);
  }

  public shouldComponentUpdate(nextProps: Props, nextState: State): boolean {
    return this.props.data !== nextProps.data
            || this.props.sensorDataPair !== nextProps.sensorDataPair
            || this.state.chartData !== nextState.chartData
            || this.state.hAxisViewWindow !== nextState.hAxisViewWindow;
  }

  public componentDidUpdate(prevProps: Props): void {
    if (prevProps.data !== this.props.data
            || prevProps.sensorDataPair !== this.props.sensorDataPair) {
      this.setChartData();
    }
  }

  public componentWillUnmount(): void {
    ChartZoomManager.instance.removeObserver(this);
  }

  public onZoomChanged(zoom: Nullable<HAxisViewWindow>): void {
    const zoomActionNeeded = this.props.chartIndex !== ChartZoomManager.instance.masterChartId;
    const isMasterChartAndNeedsZoomReset = zoom === null && this.props.chartIndex === ChartZoomManager.instance.masterChartId;

    // change state value and recreate this chart with a new value for hAxisViewWindow only if this is not the current master chart
    if (zoomActionNeeded) {
      this.setState({ hAxisViewWindow: zoom });
    // skip shouldComponentUpdate and update this chart to reset zoom when this chart was the master chart and resetting was triggered by
    // a right button mouseup event on some other chart
    } else if (isMasterChartAndNeedsZoomReset) {
      this.setState({ hAxisViewWindow: null });
      this.forceUpdate();
    }
  }

  private setChartData(): void {
    if (this.props.data != null && this.props.data.length > 1) {
      const chartData: ChartDataRow[] = [["timestamp"]];
      const sensorDataNameList: string[] = [];

      const addSensor = (sensor: string): void => {
        if (sensor != null) {
          const displayName = this.getSensorName(sensor, "displayName");

          if (displayName) {
            chartData[0].push(displayName);
          } else {
            console.error(`No display name found for sensor: ${sensor}`);
          }
          const name = this.getSensorName(sensor, "name");

          if (name) {
            sensorDataNameList.push(name);
          } else {
            console.error(`No name found for sensor: ${sensor}`);
          }
        }
      };

      // first headers
      const sensorDataDisplayNames = this.getPDSensorDataWithoutMaxData();
      sensorDataDisplayNames.forEach(addSensor);

      // then data
      const addSensorDataToChart = (column: ChartDataRow, item: Data, sensor: string): void => {
        if (sensor != null) {
          const sensorDataKey = this.getSensorDataKeyWithSensorName(item, sensor);

          if (sensorDataKey) {
            let data = item[sensorDataKey];

            if (typeof data === "boolean") {
              data = Number(data);
            }
            column.push(data);
          } else {
            console.error(`No dataKey found for sensor: ${sensor}`);
          }
        }
      };

      const sensorDataNames = this.getPDSensorDataWithoutMaxData();

      this.props.data.forEach((item: Data) => {
        const chartDataColumns: ChartDataRow = [new Date(Number(item.timestamp))];

        const sensorHandler = (sensor: string): void => addSensorDataToChart(chartDataColumns, item, sensor);

        sensorDataNames.forEach(sensorHandler);

        chartData.push(chartDataColumns);
      });
      this.setState({
        chartData,
        sensorDataNames: sensorDataNameList,
      });
    } else {
      this.setState({ chartData: [] });
    }
  }

  private getSensorDataKeyWithSensorName(item: Data, sensor: string): Maybe<string> {
    let keyInData = undefined;

    Object.keys(item).find(key => {
      if (key.includes(sensor)) {
        keyInData = key;
      }
    });
    return keyInData;
  }

  private getSensorName(tsFormattedSensorDataName: string, nameOption: "name" | "displayName"): Maybe<string> {
    let displayName = undefined;

    Object.keys(constantSensorDataDictionary).find(key => {
      if (tsFormattedSensorDataName.includes(key)) {
        displayName = nameOption === "name" ? constantSensorDataDictionary[key].name : constantSensorDataDictionary[key].displayName;
      }
    });
    return displayName;
  }

  private getSeries(): ChartSeries {
    const series: ChartSeries = {};

    this.state.sensorDataNames.forEach((sensorDataName: string, index: number) => {
      if (sensorDataName !== "timestamp" && this.props.sensorDataPair.includes(sensorDataName)) {

        this.props.sensorDataPair.forEach((sensor: string) => {
          if (sensor != null) {
            if (sensor === constantSensorDataDictionary.cab_rh.name || sensor === constantSensorDataDictionary.cab_t.name) {
              series[index] = {
                targetAxisIndex: index,
              };
            } else {
              series[index] = {
                targetAxisIndex: 0,
              };
            }
          }
        });
      }
    });
    return series;
  }

  private getPDSensorDataWithoutMaxData(): string[] {
    const { hfct_max, mic_max } = constantSensorDataDictionary;
    return this.props.sensorDataPair.filter(pairItem => pairItem !== hfct_max.name && pairItem !== mic_max.name);
  }

  private getVAxisMinAndMaxValues(targetAxisIndex?: number): MinAndMaxValue {
    const { cab_t, cab_rh, mic_avg, mic_max, hfct_avg, hfct_max } = constantSensorDataDictionary;
    const { sensorDataPair: pair } = this.props;

    const defaultValues: MinAndMaxValue = {
      minValue: -20,
      maxValue: 60,
    };

    if ((pair.includes(mic_avg.name) && pair.includes(mic_max.name)) ||
    (pair.includes(hfct_avg.name) && pair.includes(hfct_max.name))) {
      return {
        minValue: 0,
        maxValue: 2000,
      };
    } else if (pair.includes(cab_t.name) && pair.includes(cab_rh.name) && targetAxisIndex === 1) {
      return {
        minValue: 0,
        maxValue: 100,
      };
    } else {
      return defaultValues;
    }
  }

  private getChartHAxisViewWindowMinAndMax(chart: ExtendedGoogleChartWrapperGetChartReturnType): HAxisViewWindow {
    const chartLayout = chart.getChartLayoutInterface();
    const chartBounds = chartLayout.getChartAreaBoundingBox();
    const min = chartLayout.getHAxisValue(chartBounds.left);
    const max = chartLayout.getHAxisValue(chartBounds.width + chartBounds.left);
    return {
      min,
      max,
    };
  }

  private isGoogleChartWrapperWithExtendedProps(object: GoogleChartWrapperGetChartReturnType): object is ExtendedGoogleChartWrapperGetChartReturnType {
    // assertion is needed because hasKey fails to detect getChartLayoutInterface
    return (object as ExtendedGoogleChartWrapperGetChartReturnType).getChartLayoutInterface() !== undefined && hasKey(object, "container");
  }

  private setupChartZoomManaging(chart: ExtendedGoogleChartWrapperGetChartReturnType): void {
    if (this.isGoogleChartWrapperWithExtendedProps(chart)) {
      let lastHAxisValues = this.getChartHAxisViewWindowMinAndMax(chart);
      /* Start observing mutations when the chart is ready - callback of new MutationObserver will be executed on each DOM change detected in the node passed to MutationObserver.observe() */
      const observer = new MutationObserver((_mutations: MutationRecord[], _observer: MutationObserver) => {
        // no need to access parameters of callback - only getting the current zoom is important here
        const currentHAxisValues = this.getChartHAxisViewWindowMinAndMax(chart);

        // check if mutation resulted in a zoom change in the chart
        if (JSON.stringify(lastHAxisValues) !== JSON.stringify(currentHAxisValues)) {
          lastHAxisValues = this.getChartHAxisViewWindowMinAndMax(chart);

          // only set ChartZoomManager.instance.zoom if this chart is a master chart - otherwise an endless loop is created
          if (ChartZoomManager.instance.masterChartId === this.props.chartIndex) {
            // calling this setter will trigger onZoomChanged in all observers of ChartZoomManager
            ChartZoomManager.instance.zoom = lastHAxisValues;
          }
        }
      });

      observer.observe(chart.container, {
        childList: true,
        subtree: true,
      });

      chart.container.addEventListener("mousedown", (event: MouseEvent): void => {
        const isMainButtonClick = event.button === 0;

        if (isMainButtonClick) {
          /* Make this chart masterChart immediately on left button mousedown event which is guaranteed to fire when user drags to zoom */
          ChartZoomManager.instance.masterChartId = this.props.chartIndex;
          /* Prevent multiple events firing from one event */
          event.stopImmediatePropagation();
        }
      });
      chart.container.addEventListener("mouseup", (event: MouseEvent): void => {
        const isSecondaryButtonClick = event.button === 2;

        if (isSecondaryButtonClick) {
          /* Reset zoom if a zoom is set on right button mouseup event - listening for right button click doesn't work */
          // TODO: Optimization becuse forceUpdate is called after this even if resetZoom is inside an if statement that checks if ChartZoomManager.instance !== null
          ChartZoomManager.instance.resetZoom();
          /* Prevent multiple events firing from one event */
          event.stopImmediatePropagation();
        }
      });
    }
  }

  private getChartEvents(): ReactGoogleChartEvent[] {
    return [
      {
        callback: ({ chartWrapper }): void => {
          const chart = chartWrapper.getChart();

          if (this.isGoogleChartWrapperWithExtendedProps(chart)) {
            this.setupChartZoomManaging(chart);
          } else {
            console.error("Failed to set up zoom managing of chart: invalid object was returned chartWrapper.getChart()");
          }
        },
        eventName: "ready",
      },
    ];
  }

  public render(): Nullable<JSX.Element> {
    if (this.props.data && this.props.data.length === 1) {
      return <span>{translations.history.texts.notEnoughDataForVisualisation()}</span>;
    }

    if (!this.state.chartData || this.state.chartData.length <= 1 || this.state.sensorDataNames.length === 0) {
      return null;
    }

    return (
      <Chart
        chartType="LineChart"
        loader={<Loader />}
        data={this.state.chartData}
        chartEvents={this.getChartEvents()}
        options={{
          chartArea: CHART_AREA,
          title: this.props.chartTitle,
          explorer: EXPLORER,
          series: this.getSeries(),
          vAxes: {
            0: { ...this.getVAxisMinAndMaxValues(), viewWindowMode: "maximized" },
            1: { ...this.getVAxisMinAndMaxValues(1), viewWindowMode: "maximized" },
          },
          hAxis: {
            format: getDateTimeFormat(DateTimeFormatTarget.ChartTimeAxis),
            /* having undefined here will "reset" viewWindow internally based on value in data provided to chart.
             This means "initial" values which are viewWindow values set by time range selector */
            viewWindow: this.state.hAxisViewWindow ?? undefined,
          },
          legend: LEGEND,
          animation: ANIMATION,
          enableInteractivity: true,
          interpolateNulls: true,
        }}
        style={{
          width: "100%",
          height: "100%",
        }}
        formatters={[
          {
            type: "DateFormat",
            column: 0,
            options: {
              pattern: getDateTimeFormat(DateTimeFormatTarget.ChartTooltip),
            },
          },
        ]}
      />
    );
  }
}

/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { Service } from "../backend/AppSyncClientProvider";
import Event, { EventState, IEventMetadata } from "../clientSpecific/Event";
import { EventRepositoryListener } from "./EventRepositoryListener";
import AppSyncClientFactory from "../backend/AppSyncClientFactory";
import { Maybe, Nullable, Voidable } from "../../types/aliases";
import { EventsDeactivateDocument, EventsFeedDocument, EventsFeedSubscription, EventsListDocument, EventsMetadataAddDocument, EventsMetadataDeleteDocument, EventsMetadataListDocument, EventsTriggerRulesAddDocument, EventsTriggerRulesDeleteDocument, EventsTriggerRulesListDocument } from "../../generated/gqlEvents";
import { EventTriggerDbEntry, RuleProperties } from "../types/eventTypes";
import ClientProperties from "../clientSpecific/ClientProperties";
import AbstractSetSubscriptionManager from "../utils/subscriptions/AbstractSetSubscriptionManager";

export default class EventsRepository extends AbstractSetSubscriptionManager<EventRepositoryListener, EventsFeedSubscription> {
  public static readonly EVENT_AGE_DAYS = 365;
  private static __instance: EventsRepository = new EventsRepository();

  private initialized = false;
  private events: Event[] = [];
  private eventMetadata: Map<string, IEventMetadata> = new Map<string, IEventMetadata>();

  private constructor() {
    super(Service.EVENTS, EventsFeedDocument);
  }

  public static get instance(): EventsRepository {
    return EventsRepository.__instance;
  }

  public async init(): Promise<void> {
    this.eventMetadata = new Map();
    const range = ClientProperties.getDefaultEventTimestampRange(EventsRepository.EVENT_AGE_DAYS);
    await this.fetchAllEvents(`${range.start}`, `${range.end}`);
    await this.fetchEventMetadata();
    this.subscribeOnce();
    this.initialized = true;

    this.forEachListener((listener: EventRepositoryListener) => {
      listener.onEventsInitDone();
    });
  }

  public uninit(): void {
    this.unsubscribe();

    if (this.eventMetadata) {
      this.eventMetadata.clear();
    }

    this.initialized = false;
  }

  // changed to sync method which only filters events as in BF2020-ui & Utu-ui
  public getAllActiveEvents(): Event[] {
    // if (!this.events || this.events.length === 0) {
    //   const range = ClientProperties.getDefaultEventTimestampRange(ClientProperties.EVENT_AGE_DAYS);
    //   await this.fetchAllEvents(`${range.start}`, `${range.end}`);
    // }
    return this.events.filter((e: Event) => {
      return e.eventState === EventState.Active;
    });
  }

  public getAllEvents(): Event[] {
    return this.events;
  }

  public getEventDescription(eventId: string): string {
    const description = this.eventMetadata.get(eventId)?.description;
    return description ?? eventId;
  }

  public async fetchAllEvents(startTimestamp: string, endTimestamp?: string): Promise<Event[]> {
    let nextToken: Nullable<string> = null;
    let events: Event[] = [];

    try {
      do {
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.EVENTS);
        const eventsResponse = await client.query(
          EventsListDocument,
          {
            startTimestamp,
            endTimestamp: endTimestamp != null ? endTimestamp : `${Date.now()}`,
            nextToken,
          },
          {
            fetchPolicy: "network-only",
          },
        );
        // cast is required or response's type inference becomes cyclic
        // nextToken = (eventsResponse.data.eventsList?.nextToken ?? null) as Nullable<string>;
        nextToken = this.parseNextToken(eventsResponse.data.eventsList?.nextToken);
        events = events.concat(eventsResponse.data.eventsList?.events ?? []);
      } while (nextToken);
      this.events = events;
      return events;
    } catch (error) {
      console.error("Error", error);
      return [];
    }
  }

  private parseNextToken(token: Voidable<string>): Nullable<string> {
    // backend returns "e30=" (base 64 representation of empty JSON object {}) when no more items are available
    if (!token || token === "e30=") {
      return null;
    } else {
      return token;
    }
  }

  protected subscriptionHandler(result: Maybe<EventsFeedSubscription>): void {
    if (!result?.eventsFeed) return;

    const event = result.eventsFeed.item;
    const isNew = this.handleEvent(event);
    this.forEachListener((listener: EventRepositoryListener) => {
      if (isNew) {
        listener.onEvent(event);
      } else {
        listener.onEventStateChanged(event);
      }
    });
  }

  public isInitialized(): boolean {
    return this.initialized;
  }

  private async fetchEventMetadata(): Promise<void> {
    let nextToken: Nullable<string> = null;

    try {
      do {
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.EVENTS);
        const metadataResponse = await client.query(
          EventsMetadataListDocument,
          {
            nextToken,
          },
          {
            fetchPolicy: "network-only",
          },
        );
        // cast is required or response's type inference becomes cyclic
        nextToken = (metadataResponse.data.eventsMetadataList?.nextToken ?? null) as Nullable<string>;
        metadataResponse.data.eventsMetadataList?.eventMetadataItems.forEach((metadata) => {
          this.eventMetadata.set(metadata.eventId, metadata);
        });
      } while (nextToken);
    } catch (error) {
      console.error("Error", error);
    }
  }

  public async eventsTriggerRulesAdd(triggerId: string, rules: RuleProperties[], deviceId?: string): Promise<string> {
    try {
      const client = AppSyncClientFactory.createProvider().getTypedClient(Service.EVENTS);
      const response = await client.mutate(
        EventsTriggerRulesAddDocument,
        { triggerId,
          deviceId,
          rules: JSON.stringify(rules),
        },
      );
      return response.data?.eventsTriggerRulesAdd ? "success" : "error";
    } catch (error) {
      console.error("Error: " + JSON.stringify(error));
      return "error";
    }
  }

  public async eventsTriggerRulesDelete(triggerId: string): Promise<string> {
    try {
      const client = AppSyncClientFactory.createProvider().getTypedClient(Service.EVENTS);
      const response = await client.mutate(
        EventsTriggerRulesDeleteDocument,
        { triggerId },
      );
      return response.data?.eventsTriggerRulesDelete ? "success" : "error";
    } catch (error) {
      console.error("Error: " + JSON.stringify(error));
      return "error";
    }
  }

  // TODO: remove parameter that fixed problem which now seems to be fixed with fetchPolicy: "network-only",
  public async eventsTriggerRulesList(deviceId: string): Promise<EventTriggerDbEntry[]> {
    try {
      const client = AppSyncClientFactory.createProvider().getTypedClient(Service.EVENTS);
      const response = await client.query(
        EventsTriggerRulesListDocument,
        {
          deviceId,
        },
        {
          fetchPolicy: "network-only",
        },
      );
      return response.data?.eventsTriggerRulesList ? JSON.parse(response.data.eventsTriggerRulesList) : [];
    } catch (error) {
      console.error("Error: " + JSON.stringify(error));
      return [];
    }
  }

  public async deactivateEvent(event: Event): Promise<string> {
    // Set inactive for fast UI update. State will recover via subs if deactivation fails in cloud side
    event.eventState = EventState.Inactive;
    const eventPayload = {
      deviceId: event.deviceId,
      timestamp: event.timestamp,
      commentAuthor: event.commentAuthor,
      commentText: event.commentText,
      commentTimestamp: event.commentTimestamp,
    };

    try {
      const client = AppSyncClientFactory.createProvider().getTypedClient(Service.EVENTS);
      const response = await client.mutate(
        EventsDeactivateDocument,
        {
          payload: eventPayload,
        },
      );
      return response.data?.eventsDeactivate ? "success" : "error";
    } catch (error) {
      console.log(error);
      return "error";
    }
  }

  public async addEventMetadata(metadata: IEventMetadata): Promise<string> {
    try {
      const client = AppSyncClientFactory.createProvider().getTypedClient(Service.EVENTS);
      const { data } = await client.mutate(
        EventsMetadataAddDocument,
        {
          ...metadata,
        },
      );

      if (data?.eventsMetadataAdd) {
        const { eventsMetadataAdd } = data;
        this.eventMetadata.set(eventsMetadataAdd.eventId, eventsMetadataAdd);
        return eventsMetadataAdd.eventId;
      } else {
        console.error("Unexpected type of response data: ", data);
        return "error";
      }
    } catch (error) {
      console.log(error);
      return "error";
    }
  }

  public async deleteEventMetadata(eventId: string): Promise<string> {
    try {
      const client = AppSyncClientFactory.createProvider().getTypedClient(Service.EVENTS);
      await client.mutate(
        EventsMetadataDeleteDocument,
        {
          eventId,
        },
      );
      this.eventMetadata.delete(eventId);
      return "success";
    } catch (error) {
      console.log(error);
      return "error";
    }
  }

  private handleEvent(event: Event): boolean {
    const index = this.events.findIndex(EventsRepository.getIsSameEventComparator(event));
    const isNew = index === -1;

    if (isNew) {
      this.events.push(event);
    } else {
      this.events[index] = event;
    }
    return isNew;
  }

  public static getIsSameEventComparator(event: Event): (e: Event) => boolean {
    return (e: Event): boolean => event.deviceId === e.deviceId
      && event.eventId === e.eventId
      && event.timestamp === e.timestamp;
  }
}

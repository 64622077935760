/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { DataSet } from "../data/DataSet";
import { LatestData } from "../data/LatestData";
import { SessionSet } from "../data/SessionSet";
import EventSet from "../events/EventSet";
import BaseObservable from "../observer/BaseObservable";
import DeviceState from "./DeviceState";
import { Nullable, Voidable } from "../../types/aliases";
import { Attribute } from "./Attribute";
import DeviceGroup from "./DeviceGroup";
import { DeviceMetadata } from "../../types/utu-types";

export type StatePropertiesOf<TState> = TState extends DeviceState<infer TProperties> ? TProperties : never;

export interface DeviceObserver {
  onDeviceStateUpdated?: (device: Device) => void;
  onDeviceGroupsChanged?: (device: Device) => void;
}

export interface DeviceParameters {
  deviceId: string;
  attributes?: Attribute[];
}

export default abstract class Device<TState extends DeviceState = DeviceState> extends BaseObservable<DeviceObserver> {

  public static isValid(device: Voidable<Device>): boolean {
    return Device.instanceOf(device) && device.getId().length > 0;
  }

  public abstract init(): Promise<void>;

  public abstract getId(): string;

  public abstract getType(): string;

  public abstract getIcon(): string;

  public abstract getAttribute(key: string): Nullable<string>;

  public abstract getAttributes(): Attribute[];

  public abstract getState(): Nullable<TState>;

  public abstract getSessions(startTimestamp: number, endTimestamp: number): Promise<Nullable<SessionSet>>;

  public abstract getData(startTimestamp: number, endTimestamp: number): Promise<Nullable<DataSet>>;

  public abstract getLatestData(): Promise<Nullable<LatestData>>;

  public abstract getGroups(): Promise<DeviceGroup[]>;

  public abstract setState(timestamp?: number, current?: Partial<StatePropertiesOf<TState>>, next?: Partial<StatePropertiesOf<TState>>): void;

  public abstract getEvents(startTimestamp: number, endTimestamp: number): Promise<Nullable<EventSet>>;

  public abstract updateAttributes(attributes: Attribute[]): Promise<void>;

  public abstract createState(timestamp?: number, reported?: Partial<StatePropertiesOf<TState>>, desired?: Partial<StatePropertiesOf<TState>>): TState;

  public static instanceOf(value: unknown): value is Device {
    return value instanceof Device;
  }

  // utu
  public abstract getMetadata(): Promise<Nullable<DeviceMetadata>>;
  public abstract setMetadata(metadata: DeviceMetadata): Promise<string>;
}

/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { TypedAppSyncClient } from "../../backend/TypedAppSyncClient";
import { TypedDocumentNode } from "@graphql-typed-document-node/core";
import { Maybe } from "../../../types/aliases";
import { ReceiverObserver } from "../ReceiverObserver";
import { OperationDefinitionNode } from "graphql/language/ast";


export type SubscriptionHandler<TResult> = (result: Maybe<TResult>) => void;
export type SubscriptionDocument<TResult> = TypedDocumentNode<TResult, { receiver: string }>;

export default class ReceiverObserverSubscription<TResult> implements ReceiverObserver {
  private subscriptions = new Map<string, ZenObservable.Subscription>();
  private readonly operationName?: string;
  
  public constructor(
      private readonly clientProvider: () => TypedAppSyncClient,
      private readonly document: SubscriptionDocument<TResult>,
      private readonly handler: SubscriptionHandler<TResult>,
  ) {
    this.operationName = document.definitions
      .find<OperationDefinitionNode>((def): def is OperationDefinitionNode => def.kind === "OperationDefinition")
      ?.name?.value;
    console.log("Created ReceiverObserverSubscription for " + this.operationName);
  }

  public get knownReceivers(): string[] {
    return [...this.subscriptions.keys()];
  }

  public terminateSubscriptions(): void {
    this.knownReceivers.forEach(receiver => this.removeSubscription(receiver));
  }

  public onReceiversChanged(newReceivers: string[]): void {
    this.knownReceivers
      .filter(oldReceiver => !newReceivers.includes(oldReceiver))
      .forEach(receiver => this.removeSubscription(receiver));

    newReceivers
      .filter(receiver => !this.subscriptions.has(receiver))
      .forEach(receiver => this.subscribe(receiver));
  }
  
  private subscribe(receiver: string): void {
    if (this.subscriptions.has(receiver)) {
      return;
    }
    console.log(`${this.operationName} subscribing to ${receiver}`);
    const client = this.clientProvider();
    const subscription = client.subscribe(
      this.document,
      {
        receiver,
      },
    ).subscribe({
      // TODO: Fix any type
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      error: (error: any): void => {
        if (error.errorMessage === "AMQJS0008I Socket closed.") {
          this.reSubscribe(receiver);
        } else {
          console.error("Received subscription error", error);
        }
      },
      next: async (update): Promise<void> => {
        try {
          await this.handler(update.data ?? undefined);
        } catch (err) {
          console.error("subscription handler leaks errors, unsubscribing handler", err);
          this.removeSubscription(receiver);
        }
      },
    });
    this.subscriptions.set(receiver, subscription);
  }

  private removeSubscription(receiver: string): void {
    this.subscriptions.get(receiver)?.unsubscribe();
    this.subscriptions.delete(receiver);
  }
  
  private reSubscribe(receiver: string): void {
    this.removeSubscription(receiver);
    this.subscribe(receiver);
  }
}

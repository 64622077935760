/* Copyright */
import { EventSeverity, EventState, EventType } from "../../data/clientSpecific/Event";
import Device from "../../data/device/Device";
import { Fallible, Maybe, Nullable } from "../aliases";

/* HELPER TYPES */
type GenericDictionaryEntry<T> = {
  name: T;
  displayName: string;
};

type StringIndexableDictionaryEntry<T> = {
  readonly [index: string]: GenericDictionaryEntry<T>;
};

type StringIndexable = {
  readonly [index: string]: string | number | boolean;
};

type NumberIndexable<T> = {
  readonly [index: number]: Maybe<T>;
};

type PropertyTypesRemapped<T, K> = {
  [Property in keyof T]: K;
};

/* BASE SENSOR DATA NAME TYPES */
type PdPrefixes = "hfct" | "mic";

type CabPrefix = "cab";

type TransfPrefix = "transf";

type PdCabAndTransfPrefixes = PdPrefixes | CabPrefix | TransfPrefix;

type PdPrefixesFormattedWith_g = `${PdPrefixes}_g`;

type PdConfigProperties = Record<PdPrefixesFormattedWith_g, Fallible<number>>;

type CabPrefixWithMeasurementTypes = `${CabPrefix}_t` | `${CabPrefix}_rh`;

type TransfPrefixWithMeasurementTypes = `${TransfPrefix}_a` | `${TransfPrefix}_o`;

type CabAndTransfConfigKeys = CabPrefixWithMeasurementTypes | TransfPrefixWithMeasurementTypes;

type CabAndTransfConfigProperties = Record<CabAndTransfConfigKeys, boolean>;

type ConfigBase = {
  door_lv: boolean;
  door_mv: boolean;
  sigD1: Fallible<string>;
  sigD2: Fallible<string>;
  sigD3: Fallible<string>;
  sigD4: Fallible<string>;
  sigD5: Fallible<string>;
};

export type MeasurementBoardConfig = ConfigBase & PdConfigProperties & CabAndTransfConfigProperties & StringIndexable;

/* SIGMA DATA */
type SigmaKeys = Pick<ConfigBase, "sigD1" | "sigD2" | "sigD3" | "sigD4" | "sigD5">;

type SigmaSensorDataNew = PropertyTypesRemapped<SigmaKeys, number>;


/* PD DATA */
type PdPrefixesWithMeasurementIdentiefiers = `${PdPrefixes}_avg` | `${PdPrefixes}_max`;

type PDSensorData = Record<PdPrefixesWithMeasurementIdentiefiers, number>;


/* TEMPERATURE AND HUMIDITY DATA  */
type TemperatureAndHumidityKeys = Pick<MeasurementBoardConfig, "cab_t" | "cab_rh" | "transf_a" | "transf_o">;

type TemperatureAndHumiditySensorData = PropertyTypesRemapped<TemperatureAndHumidityKeys, number>;


/* DOOR DATA */
type DoorSensorData = Pick<MeasurementBoardConfig, "door_lv" | "door_mv">;

type DoorSensorDataValue = 0 | 1;

export type DoorSensorDataValueDictionary = Record<DoorSensorDataValue, GenericDictionaryEntry<DoorSensorDataValue>>;


/* COMPLETE SENSOR DATA */
// latestData palauttaa tämäntyyppistä dataa muun datan joukossa
export type SensorData = TemperatureAndHumiditySensorData & PDSensorData & DoorSensorData & SigmaSensorDataNew;

// example object
// const sensorData: SensorData = {
//   cab_t: 0,
//   cab_rh: 0,
//   transf_a: 0,
//   transf_o: 0,
//   door_lv: false,
//   door_mv: false,
//   sigD1: 0,
//   sigD2: 0,
//   sigD3: 0,
//   sigD4: 0,
//   sigD5: 0,
//   hfct_avg: 0,
//   hfct_max: 0,
//   mic_avg: 0,
//   mic_max: 0,
// };


/* SENSOR DATA DISPLAY NAMES */
export type SensorDataWithoutSigmaData = Omit<SensorData, "sigD1" | "sigD2"| "sigD3" | "sigD4" | "sigD5">;

// TODO: should sigma data be included? (will sigma device name given by user replace sigD1_1 etc?)
export type ConstantSensorDataDictionary = Record<keyof SensorDataWithoutSigmaData, GenericDictionaryEntry<keyof SensorDataWithoutSigmaData>> & StringIndexableDictionaryEntry<keyof SensorDataWithoutSigmaData>;


/* ADD ON CONFIG SELECTION DISPLAY NAMES */
export type AddOnConfigSelectionKeys = PdCabAndTransfPrefixes | keyof DoorSensorData | keyof SigmaKeys;

export type AddOnConfigSelectionDictionary = Record<AddOnConfigSelectionKeys, GenericDictionaryEntry<AddOnConfigSelectionKeys>>;


/* EVENT TRIGGER TYPE DISPLAY NAMES  */
export type SensorTypeDisplayNameDictionary = Record<PdCabAndTransfPrefixes, GenericDictionaryEntry<PdCabAndTransfPrefixes>> & StringIndexableDictionaryEntry<PdCabAndTransfPrefixes>;


/* EVENT TRIGGER OPERATORS */
export type TriggerOperators = "lessThan" | "greaterThan";

export type TriggerOperatorDictionary = Record<TriggerOperators, GenericDictionaryEntry<TriggerOperators>> & StringIndexableDictionaryEntry<TriggerOperators>;


/* LATEST DATA TABLE */
type LatestDataTableKeys = PdPrefixes | CabPrefix | TransfPrefixWithMeasurementTypes | keyof SigmaKeys | keyof DoorSensorData ;

export type LatestDataTableDictionary = Record<LatestDataTableKeys, GenericDictionaryEntry<LatestDataTableKeys>> & StringIndexableDictionaryEntry<LatestDataTableKeys> ;


/* HISTORY CHART */
type HistoryChartSensorDataNames = keyof Pick<SensorData, "cab_rh" | "cab_t" | "transf_a" | "transf_o" | "hfct_avg" | "hfct_max" | "mic_avg" | "mic_max">;

export type HistoryChartSensorDataPair = [HistoryChartSensorDataNames, HistoryChartSensorDataNames];

type HistoryChartDictionaryProperty = Record<"sensorDataNames", HistoryChartSensorDataPair>;

type ExtendedDictionaryEntry<T> = GenericDictionaryEntry<T> & HistoryChartDictionaryProperty;

export type HistoryChartDictionary = Record<PdCabAndTransfPrefixes, ExtendedDictionaryEntry<PdCabAndTransfPrefixes>>;

/* MAP DATA  */
export type MapLocation = {
  lat: number;
  lng: number;
};

export type MapLocationWithDeviceProperties = MapLocation & {
  device: Device;
};

/* ENUMS */
export enum BackendActionStatus {
  ERROR,
  SUCCESS,
}

export enum Action {
  ADD,
  DELETE,
  EDIT,
}

export enum CircuitStatus {
  UP = "up",
  DOWN = "down",
  OK = "ok",
}


/* CIRCUIT INDICATOR */

type CircuitTypeKey = "earthCircuit" | "shortCircuit";

export type SigmaDataDecodeResult = Record<CircuitTypeKey, CircuitStatus>;

// example object
// const sigmaDataDecodeResult: SigmaDataDecodeResult = {
//   earthCircuit: CircuitStatus.DOWN,
//   shortCircuit: CircuitStatus.OK,
// };


// PowerCompanySelector

type OrganizationLevelKeys = 0 | 1 | 2;

type OrganizationLevelName = "root" | "powerCompany" | "powerCompanyRegion";

// level corresponds to number of separators (:) in organizationId: root organization has 0 separators and any direct child organization of
// root organization has 1 separator etc.
// OrganizationLevelKeys are defined with the assumption that there are maximum of three levels in organization hierarchy. However, nothing
// stops an admin user from creating a deeper hierarchy
type OrganizationLevel<T, K> = {
  level: T;
  name: K;
};

export type OrganizationLevelDictionaryEntry = OrganizationLevel<OrganizationLevelKeys, OrganizationLevelName>;

export type OrganizationLevelDictionary = Record<OrganizationLevelKeys, OrganizationLevelDictionaryEntry> & NumberIndexable<OrganizationLevelDictionaryEntry>;

/* CURRENT EVENT AND METADATA TYPES - TO BE REFACTORED*/

// CommentPopup, EventsTable
export type EventComment = Pick<Event, "commentAuthor" | "commentText" | "commentTimestamp" | "eventId" | "timestamp">;

// DeviceMetaData
export interface DeviceMetadata {
  deviceId: string;
  asiakas: Nullable<string>;
  muuntamonNimi: Nullable<string>;
  muuntamonNumero: Nullable<string>;
  toimituspvm: Nullable<string>;
  tuotantotilaus: Nullable<string>;
}

// Assetista, laajennettu kommenttiavaimilla
export interface Event {
  deviceId: string;
  timestamp: string;
  eventId?: Nullable<string>;
  updatedTimestamp?: Nullable<string>;
  type?: Nullable<EventType>;
  eventState?: Nullable<EventState>;
  severity?: Nullable<EventSeverity>;
  sensorName?: Nullable<string>;
  sensorValue?: Nullable<number>;
  metadata?: Nullable<string>;
  commentText?: Nullable<string>;
  commentAuthor?: Nullable<string>;
  commentTimestamp?: Nullable<string>;
}


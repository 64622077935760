/* Copyright */
import { Box, Button, Typography } from "@material-ui/core";
import React, { Component, Fragment } from "react";
import Loader from "../../../ui/loader";
import { Maybe, Nullable } from "../../../../types/aliases";
import EventTriggerPopup from "./event-trigger-popup";
import { EventConfig, EventTriggerDbEntry } from "../../../../data/types/eventTypes";
import EventsRepository from "../../../../data/events/EventsRepository";
import ConfirmationDialogUtu from "../../../ui/confirmation-dialog-utu";
import EventTriggerTable from "./event-trigger-table";
import { Action, BackendActionStatus } from "../../../../types/utu-types";
import { hasKey } from "../../../../utils/functions";

interface Props {}

interface State {
  backendActionLoading: boolean;
  backendActionStatus: Nullable<BackendActionStatus>;
  confirmationPopupOpen: boolean;
  eventTriggerList: Nullable<EventTriggerDbEntry[]>;
  loading: boolean;
  selectedEventTriggerIndex: Nullable<number>;
  triggerPopupOpen: boolean;
}

export default class EventConfiguration extends Component<Props, State> {

  public constructor(props: Props) {
    super(props);
    this.state = {
      backendActionLoading: false,
      backendActionStatus: null,
      confirmationPopupOpen: false,
      eventTriggerList: null,
      loading: false,
      selectedEventTriggerIndex: null,
      triggerPopupOpen: false,
    };
  }

  public async componentDidMount(): Promise<void> {
    this.setState({ loading: true });
    const eventTriggerList = await EventsRepository.instance.eventsTriggerRulesList("dummy");
    this.setState({ eventTriggerList, loading: false });
  }

  public componentDidUpdate(_prevProps: Props, prevState: State): void {
    if (!this.state.confirmationPopupOpen && prevState.confirmationPopupOpen ||
      !this.state.triggerPopupOpen && prevState.triggerPopupOpen) {

      if (this.state.backendActionStatus !== null) {
        this.setState({ backendActionStatus: null });
      }

      if (this.state.selectedEventTriggerIndex !== null) {
        this.setState({ selectedEventTriggerIndex: null });
      }
    }
  }

  private renderNewTriggerButton(): JSX.Element {
    return (
      <Box display="flex" justifyContent="flex-end" p={2} border="1px solid rgb(224, 224, 224)">
        <Button
          color="primary"
          key={0}
          onClick={(): void => this.setState({ triggerPopupOpen: true })}
          variant="contained"
        >
          Luo uusi konfiguraatio
        </Button>
      </Box>
    );
  }

  private renderEventTriggerTable(): JSX.Element {
    if (this.state.loading) {
      return <Loader/>;
    } else {
      return (
        <EventTriggerTable
          onSelectTriggerForDeleting={(triggerId: string): void => this.selectTriggerForAction(triggerId, Action.DELETE)}
          onSelectTriggerForEditing={(triggerId: string): void => this.selectTriggerForAction(triggerId, Action.EDIT)}
          tableItems={this.state.eventTriggerList}
        />
      );
    }
  }

  private renderConfirmationPopup(): Maybe<JSX.Element> {
    if (!this.state.confirmationPopupOpen) {
      return;
    } else {
      const title = "Hälytyskonfiguraation poisto";
      return (
        <ConfirmationDialogUtu
          title={title}
          onCancel={(): void => this.setState({ confirmationPopupOpen: false })}
        >
          {this.renderConfirmationPopupBody()}
        </ConfirmationDialogUtu>
      );
    }
  }

  private renderConfirmationPopupBody(): JSX.Element {
    let body: Nullable<JSX.Element> = null;
    let message = "";

    if (this.state.backendActionLoading) {
      body = <Loader/>;
    } else if (this.state.backendActionStatus === BackendActionStatus.SUCCESS){
      message = "Hälytyskonfiguraation poisto onnistui";
      body = (
        <Button
          className="button"
          color="primary"
          onClick={(): void => this.setState({ confirmationPopupOpen: false })}
          style={{ marginTop: "1rem" }}
          variant="contained"
        >
        Ok
        </Button>
      );
    } else if (this.state.backendActionStatus === BackendActionStatus.ERROR){
      message = "Hälytyskonfiguraation poisto epäonnistui";
      body = (
        <Button
          className="button"
          color="primary"
          onClick={(): void => this.setState({ confirmationPopupOpen: false })}
          variant="contained"
        >
        Ok
        </Button>
      );
    } else {
      message = `Haluatko varmasti poistaa hälytyskonfiguraation ${this.getSelectedEventTrigger()?.rules[0].name}?`;
      body = (
        <Box display="flex" justifyContent="center">
          <Button
            color="primary"
            onClick={this.handleConfirmDelete}
            variant="contained"
            style={{ marginRight: "1rem" }}
          >
          Vahvista
          </Button>
          <Button
            color="primary"
            onClick={(): void => this.setState({ confirmationPopupOpen: false })}
            variant="contained"
          >
          Peruuta
          </Button>
        </Box>
      );
    }
    return (
      <>
        {message !== "" && <Typography variant="subtitle1" style={{ marginBottom: "1rem" }}>{message}</Typography>}
        {body}
      </>
    );
  }

  private renderTriggerPopup(): Maybe<JSX.Element> {
    if (!this.state.triggerPopupOpen) {
      return;
    } else {
      return (
        <EventTriggerPopup
          cancel={(): void => this.setState({ triggerPopupOpen: false })}
          open={this.state.triggerPopupOpen}
          save={this.saveEventConfig}
          selectedEventTrigger={this.getSelectedEventTrigger()}
          backendActionLoading={this.state.backendActionLoading}
          backendActionStatus={this.state.backendActionStatus}
        />
      );
    }
  }

  public render(): JSX.Element {
    return (
      <Fragment>
        {this.renderNewTriggerButton()}
        {this.renderEventTriggerTable()}
        {this.renderTriggerPopup()}
        {this.renderConfirmationPopup()}
      </Fragment>
    );
  }

  private selectTriggerForAction = (triggerId: string, action: Action.EDIT | Action.DELETE): void => {
    //CAST: the button to which this function is attached is not rendered if eventTriggerList === null
    const selectedEventTriggerIndex = this.state.eventTriggerList!.findIndex(trigger => trigger.triggerId === triggerId);

    if (selectedEventTriggerIndex !== -1) {
      if (action === Action.EDIT) {
        this.setState({ selectedEventTriggerIndex, triggerPopupOpen: true });
      } else if (action === Action.DELETE) {
        this.setState({ selectedEventTriggerIndex, confirmationPopupOpen: true });
      } else {
        console.error("Unknown parameter 'action' in EventConfiguration.selectTriggerForAction");
      }
    } else {
      console.error("this.state.eventTriggerList is corrupted in EventConfiguration.selectTriggerForAction");
    }
  };

  private saveEventConfig = async (eventConfig: Maybe<EventConfig>, isNewConfig: boolean): Promise<void> => {
    if (!eventConfig) {
      this.setState({
        backendActionStatus: BackendActionStatus.ERROR,
      });
    } else {
      this.setState({ backendActionLoading: true });
      const metadataResponse = await EventsRepository.instance.addEventMetadata(eventConfig.metadata);

      if (metadataResponse === "error") {
        this.setState({ backendActionStatus: BackendActionStatus.ERROR });
        return;
      } else {
        const { trigger } = eventConfig;
        const triggerResponse = await EventsRepository.instance.eventsTriggerRulesAdd(trigger.triggerId, trigger.rules);
        const isSuccess = triggerResponse === "success";
        this.setState({
          backendActionLoading: false,
          backendActionStatus: isSuccess ? BackendActionStatus.SUCCESS : BackendActionStatus.ERROR,
        });

        if (isSuccess) {
          this.updateEventTriggerList(trigger, Action.ADD, isNewConfig);
        }
      }
    }
  };

  private handleConfirmDelete = async (): Promise<void> => {
    // assertion: this.getSelectedEventTrigger() !== null if this function executed
    const trigger = this.getSelectedEventTrigger()!;

    if (!hasKey(trigger.rules[0].event.params, "eventId")) {
      this.setState({ backendActionStatus: BackendActionStatus.ERROR });
      console.error("Trigger to delete is not valid");
    } else {
      this.setState({ backendActionLoading: true });
      const metadataResponse = await EventsRepository.instance.deleteEventMetadata(trigger.rules[0].event.params.eventId);

      if (metadataResponse === "error") {
        this.setState({ backendActionStatus: BackendActionStatus.ERROR });
        return;
      } else {
        const triggerResponse = await EventsRepository.instance.eventsTriggerRulesDelete(trigger.triggerId);
        const isSuccess = triggerResponse === "success";
        this.setState({
          backendActionLoading: false,
          backendActionStatus: isSuccess ? BackendActionStatus.SUCCESS : BackendActionStatus.ERROR,
        });

        if (isSuccess) {
          this.updateEventTriggerList(trigger, Action.DELETE);
        }
      }
    }
  };

  private updateEventTriggerList(trigger: EventTriggerDbEntry, action: Action.ADD | Action.DELETE, isNewTrigger?: boolean): void {
    let eventTriggerList: Nullable<EventTriggerDbEntry[]> = null;

    if (action === Action.ADD) {
      if (this.state.eventTriggerList && isNewTrigger) {
        eventTriggerList = this.state.eventTriggerList.concat(trigger);
      } else if (this.state.eventTriggerList && !isNewTrigger) {
        const triggerToUpdate = this.state.eventTriggerList.find(({ triggerId }) => triggerId === trigger.triggerId);

        if (triggerToUpdate) {
          eventTriggerList = this.state.eventTriggerList.map(eTrigger => eTrigger.triggerId === triggerToUpdate.triggerId ? trigger : eTrigger);
        } else {
          console.error("this.state.eventTriggerList is corrupted in EventConfiguration.updateEventTriggerList");
        }
      } else {
        eventTriggerList = [trigger];
      }
    } else if (action === Action.DELETE) {
      // CAST: deleting is possible only if this.state.eventTriggerList !== null
      eventTriggerList = this.state.eventTriggerList!.filter(({ triggerId }) => triggerId !== trigger.triggerId);
    } else {
      console.error("Unknown parameter 'action' in EventConfiguration.updateEventTriggerList");
    }
    this.setState({ eventTriggerList });
  }

  private getSelectedEventTrigger(): Nullable<EventTriggerDbEntry> {
    const trigger = this.state.eventTriggerList
      ? this.state.selectedEventTriggerIndex !== null
        ? this.state.eventTriggerList[this.state.selectedEventTriggerIndex]
        : null
      : null;
    return trigger;
  }
}

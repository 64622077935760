/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import React, { Component } from "react";
import HEADER_LOGO from "../../assets/utu_logo0.png";
import { AuthenticatedUser } from "../../data/auth/AuthWrapper";
import CompleteAccountCreationForm from "./components/complete-account-creation-form";
import ForgotPasswordForm from "./components/forgot-password-form";
import LoginForm from "./components/login-form";

interface Props {}

interface State {
  form: Forms;
  user?: string | AuthenticatedUser;
}

export enum Forms {
  ForgotPassword,
  ConfirmSignUp,
  SignIn
}

export default class LoginView extends Component<Props, State> {

  public constructor(props: Props) {
    super(props);
    this.state = {
      form: Forms.SignIn,
    };
  }

  private renderInputFields(): JSX.Element {
    if (this.state.form === Forms.ForgotPassword && LoginView.isString(this.state.user)) {
      return (
        <ForgotPasswordForm
          onLoginRequest={(): void => this.setState({ form: Forms.SignIn })}
          username={this.state.user}
        />
      );
    } else if (this.state.form === Forms.ConfirmSignUp && this.state.user && !LoginView.isString(this.state.user)) {
      return (
        <CompleteAccountCreationForm
          user={this.state.user}
        />
      );
    }
    return (
      <LoginForm
        onSignUpConfirmRequest={(user): void => this.setState({ form: Forms.ConfirmSignUp, user })}
        onPasswordResetRequest={(user): void => this.setState({ form: Forms.ForgotPassword, user })}
      />
    );
  }

  private static isString(value: unknown): value is string {
    return typeof value === "string";
  }

  private redirectTo(form: Forms, user?: string | AuthenticatedUser): void {
    this.setState({ form, user: user });
  }

  public render(): JSX.Element {
    return (
      <section className="login-page-container col-md-12">
        <header className="header-container login col-md-12">
          <div className="logo-container login col-xlg-4 col-lg-6 col-sm-8 col-xsm-8">
            <img className="header-logo" src={HEADER_LOGO} alt="UTU Oy" />
          </div>
          <a
            href="https://d2i8hnqa0bu4o.cloudfront.net"
            className="link-to-legacy-version"
          >
            Siirry vanhaan Muuntamovalvontaan
          </a>
        </header>
        <section className="login-container col-md-12">
          <div className="login-content col-lg-4 col-md-6 col-sm-6 col-xsm-8">
            {this.renderInputFields()}
          </div>
        </section >
      </section>
    );
  }
}

/* Copyright */
import { Box } from "@material-ui/core";
import React, { Component } from "react";
import { Event } from "../../../data/clientSpecific/Event";
import Device from "../../../data/device/Device";
import { EventRepositoryListener } from "../../../data/events/EventRepositoryListener";
import EventsRepository from "../../../data/events/EventsRepository";
import { Nullable } from "../../../types/aliases";
import Loader from "../../ui/loader";

interface Props {
  device: Device;
}

interface State {
  activeEvents: Nullable<number>;
  loading: boolean;
}

class EventsCounter extends Component<Props, State> implements EventRepositoryListener {
  public constructor(props: Props) {
    super(props);
    this.state = {
      activeEvents: null,
      loading: false,
    };
  }

  public componentDidMount(): void {
    EventsRepository.instance.addListener(this);
    this.setState({ loading: true });

    if (EventsRepository.instance.isInitialized()) {
      const activeEvents = this.countEvents();
      this.setState({ loading: false, activeEvents });
    }
  }

  public componentWillUnmount(): void {
    EventsRepository.instance.removeListener(this);
  }

  public async onEventsInitDone(): Promise<void> {
    console.log("onEventsInitDone");
    this.setState({ activeEvents: this.countEvents() });

    // Set loading false in case EventsRepository initializing is not completed in componentDidMount of this component
    if (this.state.loading) {
      this.setState({ loading: false });
    }
  }

  public onEvent(_event: Event): void {
    console.log("onEventOccurred");
    this.setState({ activeEvents: this.countEvents() });
  }

  public onEventStateChanged(_event: Event): void {
    console.log("onEventStateChanged");
    this.setState({ activeEvents: this.countEvents() });
  }

  private countEvents(): number {
    const allActiveEvents = EventsRepository.instance.getAllActiveEvents();
    let eventsCount = 0;

    if (allActiveEvents) {
      for (const event of allActiveEvents) {
        if (event.deviceId === this.props.device.getId()) {
          eventsCount += 1;
        }
      }
    }
    return eventsCount;
  }

  public render(): JSX.Element {
    const uiStyle = { backgroundColor: "green", color: "white" };
    const { activeEvents } = this.state;

    if (activeEvents && activeEvents > 0) {
      uiStyle.backgroundColor = "#E53935";
      uiStyle.color = "black";
    }
    return (
      this.state.loading
        ?
        <Box ml={2}>
          <Loader leftRightPadding="0px" topBottomPadding="0px" size={2.8}/>
        </Box>
        :
        <div className="history-events-marker" style={uiStyle}>
          {activeEvents ?? 0}
        </div>
    );
  }
}

export default EventsCounter;

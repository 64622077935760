/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import { Voidable } from "../types/aliases";
import { OrganizationLevelDictionaryEntry } from "../types/utu-types";

/**
 * Object is empty, if it
 * - is null or undefined
 * - has no keys
 * - all keys have undefined value
 * @param obj
 */
export function isEmpty<T>(obj: Voidable<Partial<T>>): boolean {
  return obj == null || (
    obj.constructor === Object &&
      (
        Object.keys(obj).length === 0 ||
        !Object.values(obj).find(v => v !== undefined)
      )
  );
}

/**
 * Performs a deep equality between two objects.
 * Should work fairly similarly to JSON.stringify(o1) === JSON.stringify(o2)
 * but is much faster when performing for a lot of objects or clearly different objects.
 */
export function deepEquals(o1: unknown, o2: unknown): boolean {
  if (o1 == null || o2 == null) return o1 === o2;
  if (typeof o1 !== "object" && typeof o2 !== "object") return o1 === o2;

  if (typeof o1 === "object" && typeof o2 === "object") {
    if (Array.isArray(o1) && Array.isArray(o2)) {
      for (let l = Math.max(o1.length, o2.length) - 1; l > 0; l--)
        if (!deepEquals(o1[l], o2[l]))
          return false;
      return true;
    } else {
      const keys = new Set([...Object.keys(o1!), ...Object.keys(o2!)]);

      for (const k of [...keys]) {
        if (!deepEquals(o1?.[k as keyof typeof o1], o2?.[k as keyof typeof o2])) return false;
      }
      return true;
    }
  }
  return false;
}

/**
 * Converts string to a number or undefined. NaN values result into undefined
 *
 * @param value
 *    string potentially representing a number
 */
export function toNumber(value: string): number | undefined {
  const n = Number(value);
  return Number.isNaN(n) ? undefined : n;
}

/**
 * Converts an object into an object with the same keys but values are strings.
 * If the value was a string, it is kept the same. Simple values are converted with toString() and complex objects
 * are converted using JSON.stringify().
 *
 * @param obj
 *    object to convert
 */
export function toStringObject(obj: Record<string, unknown>): Record<string, string> {
  const entries = Object.entries(obj);
  const stringValuedEntries = entries
    .filter(([, v]) => v != null && typeof v !== "function")
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .map(([k, v]) => [k, typeof v === "object" ? JSON.stringify(v) : (v as any).toString()]);
  return Object.fromEntries(stringValuedEntries);
}

/**
 * Wraps a singular entity into an array, passes through objects that are already arrays and
 * returns empty arrays for undefined objects.
 *
 * @param obj
 */
export function arrayWrap<T>(obj?: T | T[]): T[] {
  if (!obj) return [];
  if (Array.isArray(obj)) return obj;
  else return [obj];
}

/**
 * Filter down the given array to new array with only unique items.
 * @param array
 */
export function onlyUniqueElements<T>(array: T[]): T[] {
  return [... new Set(array)];
}

/**
 * Checks whether an object has the given key
 * @param value
 *    potential object
 * @param key
 *    key name
 */
export function hasKey<TValue>(value: unknown, key: keyof TValue): value is TValue {
  if (typeof value === "object" && value != null && !Array.isArray(value)) {
    return Object.keys(value).includes(key as string);
  }
  return false;
}

export function isOrganizationLevelDictionaryEntry(value: unknown): value is OrganizationLevelDictionaryEntry {
  return value != null && hasKey(value, "level") && hasKey(value, "name");
}

/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

export interface ProvidesEntityRelationCache {
  readonly entityRelationCache: EntityRelationCache;
}

// any must be used in constructor types, unknown is too tight and wont match any constructor with actual parameters

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TypedCtor<T extends HasEntityRelations> = new (...args: any[]) => T;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Ctor = TypedCtor<HasEntityRelations>;
export type InstanceTypeOfCtor<TCtor extends Ctor> = TCtor extends TypedCtor<infer TEntity> ? TEntity : never;
export type Metadata = Record<string, unknown>;
export interface EntityRecord<TEntity> {
  entity: TEntity;
  metadata?: Metadata;
}

export function isEntityRecord<TEntity>(value: unknown): value is EntityRecord<TEntity> {
  return typeof value === "object" && value != null && "entity" in value;
}

/**
 * Contains paired (or recently unpaired entities).
 */
export class RelationChange {
  private readonly ctor: Ctor;
  public constructor(entity: HasEntityRelations | Ctor) {
    this.ctor = entity.constructor.name === "Function" ? entity as Ctor : (entity as HasEntityRelations).entityType;
  }

  public ofType<TCtor extends Ctor>(type: TCtor): boolean {
    return this.ctor === type;
  }
}

/**
 * Anything and everything stored into the cache must implement this interface
 */
export interface HasEntityRelations {
  /**
   * Type identifier for the entity. Used to figure out, if a {@link RelationChange} originates
   * from a known object type (even, if the originator is a sub-class of the original {@code HasEntityRelations}
   *
   * Usually just class reference..
   */
  readonly entityType: Ctor;

  /**
   * Callback for relation changes between cached entities
   * @param change
   */
  onRelationChange(change: RelationChange): void;
}


type MetadataMap = Map<HasEntityRelations, Metadata | undefined>;

/**
 * Caches entities that have a N-M relationship, and both of those entities need to be aware of the other.
 * For example, there are entities of type A and B, and they have the following methods:
 * A::getMyBEntities() => B[]
 * B::getMyAEntities() => A[]
 *
 * Given that there are ways to mutate those lists, and mutations to one list can cause mutations to the other list,
 * this cache centralizes those relationships without having to use cross-listeners.
 *
 */
export class EntityRelationCache {
  private entityMap = new Map<HasEntityRelations, MetadataMap>();

  /**
   * Creates a linked pair.
   * Self-links are ignored (cannot link an entity to itself).
   *
   * @param a
   *    entity
   * @param b
   *    entity
   * @param metadata
   *    custom information about the link
   */
  public link(a: HasEntityRelations, b: HasEntityRelations, metadata?: Metadata): void {
    if (a === b) {
      return;
    }

    if (this.createLink(a, b, metadata)) {
      this.notifyActionToBoth(a, b);
    }
  }

  /**
   * Removes a link from between pairs
   *
   * @param a
   *    entity
   * @param b
   *    entity
   */
  public unlink(a: HasEntityRelations, b: HasEntityRelations): void {
    if (this.removeLink(a, b)) {
      this.notifyActionToBoth(a, b);
    }
  }

  /**
   * Retrieves metadata for a pair of entities
   *
   * @param a
   *    entity
   * @param b
   *    entity
   */
  public getMetadata(a: HasEntityRelations, b: HasEntityRelations): Metadata | undefined {
    return this.entityMap.get(a)?.get(b);
  }

  /**
   * Retrieves all entities of particular type linked to the given entity
   *
   * @param a
   *    entity
   * @param ctor
   *    type's constructor
   */
  public listFor<TCtor extends Ctor>(a: HasEntityRelations, ctor: TCtor): InstanceTypeOfCtor<TCtor>[] {
    return this.listEntityRecordsFor(a, ctor).map(pair => pair.entity);
  }

  /**
   * Retrieves all {@link EntityRecord}s for a particular type linked to the given entity
   *
   * @param a
   *    entity
   * @param ctor
   *    type's constructor
   */
  public listEntityRecordsFor<TCtor extends Ctor>(a: HasEntityRelations, ctor: TCtor): EntityRecord<InstanceTypeOfCtor<TCtor>>[] {
    const metaMap = this.entityMap.get(a);

    if (!metaMap) {
      return [];
    }

    const records: EntityRecord<InstanceTypeOfCtor<TCtor>>[] = [];

    for (const [key, metadata] of metaMap.entries()) {
      if (key instanceof ctor) {
        records.push({ entity: key as InstanceTypeOfCtor<TCtor>, metadata });
      }
    }
    return records;
  }

  /**
   * Removes existing links from entity {@code a} to all entities of type {@code ctor}.
   * Use {@code keepFilter} to single out linked {@code EntityRecords} that should not be removed.
   *
   * @param a
   *    entity
   * @param ctor
   *    type's constructor
   * @param keepFilter
   *    optional filter for skipping records
   */
  public removeTypedLinks<TCtor extends Ctor>(
      a: HasEntityRelations,
      ctor: TCtor,
      keepFilter?: (entity: EntityRecord<InstanceTypeOfCtor<TCtor>>) => boolean,
  ): boolean {
    const removedEntities = this.removeLinksForType(a, ctor, keepFilter);

    if (removedEntities.length === 0) {
      return false;
    }

    const aChange = new RelationChange(a);
    removedEntities.forEach(entity => this.notifyRelationChange(entity, aChange));
    const entityTypes = new Set(removedEntities.map(entity => entity.entityType));
    entityTypes.forEach(entityType => this.notifyRelationChange(a, new RelationChange(entityType)));
    return true;
  }

  /**
   * Removes those links of a's where the linked entity does not exist in the entities list.
   * Then links those entities, that were not already linked.
   * The old and new linked entities must share type.
   *
   * If {@code entities} is an empty list, performs exactly as {@link removeTypedLinks}.
   *
   * @param a
   *    entity
   * @param typeHint
   *    constructor of the entities. Used as a type hint to perform replacements
   * @param entities
   *    list of potentially new entities to link
   * @param keepFilter
   *    method for checking, whether an old entity should be kept as linked
   */
  public replaceTypedLinks<TEntity extends HasEntityRelations>(
      a: HasEntityRelations,
      typeHint: TypedCtor<TEntity>,
      entities: TEntity[] | EntityRecord<TEntity>[],
      keepFilter?: (entity: EntityRecord<TEntity>) => boolean,
  ): void {
    const removedEntities = this.removeLinksForType(a, typeHint, keepFilter);
    const recordsToAdd = EntityRelationCache.isEntityRecordArray(entities) ? entities : entities.map(entity => ({ entity } as EntityRecord<TEntity>));

    const addedEntities = recordsToAdd
      .filter(record => this.createLink(a, record.entity, record.metadata))
      .map(record => record.entity);

    // next, we'll build two sets: removed entities and added entities
    // then we we will calculate symmetric difference (https://en.wikipedia.org/wiki/Symmetric_difference)
    // for those sets. This gives us all the entities (besides a) which have been either removed or added
    // since entity which is both removed and added should not be notified

    const addedSet = new Set(addedEntities);
    const symmetricDifference = new Set(removedEntities);

    for (const entity of addedSet) {
      if (symmetricDifference.has(entity)) {
        symmetricDifference.delete(entity);
      } else {
        symmetricDifference.add(entity);
      }
    }
    const aChange = new RelationChange(a);
    symmetricDifference.forEach(entity => this.notifyRelationChange(entity, aChange));

    if (symmetricDifference.size > 0) {
      this.notifyRelationChange(a, new RelationChange(typeHint));
    }
  }

  /**
   * Removes entity and all its links from the cache
   *
   * @param a
   *    entity
   */
  public remove(a: HasEntityRelations): boolean {
    const map = this.entityMap.get(a);

    if (!map) {
      return false;
    }
    this.entityMap.delete(a);

    const change = new RelationChange(a);

    [...map.keys()].forEach((b) => {
      this.removeFor(b, a);
      this.notifyRelationChange(b, change);
    });
    return true;
  }

  /**
   * Does cache contain mapping for entity
   *
   * @param a
   *    entity
   */
  public contains(a: HasEntityRelations): boolean {
    return this.entityMap.has(a);
  }

  /**
   * Empties the cache
   */
  public clear(): void {
    this.entityMap.clear();
  }

  private createLink(a: HasEntityRelations, b: HasEntityRelations, metadata?: Metadata): boolean {
    const ab = this.addFor(a, b, metadata);
    const ba = this.addFor(b, a, metadata);
    return ab || ba;
  }

  private removeLink(a: HasEntityRelations, b: HasEntityRelations, retainCollection?: boolean): boolean {
    const ab = this.removeFor(a, b, retainCollection);
    const ba = this.removeFor(b, a, retainCollection);
    return ab || ba;
  }

  private removeLinksForType<TCtor extends Ctor>(
      a: HasEntityRelations,
      ctor: TCtor,
      keepFilter?: (entity: EntityRecord<InstanceTypeOfCtor<TCtor>>) => boolean,
  ): InstanceTypeOfCtor<TCtor>[] {
    const keep = keepFilter ?? ((_: EntityRecord<InstanceTypeOfCtor<TCtor>>): boolean => false);
    const records = this.listEntityRecordsFor(a, ctor);

    if (records.length === 0) {
      return [];
    }

    return records
      .filter(record => !keep(record) && this.removeLink(a, record.entity))
      .map(record => record.entity);
  }

  private addFor(a: HasEntityRelations, b: HasEntityRelations, metadata?: Metadata): boolean {
    const map = this.getMap(a);

    if (map.has(b)) {
      return false;
    } else {
      map.set(b, metadata);
      return true;
    }
  }

  private removeFor(a: HasEntityRelations, b: HasEntityRelations, retainCollection?: boolean): boolean {
    const map = this.entityMap.get(a);

    if (!map) {
      return false;
    }
    const result = map.delete(b);

    if (!retainCollection) {
      this.removeIfEmpty(a);
    }
    return result;
  }

  private getMap(a: HasEntityRelations): MetadataMap {
    if (!this.entityMap.has(a)) {
      this.entityMap.set(a, new Map());
    }
    return this.entityMap.get(a)!;
  }

  private removeIfEmpty(a: HasEntityRelations): void {
    if (this.entityMap.get(a)?.size === 0) {
      this.entityMap.delete(a);
    }
  }

  private notifyActionToBoth(a: HasEntityRelations, b: HasEntityRelations): void {
    setTimeout(() => a.onRelationChange(new RelationChange(b)), 0);
    setTimeout(() => b.onRelationChange(new RelationChange(a)), 0);
  }

  private notifyRelationChange(target: HasEntityRelations, change: RelationChange): void {
    setTimeout(() => target.onRelationChange(change), 0);
  }

  private static isEntityRecordArray<TEntity>(array: unknown[]): array is EntityRecord<TEntity>[] {
    return array.length === array.filter(isEntityRecord).length;
  }
}

/* Copyright */
import React from "react";
import { ReactComponent as ArrowUpIcon } from "../../../assets/arrow-up.svg";
import { Nullable } from "../../../types/aliases";
import { SvgIcon } from "@material-ui/core";
import { CircuitStatus, SigmaDataDecodeResult } from "../../../types/utu-types";

interface Props {
  type: keyof SigmaDataDecodeResult;
  status: CircuitStatus;
}

class CircuitIndicator extends React.Component<Props> {

  private getIndicatorBoxClassName(): string | undefined {
    const baseClassName = "circuit-indicator";
    const { status } = this.props;

    if (status === CircuitStatus.DOWN || status === CircuitStatus.UP) {
      return `${baseClassName} fault`;
    } else {
      return `${baseClassName} normal`;
    }
  }

  private getEarthCircuitTextStyle(): React.CSSProperties{
    return {
      position: "relative",
      top: "1px",
    };
  }

  private renderIndicatorBox(): Nullable<JSX.Element> {
    const { type } = this.props;
    let text;

    if (type === "earthCircuit") {
      text = (
        <>
          {"I"}
          <sub style={{ fontSize: "0.7rem" }}>
            {"e"}
          </sub>
          {">>"}
        </>
      );
    } else if (type === "shortCircuit") {
      text = "I>>";
    } else {
      console.error("Unknown value 'type' in CircuitIndicator.renderIndicatorBox");
      return null;
    }
    return (
      <div className={this.getIndicatorBoxClassName()}>
        <span className="circuit-type-text" style={type === "earthCircuit" ? this.getEarthCircuitTextStyle() : undefined}>
          {text}
        </span>
      </div>
    );
  }

  private renderStatusIndication(): Nullable<JSX.Element> {
    const { status } = this.props;

    if (status === CircuitStatus.DOWN) {
      return (
        <div className="circuit-info-circle fault down">
          <SvgIcon
            style={{ fontSize: "0.75rem", color: "red", width: "18px", transform: "rotate(180deg)" }}
            viewBox="0 0 35.97 48"
            component={ArrowUpIcon}
          />
        </div>
      );
    } else if (status === CircuitStatus.UP) {
      return (
        <div className="circuit-info-circle fault up">
          <SvgIcon
            style={{ fontSize: "0.75rem", color: "green", width: "18px" }}
            viewBox="0 0 35.97 48"
            component={ArrowUpIcon}
          />
        </div>
      );
    } else {
      return (
        <div className="circuit-info-circle normal">
          <span className="circuit-ok-text">OK</span>
        </div>
      );
    }
  }

  public render(): JSX.Element {
    return (
      <div className="circuit-indicator-container">
        {this.renderIndicatorBox()}
        {this.renderStatusIndication()}
      </div>
    );
  }
}

export default CircuitIndicator;

/* Copyright */
import React from "react";
import { addOnConfigSelectionDictionary } from "../../../data/utu-dictionary-data/UtuDictionaryData";
import { Fallible, Nullable } from "../../../types/aliases";
import { AddOnConfigSelectionDictionary } from "../../../types/utu-types";
import AddOnConfigSelector from "./add-on-config-selector";
import SigmaSelector from "./sigmaD-selector";

interface Props {
  sigD1: Fallible<string>;
  sigD2: Fallible<string>;
  sigD3: Fallible<string>;
  sigD4: Fallible<string>;
  sigD5: Fallible<string>;
  onSigD1Change: (value: Fallible<string>) => void;
  onSigD2Change: (value: Fallible<string>) => void;
  onSigD3Change: (value: Fallible<string>) => void;
  onSigD4Change: (value: Fallible<string>) => void;
  onSigD5Change: (value: Fallible<string>) => void;
}

const DEFAULT_SIGMAD_NAME = "Erotin";

class SigmaDSelection extends React.Component <Props> {

  public render(): JSX.Element[] {
    const { sigD1, sigD2, sigD3, sigD4, sigD5 } = addOnConfigSelectionDictionary;
    const sigmaArray = [sigD1, sigD2, sigD3, sigD4, sigD5];
    return sigmaArray.map(({ name, displayName }) => 
      <SigmaSelector
        key={name}
        label="Laitteen nimi"
        onChange={(text: Nullable<string>): void => this.handleSigmaDNameChange(text, name)}
        showTextField={this.showSigmaDTextField(name)}
        value={this.getSigmaDTextFieldValue(name)}
      > 
        <AddOnConfigSelector
          checked={this.showSigmaDTextField(name)}
          label={displayName}
          name={displayName}
          onChange={({ target }): void => this.handleSigmaDCheckboxChange(target, name)}
        />
      </SigmaSelector>,
    );
  }

  private getSigmaDTextFieldValue(itemName: keyof AddOnConfigSelectionDictionary): string {
    let value = "";

    //CASTS: SigmaSelector uses value only when this.props.sigDn !== false
    switch (itemName) {
      case "sigD1":
        value = this.props.sigD1 as string;
        break;
      case "sigD2":
        value = this.props.sigD2 as string;
        break;
      case "sigD3":
        value = this.props.sigD3 as string;
        break;
      case "sigD4":
        value = this.props.sigD4 as string;
        break;
      case "sigD5":
        value = this.props.sigD5 as string;
        break;
      default:
        console.error("unknown parameter 'itemName' in getSigmaDNameFieldValue.showSigmaDNameField");
        value = "???";
    } 
    return value;
  }

  private showSigmaDTextField(itemName: keyof AddOnConfigSelectionDictionary): boolean {
    switch (itemName) {
      case "sigD1":
        return this.props.sigD1 !== false;
      case "sigD2":
        return this.props.sigD2 !== false;
      case "sigD3":
        return this.props.sigD3 !== false;
      case "sigD4":
        return this.props.sigD4 !== false;
      case "sigD5":
        return this.props.sigD5 !== false;
      default:
        console.error("unknown parameter 'item' in DeviceAddOnConfigDialog.showSigmaDNameField");
        return false;
    }  
  }

  private handleSigmaDCheckboxChange(target: EventTarget & HTMLInputElement, itemName: keyof AddOnConfigSelectionDictionary): void {
    const { checked } = target;
    const { onSigD1Change, onSigD2Change, onSigD3Change, onSigD4Change, onSigD5Change } = this.props;
 
    switch (itemName) {
      case "sigD1":
        onSigD1Change(checked ? this.getDefaultSigmaName(itemName) : false);
        break;
      case "sigD2":
        onSigD2Change(checked ? this.getDefaultSigmaName(itemName) : false);
        break;
      case "sigD3":
        onSigD3Change(checked ? this.getDefaultSigmaName(itemName) : false);
        break;
      case "sigD4":
        onSigD4Change(checked ? this.getDefaultSigmaName(itemName) : false);
        break;
      case "sigD5":
        onSigD5Change(checked ? this.getDefaultSigmaName(itemName) : false);
        break;
      default:
        console.error("unknown parameter 'index' in SigmaDSelection.handleSigmaDCheckboxChange");
        break;
    }
  }

  private handleSigmaDNameChange(text: Nullable<string>, item: keyof AddOnConfigSelectionDictionary): void {    
    const { onSigD1Change, onSigD2Change, onSigD3Change, onSigD4Change, onSigD5Change } = this.props;

    if (text !== null) {
      switch (item) {
        case "sigD1":
          onSigD1Change(text);
          break;
        case "sigD2":
          onSigD2Change(text);
          break;
        case "sigD3":
          onSigD3Change(text);
          break;
        case "sigD4":
          onSigD4Change(text);
          break;
        case "sigD5":
          onSigD5Change(text);
          break;
        default:
          console.error("unknown parameter 'item' in SigmaDSelection.handleSigmaDNameChange");
          break;
      }
    }
  }

  private getDefaultSigmaName(sigmaName: keyof AddOnConfigSelectionDictionary): string {
    return `${DEFAULT_SIGMAD_NAME} ${sigmaName.charAt(sigmaName.length - 1)}`;
  }
}

export default SigmaDSelection;

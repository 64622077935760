/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { Maybe } from "../../../types/aliases";
import { AbstractSubscriptionManager } from "./AbstractSubscriptionManager";

interface HasId {
  getId: () => string;
}

export default abstract class AbstractMapSubscriptionManager<TListener extends HasId, TSubscription> extends AbstractSubscriptionManager<TListener, TSubscription> {
  private listenerMap = new Map<string, TListener>();
  
  public addListener(listener: TListener): void {
    const id = listener.getId();

    if (!this.listenerMap.has(id)) {
      this.listenerMap.set(id, listener);
      this.subscribeOnce();
    }
  }

  public removeListener(listener: TListener): void {
    this.listenerMap.delete(listener.getId());

    if (this.listenerMap.size === 0) {
      this.unsubscribe();
    }
  }

  /**
   * Handle subscription result.
   * Call {@link getListener} to convert id in {@code result} into a listener object
   * @param result
   * @protected
   */
  protected abstract subscriptionHandler(result: Maybe<TSubscription>): void;

  protected getListener(id: string): Maybe<TListener> {
    return this.listenerMap.get(id);
  }
}

/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import React, { Component, Fragment, ReactNode } from "react";
import NotificationsIcon from "@material-ui/icons/Notifications";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableOptions, SelectableRows } from "mui-datatables";
import { IconButton, Typography } from "@material-ui/core";
import Organization, { OrganizationObserver } from "../../../../data/organization/Organization";
import User from "../../../../data/organization/User";
import ConfirmationDialog from "../../../ui/confirmation-dialog";
import Loader from "../../../ui/loader";
import AddNotificationSubscriptionPopup from "./add-notification-subscription-popup";
import { EventRule, NotificationSubscription } from "../../../../data/clientSpecific/Event";
import { prefixlessId } from "../../../../data/organization/Utils";
import { translations } from "../../../../generated/translationHelper";
import BackendFactory from "../../../../data/BackendFactory";

type EventSubscriptionsTableCell = string | number | RemoveCell | JSX.Element;
type EventSubscriptionsTableRow = EventSubscriptionsTableCell[];

interface Props {
  organization: Organization;
  currentUserId: string;
}

interface State {
  loading: boolean;
  users: User[];
  eventRules: EventRule[];
  tableData: EventSubscriptionsTableRow[];
  removeSubscriptionAction?: RemoveCell;
  errorMsg?: string;
  showAddNotificationSubscriptionPopup: boolean;
}

interface RemoveCell {
  subscription: NotificationSubscription;
}

export default class EventManagement extends Component<Props, State> implements OrganizationObserver {
  private readonly tableColumns: MUIDataTableColumnDef[] = [
    {
      name: "event",
      label: translations.admin.texts.event(),
      options: {
        sort: true,
      },
    },
    {
      name: "userId",
      label: translations.admin.texts.user(),
      options: {
        sort: true,
      },
    },
    {
      name: "type",
      label: translations.admin.texts.type(),
      options: {
        sort: true,
      },
    },
    {
      name: "",
      label: "",
      options: {
        customBodyRender: (remove: RemoveCell): JSX.Element => {
          return (
            <div onClick={async (): Promise<void> => {

              this.setState({ removeSubscriptionAction: { subscription: remove.subscription } });
            }}>
              <IconButton
                title={translations.common.buttons.remove()}
                style={{ backgroundColor: "#e6e6e6" }}
                size="small"
              >
                <RemoveIcon />
              </IconButton>
            </div>);
        },
      },
    },
  ];
  private readonly tableOptions: MUIDataTableOptions = {
    filterType: "checkbox",
    // TODO: Fix any type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    customToolbarSelect: (_selectedRows: any, _displayData: Array<{ data: any[]; dataIndex: number }>, _setSelectedRows: any): React.ReactNode => {
      // TODO: This is not supported yet.
      return null;
    },
    onRowsDelete: (_rowsDeleted: unknown): void => {
      // TODO: This is not supported yet.
    },
    selectableRows: "none" as SelectableRows,
    selectableRowsOnClick: false,
    search: false,
    download: false,
    print: false,
    filter: false,
    viewColumns: false,
    selectToolbarPlacement: "none",
    textLabels: {
      body: {
        noMatch: translations.admin.texts.noNotificationsAvailable(),
        toolTip: translations.common.texts.sort(),
      },
      pagination: {
        next: translations.common.buttons.nextPage(),
        previous: translations.common.buttons.previousPage(),
        rowsPerPage: translations.common.inputs.rowsPerPage(),
        displayRows: translations.common.texts.of(),
      },
    },
  };

  public constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      users: [],
      eventRules: [],
      tableData: [],
      showAddNotificationSubscriptionPopup: false,
    };
  }

  public async componentDidMount(): Promise<void> {
    this.props.organization.addObserver(this);
    await this.performLoad(
      this.getEventRules(),
      this.updateUsers(),
    );
  }

  public async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): Promise<void> {
    if (prevProps.organization.getId() !== this.props.organization.getId()) {
      prevProps.organization.removeObserver(this);
      this.props.organization.addObserver(this);
      await this.performLoad(this.updateUsers());
    }

    if (prevState.users !== this.state.users) {
      await this.performLoad(this.updateTableData());
    }
  }

  public componentWillUnmount(): void {
    this.props.organization.removeObserver(this);
  }

  private async performLoad(...promises: Promise<void>[]): Promise<void> {
    this.setState({ loading: true });

    try {
      await Promise.all(promises);
    } catch (err) {
      console.error("performLoad", err);
    }
    this.setState({ loading: false });
  }

  private async getNotificationSubscriptionsForUsers(users: User[]): Promise<NotificationSubscription[]> {
    const promises = users
      .map((user) => ({ userId: user.getId(), owningOrganizationId: this.props.organization.getId() }))
      .map((params) => BackendFactory.getNotificationBackend().getUserNotificationSubscriptions(params));
    return (await Promise.all(promises)).flat();
  }

  private async getEventRules(): Promise<void> {
    try {
      this.setState({ eventRules: await BackendFactory.getNotificationBackend().getRules() });
    } catch (err) {
      console.error("getEventRules", err);
    }
  }

  private getRuleNames(ruleIds: string[]): string {
    return this.state.eventRules
      .filter(eventRule => ruleIds.includes(eventRule.ruleId))
      .map(eventRule => eventRule.name)
      .join(", ");
  }

  private async updateTableData(): Promise<void> {
    const users = [...this.state.users];
    const notificationSubscriptions: NotificationSubscription[] = await this.getNotificationSubscriptionsForUsers(users);

    const tableData: EventSubscriptionsTableRow[] = notificationSubscriptions.map(subscription => {
      return [
        this.getRuleNames(subscription.ruleIds),
        users.find(user => subscription.userId === prefixlessId(user.getId()))?.getUsername() ?? "unknown",
        subscription.type,
        { subscription },
      ];
    });

    this.setState({ tableData });
  }

  private async updateUsers(): Promise<void> {
    try {
      this.setState({
        users: await this.props.organization.getUsers(),
      });
    } catch (err) {
      console.error("updateUsers", err);
      this.setState({
        users: [],
      });
    }
  }

  public onUsersChange(users: User[]): void {
    this.setState({ users });
  }

  private handleConfirmRemoveSubscription = async (): Promise<void> => {
    if (!this.state.removeSubscriptionAction) {
      return;
    }

    this.setState({ loading: true, removeSubscriptionAction: undefined });

    try {
      const ret = await BackendFactory.getNotificationBackend().removeNotificationSubscription({
        userId: this.state.removeSubscriptionAction.subscription.userId,
        subscriptionId: this.state.removeSubscriptionAction.subscription.id,
      });

      if (ret) {
        await this.updateTableData();
      }
    } catch (error) {
      console.error("handleSubmitAction", error);
      this.setState({
        errorMsg: translations.admin.texts.failedToDeleteSubscription(),
      });
    }
    this.setState({ loading: true });
  };

  private handleAddIconClick = async (): Promise<void> => {
    this.setState({ showAddNotificationSubscriptionPopup: true });
  };

  private handleClosePopup = (): void => {
    this.setState({
      showAddNotificationSubscriptionPopup: false,
      errorMsg: undefined,
    });
  };

  private handleCancelRemoveSubscription = (): void => {
    this.setState({ removeSubscriptionAction: undefined });
  };

  private renderEventSubscriptionsTable(): ReactNode {
    const titleElement = (
      <div>
        <NotificationsIcon className="notification-organization-icon" />
        <Typography variant="h6">{translations.admin.texts.notificationManagement()}</Typography>
      </div>
    );

    return (
      <Fragment>
        <MUIDataTable
          title={titleElement}
          data={this.state.tableData}
          columns={this.tableColumns}
          options={this.tableOptions}
        />
        <Loader show={this.state.loading} />
      </Fragment>
    );
  }

  private renderButtonsRow(): ReactNode {
    if (this.state.loading) return;
    return (
      <div className="notification-buttons-container">
        <IconButton
          title={translations.common.buttons.add()}
          onClick={this.handleAddIconClick}
          style={{ backgroundColor: "#e6e6e6", marginLeft: "3rem", marginRight: "2rem", marginTop: "1rem" }}
          size="small"
        >
          <AddIcon />
        </IconButton>
      </div>);
  }

  private renderRemoveSubscriptionPopup(): ReactNode {
    if (!this.state.removeSubscriptionAction) {
      return;
    }
    return (
      <ConfirmationDialog
        title={translations.admin.texts.removeSubscription({ subscription: this.getRuleNames(this.state.removeSubscriptionAction?.subscription.ruleIds) })}
        message={translations.admin.texts.notificationFromUser({ type: this.state.removeSubscriptionAction.subscription.type, user: this.state.users.find(user => this.state.removeSubscriptionAction?.subscription.userId === user.getId().substring(5))?.getUsername() ?? translations.common.texts.notAvailable() })}
        onConfirm={this.handleConfirmRemoveSubscription}
        onCancel={this.handleCancelRemoveSubscription}
      />
    );
  }

  private renderAddNotificationSubscriptionPopup(): ReactNode {
    return (
      <AddNotificationSubscriptionPopup
        organization={this.props.organization}
        users={this.state.users}
        rules={this.state.eventRules}
        open={this.state.showAddNotificationSubscriptionPopup}
        onClose={async (success): Promise<void> => {
          this.handleClosePopup();

          if (success) {
            return this.updateTableData();
          }
        }}
      />
    );
  }

  public render(): JSX.Element {
    return (
      <Fragment>
        {this.renderEventSubscriptionsTable()}
        {this.renderButtonsRow()}
        {this.renderAddNotificationSubscriptionPopup()}
        {this.renderRemoveSubscriptionPopup()}
      </Fragment>
    );
  }
}

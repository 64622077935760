/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import {
  DeviceChangeHandler,
  DeviceSelection,
  GroupSelection,
  isIdSelection,
  SelectedDeviceCache,
} from "./DeviceNavigationCache";
import Device from "../data/device/Device";
import DeviceGroup from "../data/device/DeviceGroup";
import { Maybe } from "../types/aliases";
import ReceiverManager, { ReceiverReplacement } from "../data/utils/receivers/ReceiverManager";
import { createRelativeDeviceIdUrl, DeviceAndTime, DeviceChangeType, DeviceRouterProps } from "./NavigationUtils";
import { isEmpty, toStringObject } from "./functions";
import Backend from "../data/backend/Backend";
import Paths, { getPathWithRetainedParams } from "../components/Paths";
import { timePeriodIsValid } from "../data/utils/TimePeriod";

export default class SelectedDeviceCacheImpl implements SelectedDeviceCache {
  private currentDevice?: Device;
  private currentGroup?: DeviceGroup;

  public constructor(
      private readonly backend: Backend,
  ) {}

  public getSelectedGroup(): Maybe<DeviceGroup> {
    return this.currentGroup;
  }

  public getSelectedDevice(): Maybe<Device> {
    return this.currentDevice;
  }

  public async setCurrentGroup(groupSelection?: GroupSelection): Promise<boolean> {
    const groupId = isIdSelection(groupSelection) ? groupSelection : groupSelection?.getId();
    // group is going to change. if getId === undefined, then current group is undefined
    const groupChanges = groupId !== this.currentGroup?.getId();

    if (groupChanges) {
      const receivers: ReceiverReplacement = {};
      if (this.currentGroup) receivers.toRemove = [this.currentGroup.getId()];
      this.currentGroup = undefined;

      if (groupSelection) {
        this.currentGroup = isIdSelection(groupSelection) ? await this.backend.getDeviceGroup(groupSelection) : groupSelection;
        if (this.currentGroup) receivers.toAdd = [this.currentGroup.getId()];
      }

      if (!isEmpty(receivers)){
        ReceiverManager.instance.replaceReceivers(receivers);
      }
    }
    return groupChanges;
  }

  public async setCurrentDevice(deviceSelection?: DeviceSelection): Promise<Maybe<Device>> {
    const deviceId = isIdSelection(deviceSelection) ? deviceSelection : deviceSelection?.getId();
    const deviceChanged = deviceId !== this.currentDevice?.getId();

    if (deviceChanged) {
      const receivers: ReceiverReplacement = { toAdd: [], toRemove: [] };

      if (deviceSelection) {
        this.currentDevice = isIdSelection(deviceSelection) ? await this.backend.getDevice(deviceSelection) : deviceSelection;

        if (this.currentDevice) {
          const groups = await this.currentDevice.getGroups();
          groups.forEach(group => receivers.toAdd?.push(group.getId()));
        }
      } else if (this.currentDevice) {
        const groups = await this.currentDevice.getGroups();
        groups.forEach(group => receivers.toRemove?.push(group.getId()));
        this.currentDevice = undefined;
      }

      if (receivers.toAdd?.length || receivers.toRemove?.length){
        ReceiverManager.instance.replaceReceivers(receivers);
      }
    }
    return this.currentDevice;
  }

  public hasCachedDevice(): boolean {
    return this.currentDevice != null;
  }

  public predictDeviceChange(routerProps: DeviceRouterProps, previousRouterProps?: DeviceRouterProps): DeviceChangeType {
    const current = routerProps.match.params.id;
    const old = previousRouterProps?.match.params.id;

    if (current && current === old) {
      return DeviceChangeType.StayedSame;
    } else if (current && current !== old) {
      return DeviceChangeType.ChangedToNew;
    } else if (old) {
      return DeviceChangeType.ChangedToNone;
    } else if (previousRouterProps) {
      return DeviceChangeType.StayedNone;
    } else if (this.currentDevice) {
      return DeviceChangeType.WillRestore;
    } else {
      return DeviceChangeType.StayedNone;
    }
  }

  public async resolveDeviceChange(handler: DeviceChangeHandler, routerProps: DeviceRouterProps, previousRouterProps?: DeviceRouterProps): Promise<void> {
    const deviceResolveResult = this.predictDeviceChange(routerProps, previousRouterProps);
    await handler.handle(deviceResolveResult, routerProps);
  }

  /**
   * Either attempts to set cached device from router path, or sets the current path from cached device,
   * if the device has changed.
   *
   * @param routerProps
   * @returns restored did the call cause an url change
   */
  public async navigateToCachedIfNoDeviceInPath(routerProps: DeviceRouterProps): Promise<boolean> {
    const pathDevice = routerProps.match.params.id;
    const restoreFromCache = !pathDevice && !!this.currentDevice;

    if (restoreFromCache) {
      const path = createRelativeDeviceIdUrl(routerProps, this.currentDevice!.getId());
      routerProps.history.push(path, routerProps.location.state);
    } else {
      await this.setCurrentDevice(pathDevice);
    }
    return restoreFromCache;
  }

  /**
   * Pushes a new relative path into the routing history, replacing (possible) previous device with the given
   * device (or an empty device, if none given). Can take query parameters that are added to the new target URL
   * @param routerProps
   * @param deviceSelection
   * @param params
   */
  public async navigateToDevice(routerProps: DeviceRouterProps, deviceSelection?: DeviceSelection, params?: Record<string, unknown>): Promise<Maybe<Device>> {
    console.log(`navigateToDevice(deviceId: ${deviceSelection}, params: ${JSON.stringify(params)})`);
    const maybeDevice = await this.setCurrentDevice(deviceSelection);

    const stringParams = params && Object.keys(params).length > 0 ? toStringObject(params) : undefined;
    const url = createRelativeDeviceIdUrl(routerProps, maybeDevice?.getId())
    + (stringParams ? "?" + new URLSearchParams(stringParams).toString() : "");

    if (routerProps.location.pathname + routerProps.location.search !== url) {
      routerProps.history.push(url, routerProps.location.state);
    }

    return this.currentDevice;
  }

  public async navigateToDeviceAndTime(routerProps: DeviceRouterProps, info?: DeviceAndTime): Promise<Maybe<Device>> {
    console.log(`navigateToDeviceAndTime(${JSON.stringify(info)})`);
    const params = info?.timePeriod && timePeriodIsValid(info.timePeriod) ? info.timePeriod : undefined;
    return this.navigateToDevice(routerProps, info?.deviceId, params);
  }

  public navigateToPath(routerProps: DeviceRouterProps, path: Paths): void {
    const url = getPathWithRetainedParams(path, routerProps);
    routerProps.history.push(url, routerProps.location.state);
  }
}

/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import Device, { DeviceParameters } from "./Device";
import { HyperHW } from "../../client/devices/HyperHW/HyperHW";
import { SuperHW } from "../../client/devices/SuperHW/SuperHW";
import { RuuviTagHW } from "../../client/devices/RuuviTagHW/RuuviTagHW";
import { Maybe } from "../../types/aliases";
import { HasEntityRelations } from "../utils/EntityRelationCache";
import AWSBackend from "../backend/AWSBackend";
import DeviceFactory from "./DeviceFactory";

const DEVICE_CONSTRUCTORS = {
  [SuperHW.type]: SuperHW,
  [HyperHW.type]: HyperHW,
  [RuuviTagHW.type]: RuuviTagHW, 
};

export default class AWSDeviceFactory implements DeviceFactory<AWSBackend>{
  public createDevice(backend: AWSBackend, type: string, params: DeviceParameters): Maybe<Device & HasEntityRelations> {
    const ctor = DEVICE_CONSTRUCTORS[type];

    if (ctor) {
      return new ctor(backend, params);
    } else {
      console.error(`Could not locate device implementation for type ${type}`);
    }
  }
}

/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { Dialog, Typography } from "@material-ui/core";
import React, { Component } from "react";

interface Props {
  onCancel: () => void;
  title: string;
}

export default class ConfirmationDialogUtu extends Component<Props> {
  public render(): JSX.Element {
    return (
      <Dialog
        onClose={this.props.onCancel}
        open={true}
      >
        <div className="popup-inner" style={{ width: "29rem", height: "14rem" }}>
          <Typography variant="h6" style={{ margin: "1rem 0" }}>
            {this.props.title}
          </Typography>
          {this.props.children}
        </div>
      </Dialog>
    );
  }
}

/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { convertTimestampToString, DateTimeFormatTarget, numberToFixed, parseDoorDataValue } from "../utils/Utils";
import Data from "../data/Data";
import Event, { EventState } from "./Event";
import { Maybe } from "../../types/aliases";
import { constantSensorDataDictionary } from "../utu-dictionary-data/UtuDictionaryData";
import { translations } from "../../generated/translationHelper";

export type StatusValue = string | number | boolean | JSX.Element;

export interface StatusEntry {
  title: string;
  value: StatusValue;
  event?: boolean;
}

export type StatusDataRow = StatusValue[];

export interface TableColumnLabels {
  [key: string]: string;
}

export interface TimestampRange {
  start: number;
  end: number;
}

export class ClientProperties {
  public static readonly LATEST_DATA_TABLE_TITLE: string = "Viimeisimmät mittaukset";

  public static readonly EVENT_TABLE_LABELS: TableColumnLabels = {
    eventState: "Tila",
    type: "Type",
    timestamp: "Aika",
    severity: "Severity",
    thingId: "Kohde",
    eventName: "Nimi",
    eventSensorValue: "Hälytysarvo",
    latestSensorValue: "Viimeisin mittaus",
    commentText: "Kommentti",
    commentAuthor: "Kuka",
    commentTimestamp: "Koska",
  };

  public static readonly LATEST_DATA_TABLE_LABELS: TableColumnLabels = {
    sensorName: "Sensori",
    reading: "Mittaus",
  };

  public static getOverlayPopupEntries(data: Data, events?: Event[]): StatusEntry[] {
    const eventTypes: string[] = [];

    if (events) {
      events.forEach(({ eventState, sensorName }) => {
        if (eventState === EventState.Active && sensorName && !eventTypes.includes(sensorName)) {
          eventTypes.push(sensorName);
        }
      });
    }
    const {
      cab_rh,
      cab_t,
      transf_a,
      transf_o,
      hfct_avg,
      mic_avg,
      door_lv,
      door_mv,
    } = constantSensorDataDictionary;

    return [
      {
        title: cab_rh.displayName,
        value: data[cab_rh.name] ?? "",
        event: eventTypes.includes(cab_rh.name),
      },
      {
        title: cab_t.displayName,
        value: data[cab_t.name] ?? "",
        event: eventTypes.includes(cab_t.name),
      },
      {
        title: transf_a.displayName,
        value: data[transf_a.name] ?? "",
        event: eventTypes.includes(transf_a.name),
      },
      {
        title: transf_o.displayName,
        value: data[transf_o.name] ?? "",
        event: eventTypes.includes(transf_o.name),
      },
      {
        title: hfct_avg.displayName,
        value: data[hfct_avg.name] ?? "",
        event: eventTypes.includes(hfct_avg.name),
      },
      {
        title: mic_avg.displayName,
        value: data[mic_avg.name] ?? "",
        event: eventTypes.includes(mic_avg.name),
      },
      {
        title: door_lv.displayName,
        value: parseDoorDataValue(data[door_lv.name]) ?? "",
        event: eventTypes.includes(door_lv.name),
      },
      {
        title: door_mv.displayName,
        value: parseDoorDataValue(data[door_mv.name]) ?? "",
        event: eventTypes.includes(door_mv.name),
      },
    ];
  }

  public static getEventTooltipFromEntities(entities: string[]): string {
    if (entities.length > 0) {
      const result = entities.join(",") + " event";
      return result.charAt(0).toUpperCase() + result.slice(1);
    } else {
      return "";
    }
  }

  public static getDefaultEventTimestampRange(days: number): TimestampRange {
    const delta: number = days * 24 * 60 * 60 * 1000;
    const startTimestamp: number = new Date().getTime() - delta;
    const endTimestamp: number = new Date().getTime();
    return {
      start: startTimestamp,
      end: endTimestamp,
    };
  }

  // TODO: This should be made dynamic based on the data received from backend (IP-471)
  public static getStatusRowEntries(data: Data): StatusEntry[] {
    return [
      { title: translations.common.data.timestamp(), value: convertTimestampToString(data.timestamp, DateTimeFormatTarget.StatusTable) },
      // Show formatted value with unit for each, or when devices report no value, show blank w/o unit:
      { title: translations.common.data.temperature(), value: ClientProperties.formatValue(data.temperature, "\u2103", 2) ?? " " },
      { title: translations.common.data.humidity(), value: ClientProperties.formatValue(data.humidity, "%", 2) ?? " " },
      { title: translations.common.data.pressure(), value: ClientProperties.formatValue(data.pressure, " hPa", 2) ?? " " },
      { title: translations.common.data.batteryLevel(), value: ClientProperties.formatValue(data.batteryLevel, "%", 2) ?? " " },
      { title: translations.common.data.speed(), value: ClientProperties.formatValue(data.speed, " km/h", 2) ?? " " },
    ];
  }

  private static formatValue(value: number | string | boolean | undefined, unit: string, decimals?: number): Maybe<string> {
    if (value === undefined) {
      return;
      // Show "0" and unit instead of blank when devices report "0" value:
    } else if (value === 0) {
      return "0" + unit;
      // Show as many decimals as stated, or if not stated, show full value including all decimals:
    } else if (typeof value === "number") {
      const num = decimals != null ? numberToFixed(value, decimals) : value;
      return `${num}${unit}`;
    } else {
      console.log("no number value reported");
      return "N/A";
    }
  }
}

export default ClientProperties;

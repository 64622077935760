/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import DeviceState from "../../../data/device/DeviceState";
import { ReferenceHWStateProperties } from "./ReferenceHWStateProperties";
import { Nullable } from "../../../types/aliases";

export class ReferenceHWState<T extends ReferenceHWStateProperties> extends DeviceState<T> {
  public get gpsFix(): Nullable<boolean> {
    return this.reported.gpsFix;
  }

  public get chargerConnected(): Nullable<boolean> {
    return this.reported.chargerConnected;
  }

  public get batterySoc(): Nullable<number> {
    return this.reported.batterySoc;
  }

  public get modem(): Nullable<string> {
    return this.reported.modem;
  }

  public get signalStrength(): Nullable<number> {
    return this.reported.signalStrength;
  }

  public get firmwareVersion(): Nullable<string> {
    return this.reported.deviceFwVersion;
  }

  public get resetCount(): Nullable<number> {
    return this.reported.resetCount;
  }
}

/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import React, { Component, ReactNode, CSSProperties, Fragment } from "react";
import DeviceGroup, { DeviceGroupObserver } from "../../../data/device/DeviceGroup";
import Device from "../../../data/device/Device";
import TreeItem from "@material-ui/lab/TreeItem";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import TreeFolder from "./tree-folder";
import DraggableDeviceItem from "./draggable-device-item";
import { TaskLoader } from "../../ui/task-loader";
import SearchFilter, { searchFilterDevice, searchFilterDeviceGroup } from "../helpers/search-filter";
import { Maybe } from "../../../types/aliases";
import AddGroupPopup from "./add-group-popup";
import { DeleteGroupPopup } from "./delete-group-popup";
import { withStyles } from "@material-ui/core";

enum Dialogs {
  None,
  AddDevice,
  DeleteDevice,
}

interface Props {
  group: DeviceGroup;
  selectedDevice?: string;
  selectedGroup?: string;
  editMode?: boolean;
  onDeviceSelect?: (device?: Device) => void;
  onGroupSelect?: (group?: DeviceGroup) => void;
  searchFilter?: SearchFilter;
  hideFolderControlIcon?: boolean;
  "data-testid"?: string;
  showEventsMode: boolean;
}

interface State {
  devices?: Device[];
  childGroups?: DeviceGroup[];
  openDialog: Dialogs;
  loading: boolean;
}

const StyledTreeItem = withStyles({
  label: {
    backgroundColor: "unset !important", // remove background-color from label element
  },
  content: {
    "&:hover": { // add hover background-color to content element
      backgroundColor: "#e0f4fb", // same as tree-item-active-bg in _colors.scss
      borderRadius: "3px",
      "& > div > svg": {
        color: "#004388", // add hover color also to expand & collapse icons. Color is same as utu-blue in _colors.scss
      },
      "& > div > div > div > div": {
        color: "#004388", // add hover color also to text. Color is same as utu-blue in _colors.scss
      },
    },
  },
  iconContainer: {
    color: "#757575", // same as icon-grey in _colors.scss
    "& svg": {
      fontSize: "1.5rem", // overwrite font-size: 18px
    },
    width: "auto", // overwrite width: 15px
    marginRight: "0.5rem", // overwrite margin-right: 4px to add more space between expand / collapse icon and text
  },
})(TreeItem);

export default class DeviceTreeLevel extends Component<Props, State> implements DeviceGroupObserver {
  public constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      openDialog: Dialogs.None,
    };
  }

  public async componentDidMount(): Promise<void> {
    this.props.group.addObserver(this);
  }

  public componentWillUnmount(): void {
    this.props.group.removeObserver(this);
  }

  public onDevicesChanged(devices: Device[]): void {
    this.setState({ devices });
  }

  public onGroupsChanged(childGroups: DeviceGroup[]): void {
    this.setState({ childGroups });
  }

  private fetchDevicesAndGroups = async(): Promise<void> => {
    console.log(`fetchDevicesAndGroups: ${this.props.group.getId()}`);
    const [devices, childGroups] = await Promise.all([
      this.props.group.getDevices(),
      this.props.group.getGroups(),
    ]);
    this.setState({ devices, childGroups });
  };

  private handleFolderClick = async (): Promise<void> => {
    if (this.props.selectedGroup !== this.props.group.getId()) {
      this.props.onGroupSelect?.(this.props.group);
    }
  };

  private getFilteredGroups(): Maybe<DeviceGroup[]> {
    const { searchFilter } = this.props;

    if (searchFilter) {
      return this.state.childGroups?.filter(group => searchFilterDeviceGroup(searchFilter, group));
    }
    return this.state.childGroups;
  }

  private getFilteredDevices(): Maybe<Device[]> {
    const { searchFilter } = this.props;

    if (searchFilter) {
      return this.state.devices?.filter(device => searchFilterDevice(searchFilter, device));
    }
    return this.state.devices;
  }

  private hasMatches(): boolean {
    if (!this.props.searchFilter) {
      return true;
    }
    const parentGroupMatch = searchFilterDeviceGroup(this.props.searchFilter, this.props.group);
    // parent matches filter if it itself matches, or one of its children match
    // this is sort of duplicate check, since device groups will perform local matches
    // but we do not have the information  whether this item is open or not, so have to check for potential
    // child matches.
    return parentGroupMatch || !!this.getFilteredDevices()?.length || !!this.getFilteredGroups()?.length;
  }

  private renderGroups(): ReactNode {
    return this.getFilteredGroups()
      ?.sort((a, b) => a.getLabel().localeCompare(b.getLabel()))
      .map((group, index) => (
        <DeviceTreeLevel
          key={group.getId()}
          group={group}
          selectedDevice={this.props.selectedDevice}
          selectedGroup={this.props.selectedGroup}
          onDeviceSelect={this.props.onDeviceSelect}
          onGroupSelect={this.props.onGroupSelect}
          editMode={this.props.editMode}
          searchFilter={this.props.searchFilter}
          data-testid={this.props["data-testid"] ? `${this.props["data-testid"]}-group-${index}` : undefined}
          showEventsMode={this.props.showEventsMode}
        />
      ));
  }

  private renderDevices(): ReactNode {
    return this.getFilteredDevices()
      ?.sort((a, b) => a.getId().localeCompare(b.getId()))
      .map((device, index) => (
        <DraggableDeviceItem
          key={device.getId()}
          device={device}
          parentGroup={this.props.group}
          editMode={this.props.editMode}
          onDeviceSelect={this.props.onDeviceSelect}
          selected={this.props.selectedDevice === device.getId()}
          data-testid={this.props["data-testid"] ? `${this.props["data-testid"]}-device-${index}` : undefined}
          showEventsMode={this.props.showEventsMode}
        />
      ));
  }

  private closeDialogs = (): void => {
    this.setState({ openDialog: Dialogs.None });
  };

  private renderDialogs(): ReactNode {
    const { group } = this.props;
    return (
      <Fragment>
        <AddGroupPopup
          parentGroup={group}
          open={this.state.openDialog === Dialogs.AddDevice}
          onClose={this.closeDialogs}
        />
        <DeleteGroupPopup
          group={group}
          open={this.state.openDialog === Dialogs.DeleteDevice}
          onClose={this.closeDialogs}
        />
      </Fragment>
    );
  }

  private renderLoader(): ReactNode {
    return (
      <TaskLoader
        runTask={!this.state.devices || !this.state.childGroups}
        task={this.fetchDevicesAndGroups}
      />
    );
  }

  private renderContents(): ReactNode {
    const nodeStyle: CSSProperties = this.hasMatches() ? { backgroundColor: "transparent" } : { display: "none" };
    const entry: JSX.Element = (
      <TreeFolder
        group={this.props.group}
        editMode={this.props.editMode}
        onAddGroup={(): void => this.setState({ openDialog: Dialogs.AddDevice })}
        onRemoveGroup={(): void => this.setState({ openDialog: Dialogs.DeleteDevice })}
        showEventsMode={this.props.showEventsMode}
      />
    );
    return (
      <StyledTreeItem
        nodeId={this.props.group.getId()}
        // Empty string hides icon
        expandIcon={this.props.hideFolderControlIcon ? " " : <ArrowDropDownIcon/>}
        collapseIcon={this.props.hideFolderControlIcon ? " " : <ArrowDropUpIcon/>}
        label={entry}
        style={nodeStyle}
        onClick={this.handleFolderClick}
        data-testid={this.props["data-testid"]}
      >
        {this.renderLoader()}
        {this.renderGroups()}
        {this.renderDevices()}
      </StyledTreeItem>
    );
  }

  public render(): ReactNode {
    return (
      <Fragment>
        {this.renderDialogs()}
        {this.renderContents()}
      </Fragment>
    );
  }
}

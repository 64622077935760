/* Copyright */
import { Checkbox, FormControlLabel } from "@material-ui/core";
import React from "react";

interface Props {
  checked: boolean;
  label: string;
  name: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

class AddOnConfigSelector extends React.Component <Props> {

  public render(): JSX.Element {
    const {
      checked,
      label,
      name,
      onChange,
    } = this.props;

    return (
      <FormControlLabel
        control={
          <Checkbox 
            checked={checked} 
            color="primary"
            onChange={onChange} 
            name={name}
          />
        }
        label={label}
      />
    );
  }

}

export default AddOnConfigSelector;

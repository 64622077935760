/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import React, { Component } from "react";
import AttributeFormUtu from "./components/attribute-form-utu";
import PasswordFormUtu from "./components/password-form-utu";

export default class UserViewUtu extends Component {

  public alertWithConfirm (): boolean {
    // TODO: replace with Material Dialog
    const alertConfirmation = window.confirm("Haluatko varmasti kirjautua ulos?");

    if (alertConfirmation){
      console.log("OK pressed on Alert Confirmation Popup.");
      return true;
    } else {
      console.log("Cancel pressed on Alert Confirmation Popup.");
      return false;
    }
  }

  public render(): JSX.Element {
    return (
      <div className="utu-user-body-container">
        <section className="utu-user-section-container">
          <AttributeFormUtu/>
          <PasswordFormUtu/>
        </section>
      </div>
    );
  }
}

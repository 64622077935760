/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import AWSAppSyncClient from "aws-appsync";
import { TypedDocumentNode } from "@graphql-typed-document-node/core";
import { ApolloQueryResult } from "apollo-client/core/types";
import { Observable } from "apollo-client/util/Observable";
import { ExecutionResult } from "graphql";
import { QueryOptions, MutationOptions, SubscriptionOptions } from "apollo-client/core/watchQueryOptions";

/**
 * Modified apollo-link's {@code FetchResult} to actually have a return type.
 * Maybe someone in the universe knows why they do not set type for the {@code ExecutionResult}.
 *
 */
export type TypedFetchResult<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T = any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  C = Record<string, any>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  E = Record<string, any>
> = ExecutionResult<T> & {
  extensions?: E;
  context?: C;
};

export type SimpleQueryOptions = Omit<QueryOptions, "query" | "variables">;
export type SimpleMutationOptions = Omit<MutationOptions, "mutation" | "variables">;
export type SimpleSubscriptionOptions = Omit<SubscriptionOptions, "query" | "variables">;

export class TypedAppSyncClient {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public constructor(private readonly client: AWSAppSyncClient<any>) {}
  
  public async query<TResult, TVariables>(document: TypedDocumentNode<TResult, TVariables>, variables: TVariables, options: SimpleQueryOptions = {}): Promise<ApolloQueryResult<TResult>> {
    return this.client.query<TResult>({
      query: document,
      variables,
      ...options,
    });
  }

  public async mutate<TResult, TVariables>(document: TypedDocumentNode<TResult, TVariables>, variables: TVariables, options: SimpleMutationOptions = {}): Promise<TypedFetchResult<TResult>> {
    const result = await this.client.mutate<TResult>({
      mutation: document,
      variables,
      ...options,
    });
    return result as TypedFetchResult<TResult>;
  }

  public subscribe<TResult, TVariables>(document: TypedDocumentNode<TResult, TVariables>, variables: TVariables, options: SimpleSubscriptionOptions = {}): Observable<TypedFetchResult<TResult>> {
    return this.client.subscribe<TResult>({
      query: document,
      variables,
      ...options,
    });
  }
}

/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import React, { Component, Fragment, ReactNode } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { DevicePathRouterProps } from "../../types/routerprops";
import DataSet, { DataObserver } from "../../data/data/DataSet";
import Device from "../../data/device/Device";
import { getDisplayName, stateIsHyper } from "../../data/utils/Utils";
import LiveControl from "./components/live-control";
import HistoryChart from "./components/history-chart";
import { Maybe } from "../../types/aliases";
import UtuTimeRangePicker from "../ui/utu-time-range-picker";
import ThingMetadata from "./components/history-thing-metadata";
import LatestDataTable from "./components/latest-data-table";
import Data from "../../data/data/Data";
import { HistoryChartSensorDataPair, MapLocation } from "../../types/utu-types";
import { historyChartDictionary } from "../../data/utu-dictionary-data/UtuDictionaryData";
import { DeviceChangeResultHandler } from "../../utils/DeviceChangeResultHandler";
import DeviceNavigationCache from "../../utils/DeviceNavigationCache";
import { DeviceAndTime, DeviceChangeType, idFromProps, parseDeviceAndTime } from "../../utils/NavigationUtils";
import TimePeriod, { timePeriodIsValid } from "../../data/utils/TimePeriod";
import DeviceDrawer from "../drawers/device-drawer";
import { translations } from "../../generated/translationHelper";
import { ContentSplash } from "../ui/content-splash";
import Loader from "../ui/loader";
import EventsSection from "./components/events-section";
import LocationSection from "./components/location-section";
import ChartZoomManager from "../../data/utils/ChartZoomManager";

interface Props extends RouteComponentProps<DevicePathRouterProps> {
  mountEventsTableExpanded: boolean;
  setHistoryViewEventsTableToMountCollapsed: () => void;
}

interface State {
  isLoading: boolean;
  drawerOpen: boolean;
  liveMode: boolean;
  selectedLocation?: MapLocation;
  dataSet?: DataSet;
  sensorData?: Data[];
  device?: Device;
}


const DEFAULT_TIME_WINDOW_MS: number = 2 * 60 * 60 * 1000;
const RESET_STATE = { dataSet: undefined, liveMode: false, selectedLocation: undefined, drawerOpen: false, device: undefined };

class HistoryView extends Component<Props, State> implements DataObserver {

  private readonly deviceChangeHandler = new DeviceChangeResultHandler({
    stateResetCallback: (): void => this.setState(RESET_STATE),
    deviceChangeCallback: (id: string): Promise<void> => this.changeDevice(id),
  });

  public constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: false,
      drawerOpen: false,
      liveMode: false,
    };
  }

  public async componentDidMount(): Promise<void> {
    const deviceResolveResult = DeviceNavigationCache.getInstance().predictDeviceChange(this.props);

    if (deviceResolveResult === DeviceChangeType.ChangedToNew) {
      const { deviceId, timePeriod } = parseDeviceAndTime(this.props);
      if (deviceId) await this.changeDevice(deviceId, timePeriod);
    } else if (deviceResolveResult === DeviceChangeType.WillRestore) {
      const deviceId = DeviceNavigationCache.getInstance().getSelectedDevice()?.getId();
      if (deviceId) await this.setUrlFromRouteInfo({ deviceId, timePeriod: {} });
    }
  }

  public async componentDidUpdate(prevProps: Props, _prevState: State): Promise<void> {
    await DeviceNavigationCache.getInstance().resolveDeviceChange(this.deviceChangeHandler, this.props, prevProps);
  }

  public componentWillUnmount(): void {
    this.state.dataSet?.removeObserver(this);
  }

  private async setUrlFromRouteInfo(routeInfo?: DeviceAndTime): Promise<void> {
    await DeviceNavigationCache.getInstance().navigateToDeviceAndTime(this.props, routeInfo);
    this.setState({ device: DeviceNavigationCache.getInstance().getSelectedDevice() });
  }

  private async changeDevice(newDeviceId: string, timePeriod?: Partial<TimePeriod>): Promise<void> {
    this.state.dataSet?.removeObserver(this);

    const setNewDevice = async (): Promise<void> => {
      const newTimePeriod = timePeriod && timePeriodIsValid(timePeriod)
        ? timePeriod
        : HistoryView.getDefaultTimePeriod();
      const device = await DeviceNavigationCache.getInstance().setCurrentDevice(newDeviceId);

      this.setState({ device }, async () => {
        await Promise.all([
          this.updateUrlTimestampAndFetchData(newTimePeriod),
        ]);
        this.setState({ isLoading: false });
      });
    };

    this.setState(
      { ...RESET_STATE, isLoading: true },
      setNewDevice,
    );
  }

  private static getDefaultTimePeriod(): TimePeriod {
    const endTimestamp = Date.now();
    return {
      endTimestamp,
      startTimestamp: endTimestamp - DEFAULT_TIME_WINDOW_MS,
    };
  }

  public async onDataUpdate(dataSet: DataSet): Promise<void> {
    if (this.state.liveMode && dataSet.getId() === this.state.device?.getId()) {
      const sensorData = [...dataSet.getData()];
      /* this is to ensure that all charts return to initial values of hAxisViewWindow */
      ChartZoomManager.instance.resetZoom();
      this.setState({ sensorData });
      await this.setUrlFromRouteInfo({ deviceId: this.state.device.getId(), timePeriod: dataSet.getTimePeriod() });
    }
  }

  private async fetchData(timePeriod: TimePeriod): Promise<void> {
    if (!this.state.device || !timePeriodIsValid(timePeriod)) {
      return;
    }
    this.setState({ isLoading: true });

    try {
      const dataSet = await this.state.device.getData(timePeriod.startTimestamp, timePeriod.endTimestamp);
      this.state.dataSet?.removeObserver(this);

      if (dataSet) {
        if (this.state.liveMode) dataSet.addObserver(this);
        this.setState({ dataSet, sensorData: dataSet.getData() });
      }
    } catch (error) {
      console.error("fetchData", error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  private handleTimeRangeSelect = async (startTimestamp: number, endTimestamp: number): Promise<void> => {
    await this.updateUrlTimestampAndFetchData({ startTimestamp, endTimestamp });
  };

  private handleLiveDataToggle = (): void => {
    const enablingLiveMode = !this.state.liveMode && !!this.state.device;

    if (enablingLiveMode) {
      this.setState({ liveMode: true }, async () => {
        const { startTimestamp } = HistoryView.getDefaultTimePeriod();
        const newTimePeriod = {
          startTimestamp,
          endTimestamp: Date.now(),
        };
        await this.updateUrlTimestampAndFetchData(newTimePeriod);
      });
    } else {
      this.setState({ liveMode: false });
      this.state.dataSet?.removeObserver(this);
    }
  };

  private async updateUrlTimestampAndFetchData(timePeriod: TimePeriod): Promise<void> {
    await Promise.all([
      this.setUrlFromRouteInfo({ deviceId: this.state.device?.getId(), timePeriod }),
      await this.fetchData(timePeriod),
    ]);
  }

  private handleDeviceSelect = async (device?: Device): Promise<void> => {
    await DeviceNavigationCache.getInstance().navigateToDevice(this.props, device);
    this.setState({ device: DeviceNavigationCache.getInstance().getSelectedDevice() });
  };

  private async getNewDataSet(): Promise<void> {
    const { timePeriod } = parseDeviceAndTime(this.props);
    const { startTimestamp } = HistoryView.getDefaultTimePeriod();
    const newTimePeriod = {
      startTimestamp: timePeriod.startTimestamp ?? startTimestamp,
      endTimestamp: Date.now(),
    };
    await this.updateUrlTimestampAndFetchData(newTimePeriod);
  }

  // this can be removed when device handles addOnConfig correctly and sends data only according to addOnConfig
  private isChartWithoutData(sensorDataNamesInChart: HistoryChartSensorDataPair, data: Data[]): boolean {
    const wantedDataKeys: string[] = [];
    Object.keys(data[0]).filter(key => {
      if (key.includes(sensorDataNamesInChart[0]) || key.includes(sensorDataNamesInChart[1])) {
        wantedDataKeys.push(key);
      }
    });
    return !data.every(data =>
      wantedDataKeys.every(dataKey =>
        data[dataKey] === null));
  }

  private getChartsFromConfig(data: Data[]): Maybe<JSX.Element[]> {
    if (this.state.device) {
      const state = this.state.device.getState();
      const chartArray: JSX.Element[] = [];

      if (state !== null && stateIsHyper(state)) {
        const measurements = state.getMeasurementInfo();

        if (measurements) {
          const sensorNames = measurements.map(({ sensorName }) => sensorName);
          const chartsWithDataInAddOnConfig = Object.values(historyChartDictionary).filter(({ sensorDataNames }) =>
            sensorDataNames.every(name => sensorNames.includes(name)));
          const chartsWithData = chartsWithDataInAddOnConfig.filter(({ sensorDataNames }) => this.isChartWithoutData(sensorDataNames, data));
          chartsWithData.forEach((chart, index) => {
            chartArray.push(
              <div className="chart-matrix-block" key={chart.name}>
                <HistoryChart
                  data={data}
                  sensorDataPair={chart.sensorDataNames}
                  chartTitle={chart.displayName}
                  chartIndex={index}
                />
              </div>,
            );

          });
        }
      }

      if (chartArray.length === 0) {
        console.error("No charts could be created");
      } else {
        return chartArray;
      }
    }
  }

  private renderLoader(): ReactNode {
    return <Loader show={this.state.isLoading} />;
  }

  private renderErrorMessage(): Maybe<JSX.Element> {
    if (this.state.isLoading) return;

    if (this.state.device && (this.state.dataSet?.getData().length ?? 0) === 0) {
      return (
        <ContentSplash>
          <p><span>{translations.history.texts.deviceSelected({ deviceName: getDisplayName(this.state.device) })}</span></p>
          <p><span>{translations.history.texts.noTimeSeriesDataFound()}</span></p>
        </ContentSplash>
      );
    } else if (!this.state.device) {
      return (
        <ContentSplash>
          <p><span>{translations.history.texts.pleaseSelectDeviceAndTimePeriod()}</span></p>
        </ContentSplash>
      );
    }
  }

  private renderToolbar = (): Maybe<JSX.Element> => {
    if (this.state.device) {

      let { startTimestamp, endTimestamp } = parseDeviceAndTime(this.props).timePeriod;

      if (this.state.liveMode) {
        startTimestamp = HistoryView.getDefaultTimePeriod().startTimestamp;
        endTimestamp = Date.now();
      }

      return (
        <div className="toolbar-container">
          <div className="space-left-column"/>
          <div className="other-stuff">
            <UtuTimeRangePicker
              startTimestamp={startTimestamp}
              endTimestamp={endTimestamp}
              onTimeRangeSelect={this.handleTimeRangeSelect}
              selectedDevice={this.state.device}
              liveDataIsActivated={this.state.liveMode}
            />
          </div>
          <div className="other-stuff">
            <LiveControl
              liveData={this.state.liveMode}
              onLiveDataToggled={this.handleLiveDataToggle}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className="toolbar-container" >
          <div className="space-left-column"/>
        </div>
      );
    }
  };

  private renderEvents(): Maybe<JSX.Element> {
    const { device, isLoading } = this.state;

    // prevent component appearing above of loader when data fetching is not completely finished
    if (!isLoading && device) {
      return (
        <EventsSection
          device={device}
          mountTableExpanded={this.props.mountEventsTableExpanded}
          setHistoryViewEventsTableToMountCollapsed={this.props.setHistoryViewEventsTableToMountCollapsed}
        />
      );
    }
  }

  private renderLatestData = (): Maybe<JSX.Element> => {
    const { device, isLoading } = this.state;

    // prevent component appearing above of loader when data fetching is not completely finished
    if (!isLoading && device) {
      return (
        <LatestDataTable device={device} showOnlyActiveEvents={false}/>
      );
    }
  };

  private renderLocation(): Maybe<JSX.Element> {
    const { device } = this.state;

    if (device) {
      return (
        <LocationSection device={device}/>
      );
    }
  }

  private renderThingIdentification(): JSX.Element {
    return (
      <Fragment>
        <ThingMetadata
          selectedDevice={this.state.device}
          triggerDataSetUpdate={(): Promise<void> => this.getNewDataSet()}
        />
        {this.renderLocation()}
      </Fragment>
    );
  }

  private renderChartView(): Maybe<JSX.Element> {
    if (this.state.sensorData?.length) {
      const charts = this.getChartsFromConfig(this.state.sensorData);

      if (charts && !this.state.isLoading) {
        return (
          <div className="chart-2x2-matrix">
            {charts}
          </div>
        );
      }
    }
  }

  private renderDeviceDrawer(): ReactNode {
    return (
      <DeviceDrawer
        onDeviceSelect={this.handleDeviceSelect}
        selectedDeviceId={idFromProps(this.props)}
      />
    );
  }

  public render(): JSX.Element {
    return (
      <Fragment>
        {this.renderDeviceDrawer()}
        {this.renderToolbar()}
        <div className="history-body-container">
          <div className="thing-details-container">
            {this.renderThingIdentification()}
          </div>
          <div className="border"/>
          <div className="chart-container">
            {this.renderEvents()}
            {this.renderLoader()}
            {this.renderLatestData()}
            {this.renderErrorMessage()}
            {this.renderChartView()}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(HistoryView);

/* Copyright */
import React, { Fragment } from "react";
import Device from "../../../data/device/Device";
import { Maybe, Nullable } from "../../../types/aliases";
import { capitalizeFirstLetter } from "../../../data/utils/Utils";
import { MapLocation } from "../../../types/utu-types";
import Loader from "../../ui/loader";

interface Props {
  device: Device;
  location: MapLocation;
}

interface State {
  loading: boolean;
  errorMsg?: string;
  humidity?: number;
  location?: string;
  temperature?: number;
  weatherType?: string;
  windSpeed?: number;
}

interface WeatherReponse {
  coord: Location;
  weather: WeatherType[];
  main: WeatherMainData;
  wind: Wind;
  dt: number; // unix time for measurement
  timezone: number;
  name: string;
}

interface WeatherType {
  id: number;
  main: string;
  description: string;
  icon: string;
}

interface WeatherMainData {
  temp: number;
  feels_like: number;
  temp_min: number;
  temp_max: number;
  pressure: number;
  humidity: number;
}

interface Wind {
  speed: number;
  deg: number;
}

/** Fetch current weather data from open weather api and
   * display the weather data in a box
   * @param {Device} device
   */
class HistoryWeather extends React.Component<Props, State> {
  private interval: Nullable<number> = null;

  public constructor(props: Props) {
    super(props);
    this.getWeather = this.getWeather.bind(this);
    this.state = {
      loading: true,
    };
  }

  public async componentDidMount(): Promise<void> {
    if (this.props.device) {
      await this.initWeatherNew();
      this.setState({ loading: false });
    }
  }

  public componentWillUnmount(): void {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  private async initWeatherNew(): Promise<void> {
    const { location } = this.props;

    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
    this.getWeather(location);
    this.interval = window.setInterval(() => this.getWeather(location), 60000);
  }

  private async getWeatherData({ lat, lng: lon }: MapLocation): Promise<Maybe<WeatherReponse>> {
    const httpResponse = await fetch(`https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lon}&lang=fi&units=metric&APPID=65901a7c2f1de8e9142f52eb996ee95d`);

    if (httpResponse.ok) {
      const parsedResponse = await httpResponse.json();
      return parsedResponse;
    }
  }

  private getWeather(location: MapLocation): void {
    this.getWeatherData(location).then((resp: Maybe<WeatherReponse>) => {
      if (resp) {
        const {
          name,
          main,
          weather,
          wind,
        } = resp;
        this.setState({
          errorMsg: undefined,
          location: name,
          temperature: main.temp,
          humidity: main.humidity,
          weatherType: capitalizeFirstLetter(weather[0].description),
          windSpeed: wind.speed,
        });
      } else {
        this.setState({ errorMsg: "Tietojen hakemisessa tapahtui virhe" });
      }
    });
  }

  public renderWeatherText(): JSX.Element {
    return (
      this.state.errorMsg
        ?
        <p style={{ textAlign: "center", lineHeight: "1.5rem" }}>{this.state.errorMsg}</p>
        :
        <Fragment>
          <p>{this.state.location}</p>
          <p>{this.state.temperature}&ordm;C</p>
          <p>{this.state.weatherType}</p>
          <p>Kosteus: {this.state.humidity}%</p>
          <p>Tuuli: {this.state.windSpeed}m/s</p>
        </Fragment>
    );
  }

  public render(): JSX.Element {
    return (
      <div className="weather-container">
        {this.state.loading
          ?
          <Loader/>
          :
          this.renderWeatherText()}
      </div>
    );
  }
}

export default HistoryWeather;

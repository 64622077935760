/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { Nullable } from "../../types/aliases";

export enum OtaState {
  Idle = "IDLE",
  InProgress = "IN_PROGRESS",
  Done = "DONE",
  Cancelled = "CANCELLED",
}

export interface OtaUpdateState {
  deviceId: string;
  updateFirmwareVersion?: Nullable<string>;
  updateState?: Nullable<OtaState>;
  resultCode?: Nullable<number>;
  progressPercent?: Nullable<number>;
  timestamp?: Nullable<number>;
}

export interface OtaUpdate {
  otaId: string;
  firmwareVersion: string;
  size?: Nullable<number>;
  description?: Nullable<string>;
  filename?: Nullable<string>;
  enabled?: Nullable<boolean>;
  urlExpirationSeconds?: Nullable<number>;
}

/* Copyright */
import React, { Component } from "react";
import Device from "../../../data/device/Device";
import { getDisplayName } from "../../../data/utils/Utils";

interface Props {
  selectedDevice: Device;
}

export default class StatusHover extends Component<Props> {

  public render(): JSX.Element {
    const { selectedDevice } = this.props;

    return (
      <div className="status-hover-container">
        {getDisplayName(selectedDevice)}
      </div>
    );
  }
}

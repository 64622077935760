/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { ICredentials } from "@aws-amplify/core";
import { Auth } from "aws-amplify";
import AWSAppSyncClient, { AUTH_TYPE } from "aws-appsync";
import awsconfig from "../../aws-config";
import { TypedAppSyncClient } from "./TypedAppSyncClient";
import { AuthOptions } from "aws-appsync-auth-link";
import AuthListener, { AuthEvent } from "../auth/AuthListener";

// using non-zero positive values in order for client caching to work
export enum Service {
  USERS = 1,
  DATA = 2,
  DEVICE = 3,
  EVENTS = 4,
  STATS = 5,
}

// using prime numbers to avoid client cache collisions
export enum ClientType {
  TYPE_COGNITO = 3,
  TYPE_IAM = 5,
}

// TODO: merge this with AppSyncClientFactory
export default class AppSyncClientProvider implements AppSyncClientProvider {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private clientCache: AWSAppSyncClient<any>[] = []; // sparse array

  private authEventHandler = (event: AuthEvent): void => {
    if (event === "SignedOut") {
      this.clientCache = [];
    }
  };

  private readonly authListener = new AuthListener(this.authEventHandler);

  public getTypedClient(service: Service, type: ClientType = ClientType.TYPE_COGNITO): TypedAppSyncClient {
    const innerClient = this.getAppSyncClient(service, type);
    return new TypedAppSyncClient(innerClient);
  }

  // TODO: Fix any type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getAppSyncClient(service: Service, type: ClientType = ClientType.TYPE_COGNITO): AWSAppSyncClient<any> {
    const cacheSlot = service * type;

    if (!this.clientCache[cacheSlot]) {
      this.clientCache[cacheSlot] = this.createClient(service, type);
    }
    return this.clientCache[cacheSlot];
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private createClient(service: Service, type: ClientType): AWSAppSyncClient<any> {
    let authOptions: AuthOptions;

    if (type === ClientType.TYPE_IAM) {
      authOptions = {
        credentials: (): Promise<ICredentials> => Auth.currentCredentials(),
        type: AUTH_TYPE.AWS_IAM,
      };
    } else if (type === ClientType.TYPE_COGNITO) {
      authOptions = {
        // using Identity Token for authentication, otherwise our custom claims cannot be added to the auth token.
        // this has the distinct downside that the token cannot be invalidated from "server" side, only timeout will
        // clean identity tokens. maybe one day AWS will allow custom claims on access tokens :fingers-crossed:
        jwtToken: async (): Promise<string> => (await Auth.currentSession()).getIdToken().getJwtToken(),
        type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
      };
    } else {
      throw new Error("Unknown AppSync authentication requested!");
    }

    return new AWSAppSyncClient({
      auth: authOptions,
      region: awsconfig.aws_appsync_region!,
      url: this.getEndpoint(service),
      disableOffline: true,
    });
  }

  private getEndpoint(service: Service): string {
    switch (service) {
      case Service.USERS:
        return awsconfig.aws_appsync_graphqlEndpoint_users!;
      case Service.DATA:
        return awsconfig.aws_appsync_graphqlEndpoint_data!;
      case Service.DEVICE:
        return awsconfig.aws_appsync_graphqlEndpoint_device!;
      case Service.EVENTS:
        return awsconfig.aws_appsync_graphqlEndpoint_events!;
      case Service.STATS:
        return awsconfig.aws_appsync_graphqlEndpoint_stats!;
      default:
        throw new Error("Unknown AppSync service requested");
    }
  }
}

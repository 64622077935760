/* Copyright */
import React, { Component, Fragment } from "react";
import BackendFactory from "../../../data/BackendFactory";
import DeviceGroup from "../../../data/device/DeviceGroup";
import { ErrorWithCode, isErrorWithCode } from "../../../data/utils/ErrorUtils";
import { organizationLevelDictionary } from "../../../data/utu-dictionary-data/UtuDictionaryData";
import { Maybe, Nullable } from "../../../types/aliases";
import { OrganizationLevelDictionaryEntry } from "../../../types/utu-types";
import { isOrganizationLevelDictionaryEntry } from "../../../utils/functions";
import Loader from "../loader";
import CompanyAndRegionSelector from "./components/company-and-region-selector";
import { CheckboxLabel, PowerCompanyResetButton, PowerCompanySelectorButton, PowerCompanySelectorCheckbox } from "./components/styled-mui-components";

interface Props {
  checkboxLabel: string;
  onDeviceSelectionsChanged: (deviceIds: string[]) => void;
  onEventsOnlyCheckedChanged?: (checked: boolean) => void;
  groups: DeviceGroup[];
}

type OrganizationLevelType = OrganizationLevelDictionaryEntry | typeof UNKNOWN_ORGANIZATION_LEVEL_IDENTIFIER;

const UNKNOWN_ORGANIZATION_LEVEL_IDENTIFIER = "unknownOrganizationLevel";

interface State {
  loading: boolean;
  powerCompanies: Nullable<DeviceGroup[]>;
  powerCompanyInputLabel: Nullable<string>;
  powerCompanyRegionInputLabel: Nullable<string>;
  powerCompanyRegions: Nullable<DeviceGroup[]>;
  selectedPowerCompany: Nullable<DeviceGroup>;
  selectedPowerCompanyFilter: Nullable<string>;
  selectedPowerCompanyRegion: Nullable<DeviceGroup>;
  selectedPowerCompanyRegionFilter: Nullable<string>;
  organizationLevel: Nullable<OrganizationLevelType>;
}

class PowerCompanySelector extends Component<Props, State> {

  private static defaultInputLabelPowerCompany = "Valitse sähköyhtiö";
  private static defaultInputLabelPowerCompanyRegion = "Valitse alue";

  public constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      powerCompanies: null,
      powerCompanyInputLabel: PowerCompanySelector.defaultInputLabelPowerCompany,
      powerCompanyRegionInputLabel: PowerCompanySelector.defaultInputLabelPowerCompanyRegion,
      powerCompanyRegions: null,
      selectedPowerCompany: null,
      selectedPowerCompanyFilter: null,
      selectedPowerCompanyRegion: null,
      selectedPowerCompanyRegionFilter: null,
      organizationLevel: null,
    };
  }

  public async componentDidMount(): Promise<void> {
    if (!this.props.groups.length) {
      this.setState({ loading: true });
    } else {
      await this.setOrganizationLevel();
      await this.setPowerCompaniesAndRegions();
    }
  }

  public async componentDidUpdate(prevProps: Props): Promise<void> {
    if (this.props.groups.length && !prevProps.groups.length) {
      await this.setOrganizationLevel();
      await this.setPowerCompaniesAndRegions();
      if (this.state.loading) this.setState({ loading: false });
    }
  }

  private async setPowerCompaniesAndRegions(): Promise<void> {
    const { organizationLevel } = this.state;

    // companies or groups are not needed in other cases
    if (isOrganizationLevelDictionaryEntry(organizationLevel)) {
      if (organizationLevel.name === "root") {
        const powerCompanies = await this.props.groups[0].getGroups();
        this.setState({ powerCompanies });
      } else if (organizationLevel.name === "powerCompany") {
        const powerCompanyRegions = await this.props.groups[0].getGroups();
        this.setState({ powerCompanyRegions });
      }
    }
  }

  private countOrganizationLevelFromId(id: string): number {
    const organizationSeparatorMatches = id.matchAll(/:/g);
    return [...organizationSeparatorMatches].length;
  }

  // this method assumes that this.props.groups[0] is the "root" group in organization that the belongs to
  private async setOrganizationLevel(): Promise<void> {
    let userOrganizationLevel: Nullable<OrganizationLevelType> = null;

    try {
      userOrganizationLevel = await this.getOrganizationLevelOfGroup(this.props.groups[0]);
    } catch (error) {
      if (isErrorWithCode(error)) {
        if (error.code === "UnknownOrganizationLevelException") {
          userOrganizationLevel = "unknownOrganizationLevel";
        }
        console.error("Error: ", error.message);
      }
    } finally {
      this.setState({ organizationLevel: userOrganizationLevel });
    }
  }

  private async getOrganizationLevelOfGroup(group: DeviceGroup): Promise<OrganizationLevelDictionaryEntry> {
    const organizationId = group.getOrganization();

    if (organizationId !== undefined) {
      const user = await BackendFactory.getOrganizationBackend().getCurrentUser();

      // extra check to ensure that the user has received the right root group
      if (user?.getHomeOrganizationId() === organizationId) {
        const levelCount = this.countOrganizationLevelFromId(organizationId);
        const organizationLevel = organizationLevelDictionary[levelCount];

        if (organizationLevel !== undefined) {
          return organizationLevel;
        } else {
          throw new ErrorWithCode("UnknownOrganizationLevelException", `Level ${levelCount} does not have an entry in organizationLevelDictionary`);
        }
      } else {
        throw new ErrorWithCode("UserAndGroupIdMismatchException", "User and root group have different organization ids");
      }
    } else {
      throw new ErrorWithCode("OrganizationIdException", "Failed to get organizationId of a group");
    }
  }

  private async fetchPowerCompanyRegions(company: DeviceGroup): Promise<void> {
    const powerCompanyRegions = await company.getGroups();
    this.setState({ powerCompanyRegions });
  }

  private handlePowerCompanyFilterChange = (value: Maybe<string>): void => {
    if (value === undefined) {
      this.clearSelections();
    } else {
      if (this.state.powerCompanies) {
        const selectedPowerCompany = this.state.powerCompanies.find(group => group.getId() === value);

        if (selectedPowerCompany) {
          this.setState({
            selectedPowerCompany,
            selectedPowerCompanyFilter: selectedPowerCompany.getId(),
            selectedPowerCompanyRegionFilter: null,
            selectedPowerCompanyRegion: null,
            powerCompanyInputLabel: null,
            powerCompanyRegionInputLabel: PowerCompanySelector.defaultInputLabelPowerCompanyRegion,
          }, (): Promise<void> => this.fetchPowerCompanyRegions(selectedPowerCompany));
        } else {
          console.error(`No group found for string: ${value}`);
        }
      }
    }
  };

  private handlePowerCompanyRegionFilterChange = (value: Maybe<string>): void => {
    if (value === undefined) {
      this.setState({
        selectedPowerCompanyRegion: null,
        selectedPowerCompanyRegionFilter: null,
        powerCompanyRegionInputLabel: PowerCompanySelector.defaultInputLabelPowerCompanyRegion,
      });
    } else {
      if (this.state.powerCompanyRegions) {
        const selectedPowerCompanyRegion = this.state.powerCompanyRegions.find(group => group.getId() === value);

        if (selectedPowerCompanyRegion) {
          this.setState({
            selectedPowerCompanyRegion,
            selectedPowerCompanyRegionFilter: selectedPowerCompanyRegion.getId(),
            powerCompanyRegionInputLabel: null,
          });
        }
      }
    }
  };

  private clearSelections = (): void => {
    this.setState({
      selectedPowerCompany: null,
      selectedPowerCompanyRegion: null,
      selectedPowerCompanyFilter: null,
      selectedPowerCompanyRegionFilter: null,
      powerCompanyInputLabel: PowerCompanySelector.defaultInputLabelPowerCompany,
      powerCompanyRegionInputLabel: PowerCompanySelector.defaultInputLabelPowerCompanyRegion,
    });
  };

  private checkboxStateChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (this.props.onEventsOnlyCheckedChanged) {
      this.props.onEventsOnlyCheckedChanged(event.target.checked);
    }
  };

  private async getDeviceIdsRecursively(group: DeviceGroup, deviceIdList: string[]): Promise<void> {
    const subGroups = await group.getGroups();
    const devices = await group.getDevices();

    if (devices.length > 0) {
      deviceIdList.push(...devices.map(device => device.getId()));
    }

    if (subGroups.length > 0) {
      await Promise.all(subGroups.map(async (g): Promise<void> => this.getDeviceIdsRecursively(g, deviceIdList)));
    }
  }

  private async handleClickNew(): Promise<void> {
    let groups: Nullable<DeviceGroup[]> = null;
    const idList: string[] = [];

    // user is on root level
    if (this.state.powerCompanies) {
      groups = this.state.powerCompanies;

      // user has selected a region
      if (this.state.selectedPowerCompanyRegion) {
        groups = [this.state.selectedPowerCompanyRegion];
      // user has selected a company
      } else if (this.state.selectedPowerCompany) {
        groups = [this.state.selectedPowerCompany];
      // user has not selected anything: gather also devices on directly under root level
      } else {
        const deviceIdsOnRootLevelGroup = await this.getDeviceIdsOnRootLevelGroup();
        if (deviceIdsOnRootLevelGroup.length > 0) idList.push(...deviceIdsOnRootLevelGroup);
      }
    // user is on powerCompany level
    } else if (this.state.powerCompanyRegions) {
      groups = this.state.powerCompanyRegions;

      // user has selected a region
      if (this.state.selectedPowerCompanyRegion) {
        groups = [this.state.selectedPowerCompanyRegion];
      }
    }

    if (groups) {
      await Promise.all(groups.map(async (g): Promise<void> => this.getDeviceIdsRecursively(g, idList)));
      this.props.onDeviceSelectionsChanged(idList);
    }
  }

  private async getDeviceIdsOnRootLevelGroup(): Promise<string[]> {
    return (await this.props.groups[0].getDevices()).map(device => device.getId());
  }

  private renderButtonAndCheckbox(): JSX.Element {
    const checkBoxContainer = (
      <div className="power-company-selector-container">
        <CheckboxLabel
          value="end"
          control={
            <PowerCompanySelectorCheckbox
              color="primary"
              disableRipple={true}
              className="power-company-selector-checkbox"
              onChange={this.checkboxStateChanged}
            />
          }
          label={this.props.checkboxLabel}
          labelPlacement="start"
        />
      </div>
    );
    const { organizationLevel } = this.state;
    const renderOnlyCheckBoxContainer = isOrganizationLevelDictionaryEntry(organizationLevel)
      ? organizationLevel.name === "powerCompanyRegion"
      : organizationLevel === "unknownOrganizationLevel";

    if (renderOnlyCheckBoxContainer) {
      return checkBoxContainer;
    } else {
      // TODO: should user on powerCompanyRegion level see also this content?
      return (
        <Fragment>
          {checkBoxContainer}
          <div className="power-company-selector-container">
            <div className="power-company-selector-button">
              <PowerCompanySelectorButton
                variant="contained"
                size="medium"
                color="primary"
                onClick={(): Promise<void> => this.handleClickNew()}
              >
                Näytä
              </PowerCompanySelectorButton>
            </div>
          </div>
          <div className="power-company-selector-container">
            <PowerCompanyResetButton onClick={this.clearSelections} disableRipple>
              Tyhjennä valinnat
            </PowerCompanyResetButton>
          </div>
        </Fragment>
      );
    }
  }

  private renderSelectors(): JSX.Element {
    const { organizationLevel,
      selectedPowerCompanyFilter,
      powerCompanyInputLabel,
      powerCompanies,
      selectedPowerCompanyRegionFilter,
      powerCompanyRegionInputLabel,
      powerCompanyRegions,
    } = this.state;
    return (
      <Fragment>
        {(isOrganizationLevelDictionaryEntry(organizationLevel) && organizationLevel.name === "root")
        &&
        <CompanyAndRegionSelector
          currentValue={selectedPowerCompanyFilter || ""}
          label={powerCompanyInputLabel}
          labelId="power-company-selector-label-id"
          onChange={this.handlePowerCompanyFilterChange}
          values={powerCompanies ?? null}
        />
        }
        {(isOrganizationLevelDictionaryEntry(organizationLevel) && organizationLevel.name !== "powerCompanyRegion")
        &&
        <CompanyAndRegionSelector
          currentValue={selectedPowerCompanyRegionFilter || ""}
          label={powerCompanyRegionInputLabel}
          labelId="power-company-region-selector-label-id"
          onChange={this.handlePowerCompanyRegionFilterChange}
          values={powerCompanyRegions ?? null}
        />
        }
        {this.renderButtonAndCheckbox()}
      </Fragment>
    );
  }

  public render(): JSX.Element {
    return (
      <div className="power-company-selector-main-container">
        {this.state.loading ? <Loader leftRightPadding="0px" topBottomPadding="0px"/> : this.renderSelectors()}
      </div>
    );
  }
}

export default PowerCompanySelector;

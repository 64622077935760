import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AWSJSON: string;
}

export interface Query {
  __typename?: 'Query';
  urlsQsEmbedGenerate?: Maybe<QsEmbedUrlResponse>;
}


export interface QueryUrlsQsEmbedGenerateArgs {
  request: QsEmbedUrlRequest;
}

export interface QsEmbedUrlRequest {
  dashboardId: Scalars['String'];
  emailAddress: Scalars['String'];
  openIdToken: Scalars['String'];
  resetDisabled: Scalars['Boolean'];
  sessionLifetimeInMinutes: Scalars['Int'];
  sessionName: Scalars['String'];
  undoRedoDisabled: Scalars['Boolean'];
}

export interface QsEmbedUrlResponse {
  __typename?: 'QsEmbedUrlResponse';
  embedUrl?: Maybe<Scalars['String']>;
}

export interface Schema {
  __typename?: 'Schema';
  query?: Maybe<Query>;
}

export type UrlsQsEmbedGenerateQueryVariables = Exact<{
  request: QsEmbedUrlRequest;
}>;


export type UrlsQsEmbedGenerateQuery = { __typename?: 'Query', urlsQsEmbedGenerate?: { __typename?: 'QsEmbedUrlResponse', embedUrl?: string | null | undefined } | null | undefined };


export const UrlsQsEmbedGenerateDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"urlsQsEmbedGenerate"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"request"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"QsEmbedUrlRequest"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"urlsQsEmbedGenerate"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"request"},"value":{"kind":"Variable","name":{"kind":"Name","value":"request"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"embedUrl"}}]}}]}}]} as unknown as DocumentNode<UrlsQsEmbedGenerateQuery, UrlsQsEmbedGenerateQueryVariables>;
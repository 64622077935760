/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { ReferenceHWState } from "../ReferenceHW/ReferenceHWState";
import { HyperHWStateProperties } from "./HyperHWStateProperties";
import { Aggregate, MeasurenmentInfo } from "../../../generated/gqlData";
import { Maybe, Nullable, Voidable } from "../../../types/aliases";
import { parseAddOnConf } from "../../../data/utils/Utils";
import { addOnConfigSelectionDictionary, constantSensorDataDictionary } from "../../../data/utu-dictionary-data/UtuDictionaryData";

export enum HyperIntervalTypes {
  MEASURE,
  UPDATE
}

export class HyperHWState extends ReferenceHWState<HyperHWStateProperties> {

  private static readonly DEFAULT_INTERVAL = 15;
  private static readonly MAX_INTERVAL = 3600;
  private static readonly MIN_UPDATE_INTERVAL = 0;
  private static readonly MIN_MEASUREMENT_INTERVAL = 5;

  public static toValidInterval(value: Voidable<number>, type: HyperIntervalTypes): number {
    if (value == null) {
      return HyperHWState.DEFAULT_INTERVAL;
    }

    if (value > HyperHWState.MAX_INTERVAL) {
      return HyperHWState.MAX_INTERVAL;
    }
    const min = HyperHWState.getMinIntervalValue(type);

    if (value < min) {
      return min;
    }
    return value;
  }

  public static getMinIntervalValue(type: HyperIntervalTypes): number {
    switch (type) {
      case HyperIntervalTypes.MEASURE:
        return this.MIN_MEASUREMENT_INTERVAL;
      case HyperIntervalTypes.UPDATE:
        return this.MIN_UPDATE_INTERVAL;
      default:
        return 0;
    }
  }

  public getMeasurementInfo(): Nullable<MeasurenmentInfo[]> {
    const config = this.addOnConf;

    if (config) {
      const parsedConfig = parseAddOnConf(config);
      const selectedKeysInConfig = Object.keys(parsedConfig).filter(key => parsedConfig[key] !== false);
      return selectedKeysInConfig.reduce<MeasurenmentInfo[]>((acc, curr) => {
        if (curr.startsWith(addOnConfigSelectionDictionary.hfct.name) || curr.startsWith(addOnConfigSelectionDictionary.mic.name)) {
          this.addPDMeasurements(acc, curr);
        } else {
          acc.push(
            {
              aggregate: curr.includes(Aggregate.Avg) ? Aggregate.Avg : Aggregate.Max,
              sensorName: curr,
              deviceId: this.deviceId,
            },
          );
        }
        return acc;
      }, []);
    } else {
      console.error(`MeasurenmentInfo could not be found for device with id ${this.deviceId}`);
      return null;
    }
  }

  private addPDMeasurements(measurements: MeasurenmentInfo[], keyInConfig: string): void {
    if (keyInConfig.startsWith(addOnConfigSelectionDictionary.hfct.name)) {
      measurements.push(
        {
          aggregate: Aggregate.Avg,
          sensorName: constantSensorDataDictionary.hfct_avg.name,
          deviceId: this.deviceId,
        },
        {
          aggregate: Aggregate.Max,
          sensorName: constantSensorDataDictionary.hfct_max.name,
          deviceId: this.deviceId,
        },
      );
    } else if (keyInConfig.startsWith(addOnConfigSelectionDictionary.mic.name)) {
      measurements.push(
        {
          aggregate: Aggregate.Avg,
          sensorName: constantSensorDataDictionary.mic_avg.name,
          deviceId: this.deviceId,
        },
        {
          aggregate: Aggregate.Max,
          sensorName: constantSensorDataDictionary.mic_max.name,
          deviceId: this.deviceId,
        },
      );
    }
  }

  public get noModemSleep(): Nullable<boolean> {
    return this.deltaExists("noModemSleep") ? this.desired.noModemSleep : this.reported.noModemSleep;
  }

  public set noModemSleep(enabled: Nullable<boolean>) {
    this.desired.noModemSleep = enabled;
    this.changedValues.noModemSleep = enabled;
  }

  public get updateInterval(): Nullable<number> {
    return this.deltaExists("updateInterval") ? this.desired.updateInterval : this.reported.updateInterval;
  }

  public set updateInterval(value: Nullable<number>) {
    const validatedValue = HyperHWState.toValidInterval(value, HyperIntervalTypes.UPDATE);
    this.desired.updateInterval = validatedValue;
    this.changedValues.updateInterval = validatedValue;
  }

  public get measurementInterval(): Nullable<number> {
    return this.deltaExists("measurementInterval") ? this.desired.measurementInterval : this.reported.measurementInterval;
  }

  public set measurementInterval(value: Nullable<number>) {
    const validatedValue = HyperHWState.toValidInterval(value, HyperIntervalTypes.MEASURE);
    this.desired.measurementInterval = validatedValue;
    this.changedValues.measurementInterval = validatedValue;
  }

  public get addOnConf(): Maybe<string> {
    return this.deltaExists("addOnConf") ? this.desired.addOnConf : this.reported.addOnConf;
  }

  public set addOnConf(value: Maybe<string>) {
    this.desired.addOnConf = value;
    this.changedValues.addOnConf = value;
  }


  public get latitude(): Nullable<number> {
    return this.deltaExists("latitude") ? this.desired.latitude : this.reported.latitude;
  }

  public get longitude(): Nullable<number> {
    return this.deltaExists("longitude") ? this.desired.longitude : this.reported.longitude;
  }

  // TODO: are setters for latitude and longitude needed?
}

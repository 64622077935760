/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import { RouteComponentProps } from "react-router-dom";
import { DevicePathRouterProps } from "../types/routerprops";
import TimePeriod from "../data/utils/TimePeriod";
import { toNumber } from "./functions";
import { Maybe } from "../types/aliases";

export type DeviceRouterProps = RouteComponentProps<DevicePathRouterProps>;
export enum DeviceChangeType {
  ChangedToNone = "ChangedToNone",
  ChangedToNew = "ChangedToNew",
  StayedSame = "StayedSame",
  StayedNone = "StayedNone",
  WillRestore = "WillRestore",
}
export interface DeviceAndTime {
  deviceId?: string;
  timePeriod: Partial<TimePeriod>;
}

export function idFromProps(props: DeviceRouterProps): Maybe<string> {
  return props.match.params.id;
}

export function isDeviceInProps(routerProps: DeviceRouterProps): boolean {
  return !!idFromProps(routerProps);
}

/**
 * Creates a new relative path from {@link DeviceRouterProps} by either replacing or removing the device in the path
 * @param routerProps
 * @param deviceId
 *    id of the new device (if empty, old device is simply removed)
 */
export function createRelativeDeviceIdUrl(routerProps: DeviceRouterProps, deviceId?: string): string {
  const oldId = routerProps.match.params.id;
  const oldPath = routerProps.location.pathname;
  const basePathTemp = !oldId ? oldPath : oldPath.substring(0, oldPath.lastIndexOf(`/${oldId}`));
  const basePath = basePathTemp.endsWith("/") ? basePathTemp.substring(0, basePathTemp.length - 1) : basePathTemp;
  return !deviceId ? basePath : `${basePath}/${deviceId}`;
}

/**
 * Collects a {@link DeviceAndTime} object from given {@link DeviceRouterProps}
 * @param props
 */
export function parseDeviceAndTime(props: DeviceRouterProps): DeviceAndTime {
  const urlParams = new URLSearchParams(props.location.search);
  const startTimestamp = urlParams.get("startTimestamp");
  const endTimestamp = urlParams.get("endTimestamp");
  return {
    deviceId: props.match.params.id ?? undefined,
    timePeriod: {
      startTimestamp: startTimestamp ? toNumber(startTimestamp) : undefined,
      endTimestamp: endTimestamp ? toNumber(endTimestamp) : undefined,
    },
  };
}

/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { Service } from "../backend/AppSyncClientProvider";
import Device from "./Device";
import { Maybe } from "../../types/aliases";
import { DevicesStatesUpdateFeedDocument, DevicesStatesUpdateFeedSubscription } from "../../generated/gqlDevice";
import AbstractMapSubscriptionManager from "../utils/subscriptions/AbstractMapSubscriptionManager";

export default class ShadowSubscriptionManager extends AbstractMapSubscriptionManager<Device, DevicesStatesUpdateFeedSubscription> {
  private static __instance: ShadowSubscriptionManager = new ShadowSubscriptionManager();

  private constructor() {
    super(Service.DEVICE, DevicesStatesUpdateFeedDocument);
  }

  public static get instance(): ShadowSubscriptionManager {
    return this.__instance;
  }

  protected subscriptionHandler(result: Maybe<DevicesStatesUpdateFeedSubscription>): void {
    const { deviceId, reported, desired, timestamp } = result?.devicesStatesUpdateFeed?.item ?? {};

    if (deviceId) {
      const reportedProps = reported ? JSON.parse(reported) : undefined;
      const desiredProps = desired ? JSON.parse(desired) : undefined;
      this.getListener(deviceId)?.setState(timestamp ?? undefined, reportedProps, desiredProps);
    }
  }
}

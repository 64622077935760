/*
 * Copyright
*/
import { SvgIcon } from "@material-ui/core";
import React, { Component } from "react";

class TransformerIcon extends Component {

  public render(): JSX.Element {
    return (
      <SvgIcon>
        <path fill="currentColor" d="M23,7H1V5L12,2,23,5ZM21,8H3V22H21ZM7,19H6V15H7Zm2,0H8V15H9Zm2,0H10V15h1Z"/>
        <rect width="24" height="24" fill="none"/>
      </SvgIcon>
    );
  }
}

export default TransformerIcon;

/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import { Maybe, Nullable } from "../../../types/aliases";
import Device, { DeviceObserver } from "../../../data/device/Device";
import React, { Component, ReactNode } from "react";
import { ReferenceHWState } from "../../../client/devices/ReferenceHW/ReferenceHWState";
import { ReferenceHWStateProperties } from "../../../client/devices/ReferenceHW/ReferenceHWStateProperties";
import moment from "moment";
import { DateTimeFormatTarget, getDateTimeFormat, getDisplayName, TOOLTIP_DELAY_MS } from "../../../data/utils/Utils";
import DeviceSettingsButton from "../../device-settings/device-settings-button";
import LocationIcon from "../../ui/location-icon";
import WarningIcon from "@material-ui/icons/Warning";
import { getPowerSupplyStateIcon, getSignalStrengthIcon } from "../helpers/icon-factory";
import DeviceGroup from "../../../data/device/DeviceGroup";
import DeviceState from "../../../data/device/DeviceState";
import { DeviceStateProperties } from "../../../data/device/DeviceStateProperties";
import Loader from "../../ui/loader";
import { EventRepositoryListener } from "../../../data/events/EventRepositoryListener";
import EventsRepository from "../../../data/events/EventsRepository";
import Event from "../../../data/clientSpecific/Event";
import accessControlled from "../../access-control/access-controlled";
import ViewAccessMethods from "../../../ViewAccessMethods";
import TransformerIcon from "../../ui/transformer-icon";
import { Tooltip, withTheme } from "@material-ui/core";

const ACDeviceSettingsButton = accessControlled(DeviceSettingsButton, ViewAccessMethods.hasRootGroupSuperAdminAccess);

export interface Props {
  device: Device;
  editMode?: boolean;
  showEventsMode: boolean;
  parentGroup?: DeviceGroup;
  selected?: boolean;
  onDeviceSelect?: (device?: Device) => void;
  "data-testid"?: string;
}

interface State {
  deviceState: Nullable<DeviceState<DeviceStateProperties>>;
  selected: boolean;
  hasActiveEvents: boolean;
  loading: boolean;
  displayNameTooltipOpen: boolean;
  statusContainerTooltipOpen: boolean;
}

class DraggableDeviceItem extends Component<Props, State> implements DeviceObserver, EventRepositoryListener {

  public constructor(props: Props) {
    super(props);
    this.state = {
      deviceState: props.device.getState(),
      selected: false,
      hasActiveEvents: false,
      loading: false,
      displayNameTooltipOpen: false,
      statusContainerTooltipOpen: false,
    };
  }

  public componentDidMount(): void {
    this.props.device.addObserver(this);
    EventsRepository.instance.addListener(this);
    this.setState({ loading: true });

    if (this.props.showEventsMode && EventsRepository.instance.isInitialized) {
      this.setState({ hasActiveEvents: this.deviceHasActiveEvents(), loading: false });
    }
  }

  public componentDidUpdate(prevProps: Readonly<Props>): void {
    if (this.props.selected !== prevProps.selected) {
      // since props are our primary source of selection "truth", we will always invalidate our internal selection
      // state when props selection state changes.
      this.setState({ selected: false });
    }
  }

  public componentWillUnmount(): void {
    this.props.device.removeObserver(this);
    EventsRepository.instance.removeListener(this);
  }

  public onEventsInitDone(): void {
    if (this.props.showEventsMode && this.state.loading) {
      this.setState({ hasActiveEvents: this.deviceHasActiveEvents(), loading: false });
    }
  }

  public onEvent(_event: Event): void {
    if (this.props.showEventsMode) {
      this.setState({ hasActiveEvents: this.deviceHasActiveEvents() });
    }
  }

  public onEventStateChanged(_event: Event): void {
    if (this.props.showEventsMode) {
      this.setState({ hasActiveEvents: this.deviceHasActiveEvents() });
    }
  }

  private handleClick = (): void => {
    if (!this.props.selected) {
      this.props.onDeviceSelect?.(this.props.device);
      this.setState({ selected: true });
    }
  };

  public onDeviceStateUpdated(device: Device): void {
    this.setState({ deviceState: device.getState() });
  }

  private handleDragStart = (event: React.DragEvent): void => {
    this.setState({ displayNameTooltipOpen: false });

    if (this.props.parentGroup) {
      event.dataTransfer.setData("group", this.props.parentGroup.getId());
    }
    event.dataTransfer.setData("device", this.props.device.getId());
  };

  private getTooltipTitle = (): string => {
    let timestampAsString = "Tietoja ei ole saatavilla";

    if (this.state.deviceState && this.state.deviceState.getStateUpdatedTimestampMillis()) {
      const timestamp = this.state.deviceState.getStateUpdatedTimestampMillis();
      timestampAsString = moment(timestamp).format(getDateTimeFormat(DateTimeFormatTarget.ShadowUpdate));
    }
    return "Viimeisin päivitys: " + timestampAsString;
  };

  private getSignalStatusElement(): Maybe<JSX.Element> {
    const icon = getSignalStrengthIcon(this.state.deviceState);

    if (icon) {
      return (
        <img
          className="status-icon"
          src={icon}
          alt="Signal"
        />
      );
    }
  }

  private getPowerSupplyStatusElement(): Maybe<JSX.Element> {
    const icon = getPowerSupplyStateIcon(this.state.deviceState);

    if (icon) {
      return (
        <img
          className="status-icon"
          src={icon}
          alt="Battery"
        />
      );
    }
  }

  private renderDeviceIcon(): Maybe<JSX.Element> {
    if (this.state.loading) {
      return (
        <Loader
          leftRightPadding="0"
          topBottomPadding="0"
          size="small"
        />
      );
    // UTU modification: override device.getIcon() for Hyper devices to enable changing color of the icon
    } else if (this.props.device && this.props.device.getType() === "Hyper") {
      return <TransformerIcon/>;
    } else {
      return <img src={this.props.device.getIcon()} alt="device logo" />;
    }
  }

  private deviceHasActiveEvents(): boolean {
    return EventsRepository.instance.getAllActiveEvents().some(({ deviceId }) => deviceId === this.props.device.getId());
  }

  private renderDeviceName(): ReactNode {
    return (
      <div
        className="iot-item-link col-sm-8 col-xsm-8"
        data-testid={"device-item-" + this.props.device.getId()}
        onClick={this.handleClick}
      >
        <div
          className="iot-icon"
        >
          {this.renderDeviceIcon()}
        </div>
        <div
          className="iot-name col-sm-8 col-xsm-8"
        >
          <Tooltip
            arrow
            title={this.getTooltipTitle()}
            placement="top-start"
            enterDelay={TOOLTIP_DELAY_MS}
            id="device-info-tooltip"
            open={this.state.displayNameTooltipOpen}
            onClose={(): void => this.setState({ displayNameTooltipOpen: false })}
            onOpen={(): void => this.setState({ displayNameTooltipOpen: true })}
          >
            <span>{getDisplayName(this.props.device)}</span>
          </Tooltip>
        </div>
      </div>
    );
  }

  private renderStatusContainer(): Maybe<JSX.Element> {
    if (!this.props.editMode) {
      const deviceState = this.state.deviceState as Nullable<ReferenceHWState<ReferenceHWStateProperties>>;
      const { hasActiveEvents } = this.state;
      return (
        <div className="iot-status-container col-sm-4 col-xsm-4">
          {hasActiveEvents && <WarningIcon htmlColor="#ff0000"/>}
          {/* if WarningIcon is not rendered, align icons with those tree items which rendered WarningIcon*/}
          <span style={{ marginLeft: !hasActiveEvents ? "1.5rem" : undefined }}>
            <ACDeviceSettingsButton
              device={this.props.device}
              isIcon={true}
              showAccessError={false}
            />
            <Tooltip
              arrow
              title={this.getTooltipTitle()}
              placement="top-end"
              enterDelay={TOOLTIP_DELAY_MS} id="device-info-tooltip"
              open={this.state.statusContainerTooltipOpen}
              onClose={(): void => this.setState({ statusContainerTooltipOpen: false })}
              onOpen={(): void => this.setState({ statusContainerTooltipOpen: true })}
            >
              <span>
                {this.getSignalStatusElement()}
                <LocationIcon
                  locationStatus={deviceState?.gpsFix ?? undefined}
                  updateMilliseconds={deviceState?.getStateUpdatedTimestampMillis()}
                />
                {this.getPowerSupplyStatusElement()}
              </span>
            </Tooltip>
          </span>
        </div>
      );
    }
  }

  public render(): JSX.Element {
    const selected = this.props.selected || this.state.selected;
    const divClass = selected ? "tree-node-selected" : "tree-node-not-selected";
    return (
      <div
        className={divClass}
        draggable={this.props.editMode}
        onDragStart={(event: React.DragEvent): void => this.handleDragStart(event)}
        data-testid={this.props["data-testid"]}
      >
        {this.renderDeviceName()}
        {this.renderStatusContainer()}
      </div >
    );
  }
}

export default withTheme(DraggableDeviceItem);

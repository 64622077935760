/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import React, { Component } from "react";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import Map from "ol/Map";
import OSM from "ol/source/OSM";
import VectorSource from "ol/source/Vector";
import Icon from "ol/style/Icon";
import Style from "ol/style/Style";
import View from "ol/View";
import Marker from "../../../assets/ic_muuntamo_circle.svg";
import EventMarker from "../../../assets/ic_warning_circle.svg";
import Event from "../../../data/clientSpecific/Event";
import Device from "../../../data/device/Device";
import { EventRepositoryListener } from "../../../data/events/EventRepositoryListener";
import EventsRepository from "../../../data/events/EventsRepository";
import { Nullable } from "../../../types/aliases";
import { MapLocation } from "../../../types/utu-types";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const proj = require("ol/proj");

interface Props {
  device: Device;
  markerLocation?: MapLocation;
}

const DEFAULT_CENTER: [number, number] = [0, 0];
const ZOOM_DEFAULT = 2;
const ZOOM_VALUE = 11;

class HistoryMap extends Component<Props> implements EventRepositoryListener {
  private map: Nullable<Map> = null;
  private selectedMarkerLayer?: VectorLayer;

  public async componentDidMount(): Promise<void> {
    EventsRepository.instance.addListener(this);
    this.renderMap();

    if (this.props.markerLocation && EventsRepository.instance.isInitialized()) {
      this.renderSelectedMarker();
      this.renderOverlay();
    }
  }

  public componentDidUpdate(prevProps: Props): void {
    // this.props.markerLocation might be undefined componentDidMount -> render marker here
    if (!prevProps.markerLocation && this.props.markerLocation) {

      if (this.selectedMarkerLayer && this.map) {
        this.map.removeLayer(this.selectedMarkerLayer);
        this.selectedMarkerLayer = undefined;
      }

      // handle marker rendering here in case EventsRepository is initialized when executing gets here
      if (EventsRepository.instance.isInitialized()) {
        this.renderSelectedMarker();
        this.renderOverlay();
      }
    }
  }


  public componentWillUnmount(): void {

    EventsRepository.instance.removeListener(this);
  }

  public onEventsInitDone(): void {
    // usually EventsRepository is not yet initialized when componentDidUpdate executes multiple times so handle marker rendering here
    if (this.props.markerLocation) {
      this.renderSelectedMarker();
      this.renderOverlay();
    }
  }

  public onEvent(_event: Event): void {
    if (this.props.markerLocation) {
      this.renderSelectedMarker();
      this.renderOverlay();
    }
  }

  public onEventStateChanged(_event: Event): void {
    if (this.props.markerLocation) {
      this.renderSelectedMarker();
      this.renderOverlay();
    }
  }

  private renderSelectedMarker(): void {
    const iconStyle = new Style({
      image: new Icon({
        anchor: [0.5, 0.5],
        src: Marker,
      }),
    });

    const eventIconStyle = new Style({
      image: new Icon({
        anchor: [0.5, 0.5],
        src: EventMarker,
      }),
    });

    const allActiveEvents = EventsRepository.instance.getAllActiveEvents();
    const markers: Feature[] = [];

    if (this.props.markerLocation) {
      const iconFeature = new Feature({
        geometry: new Point(proj.fromLonLat([this.props.markerLocation.lng,
          this.props.markerLocation.lat])),
        properties: this.props.markerLocation,
        type: "marker",
      });

      const deviceHasEvents = allActiveEvents.some(({ deviceId }) => deviceId === this.props.device.getId());
      iconFeature.setStyle(deviceHasEvents ? eventIconStyle : iconStyle);
      markers.push(iconFeature);
      this.renderLayer(markers);
    }
  }

  private renderLayer(features: Feature[]): void {
    if (this.map !== null) {
      const vectorSource = new VectorSource({
        features,
      });
      const vectorLayer = new VectorLayer({
        source: vectorSource,
      });
      this.selectedMarkerLayer = vectorLayer;
      this.map.addLayer(vectorLayer);
    }
  }

  private renderMap(): void {
    const tileLayer = new TileLayer({
      source: new OSM(),
    });

    this.map = new Map({
      layers: [tileLayer],
      target: "map",
      view: new View({
        center: DEFAULT_CENTER,
        extent: proj.transformExtent([180, -90, -180, 90], "EPSG:4326", "EPSG:3857"),
        minZoom: ZOOM_DEFAULT,
        zoom: ZOOM_DEFAULT,
      }),
    });
  }

  private renderOverlay(): void {
    if (this.map !== null && this.props.markerLocation) {
      const currentZoomValue = this.map.getView().getZoom();
      const targetZoomValue = currentZoomValue !== undefined ? currentZoomValue > ZOOM_DEFAULT ? currentZoomValue : ZOOM_VALUE : ZOOM_DEFAULT;
      const coordinates = proj.fromLonLat([this.props.markerLocation.lng,
        this.props.markerLocation.lat]);
      this.map.getView().animate({ center: coordinates }, { zoom: targetZoomValue });
    }
  }

  public render(): JSX.Element {
    return (
      <div id="map" />
    );
  }
}

export default HistoryMap;

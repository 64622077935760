/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { AWSOrganizationBackend } from "./AWSOrganizationBackend";
import User, { UserParameters } from "./User";
import AppSyncClientFactory from "../backend/AppSyncClientFactory";
import { Service } from "../backend/AppSyncClientProvider";
import {
  PolicyGrant,
  ResultType,
  UsersDeleteDocument,
  UsersGrantsListDocument,
  UsersPolicyGroupsListDocument,
} from "../../generated/gqlUsers";
import Organization from "./Organization";
import AWSOrganization from "./AWSOrganization";
import { verifyOrganizationType } from "./AWSTypeUtils";
import PolicyGroup from "./PolicyGroup";
import AWSPolicyGroup from "./AWSPolicyGroup";
import AuthWrapper from "../auth/AuthWrapper";
import { isDefined } from "../../utils/types";
import PromiseWaitList from "../utils/PromiseWaitList";
import { HasEntityRelations, RelationChange } from "../utils/EntityRelationCache";
import { PromiseSemaphore } from "../utils/PromiseSemaphore";
import { throwGQLError } from "../utils/Utils";
import { policyListContainsPermission } from "./Utils";

export default class AWSUser extends User implements HasEntityRelations {
  public readonly entityType = AWSUser;
  private policyGroupSemaphore = new PromiseSemaphore(() => this.backendFetchPolicyGroups());

  // caches getUserGrants results
  // TODO: these need to react to both user's PolicyGroup and Organization changes. Now the cache never updates.
  // TODO: this is dependent on PolicyGroup's policies - it is not enough to listen to relation changes from EntityPairCache
  private grantCache = new Map<string, PromiseWaitList<PolicyGrant[]>>();

  public constructor(private readonly backend: AWSOrganizationBackend, parameters: UserParameters) {
    super(parameters);
  }

  public async getHomeOrganization(): Promise<Organization> {
    if (!this.homeOrganization) {
      this.homeOrganization = await this.backendGetHomeOrganization();
    }
    return this.homeOrganization;
  }

  public async getOrganizations(): Promise<Organization[]> {
    // TODO: backend does not have an implementation for this
    return [await this.getHomeOrganization()];
  }

  public async belongsToPolicyGroup(policyGroupId: string): Promise<boolean> {
    await this.policyGroupSemaphore.guard();
    const policyGroups = this.backend.entityRelationCache.listFor(this, AWSPolicyGroup);

    for (const pg of policyGroups) {
      if (pg.getId() === policyGroupId) {
        return true;
      }
    }
    return false;
  }

  public async hasGrants(organizationId: string, ...actions: string[]): Promise<boolean> {
    const grants = await this.getUserGrants(organizationId);
    return actions.reduce((hasPermission, action) => {
      return hasPermission && grants.some((grant) => policyListContainsPermission(grant.policies, action));
    }, true as boolean);
  }

  public async hasPolicyGroupNamed(policyGroupName: string, organizationId?: string): Promise<boolean> {
    const organization = organizationId ?? (await AuthWrapper.getCurrentAuthenticatedUserClaims())?.homeOrganizationId;
    await this.policyGroupSemaphore.guard();
    return this.backend.entityRelationCache.listFor(this, AWSPolicyGroup)
      .filter((policyGroup) => policyGroup.getOrganizationId() === organization)
      .some((policyGroup) => policyGroup.getName() === policyGroupName);
  }

  public async getPolicyGroups(organizationId?: string): Promise<PolicyGroup[]> {
    await this.policyGroupSemaphore.guard();
    return organizationId
      ? this.backend.entityRelationCache.listFor(this, AWSPolicyGroup)
        .filter((pg) => pg.getOrganizationId() === organizationId)
      : this.backend.entityRelationCache.listFor(this, AWSPolicyGroup);
  }

  public async delete(): Promise<void> {
    const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
    const result = await client.mutate(UsersDeleteDocument, { userId: this.id });

    if (!result.data || result.data.usersDelete?.result !== ResultType.Ok) {
      throwGQLError(
        result,
        result.data?.usersDelete?.failureReason ?? "Failed to delete user",
      );
    }

    await this.backend.cleanEntityFromCaches(this.id);
    this.notifyAction(observer => observer.onDelete?.(this));
    this.clearObservers();
  }

  public onRelationChange(change: RelationChange): void {
    if (change.ofType(AWSPolicyGroup) && this.policyGroupSemaphore.invoked()) {
      const policyGroups = this.backend.entityRelationCache.listFor(this, AWSPolicyGroup);
      // TODO:  we could potentially trash the grants cache here, but it would cause the grants to be
      //        reloaded at least one extra time:
      //          someone asks for grants, then policy groups. policy groups get updated for the first time, and cause
      //          this callback to ping, wiping grants. very annoying
      this.notifyAction(observer => observer.onPolicyGroupsChange?.(policyGroups, this));
    } else if (change.ofType(AWSOrganization)) {
      // TODO: invocation guard once getOrganizations is actually implemented
      const organizations = this.backend.entityRelationCache.listFor(this, AWSOrganization);
      this.notifyAction(observer => observer.onOrganizationsChange?.(organizations, this));
    }
  }

  private async backendGetHomeOrganization(): Promise<AWSOrganization> {
    const organization = await this.backend.getOrganization(this.homeOrganizationId);

    if (!organization) {
      throw new Error(`Home organization of user '${this.id}' does not exist`);
    }
    verifyOrganizationType(organization);
    return organization as AWSOrganization;
  }

  private async backendFetchPolicyGroups(): Promise<void> {
    const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
    // TODO: nextToken
    const response = await client.query(
      UsersPolicyGroupsListDocument,
      {
        userId: this.getId(),
      },
    );

    // TODO: same efficiency problem as AWSOrganization::getUsers. OrganizationBackend's caching should alleviate this.
    const policyGroups = await Promise.all(
      response.data.usersPolicyGroupsList?.groups
        ?.map((id) => this.backend.getPolicyGroup(id)) ?? [],
    );

    this.backend.entityRelationCache.replaceTypedLinks(this, AWSPolicyGroup, policyGroups.filter(isDefined));
  }

  private async getUserGrants(organizationId: string): Promise<PolicyGrant[]> {
    // TODO: for the current users, we could use token claims instead of reaching to backend
    if (this.grantCache.has(organizationId)) {
      return this.grantCache.get(organizationId)!.get();
    }
    this.grantCache.set(organizationId, new PromiseWaitList<PolicyGrant[]>(this.username + ".grants"));
    const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
    const response = await client.query(
      UsersGrantsListDocument,
      {
        userId: this.getId(),
        organizationId,
      },
    );

    // ignoring grants errors - no grants, no permissions

    const grants = response.data.usersGrantsList?.grants ?? [];
    this.grantCache.get(organizationId)!.set(grants);
    return grants;
  }

  public static instanceOf(value: unknown): value is AWSUser {
    return value instanceof AWSUser;
  }
}

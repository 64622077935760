/* Copyright */
import React, { Component, ReactNode } from "react";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { EventTriggerDbEntry } from "../../../../data/types/eventTypes";
import { Nullable } from "../../../../types/aliases";
import EventTriggerTableItem from "./event-trigger-table-item";

interface Props {
  onSelectTriggerForDeleting: (triggerId: string) => void;
  onSelectTriggerForEditing: (triggerId: string) => void;
  tableItems: Nullable<EventTriggerDbEntry[]>;
}

export default class EventTriggerTable extends Component<Props> {

  private renderEventTriggerTableItems(): JSX.Element | JSX.Element[] {
    if (this.props.tableItems) {
      return this.props.tableItems.map(eventTrigger => {
        return (
          <EventTriggerTableItem
            eventTrigger={eventTrigger}
            onSelectTriggerForDeleting={this.props.onSelectTriggerForDeleting}
            onSelectTriggerForEditing={this.props.onSelectTriggerForEditing}
            key={eventTrigger.triggerId}
          />
        );
      });
    } else {
      return (
        <tr>
          <td className="users-list-empty-text">Hälytyskonfiguraatioita ei löytynyt</td>
        </tr>
      );
    }
  }
  
  public render(): ReactNode {
    return (
      <Table className="org-users-table">
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>Hälytyksen nimi</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.renderEventTriggerTableItems()}
        </TableBody>
      </Table>
    );
  }
}

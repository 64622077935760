/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */
import Box from "@material-ui/core/Box";
import LinearProgress, { LinearProgressProps } from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import React, { FunctionComponent, PropsWithChildren, ReactElement } from "react";

type Props = LinearProgressProps & { value: number };

const LinearProgressWithLabel: FunctionComponent<Props> = (props: PropsWithChildren<Props>): ReactElement => {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
};

export default LinearProgressWithLabel;

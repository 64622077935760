/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { Service } from "../backend/AppSyncClientProvider";
import { Session } from "../clientSpecific/Session";
import SessionSet from "./SessionSet";
import AppSyncClientFactory from "../backend/AppSyncClientFactory";
import { Nullable } from "../../types/aliases";
import { isDefined } from "../../utils/types";
import { DevicesSessionsListDocument } from "../../generated/gqlData";

export default class AWSSessionSet extends SessionSet {
  private data: Session[] = [];

  public constructor(
      private readonly deviceId: string,
      private readonly startTimestamp: number,
      private readonly endTimestamp: number) {
    super();
  }

  public getId(): string {
    return this.deviceId;
  }

  public getSessions(): Session[] {
    return this.data;
  }

  public async fetch(): Promise<void> {
    try {
      let nextToken: Nullable<string> = null;
      let sessions: Session[] = [];

      const appSyncClient = AppSyncClientFactory.createProvider().getTypedClient(Service.DATA);

      do {
        const response = await appSyncClient.query(
          DevicesSessionsListDocument,
          {
            deviceId: this.deviceId,
            startTimestamp: this.startTimestamp.toString(),
            endTimestamp: this.endTimestamp.toString(),
            nextToken,
          },
        );
        // cast required to avoid cyclic type inference for response
        nextToken = (response.data.devicesSessionsList?.nextToken ?? null) as Nullable<string>;
        const convertedSessions = response.data.devicesSessionsList?.sessions
          ?.filter(isDefined)
          .map((fragment): Session => ({
            deviceId: fragment.deviceId,
            sessionId: fragment.sessionId,
            durationMs: fragment.durationMs ?? 0,
          }));
        sessions = sessions.concat(convertedSessions ?? []);
      } while (nextToken);
      this.data = sessions;
    } catch (error) {
      console.error("Error", error);
    }
  }

  public setData(data: Session): void {
    this.data.push(data);
  }
}

/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { isObject } from "./Utils";

export function isError(error: unknown): error is Error {
  return isObject(error)
  && typeof error.name === "string"
  && typeof error.message === "string"
  && (!error.stack || typeof error.stack === "string");
}

export function isErrorWithCode(error: unknown): error is ErrorWithCode {
  return isObject(error) && isError(error) && typeof error.code === "string";
}

export class ErrorWithCode extends Error {
  public readonly code: string;

  public constructor(code: string, message?: string) {
    super(message);
    this.code = code;
  }
}

/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import { IconButton, Table, TableCell, TableHead, TableRow } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import React, { Component, Fragment } from "react";
import Device from "../../../../data/device/Device";
import DeviceGroup from "../../../../data/device/DeviceGroup";
import SearchBar from "../../../ui/search-bar";
import ErrorDialog from "../../../ui/error-dialog";
import Loader from "../../../ui/loader";
import AddDevicePopup from "./add-device-popup";
import { Maybe } from "../../../../types/aliases";
import DeviceTree from "../../../device-browsing/device-tree";
import { getCaseInsensitiveSearchFilter } from "../../../device-browsing/helpers/search-filter";
import DeviceNavigationCache from "../../../../utils/DeviceNavigationCache";
import { translations } from "../../../../generated/translationHelper";

interface Props {
  groups: DeviceGroup[];
  onGroupsUpdate?: () => void;
}

interface State {
  searchFilter: string;
  tabValue: number;
  showAddDevicePopup: boolean;
  loading: boolean;
  selectedDevice?: Device;
  parentGroup?: DeviceGroup;
  errorMsg?: string;
}

export default class DeviceManagement extends Component<Props, State> {

  public constructor(props: Props) {
    super(props);
    this.state = {
      searchFilter: "",
      tabValue: 0,
      showAddDevicePopup: false,
      loading: false,
    };
  }

  public async componentDidUpdate(): Promise<void> {
    const selectedDevice = DeviceNavigationCache.getInstance().getSelectedDevice();

    if (selectedDevice?.getId() !== this.state.selectedDevice?.getId()) {
      this.setState({ selectedDevice });
    }
  }

  private onSearchTriggered(searchString: string): void {
    console.log("onSearchTriggered: " + searchString);
    this.setState({ searchFilter: searchString });
  }

  private handleClosePopup = (): void => {
    // TODO: If popups needed in sequence, then split this
    this.setState({
      showAddDevicePopup: false,
      parentGroup: undefined,
      errorMsg: undefined,
    });
  };

  private handleAddDeviceIconClick = (): void => {
    this.setState({
      parentGroup: this.props.groups?.[0],
      showAddDevicePopup: true,
    });
  };

  private renderAddDevicePopup(): Maybe<JSX.Element> {
    if (this.state.parentGroup) {
      return (
        <AddDevicePopup
          open={this.state.showAddDevicePopup}
          targetGroup={this.state.parentGroup}
          onClose={(success): void => {
            this.handleClosePopup();

            if (success) {
              this.props.onGroupsUpdate?.();
            }
          }}
        />
      );
    }
  }

  private renderPopups(): Maybe<JSX.Element> {
    if (this.state.errorMsg) {
      return (
        <ErrorDialog
          errorMsg={this.state.errorMsg}
          onClose={this.handleClosePopup}
        />
      );
    } else {
      return this.renderAddDevicePopup();
    }
  }

  private renderTreeBrowserMode(): JSX.Element {
    return (
      <Fragment>
        <SearchBar
          searchString={this.state.searchFilter}
          onSearchTriggered={(searchString: string): void => this.onSearchTriggered(searchString)}
          className="admin-search-bar-container"
        />
        <DeviceTree
          groups={this.props.groups}
          searchFilter={getCaseInsensitiveSearchFilter(this.state.searchFilter)}
          editMode={true}
          showEventsMode
          data-testid="devices-tree"
        />
      </Fragment>
    );
  }

  private renderDeviceBrowserMode(): JSX.Element {
    if (this.state.loading) {
      return <Loader />;
    } else {
      return this.renderTreeBrowserMode();
    }
  }

  private renderAddDeviceButton(): Maybe<JSX.Element> {
    if (this.props.groups && !this.state.loading) {
      return (
        <IconButton
          title={translations.admin.buttons.addDevice()}
          onClick={this.handleAddDeviceIconClick}
          style={{ backgroundColor: "#e6e6e6", marginLeft: "3rem", marginTop: "1rem" }}
          size="small"
        >
          <AddIcon />
        </IconButton>
      );
    }
  }

  public render(): JSX.Element {
    return (
      <Fragment>
        <Table className="org-groups-table">
          <TableHead>
            <TableRow>
              <TableCell>{translations.admin.texts.groups()} / {translations.admin.texts.devices()}</TableCell>
            </TableRow>
          </TableHead>
        </Table>
        {this.renderDeviceBrowserMode()}
        {this.renderAddDeviceButton()}
        {this.renderPopups()}
      </Fragment>
    );
  }
}

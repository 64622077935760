/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import React, { Component, Fragment } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import Device from "../../data/device/Device";
import CircularIndicator from "./components/circular-indicator";
import StatusAlarmChart from "./components/status-alarm-chart";
import PowerCompanySelector from "../ui/power-company-selector";
import DeviceGroup from "../../data/device/DeviceGroup";
import StatusMapUtu from "./components/status-map-utu";
import { MapLocationWithDeviceProperties } from "../../types/utu-types";
import { getMapLocation } from "../../data/utils/Utils";

interface Props extends RouteComponentProps {
  groups: DeviceGroup[];
  devices?: Device[];
}

interface State {
  devices?: Device[];
  filteredMapData: MapLocationWithDeviceProperties[];
  isLoading: boolean;
  onlyAlarmsActivated: boolean;
  mapData?: MapLocationWithDeviceProperties[];
}

class StatusView extends Component<Props, State> {

  public constructor(props: Props) {
    super(props);
    this.state = {
      filteredMapData: [],
      isLoading: false,
      onlyAlarmsActivated: false,
    };
  }

  public async componentDidMount(): Promise<void> {
    const { devices } = this.props;

    if (devices) {
      await this.fetchMapdata();
      this.setState({ devices });
      this.filterData();
    }
  }

  public async componentDidUpdate(prevProps: Props): Promise<void> {
    // this.props.devices is undefined in mount phase during first render of Body -> assign devices to state here
    if (!this.state.devices && (!prevProps.devices && this.props.devices)) {
      this.setState({ devices: this.props.devices });
    }

    // fetch and filter data when devices has been assigned to state in the above if-block
    if ((this.props.devices?.length ?? 0) > 0 && this.props.devices!.length !== prevProps.devices?.length) {
      await this.fetchMapdata();
      this.filterData();
    }
  }

  private async fetchMapdata(): Promise<void> {
    const mapData: MapLocationWithDeviceProperties[] = [];
    // assertion: method is never executed if this.props.devices === undefined
    await Promise.all(this.props.devices!.map(async(device) => {
      const mapLocation = await getMapLocation(device);

      if (mapLocation) {
        mapData.push({ ...mapLocation, device });
      }
    }));
    this.setState({ mapData });
  }

  private getAcceptedDevices(acceptedIds: string[]): Device[] {
    if (this.props.devices) {
      const acceptedDevices = this.props.devices.filter(device => {
        if (acceptedIds) {
          if (acceptedIds.includes(device.getId())) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      });
      return acceptedDevices;
    } else {
      return [];
    }
  }

  private onDeviceSelectionsChanged = (deviceIds: string[]): void => {
    console.log(`onDeviceSelectionsChanged ${deviceIds}`);
    this.setState({ devices: this.getAcceptedDevices(deviceIds) });
    this.filterData();
  };

  private onAlarmsOnlyCheckedChanged = (checked: boolean): void => {
    console.log(`onAlarmsOnlyCheckedChanged ${checked}`);

    if (this.state.onlyAlarmsActivated !== checked) {
      this.setState({ onlyAlarmsActivated: checked });
    }
    this.filterData();
  };

  private filterData(): void {
    const { devices, mapData } = this.state;

    if (devices && mapData) {
      const acceptedIds = devices.map(device => device.getId());
      const mapDataList = (mapData ?? []).slice();
      const filteredMapData = mapDataList.filter(({ device }) => acceptedIds.includes(device.getId()));
      console.log(`filterData: filtered ${mapDataList.length} down to ${filteredMapData.length} items`);
      this.setState({ filteredMapData });
    }
  }

  private renderMap(): JSX.Element {
    return (
      <div className="status-map-container">
        <StatusMapUtu
          markerData={this.state.filteredMapData}
          showOnlyEvents={this.state.onlyAlarmsActivated}
        />
      </div>
    );
  }

  public render(): JSX.Element {
    return (
      <Fragment>
        <div className="toolbar-container">
          <PowerCompanySelector
            checkboxLabel="Vain hälytykset"
            onDeviceSelectionsChanged={this.onDeviceSelectionsChanged}
            onEventsOnlyCheckedChanged={this.onAlarmsOnlyCheckedChanged}
            groups={this.props.groups}
          />
        </div>
        <div className="status-container">
          <div className="left-column-container">
            <CircularIndicator devices={this.props.devices!} />
            <div className="chart-container">
              <StatusAlarmChart devices={this.props.devices} />
            </div>
            <div className="background-container" />
          </div>
          <div className="right-column-container">
            {this.renderMap()}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(StatusView);

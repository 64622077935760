/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { Maybe } from "../../../types/aliases";
import { AbstractSubscriptionManager } from "./AbstractSubscriptionManager";

export default abstract class AbstractSetSubscriptionManager<TListener, TSubscription> extends AbstractSubscriptionManager<TListener, TSubscription> {
  private listenerSet = new Set<TListener>();

  public addListener(listener: TListener): void {
    if (!this.listenerSet.has(listener)) {
      this.listenerSet.add(listener);
      this.subscribeOnce();
    }
  }
  public removeListener(listener: TListener): void {
    this.listenerSet.delete(listener);

    if (this.listenerSet.size === 0) {
      this.unsubscribe();
    }
  }
  /**
   * Handle subscription result.
   * Call {@link getListener} to convert id in {@code result} into a listener object
   * @param result
   * @protected
   */
  protected abstract subscriptionHandler(result: Maybe<TSubscription>): void;

  protected forEachListener(method: (listener: TListener) => void): void {
    this.listenerSet.forEach(method);
  }
}

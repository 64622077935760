/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { Dialog, Tab, Tabs } from "@material-ui/core";
import React, { Component, ReactNode } from "react";
import Device, { DeviceObserver } from "../../data/device/Device";
import { getDisplayName } from "../../data/utils/Utils";
import TabPanel from "../ui/tab-panel";
import SettingsPageGeneral from "./components/settings-page-general";
import SettingsPageOta from "./components/settings-page-ota";
import SettingsPageAttributes from "./components/settings-page-attributes";
import { Nullable } from "../../types/aliases";
import DeviceState from "../../data/device/DeviceState";
import { DeviceStateProperties } from "../../data/device/DeviceStateProperties";
import { translations } from "../../generated/translationHelper";

interface Props {
  selectedDevice: Device;
  closeSettings: () => void;
}

interface State {
  deviceState: Nullable<DeviceState<DeviceStateProperties>>;
  settingsPage: number;
}

export default class DeviceSettingsPopup extends Component<Props, State> implements DeviceObserver {

  public constructor(props: Props) {
    super(props);
    this.state = {
      deviceState: this.props.selectedDevice.getState(),
      settingsPage: 0,
    };
  }

  public componentDidMount(): void {
    this.props.selectedDevice.addObserver(this);
  }

  public componentWillUnmount(): void {
    this.props.selectedDevice.removeObserver(this);
  }

  public onDeviceStateUpdated(device: Device): void {
    const deviceState = device.getState();
    this.setState({
      deviceState: deviceState,
    });
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  private handlePageChange = (event: React.ChangeEvent<{}>, value: number): void => {
    console.log(`Changing page from ${this.state.settingsPage} to ${value}`);
    this.setState({ settingsPage: value });
  };

  private renderGeneralPage(): ReactNode {
    if (this.state.deviceState) {
      return (
        <SettingsPageGeneral
          deviceState={this.state.deviceState}
          deviceType={this.props.selectedDevice.getType()}
          closeSettings={this.props.closeSettings}
        />
      );
    }
  }

  private renderOtaPage(): ReactNode {
    if (this.state.deviceState) {
      return (
        <SettingsPageOta
          deviceState={this.state.deviceState}
          onCloseSettings={this.props.closeSettings}
        />
      );
    }
  }

  public render(): JSX.Element {
    return (
      <Dialog
        maxWidth={"md"}
        onClose={this.props.closeSettings}
        open={true}
      >
        <div className="popup-inner">
          <div className="popup-header">
            <div className="popup-header-device-name">
              <img className="device-icon" alt="Device type" src={this.props.selectedDevice.getIcon()} />
              <span>{getDisplayName(this.props.selectedDevice)}</span>
            </div>
            <div className="popup-header-device-type">
              {this.props.selectedDevice.getType()}
            </div>
            <div className="popup-header-secondary-info">
              {this.props.selectedDevice.getId()}
            </div>
          </div>

          <div className="popup-body">
            <Tabs
              value={this.state.settingsPage}
              onChange={this.handlePageChange}
              centered={true}
            >
              <Tab label={translations.deviceSettings.tabs.general()} />
              <Tab label={translations.deviceSettings.tabs.attributes()} />
              <Tab label={translations.deviceSettings.tabs.otaUpdate()} />
            </Tabs>
            <TabPanel
              value={this.state.settingsPage}
              index={0}
              retainChildrenWhenInvisible={true}
            >
              {this.renderGeneralPage()}
            </TabPanel>
            <TabPanel
              value={this.state.settingsPage}
              index={1}
              retainChildrenWhenInvisible={true}
            >
              <SettingsPageAttributes
                selectedDevice={this.props.selectedDevice}
                onCloseSettings={this.props.closeSettings}
              />
            </TabPanel>
            <TabPanel
              value={this.state.settingsPage}
              index={2}
              retainChildrenWhenInvisible={true}
            >
              {this.renderOtaPage()}
            </TabPanel>
          </div>
        </div>
      </Dialog>
    );
  }
}

/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import BackendFactory from "../../../data/BackendFactory";
import React, { Component, Fragment, ReactNode } from "react";
import FormDialog from "../../ui/form-dialog";
import { FormControl, TextField } from "@material-ui/core";
import DeviceGroup from "../../../data/device/DeviceGroup";
import ErrorDialog from "../../ui/error-dialog";
import Loader from "../../ui/loader";
import { translations } from "../../../generated/translationHelper";

interface Props {
  parentGroup: DeviceGroup;
  open: boolean;
  // success: device group was created and added
  onClose: (success: boolean) => void;
}

interface State {
  loading: boolean;
  name: string;
  errorMessage?: string;
}

export default class AddGroupPopup extends Component<Props, State> {

  public constructor(props: Props) {
    super(props);
    this.state = {
      name: "",
      loading: false,
    };
  }

  private createNewGroup = async (): Promise<void> => {
    if (!this.formIsValid()) {
      throw new Error("Invalid control flow");
    }

    this.setState({ loading: true });

    try {
      await BackendFactory.getBackend().createDeviceGroup({
        displayName: this.state.name!,
        parentGroup: this.props.parentGroup,
      });
      this.props.onClose(true);
    } catch (error) {
      console.error("Failed to create group", error);
      this.setState({
        errorMessage: translations.admin.texts.failedToAddGroup(),
      });
      this.props.onClose(false);
    } finally {
      this.setState({ loading: false });
    }
  };

  private formIsValid(): boolean {
    return !!this.state.name && this.state.name.length > 3;
  }

  public render(): ReactNode {
    return (
      <Fragment>
        <ErrorDialog
          onClose={(): void => {
            this.setState({ errorMessage: undefined });
            this.props.onClose(false);
          }}
          errorMsg={this.state.errorMessage}
        />
        <FormDialog
          title={translations.admin.texts.addNewGroup()}
          acceptButtonText={translations.common.buttons.add()}
          isOpen={this.props.open}
          onAccept={this.createNewGroup}
          onCancel={(): void => this.props.onClose(false)}
          disableAccept={!this.formIsValid()}
          maxWidth={"xs"}
        >
          <FormControl component="fieldset" margin="normal" fullWidth>
            <TextField
              variant="outlined"
              value={this.state.name}
              label={translations.admin.texts.groupName()}
              onChange={(event): void =>
                this.setState({ name: event.currentTarget.value })}
            />
          </FormControl>
          <Loader show={this.state.loading}/>
        </FormDialog>
      </Fragment>
    );
  }
}

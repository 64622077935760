/* Copyright */
import React, { Component } from "react";
import { TextField, MenuItem, Box } from "@material-ui/core";
import { Nullable } from "../../../../types/aliases";

interface Props {
  currentValue: string | number;
  label: string;
  updateValue: (value: string) => void;
  values: Nullable<string[]>;
  disabled?: boolean;
  error?: boolean;
}

export default class TriggerPropertySelector extends Component<Props> {

  public render(): JSX.Element {
    return (
      <Box mb="1rem" width="100%">
        <TextField
          error={this.props.error}
          value={this.props.currentValue}
          variant="outlined"
          label={this.props.label}
          select={this.props.values !== null}
          size="small"
          fullWidth={true}
          disabled={this.props.disabled === true}
          onChange={({ target }): void => this.props.updateValue(target.value)}
        >
          {this.props.values !== null
            ?
            this.props.values.map(value => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))
            : null}
        </TextField>
      </Box>
    );
  }
}

/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Paths from "../../Paths";
import ViewAccessMethods from "../../../ViewAccessMethods";
import accessControlled from "../../access-control/access-controlled";
import { translations } from "../../../generated/translationHelper";

interface Props {
  firstName?: string;
  logOut: () => Promise<void>;
  open: boolean;
  onNavLinkClick: () => void;
  height?: string;
}

interface State {
  height: string;
}

const AdminContent = accessControlled(React.Fragment, ViewAccessMethods.hasAdminAccess);

const DEFAULT_HEIGHT = "140px";

class UserDropdownMenu extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      height: DEFAULT_HEIGHT,
    };
  }

  public async componentDidMount(): Promise<void> {
    await this.setUserMenuHeight();
  }

  // UserDropdownMenu is opened and closed with a transition of height from 0% to 50% which matches nicely
  // the transition from opacity 0 to default opacity value. Therefore UserDropdownMenu needs its max-height set to a fixed value
  // and if the user is not an admin the height of the menu needs to be less than default value because the "Admin" item in menu is not shown
  private async setUserMenuHeight(): Promise<void> {
    const isNotAdmin = !await ViewAccessMethods.hasAdminAccess();

    if (isNotAdmin) {
      this.setState({ height: "100px" });
    }
  }

  public render(): JSX.Element {
    return (
      <div className={this.props.open ? "user-menu" : "user-menu hidden"} style={{ maxHeight: this.state.height }}>
        <ul className="user-menu-list">
          <li className="user-menu-item">
            <NavLink
              to={Paths.USER}
              onClick={(): void => this.props.onNavLinkClick()}
              activeClassName="active"
              className="user-menu-item-link"
              data-testid="user-tab"
            >
              {this.props.firstName ?? translations.common.texts.user()}
            </NavLink>
          </li>
          <AdminContent showLoader={false} showAccessError={false}>
            <li className="user-menu-item">
              <NavLink
                to={Paths.ADMIN}
                onClick={(): void => this.props.onNavLinkClick()}
                activeClassName="active"
                className="user-menu-item-link"
              >
                {translations.common.texts.admin()}
              </NavLink>
            </li>
          </AdminContent>
          <li className="user-menu-item" onClick={this.props.logOut} >
            <div className="user-menu-item-link" style={{ display: "flex", borderLeft: "none", paddingLeft: "24px" }}>
              <span>Kirjaudu ulos</span>
              <div className="exit-icon">
                <ExitToAppIcon style={{ height: "25px", width: "25px" }}/>
              </div>
            </div>
          </li>
        </ul>
      </div>
    );
  }
}

export default UserDropdownMenu;

/* Copyright */
import React, { Component, ReactNode } from "react";
import { Button, TableCell, TableRow, Typography } from "@material-ui/core";
import { EventTriggerDbEntry } from "../../../../data/types/eventTypes";

interface Props {
  eventTrigger: EventTriggerDbEntry;
  onSelectTriggerForDeleting: (triggerId: string) => void;
  onSelectTriggerForEditing: (triggerId: string) => void;
}

export default class EventTriggerTableItem extends Component<Props> {
  
  public render(): ReactNode {
    return (
      <TableRow >
        <TableCell>
          <Typography color="textPrimary">
            {this.props.eventTrigger.rules[0].name ?? "Nimetön hälytys"}
          </Typography>
        </TableCell>
        <TableCell align="right">
          <Button
            onClick={(): void => this.props.onSelectTriggerForDeleting(this.props.eventTrigger.triggerId)}
            variant="contained"
            color="primary"
            style={{ marginRight: "1rem" }}
          >
            Poista
          </Button>
          <Button
            onClick={(): void => this.props.onSelectTriggerForEditing(this.props.eventTrigger.triggerId)}
            variant="contained"
            color="primary"
          >
            Muokkaa
          </Button>
        </TableCell>
      </TableRow>
    );
  }
}

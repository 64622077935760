/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { Auth } from "aws-amplify";
import AWSThingGroup from "../device/AWSThingGroup";
import DeviceGroup from "../device/DeviceGroup";
import { Service } from "./AppSyncClientProvider";
import Backend, { CreateDeviceGroupParams } from "./Backend";
import Device from "../device/Device";
import AppSyncClientFactory from "./AppSyncClientFactory";
import AuthWrapper from "../auth/AuthWrapper";
import { Maybe, Nullable } from "../../types/aliases";
import { throwGQLError } from "../utils/Utils";
import { UrlsQsEmbedGenerateDocument, UrlsQsEmbedGenerateQueryVariables } from "../../generated/gqlStats";
import {
  DeviceFieldsFragment,
  DeviceGroupFieldsFragment,
  DeviceGroupsCreateDocument,
  DeviceGroupsDevicesListDocument,
  DeviceGroupsGetDocument,
  DeviceGroupsListDocument,
  DevicesDeviceGroupsListDocument,
  DevicesGetDocument,
  DevicesSearchDocument,
} from "../../generated/gqlDevice";
import { Attribute } from "../device/Attribute";
import AuthListener, { AuthEvent } from "../auth/AuthListener";
import AsyncCache from "../utils/AsynCache";
import { isDefined } from "../../utils/types";
import { EntityRelationCache, ProvidesEntityRelationCache } from "../utils/EntityRelationCache";
import DeviceFactory from "../device/DeviceFactory";
import AWSThing from "../device/AWSThing";
import DeviceState from "../device/DeviceState";
import Data from "../data/Data";
import { prefixlessId } from "../organization/Utils";

type UnknownAWSThing = AWSThing<Data, DeviceState>;

export function narrowDownAttributeTypes(attrs: Array<{key: string; value?: Nullable<string>}>): Attribute[] {
  return attrs.map(({ key, value }) => ({ key, value: value ?? undefined }));
}

export default class AWSBackend implements Backend, ProvidesEntityRelationCache {
  // TODO: it might be smarter to cache devices to DeviceFactory
  private deviceCache = new AsyncCache<Device>();
  private groupCache = new AsyncCache<DeviceGroup>();
  private rootGroupIds?: string[];

  public readonly entityRelationCache = new EntityRelationCache();

  public constructor(private readonly deviceFactory: DeviceFactory<AWSBackend>) {}

  private authEventHandler = (event: AuthEvent): void => {
    if (event === "SignedOut") {
      this.groupCache.clear();
      this.deviceCache.clear();
      this.entityRelationCache.clear();
      this.rootGroupIds = undefined;
    }
  };

  private readonly authListener = new AuthListener(this.authEventHandler);

  public async getQsEmbedUrl(openIdToken: string, dashboardId: string): Promise<Maybe<string>> {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const variables: UrlsQsEmbedGenerateQueryVariables = {
        request: {
          dashboardId,
          emailAddress: user.username,
          openIdToken,
          sessionName: user.username,
          undoRedoDisabled: true,
          resetDisabled: true,
          sessionLifetimeInMinutes: 600,
        },
      };
      const client = AppSyncClientFactory.createProvider().getTypedClient(Service.STATS);
      const embedUrlResponse = await client.query(UrlsQsEmbedGenerateDocument, variables);
      return embedUrlResponse.data.urlsQsEmbedGenerate?.embedUrl ?? undefined;
    } catch (error) {
      console.error("getQsEmbedUrl: ", error);
    }
  }

  public async getDevice(id: string): Promise<Maybe<Device>> {
    const fetchDevice = async (): Promise<Maybe<Device>> => {
      const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);

      try {
        const response = await client.query(
          DevicesGetDocument,
          {
            deviceId: id,
          },
        );

        if (response.data.devicesGet) {
          return this.deviceFragmentIntoDevice(response.data.devicesGet);
        }
      } catch (err) {
        console.error("Failed to fetch device.", err);
      }
    };

    return this.deviceCache.get(id, fetchDevice);
  }

  public async getDeviceGroup(id: string): Promise<Maybe<DeviceGroup>> {
    const fetchDeviceGroup = async (): Promise<Maybe<DeviceGroup>> => {
      const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);

      const response = await client.query(
        DeviceGroupsGetDocument,
        {
          groupId: id,
        },
      );

      if (response.data.deviceGroupsGet) {
        return this.fragmentIntoDeviceGroup(response.data.deviceGroupsGet);
      }
    };

    return this.groupCache.get(id, fetchDeviceGroup);
  }

  public async findDeviceGroupByName(name: string, organizationId?: string): Promise<Maybe<DeviceGroup>> {
    const orgString = organizationId ? prefixlessId(organizationId) : organizationId;

    const selectByOrganization = (group: DeviceGroup): boolean => {
      if (!orgString) return true;
      const groupOrg = group.getOrganization();
      if (!groupOrg) return true;
      return orgString.startsWith(prefixlessId(groupOrg));
    };

    const roots = await this.getRootDeviceGroups();

    let selected = roots.filter(selectByOrganization);

    while (selected.length > 0) {
      const match = selected.find(group => group.getLabel() === name);
      if (match) return match;
      selected = (await Promise.all(selected.map(group => group.getGroups()))).flat();
    }
  }

  public async getRootDeviceGroups(): Promise<DeviceGroup[]> {
    if (this.rootGroupIds) {
      const groups = await Promise.all(this.rootGroupIds.map(id => this.getDeviceGroup(id)));
      const filteredGroups = groups.filter(isDefined);

      if (groups.length !== filteredGroups.length) {
        console.error("Invalid root group id cache state, adjusting");
        this.rootGroupIds = filteredGroups.map(group => group.getId());
      }
      return filteredGroups;
    }

    const groups = await this.getDeviceGroups();
    this.rootGroupIds = groups.map(group => group.getId());
    return groups;
  }

  public async getAllDeviceGroups(): Promise<DeviceGroup[]> {
    return this.getDeviceGroups({ recursive: true });
  }

  public async getDeviceGroups({ parent, recursive = false }: {parent?: DeviceGroup; recursive?: boolean} = {}): Promise<DeviceGroup[]> {
    try {
      let nextToken: Nullable<string> = null;
      let groupFragments: DeviceGroupFieldsFragment[] = [];

      const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);

      do {
        const groupListResponse = await client.query(
          DeviceGroupsListDocument,
          {
            recursive,
            includeAttributes: true,
            parentGroupId: parent?.getId(),
            nextToken,
          },
        );
        // for some reason, typescript gets trapped into a circular inference hell, if the type of the
        // nextToken is not respecified
        nextToken = (groupListResponse.data?.deviceGroupsList?.nextToken ?? null) as Nullable<string>;
        groupFragments = groupFragments.concat(groupListResponse.data.deviceGroupsList?.deviceGroups ?? []);
      } while (nextToken);

      const groups = await this.cacheFragments(this.groupCache, groupFragments, this.fragmentIntoDeviceGroup);

      if (parent && !recursive) {
        groups.forEach(group => this.entityRelationCache.link(
          parent as AWSThingGroup,
          group as AWSThingGroup,
          { parentId: parent.getId() },
        ));
      } else if (recursive) {
        console.warn("Cannot establish parent-child relationship between device groups in recursive calls");
      }
      return groups;
    } catch (error) {
      console.error("getDeviceGroups", error);
      return [];
    }
  }

  public async createDeviceGroup(params: CreateDeviceGroupParams): Promise<void> {
    if (params.parentGroup && !AWSThingGroup.instanceOf(params.parentGroup)) {
      throw new Error("Invalid DeviceGroup implementation for parent group");
    }

    const org: string = params.organizationId ?? await AWSBackend.getOrganization(params.parentGroup);
    const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
    const groupResponse = await client.mutate(
      DeviceGroupsCreateDocument,
      {
        groupId: params.displayName,
        parentGroupId: params.parentGroup?.getId() ?? null,
        organizationId: org,
      },
    );

    if (!groupResponse.data?.deviceGroupsCreate) {
      console.error("Failed to create group, backend response empty: " + JSON.stringify(groupResponse.errors));
      throwGQLError(groupResponse);
    }

    const newGroup = await this.fragmentIntoDeviceGroup(groupResponse.data.deviceGroupsCreate);

    this.groupCache.set(newGroup.getId(), newGroup);

    if (!params.parentGroup) {
      this.rootGroupIds = (this.rootGroupIds ?? []).concat(newGroup.getId());
    } else {
      this.entityRelationCache.link(params.parentGroup, newGroup, { parentId: params.parentGroup.getId() });
    }
  }

  public async searchDevices(query: string): Promise<Device[]> {
    // TODO: should we have query-specific cache?
    try {
      const deviceFragments: DeviceFieldsFragment[] = [];
      let nextToken: Nullable<string> = null;

      const appSyncClient = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);

      do {
        const searchDevicesResponse = await appSyncClient.query(
          DevicesSearchDocument,
          {
            query,
            nextToken,
          },
        );
        nextToken = (searchDevicesResponse.data?.devicesSearch?.nextToken ?? null) as Nullable<string>;
        deviceFragments.push(...(searchDevicesResponse.data?.devicesSearch?.devices ?? []));
      } while (nextToken);

      return this.cacheFragments(this.deviceCache, deviceFragments, this.deviceFragmentIntoDevice);
    } catch (error) {
      console.error("searchDevices", error);
      return [];
    }
  }

  /////
  /// AWSBackend specific public methods
  /////

  public async getDeviceGroupDevices(group: AWSThingGroup): Promise<Device[]> {
    try {
      const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
      let nextToken: Nullable<string> = null;
      const deviceFragments: DeviceFieldsFragment[] = [];

      do {
        const deviceIdListResponse = await client.query(
          DeviceGroupsDevicesListDocument,
          {
            groupId: group.getId(),
            nextToken,
          },
        );
        // cast is required to avoid cyclic type inference on response type
        nextToken = (deviceIdListResponse.data.deviceGroupsDevicesList?.nextToken ?? null) as Nullable<string>;
        deviceFragments.push(...(deviceIdListResponse.data.deviceGroupsDevicesList?.devices ?? []));
      } while (nextToken);

      // no need to generate group<->device links here, since the deviceFragmentIntoDevice call already generates the links
      const devices = await this.cacheFragments(this.deviceCache, deviceFragments, this.deviceFragmentIntoDevice);
      devices.forEach(device => this.entityRelationCache.link(device as UnknownAWSThing, group));

      return devices;
    } catch (error) {
      console.error("getDeviceGroupDevices", error);
      return [];
    }
  }

  public async linkDeviceGroupsForDevice<TDevice extends UnknownAWSThing>(device: TDevice): Promise<void> {
    const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
    let nextToken: Nullable<string> = null;
    const groupFragments: DeviceGroupFieldsFragment[] = [];

    try {
      do {
        const response = await client.query(
          DevicesDeviceGroupsListDocument,
          {
            deviceId: device.getId(),
            nextToken,
          });

        nextToken = (response.data?.devicesDeviceGroupsList?.nextToken ?? null) as Nullable<string>;

        groupFragments.push(...(response.data?.devicesDeviceGroupsList?.deviceGroups ?? []));
      } while (nextToken);

      const groups = await this.cacheFragments(this.groupCache, groupFragments, this.fragmentIntoDeviceGroup) as AWSThingGroup[];
      groups.forEach(group => this.entityRelationCache.link(device, group));
    } catch (error) {
      console.error("linkDeviceGroupsForDevice", error);
    }
  }

  public async removeLocal(thing: UnknownAWSThing | AWSThingGroup): Promise<void> {
    if (Device.instanceOf(thing)) {
      await this.deviceCache.delete(thing.getId());
    } else if (DeviceGroup.instanceOf(DeviceGroup)) {
      await this.groupCache.delete(thing.getId());
    }
    this.entityRelationCache.remove(thing);
  }

  /**
   * Takes a collection of fragments, which are either
   * - if id matches something in cache, replaced with the cached entity
   * - converted into the desired entity, and then cached
   *
   * @param cache
   *    an AsyncCache into which to store the converted fragments
   * @param fragments
   *    list of fragments to go through
   * @param fragmentConverter
   *    method for converting fragment into the desired entity type
   * @private
   */
  private async cacheFragments<TFrag extends { id: string }, TType>(cache: AsyncCache<TType>, fragments: TFrag[], fragmentConverter: (f: TFrag) => Promise<Maybe<TType>>): Promise<TType[]>{
    const results = await Promise.all(
      fragments.map(fragment => cache.get(fragment.id, () => fragmentConverter(fragment))),
    );
    return results.filter(isDefined);
  }

  private fragmentIntoDeviceGroup = async (fragment: DeviceGroupFieldsFragment): Promise<AWSThingGroup> => {
    return new AWSThingGroup(
      this,
      {
        groupId: fragment.id,
        attributes: narrowDownAttributeTypes(fragment.attr),
      });
  };

  private deviceFragmentIntoDevice = async (fragment: DeviceFieldsFragment): Promise<Maybe<Device>> => {
    const device = this.deviceFactory.createDevice(
      this,
      fragment.type,
      {
        deviceId: fragment.id,
        attributes: narrowDownAttributeTypes(fragment.attr),
      },
    );

    if (device) {
      // While this is pretty bad for performance, it was already done everywhere anyways.
      // It should actually probably be part of the factory instead.
      await device.init();
      return device;
    }
  };

  private static async getOrganization(group?: DeviceGroup): Promise<string> {
    let org = group ? group.getOrganization() : undefined;

    if (!org || org.length === 0) {
      const claims = await AuthWrapper.getCurrentAuthenticatedUserClaims();
      org = claims?.homeOrganizationId;
    }

    if (!org) {
      throw new Error("No organization available");
    }

    return org;
  }
}

/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import SuperIcon from "../../../assets/iot-item-24px.svg";
import { ReferenceHW } from "../ReferenceHW/ReferenceHW";
import { SuperHWState } from "./SuperHWState";
import { SuperHWStateProperties } from "./SuperHWStateProperties";
import { SuperHWData } from "./SuperHWData";
import AWSBackend from "../../../data/backend/AWSBackend";
import { DeviceParameters } from "../../../data/device/Device";

export class SuperHW extends ReferenceHW<SuperHWData, SuperHWState> {
  public static type = "Super";

  public constructor(backend: AWSBackend, params: DeviceParameters) {
    super(SuperHW.type, backend, params);
  }

  public getIcon(): string {
    return SuperIcon;
  }

  public createState(timestamp?: number, reported?: Partial<SuperHWStateProperties>, desired?: Partial<SuperHWStateProperties>):
  SuperHWState {
    return new SuperHWState(this.getId(), new SuperHWStateProperties(reported ?? {}), new SuperHWStateProperties(desired ?? {}), timestamp);
  }

  public toggleLed(): void {
    if (this.state) {
      this.state.ledEnabled = !this.state.ledEnabled;
    }
  }
}

/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { Service } from "../backend/AppSyncClientProvider";
import Event, { eventIdentitiesMatch } from "../clientSpecific/Event";
import EventSet, { EventObserver } from "./EventSet";
import EventsSubscriptionManager from "./EventsSubscriptionManager";
import AppSyncClientFactory from "../backend/AppSyncClientFactory";
import { Nullable } from "../../types/aliases";
import { DevicesEventsListDocument, EventsDeactivateDocument, EventState } from "../../generated/gqlEvents";
import { isDefined } from "../../utils/types";
import TimePeriod from "../utils/TimePeriod";
import { toNumber } from "../../utils/functions";

export default class AWSEventSet extends EventSet {
  private readonly deviceId: string;
  private readonly period: TimePeriod;
  private events: Event[];

  public constructor(deviceId: string, startTimestamp: number, endTimestamp: number) {
    super();
    this.deviceId = deviceId;
    this.period = { startTimestamp, endTimestamp };
    this.events = [];
  }

  public async fetch(): Promise<void> {
    let nextToken: Nullable<string> = null;
    let events: Event[] = [];

    try {
      do {
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.EVENTS);
        const deviceEventsResponse = await client.query(
          DevicesEventsListDocument,
          {
            deviceId: this.deviceId,
            period: { endTimestamp: this.period.endTimestamp.toString(), startTimestamp: this.period.startTimestamp.toString() },
            nextToken,
          },
        );
        // cast is required or response's type inference goes into a loop
        nextToken = (deviceEventsResponse.data.devicesEventsList?.nextToken ?? null) as Nullable<string>;
        events = events.concat(deviceEventsResponse.data.devicesEventsList?.events?.filter(isDefined) ?? []);
      } while (nextToken);
      this.events = events.sort(EventSet.eventOrdering);
    } catch (error) {
      console.error("Error", error);
      this.events = [];
    }
  }

  public getId(): string {
    return this.deviceId;
  }

  public getData(): Event[] {
    return [...this.events];
  }

  public getTimePeriod(): TimePeriod {
    return this.period;
  }

  public addOrUpdateEvent(event: Event): void {
    const matchIndex = this.events.findIndex((e: Event) => eventIdentitiesMatch(event, e));

    if (matchIndex >= 0) {
      this.events[matchIndex] = event;
    } else {
      this.addEvent(event);
    }
    this.notifyAction(observer => observer.onEventSetUpdate(this));
  }

  public async deactivateEvent(event: Event): Promise<void> {
    // Set inactive for fast UI update. State will recover via subs if deactivation fails in cloud side
    event.eventState = EventState.Inactive;
    const eventPayload = {
      deviceId: event.deviceId,
      timestamp: event.timestamp,
    };

    try {
      const client = AppSyncClientFactory.createProvider().getTypedClient(Service.EVENTS);
      await client.mutate(
        EventsDeactivateDocument,
        {
          payload: eventPayload,
        },
      );
    } catch (error) {
      console.log(error);
    }
  }

  public async addObserver(observer: EventObserver): Promise<void> {
    if (!this.events) {
      await this.fetch();
    }
    super.addObserver(observer);
    EventsSubscriptionManager.instance.addListener(this);
  }

  public removeObserver(observer: EventObserver): void {
    super.removeObserver(observer);
    EventsSubscriptionManager.instance.removeListener(this);
  }

  private addEvent(event: Event): void {
    this.events.push(event);
    this.events.sort(EventSet.eventOrdering);
    const timestamp = toNumber(event.timestamp);

    if (timestamp && timestamp > this.period.endTimestamp) {
      this.period.endTimestamp = timestamp;
    }
  }
}

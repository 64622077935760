/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { Service } from "../backend/AppSyncClientProvider";
import Data, { DataUtil } from "./Data";
import DataSet, { DataObserver } from "./DataSet";
import DataSubscriptionManager from "./DataSubscriptionManager";
import AppSyncClientFactory from "../backend/AppSyncClientFactory";
import { Nullable } from "../../types/aliases";
import { isDefined } from "../../utils/types";
import { DevicesMeasurementsListTsDocument } from "../../generated/gqlData";
import BackendFactory from "../BackendFactory";
import { stateIsHyper } from "../utils/Utils";
import TimePeriod from "../utils/TimePeriod";

export default class AWSDataSet<TData extends Data> extends DataSet<TData> {
  protected data: TData[] = [];

  public constructor(
      private readonly deviceId: string,
      protected type: string,
      private startTimestamp: number,
      private endTimestamp: number,
  ) {
    super();
  }

  public async fetch(): Promise<void> {
    let nextToken: Nullable<string> = null;
    let data: TData[] = [];
    const device = await BackendFactory.getBackend().getDevice(this.deviceId);
    // assertion: fetch is used only when a device is selected
    const state = device!.getState();

    if (state !== null && stateIsHyper(state)) {
      const measurements = state.getMeasurementInfo();

      if (measurements) {
        const interval = Math.floor((this.endTimestamp / 1000 - this.startTimestamp / 1000) / 300);

        try {
          do {
            const appSyncClient = AppSyncClientFactory.createProvider().getTypedClient(Service.DATA);
            const dataResponse = await appSyncClient.query(
              DevicesMeasurementsListTsDocument,
              {
                deviceId: this.deviceId,
                startTimestamp: this.startTimestamp.toString(),
                endTimestamp: this.endTimestamp.toString(),
                interval,
                measurements,
                nextToken,
              },
            );
            // cast is required or response encounters a cyclic type inference
            nextToken = (dataResponse.data.devicesMeasurementsListTS?.nextToken ?? null) as Nullable<string>;
            const dataItems: TData[] = (dataResponse.data.devicesMeasurementsListTS?.measurementItems ?? [])
              .map((item) => DataUtil.parseDataFragment<TData>(item, this.type))
              .filter(isDefined);
            data = data.concat(dataItems);
          } while (nextToken);
          this.setData(data);
        } catch (error) {
          console.error("Error", error);
        }
      }
    }
  }

  public getId(): string {
    return this.deviceId;
  }

  public getData(): TData[] {
    return this.data;
  }

  public getType(): string {
    return this.type;
  }

  public getTimePeriod(): TimePeriod {
    return { startTimestamp: this.startTimestamp, endTimestamp: this.endTimestamp };
  }

  public setData(data: TData[]): void {
    const newData = [...data];
    newData.sort(this.timestampOrder);
    this.data = newData;
  }

  public appendData(data: TData): void {
    this.data.push(data);
    this.data.sort(this.timestampOrder);

    if (data.timestamp > this.endTimestamp) {
      this.endTimestamp = data.timestamp;
    }
    this.notifyAction(observer => observer.onDataUpdate(this));
  }


  public async addObserver(observer: DataObserver): Promise<void> {
    if (!this.data) {
      await this.fetch();
    }
    super.addObserver(observer);

    if (this.observerCount === 1) {
      DataSubscriptionManager.instance.addListener(this);
    }
  }

  public removeObserver(observer: DataObserver): void {
    super.removeObserver(observer);

    if (this.observerCount === 0) {
      DataSubscriptionManager.instance.removeListener(this);
    }
  }
  private readonly timestampOrder = (a: TData, b: TData): number => a.timestamp - b.timestamp;
}

/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { Grid, List, Switch, TextField } from "@material-ui/core";
import React, { Component } from "react";
import { ColorResult } from "react-color";
import CompactPicker from "react-color/lib/components/compact/Compact";
import { SuperHW } from "../../../../client/devices/SuperHW/SuperHW";
import Loader from "../../../ui/loader";
import SettingsListItem from "../settings-list-item";
import SettingsControls from "./settings-controls";
import { Maybe, Nullable } from "../../../../types/aliases";
import DeviceState from "../../../../data/device/DeviceState";
import { DeviceStateProperties } from "../../../../data/device/DeviceStateProperties";
import { HyperHW } from "../../../../client/devices/HyperHW/HyperHW";
import { parseAddOnConf, getDeviceStateAsHyper, getDeviceStateAsSuper, stateIsHyper, stateIsSuper } from "../../../../data/utils/Utils";
import { HyperHWState, HyperIntervalTypes } from "../../../../client/devices/HyperHW/HyperHWState";
import { MeasurementBoardConfig } from "../../../../types/utu-types";
import { translations } from "../../../../generated/translationHelper";

interface Props {
  deviceType: string;
  deviceState: DeviceState<DeviceStateProperties>;
  closeSettings: () => void;
}

interface State {
  changesMade: boolean;
  displayColorPicker: boolean;
  ledEnabled: boolean;
  ledColor: string;
  displayName: string;
  noModemSleep: boolean;
  updateInterval: number;
  measurementInterval: number;
  firmwareVersion: string;
  resetCount: number;
  addOnConf: Maybe<MeasurementBoardConfig>;
}

type Color = { r: number; g: number; b: number; a: number };

export default class SettingsPageGeneral extends Component<Props, State> {

  public constructor(props: Props) {
    super(props);

    const hyperState = getDeviceStateAsHyper(this.props.deviceState);
    const superState = getDeviceStateAsSuper(this.props.deviceState);

    this.state = {
      changesMade: false,
      displayColorPicker: false,
      ledEnabled: superState?.ledEnabled || false,
      ledColor: superState?.ledColor || "#000000",
      displayName: this.props.deviceState.displayName || "",
      noModemSleep: hyperState?.noModemSleep || false,
      updateInterval: HyperHWState.toValidInterval(hyperState?.updateInterval, HyperIntervalTypes.UPDATE),
      measurementInterval: HyperHWState.toValidInterval(hyperState?.measurementInterval, HyperIntervalTypes.MEASURE),
      firmwareVersion: superState?.firmwareVersion || "N/A",
      resetCount: superState?.resetCount || 0,
      addOnConf: hyperState?.addOnConf ? parseAddOnConf(hyperState.addOnConf) : undefined,
    };
  }

  private handleCancel = (): void => {
    this.props.deviceState.revert();
    this.props.closeSettings();
  };

  private saveDeviceSettings = async (): Promise<void> => {
    this.setState({ changesMade: false });
    await this.props.deviceState.store();
  };

  private toggleColorPicker = (): void => {
    this.setState((prevState: State) => ({ displayColorPicker: !prevState.displayColorPicker }));
  };

  private ledStateChanged = (): void => {
    if (stateIsSuper(this.props.deviceState) && this.props.deviceState.ledEnabled !== null) {
      this.props.deviceState.ledEnabled = !this.props.deviceState.ledEnabled;
      this.setState({ ledEnabled: this.props.deviceState.ledEnabled, changesMade: true });
    }
  };

  private ledColorChanged = (color: ColorResult): void => {
    if (stateIsSuper(this.props.deviceState) && this.props.deviceState.ledColor !== null) {
      this.props.deviceState.ledColor = color.hex;
      this.setState({ ledColor: color.hex, changesMade: true });
    }
  };

  private displayNameChanged = (event: React.FormEvent<HTMLInputElement>): void => {
    const newName = event.currentTarget.value;

    if (this.props.deviceState.displayName !== null) {
      this.props.deviceState.displayName = newName;
      this.setState({ displayName: newName, changesMade: true });
    }
  };

  private noModemSleepChanged = (): void => {
    if (stateIsHyper(this.props.deviceState) && getDeviceStateAsHyper(this.props.deviceState).noModemSleep != null) {
      const enabled = !getDeviceStateAsHyper(this.props.deviceState).noModemSleep;
      getDeviceStateAsHyper(this.props.deviceState).noModemSleep = enabled;
      this.setState({ noModemSleep: enabled, changesMade: true });
    }
  };

  private updateIntervalChanged = (event: React.FormEvent<HTMLInputElement>): void => {
    const newValue = Number(event.currentTarget.value);

    if (stateIsHyper(this.props.deviceState)
      && getDeviceStateAsHyper(this.props.deviceState).updateInterval !== null) {
      getDeviceStateAsHyper(this.props.deviceState).updateInterval = newValue;
      this.setState({ updateInterval: newValue, changesMade: true });
    }
  };

  private measurementIntervalChanged = (event: React.FormEvent<HTMLInputElement>): void => {
    const newValue = Number(event.currentTarget.value);

    if (stateIsHyper(this.props.deviceState)
      && getDeviceStateAsHyper(this.props.deviceState).measurementInterval !== null) {
      getDeviceStateAsHyper(this.props.deviceState).measurementInterval = newValue;
      this.setState({ measurementInterval: newValue, changesMade: true });
    }
  };

  private getRgbaLedColor(): Color {
    let color: Color = {
      r: 255,
      g: 255,
      b: 255,
      a: 1,
    };

    if (this.state.ledColor !== null) {
      color = {
        r: parseInt(this.state.ledColor.slice(1, 3), 16),
        g: parseInt(this.state.ledColor.slice(3, 5), 16),
        b: parseInt(this.state.ledColor.slice(5, 7), 16),
        a: 1,
      };
    }
    return color;
  }

  private renderColorPicker(): Maybe<JSX.Element> {
    if (this.state.displayColorPicker) {
      return (
        <div className="popover" onClick={this.toggleColorPicker}>
          <CompactPicker
            color={this.state.ledColor}
            onChange={this.ledColorChanged}
          />
        </div>
      );
    }
  }

  private getLoader(key: string): Maybe<JSX.Element> {
    // TODO: better state handling in settings
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (this.props.deviceState.beingApplied(key as any)) {
      return (
        <Loader
          size="small"
          topBottomPadding="0"
          leftRightPadding="0.2rem"
        />
      );
    }
  }

  public render(): Nullable<JSX.Element> {
    if (!this.props.deviceState) {
      console.error("Settings general page does not have device state");
      return null;
    }
    const color = this.getRgbaLedColor();
    const isSuper = this.props.deviceType === SuperHW.type;
    const showModemControls = this.props.deviceType === HyperHW.type;
    return (
      <Grid container={true} direction="column" spacing={2}>
        <Grid item={true}>
          <List>
            <SettingsListItem label={translations.common.data.displayName()}>
              <TextField
                value={this.state.displayName}
                onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                  this.displayNameChanged(event)}
              />
              {this.getLoader("displayName")}
            </SettingsListItem>
            {showModemControls && <SettingsListItem label={translations.deviceSettings.texts.disableModemSleep()}>
              <Switch
                edge="end"
                onChange={this.noModemSleepChanged}
                checked={this.state.noModemSleep}
                color="primary"
              />
              {this.getLoader("noModemSleep")}
            </SettingsListItem>}
            {showModemControls && <SettingsListItem label={translations.deviceSettings.texts.updateInterval()}>
              <TextField
                value={this.state.updateInterval}
                inputMode="numeric"
                onChange={(event: React.ChangeEvent<HTMLInputElement>): void => this.updateIntervalChanged(event)}
              />
              {this.getLoader("updateInterval")}
            </SettingsListItem>}
            {showModemControls && <SettingsListItem label={translations.deviceSettings.texts.measurementInterval()}>
              <TextField
                value={this.state.measurementInterval}
                inputMode="numeric"
                onChange={(event: React.ChangeEvent<HTMLInputElement>): void => this.measurementIntervalChanged(event)}
              />
              {this.getLoader("measurementInterval")}
            </SettingsListItem>}
            {isSuper && <SettingsListItem label={translations.deviceSettings.texts.toggleLed()}>
              <Switch
                edge="end"
                onChange={this.ledStateChanged}
                checked={this.state.ledEnabled}
                color="primary"
              />
              {this.getLoader("ledEnabled")}
            </SettingsListItem>}
            {isSuper && <SettingsListItem label={translations.deviceSettings.texts.ledColor()}>
              <div className="swatch" onClick={this.toggleColorPicker}>
                <div
                  className="color"
                  style={{
                    background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
                  }}
                />
              </div>
              {this.getLoader("ledColor")}
            </SettingsListItem>}
            {this.renderColorPicker()}
            <SettingsListItem label={translations.deviceSettings.texts.firmwareVersion()}>
              {this.state.firmwareVersion}
            </SettingsListItem>
            <SettingsListItem label={translations.deviceSettings.texts.resetCount()}>{this.state.resetCount}</SettingsListItem>
          </List>
        </Grid>
        <Grid item={true}>
          <SettingsControls
            changesMade={this.state.changesMade}
            submitButtonLabel={translations.common.buttons.apply()}
            cancelButtonLabel={translations.common.buttons.close()}
            onSave={this.saveDeviceSettings}
            onCancel={this.handleCancel}
          />
        </Grid>
      </Grid>
    );
  }
}

/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import { Maybe } from "../../types/aliases";
import BaseObservable from "../observer/BaseObservable";
import { Attribute } from "./Attribute";
import Device from "./Device";

export interface DeviceGroupParameters {
  groupId: string;
  attributes: Attribute[];
  parentGroupId?: string;
}

export interface DeviceGroupObserver {
  onGroupsChanged?: (groups: DeviceGroup[], parent: DeviceGroup) => void;
  onDevicesChanged?: (devices: Device[], group: DeviceGroup) => void;
  onDelete?: (group: DeviceGroup) => void;
}

export type DeviceId = string;

export default abstract class DeviceGroup extends BaseObservable<DeviceGroupObserver> {
  private readonly id: string;
  private readonly attributes: Attribute[];

  protected constructor(parameters: DeviceGroupParameters) {
    super();
    this.id = parameters.groupId;
    this.attributes = parameters.attributes;
  }

  public getId(): string {
    return this.id;
  }

  public getAttributes(): Attribute[] {
    return this.attributes;
  }

  public abstract getLabel(): string;

  public abstract getOrganization(): Maybe<string>;

  public abstract getGroups(): Promise<DeviceGroup[]>;

  public abstract getDevices(): Promise<Device[]>;

  public abstract addDevice(device: DeviceId | Device): Promise<void>;

  public abstract removeDevice(device: DeviceId | Device): Promise<void>;

  public abstract delete(): Promise<void>;

  public abstract childGroupCanBeAdded(): boolean;

  public abstract canBeDeleted(): boolean;

  public static instanceOf(value: unknown): value is DeviceGroup {
    return value instanceof DeviceGroup;
  }
}

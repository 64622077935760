/* Copyright */
import React from "react";
import { Dialog, TextField, Button, Typography, Box } from "@material-ui/core";
import { Maybe, Nullable } from "../../../types/aliases";
import { EventComment } from "../../ui/events-table";
import Loader from "../../ui/loader";
import { BackendActionStatus } from "../../../types/utu-types";

interface Props {
  backendActionLoading: boolean;
  backendActionStatus: Nullable<BackendActionStatus>;
  comment: Nullable<EventComment>;
  open: boolean;
  onCancel: () => void;
  onSave: (comment: EventComment) => void;
}

interface State {
  commentText: string;
}

class CommentPopup extends React.Component<Props, State> {

  public constructor(props: Props) {
    super(props);
    this.state = {
      commentText: this.props.comment && this.props.comment.commentText ? this.props.comment.commentText : "",
    };
  }

  public componentDidUpdate(prevProps: Props, _prevState: State): void {
    if (this.props.comment && !prevProps.comment) {
      this.setState({
        commentText: this.props.comment.commentText ?? "",
      });
    } else if (!this.props.comment && prevProps.comment) {
      this.setState({
        commentText: "",
      });
    } else if (this.props.comment && prevProps.comment) {
      if (this.props.comment.commentText !== prevProps.comment.commentText) {
        this.setState({
          commentText: this.props.comment.commentText ? this.props.comment.commentText : "",
        });
      }
    }
  }

  private renderEditBody(): JSX.Element {
    const style: React.CSSProperties = { marginBottom: "2rem" };
    return (
      <>
        <TextField
          label="Kommentti"
          size="small"
          variant="outlined"
          value={this.state.commentText ? this.state.commentText : ""}
          style={style}
          onChange={({ target }): void => this.setState({ commentText: target.value })}
        />
        <TextField
          value={this.props.comment?.commentAuthor}
          style={style}
          label="Nimi"
          size="small"
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
        />
      </>
    );
  }

  private renderConfirmBody(): JSX.Element {
    const message = this.props.backendActionStatus === BackendActionStatus.SUCCESS
      ? "Kommentti on nyt tallennettu"
      : "Kommentin tallentaminen epäonnistui";
    return (
      <Typography align="center">
        {message}
      </Typography>
    );
  }

  private renderBody(): JSX.Element {
    if (this.props.backendActionLoading) {
      return <Loader/>;
    } else if (this.props.backendActionStatus !== null) {
      return this.renderConfirmBody();
    } else {
      return this.renderEditBody();
    }
  }

  private renderButtons(): Maybe<JSX.Element> {
    if (this.props.backendActionStatus !== null) {
      return (
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={(): void => this.props.onCancel()}
        >
          Ok
        </Button>
      );
    } else if (!this.props.backendActionLoading) {
      return (
        <Box display="flex" justifyContent="center">
          <Button
            variant="contained"
            size="small"
            color="primary"
            style={{ marginRight: "1rem" }}
            onClick={(): void => this.props.onSave({
              eventId: this.props.comment?.eventId,
              timestamp: this.props.comment?.timestamp ?? "",
              commentAuthor: this.props.comment?.commentAuthor,
              commentText: this.state.commentText,
              commentTimestamp: Date.now() + "",
            })}
          >
            Tallenna
          </Button>
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={(): void => this.props.onCancel()}
          >
            Peruuta
          </Button>
        </Box>
      );
    }
  }

  public render(): JSX.Element {
    return (
      <Dialog open={this.props.open}>
        <Box p="1rem">
          <Typography variant="h6">
            Muokkaa kommenttia
          </Typography>
          <Box width="13rem" mt="1rem">
            <Box height="10rem" display="flex" flexDirection="column" justifyContent="center">
              {this.renderBody()}
            </Box>
            <Box height="2rem" display="flex" justifyContent="center">
              {this.renderButtons()}
            </Box>
          </Box>
        </Box>
      </Dialog>
    );
  }
}

export default CommentPopup;

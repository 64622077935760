/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import React, { Component } from "react";
import Loader from "../../ui/loader";
import { Maybe } from "../../../types/aliases";
import AuthWrapper from "../../../data/auth/AuthWrapper";
import { Button, Grid, Typography } from "@material-ui/core";
import PasswordField from "../../ui/password-field";
import { translations } from "../../../generated/translationHelper";
import { isErrorWithCode } from "../../../data/utils/ErrorUtils";

interface Props {}

interface State {
  isLoading: boolean;
  username: string;
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
  error?: string;
  success?: string;
}

export default class PasswordForm extends Component<Props, State> {

  public constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: false,
      username: "",
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    };
  }

  public componentDidMount(): void {
    this.getloggedInUserName();
  }

  private async getloggedInUserName(): Promise<void> {
    const username = await AuthWrapper.getCurrentAuthenticatedUsername();
    this.setState({ username });
  }

  private handlePasswordSubmit = async (): Promise<void> => {
    try {
      this.setState({ isLoading: true });
      await AuthWrapper.submitNewPassword(this.state.oldPassword, this.state.newPassword);
      this.setSuccessMessage("Salasanan päivittäminen onnistui");
      this.setState({
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      });
    } catch (error) {
      console.error("handlePasswordSubmit", error);
      if (isErrorWithCode(error)) this.handleErrorCode(error.message);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  private handleErrorCode(code?: string): void {
    switch (code) {
      case "Attempt limit exceeded, please try after some time.":
        this.setErrorMessage(translations.common.texts.tooManyAttempts());
        break;
      case "Incorrect username or password.":
        this.setErrorMessage(translations.user.texts.incorrectCredentials());
        break;
      case "Network error":
        this.setErrorMessage(translations.common.texts.networkError());
        break;
      case "Password did not conform with policy: Password must have numeric characters":
        this.setErrorMessage(translations.common.texts.passwordMustHaveNumbers());
        break;
      case "Password did not conform with policy: Password must have lowercase characters":
        this.setErrorMessage(translations.common.texts.passwordMustHaveLowercaseCharacters());
        break;
      case "1 validation error detected: Value at 'previousPassword' failed to satisfy constraint: Member must have length greater than or equal to 6":
      case "2 validation errors detected: Value at 'previousPassword' failed to satisfy constraint: Member must have length greater than or equal to 6; Value at 'proposedPassword' failed to satisfy constraint: Member must have length greater than or equal to 6":
      case "1 validation error detected: Value at 'proposedPassword' failed to satisfy constraint: Member must have length greater than or equal to 6":
      case "Password did not conform with policy: Password not long enough":
        this.setErrorMessage(translations.common.texts.passwordMustBeLongEnough());
        break;
      case "Invalid session for the user, session is expired.":
        this.setErrorMessage(translations.common.texts.userSessionExpired());
        break;
      default:
        this.setErrorMessage(translations.common.texts.unableToPerformAction());
        break;
    }
  }

  private setErrorMessage(error?: string): void {
    this.setState({ error });
  }

  private setSuccessMessage(success: string): void {
    this.setState({ success });
    setTimeout(() => {
      this.setState({ success: undefined });
    }, 4000);
  }

  private renderPasswordValidationMessage(): Maybe<JSX.Element> {
    if (this.state.newPassword.length > 0 && this.state.confirmPassword.length > 0 && this.state.newPassword !== this.state.confirmPassword) {
      return (
        <span>{translations.common.texts.passwordsNotMatching()}</span>
      );
    }
  }

  private renderPasswordSubmitMessage(): Maybe<JSX.Element> {
    let message;

    if (this.state.error) {
      message = translations.common.texts.errorOccurred({ error: this.state.error });
    } else if (this.state.success){
      message = this.state.success;
    }
    return (
      <span data-testid={this.state.error ? "user-attributes-error" : undefined}>{message}</span>
    );
  }

  private renderLoader(): Maybe<JSX.Element> {
    if (this.state.isLoading) {
      return <Loader topBottomPadding="0"/>;
    }
  }

  private renderInputs(): JSX.Element {
    const isEnabled = this.state.newPassword.length > 0 && this.state.confirmPassword.length > 0;
    const errorAndSuccessReset: Pick<State, "success" | "error"> = { success: undefined, error: undefined };

    return (
      <Grid container item={true} xs={12} sm={10} md={8} direction="column" alignItems="center">
        <Grid item={true}>
          <PasswordField
            size="small"
            label={translations.user.inputs.oldPassword()}
            inputProps={{ "data-testid": "old-password-field" }}
            autoComplete="current-password"
            onChange={(oldPassword: string): void => {
              this.setState({ oldPassword, ...errorAndSuccessReset });
            }}
          />
        </Grid>
        <Grid item={true}>
          <PasswordField
            size="small"
            label={translations.common.inputs.newPassword()}
            inputProps={{ "data-testid": "new-password-field" }}
            autoComplete="current-password"
            onChange={(newPassword: string): void => {
              this.setState({ newPassword, ...errorAndSuccessReset });
            }}
          />
        </Grid>
        <Grid item={true}>
          <PasswordField
            size="small"
            label={translations.common.inputs.confirmNewPassword()}
            inputProps={{ "data-testid": "confirm-password-field" }}
            autoComplete="current-password"
            onChange={(confirmPassword): void => {
              this.setState({ confirmPassword, ...errorAndSuccessReset });
            }}
          />
        </Grid>
        <Grid item={true} container={true} justifyContent="center">
          <Button
            disabled={!isEnabled}
            variant="contained"
            data-testid="confirm-change-password"
            color="primary"
            onClick={this.handlePasswordSubmit}
          >
            {translations.user.buttons.confirmPasswordChange()}
          </Button>
        </Grid>
      </Grid>
    );
  }

  public render(): JSX.Element {
    return (
      <Grid container={true} spacing={2}>
        <Grid item={true} container={true} justifyContent="center">
          <Typography variant="h6" style={{ fontWeight: "bold" }}>{translations.user.texts.enterOldPasswordAndNewPassword()}</Typography>
        </Grid>
        <Grid item={true} container={true} spacing={2} justifyContent="center">
          {this.renderInputs()}
        </Grid>
        <div className="utu-user-loader-container">
          {this.renderPasswordValidationMessage()}
          {this.renderLoader()}
          {this.renderPasswordSubmitMessage()}
        </div>
      </Grid>
    );
  }
}

/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import Observable from "./Observable";

export default abstract class BaseObservable<TObserver> implements Observable<TObserver> {
  // set to true to enable logging for all observers
  private static enableSpam = false;
  private readonly observers: Set<TObserver> = new Set<TObserver>();

  public addObserver(observer: TObserver): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    BaseObservable.log(`Observable[${this.constructor.name}]: added observer ${(observer as any).constructor.name} `);
    this.observers.add(observer);
  }

  public removeObserver(observer: TObserver): void {
    if (this.observers.delete(observer)) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      BaseObservable.log(`Observable[${this.constructor.name}]: removed observer ${(observer as any).constructor.name} `);
    }
  }

  public isObservedBy(observer: TObserver): boolean {
    return this.observers.has(observer);
  }

  protected get observerCount(): number {
    return this.observers.size;
  }

  protected notifyAction(action: (observer: TObserver) => void): void {
    [...this.observers].forEach((observer) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      BaseObservable.log(`Observable[${this.constructor.name}]: notifying ${(observer as any).constructor.name} `);
      // set the callback into the event loop, to avoid long operations on the main
      setTimeout(() => action(observer), 0);
    });
  }

  protected clearObservers(): void {
    this.observers.clear();
  }

  private static log(line: string): void {
    if (BaseObservable.enableSpam) {
      console.log(line);
    }
  }
}

/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import React, { Component, ReactNode } from "react";
import Loader, { LoaderSize } from "./loader";

interface Props {
  runTask: boolean;
  task: () => Promise<void>;
  size?: LoaderSize;
}

interface State {
  loading: boolean;
}

export class TaskLoader extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
    };
  }
  
  public async componentDidMount(): Promise<void> {
    await this.performTask();
  }

  public async componentDidUpdate(prevProps: Readonly<Props>): Promise<void> {
    if (this.props.runTask && this.props.runTask !== prevProps.runTask) {
      await this.performTask();
    }
  }
  
  private async performTask(): Promise<void> {
    this.setState({ loading: true });
    await this.props.task();
    this.setState({ loading: false });
  }

  public render(): ReactNode {
    return (<Loader show={this.state.loading} size={this.props.size}/>);
  }
}

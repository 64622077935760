/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import Button from "@material-ui/core/Button";
import React, { Component, Fragment, ReactNode } from "react";
import WarningIcon from "@material-ui/icons/Warning";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { DevicePathRouterProps } from "../../../types/routerprops";
import { getDisplayName } from "../../../data/utils/Utils";
import DeviceSettingsButton from "../../device-settings/device-settings-button";
import Device from "../../../data/device/Device";
import { Box, Grid } from "@material-ui/core";
import Paths from "../../Paths";
import DeviceNavigationCache from "../../../utils/DeviceNavigationCache";
import BackendFactory from "../../../data/BackendFactory";
import { idFromProps } from "../../../utils/NavigationUtils";
import { translations } from "../../../generated/translationHelper";
import Event from "../../../data/clientSpecific/Event";
import { MapLocationWithDeviceProperties } from "../../../types/utu-types";
import Data from "../../../data/data/Data";
import ClientProperties, { StatusEntry } from "../../../data/clientSpecific/ClientProperties";
import Loader from "../../ui/loader";
import { Maybe } from "../../../types/aliases";
import ViewAccessMethods from "../../../ViewAccessMethods";
import accessControlled from "../../access-control/access-controlled";
import CloseIcon from "../../../assets/baseline-close-black-24px.svg";

const ACDeviceSettingsButton = accessControlled(DeviceSettingsButton, ViewAccessMethods.hasRootGroupSuperAdminAccess);

interface Props extends RouteComponentProps<DevicePathRouterProps> {
  popupData: MapLocationWithDeviceProperties;
  onClose: () => void;
  events?: Event[];
}

interface State {
  device?: Device;
  sensorData?: Data;
  loading: boolean;
}

class StatusPopupUtu extends Component<Props, State> {

  public constructor(props: Props) {
    super(props);
    this.state = { loading: false };
  }

  public async componentDidMount(): Promise<void> {
    this.setState({ loading: true });
    await this.getDevice();
    await this.getSensorData();
    this.setState({ loading: false });
  }

  private async getDevice(): Promise<void> {
    const deviceId = idFromProps(this.props);

    if (deviceId) {
      const device = await BackendFactory.getBackend().getDevice(deviceId);
      this.setState({ device });
    }
  }

  private async getSensorData(): Promise<void> {
    const { device } = this.state;

    if (device) {
      const latestData = await device.getLatestData();

      if (latestData) {
        this.setState({ sensorData: latestData.getData() });
      }
    }
  }

  private openHistory = (): void => {
    DeviceNavigationCache.getInstance().navigateToPath(this.props, Paths.HISTORY);
  };

  private static convertToLocalTime(timestamp: number): string {
    return new Date(timestamp).toLocaleString();
  }

  private getTableBody(): Maybe<JSX.Element[]> {
    if (this.state.sensorData) {
      const entries: StatusEntry[] = ClientProperties.getOverlayPopupEntries(this.state.sensorData, this.props.events);
      return entries.reduce<JSX.Element[]>((acc, { value, title, event }) => {
        if (value !== "") {
          acc.push(
            <tr key={title}>
              <td>
                <b>{title}</b>
              </td>
              <td>{value}</td>
              {event && <td><WarningIcon htmlColor="#e53935"/></td>}
            </tr>,
          );
        }
        return acc;
      }, []);
    }
  }

  private renderBody(): Maybe<JSX.Element> {
    if (this.state.sensorData) {
      return (
        <div className="status-popup-utu-body">
          <p>{StatusPopupUtu.convertToLocalTime(this.state.sensorData.timestamp)}</p>
          <table>
            <tbody>
              {this.getTableBody()}
            </tbody>
          </table>
        </div>
      );
    }
  }

  private renderButtons(): JSX.Element {
    return (
      <Grid container justifyContent="center" spacing={2} style={{ padding: "1rem 0" }}>
        <Grid item>
          <ACDeviceSettingsButton
            device={this.state.device}
            isIcon={false}
            showAccessError={false}
          />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={this.openHistory}
          >
            {translations.common.buttons.history()}
          </Button>
        </Grid>
      </Grid>
    );
  }

  private renderHeader(): JSX.Element {
    return (
      <Fragment>
        <div className="status-popup-utu-header">
          {/* Assertion: device is checked in parent method */}
          <img className="device-icon" alt="" src={(this.state.device as Device).getIcon()} />
          {getDisplayName(this.state.device)}
        </div>
        <img className="close-icon-utu" alt="close" src={CloseIcon} onClick={this.props.onClose}/>
      </Fragment>
    );
  }

  public render(): ReactNode {
    if (!this.state.device) return null;
    return (
      this.state.loading
        ?
        <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
          <Loader/>
        </Box>
        :
        <Fragment>
          {this.renderHeader()}
          {this.renderBody()}
          {this.renderButtons()}
        </Fragment>
    );
  }
}

export default withRouter(StatusPopupUtu);

/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import PromiseWaitList from "./PromiseWaitList";

// custom implementation of "null" in case null is a valid value to return by the guarded promise
const NOTHING = {};

/**
 * Simplified version of PromiseWaitList, used purely as a lock for a method that needs to execute an expensive
 * async call once, regardless of how many times per second the method is called.
 */
export class PromiseSemaphore<TResult = void> {
  private waitList?: PromiseWaitList<TResult>;
  private guarding = false;
  
  public constructor(private readonly promiseInjector: () => Promise<TResult>) {}

  /**
   * Call the promise and eventually deliver the result to all callers waiting on this.
   */
  public async guard(): Promise<TResult> {
    this.guarding = true;

    if (!this.waitList) {
      this.waitList = new PromiseWaitList<TResult>();
      
      try {
        const result = await this.promiseInjector();

        if (result === undefined) {
          this.waitList.set(NOTHING as TResult);
          return undefined as unknown as TResult;
        } else {
          this.waitList.set(result);
          return result;
        }
      } catch (err) {
        this.waitList.fail(err);
        throw err;
      }
    } else {
      const result = await this.waitList.get();
      return result === NOTHING ? undefined as unknown as TResult : result;
    }
  }

  /**
   * Check whether guard() has been called
   */
  public invoked(): boolean {
    return this.guarding;
  }
}

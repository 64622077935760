/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import React, { ChangeEvent, Component, ReactNode } from "react";
import { IconButton, InputAdornment, OutlinedTextFieldProps, TextField } from "@material-ui/core";
import VisibilityOnIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

interface Props extends Omit<OutlinedTextFieldProps, "onChange" | "variant" | "type" | "value"> {
  onChange: (password: string) => void;
}

interface State {
  password: string;
  isPasswordVisible: boolean;
}

export default class PasswordField extends Component<Props, State> {

  public constructor(props: Props) {
    super(props);
    this.state = {
      password: "",
      isPasswordVisible: false,
    };
  }

  private handleTogglingPasswordVisibility = (): void => {
    this.setState((prevState: State) => ({ isPasswordVisible: !prevState.isPasswordVisible }));
  };

  private handlePasswordChange = (event: ChangeEvent<HTMLInputElement>): void => {
    this.setState({ password: event.target.value });
    this.props.onChange(event.target.value);
  };

  public render(): ReactNode {
    return (
      <TextField
        className="utu-password-field"
        {...this.props}
        type={this.state.isPasswordVisible ? "text" : "password"}
        variant="outlined"
        value={this.state.password}
        onChange={this.handlePasswordChange}
        InputProps={{
          ...this.props.InputProps,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                className="visibility-icon"
                edge="end"
                onClick={this.handleTogglingPasswordVisibility}
              >
                {this.state.isPasswordVisible ? <VisibilityOnIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    );
  }
}

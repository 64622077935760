/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import React, { Component, ReactNode } from "react";
import Loader from "../ui/loader";
import { Typography } from "@material-ui/core";
import BackendFactory from "../../data/BackendFactory";
import User from "../../data/organization/User";
import Organization from "../../data/organization/Organization";

interface State {
  hasAccess?: boolean;
}

interface Props<TWrappedProps> {
  // organization(Id) for the organization within which to perform policy checks
  // if no context organization is provided, uses user's home organization
  contextOrganization?: string | Organization;
  // whether to show error message if the user does not have access.
  // true by default
  showAccessError?: true | boolean;
  // whether to show <Loader> component while resolving access status
  // true by default
  showLoader?: true | boolean;
  // alternative for the <Loader> component. Only used if showLoader = true
  loaderComponent?: JSX.Element;
  // set of props to pass to the wrapped component, when the user does not have access to the component 
  // (access is being checked or access has been denied).
  // setting this implies showAccessError = false && showLoader = false
  // Example value: { disabled: true }, given that wrapped component has prop "disabled"
  accessDeniedProps?: Partial<TWrappedProps>;
}

// list of policies the user requires in order to access the component
type PolicyList = string[];
// async callback for checking if the user has access to the component
type AuthorizationCallback = (user: User) => Promise<boolean>;



/**
 * HOC (https://reactjs.org/docs/higher-order-components.html) for adding access control for a component
 * @param WrappedComponent
 *    component which required access control, such as admin panel components
 * @param policiesOrCallback
 *    either a list of required policies or a callback method for performing authorization
 */
export default function accessControlled<TProps>(WrappedComponent: React.ComponentType<TProps>, policiesOrCallback: PolicyList | AuthorizationCallback): React.ComponentClass<Props<TProps> & TProps> {

  if (Array.isArray(policiesOrCallback) && policiesOrCallback.length === 0) {
    throw new Error("requiredPolicies cannot be an empty array");
  }

  return class AccessControlledContent extends Component<Props<TProps> & TProps, State> {

    public constructor(props: Props<TProps> & TProps) {
      super(props);
      this.state = {};
    }

    public async componentDidMount(): Promise<void> {
      const user = await BackendFactory.getOrganizationBackend().getCurrentUser();
      
      if (!user) {
        console.warn("Failed to properly mount access controlled component: no authenticated user");
        return;
      }

      if (Array.isArray(policiesOrCallback)) {
        const organization = typeof this.props.contextOrganization === "string"
          ? this.props.contextOrganization
          : this.props.contextOrganization?.getId();

        this.setState({
          hasAccess: await user.hasGrants(
            organization ?? user.getHomeOrganizationId(),
            ...policiesOrCallback,
          ),
        });
      } else {
        this.setState({
          hasAccess: await policiesOrCallback(user),
        });
      }
    }
    
    public render(): ReactNode {
      const {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        contextOrganization,
        showAccessError,
        showLoader,
        loaderComponent,
        accessDeniedProps,
        ...rest
      } = this.props;

      const showComponent = this.state.hasAccess === true || accessDeniedProps;

      if (showComponent) {
        const wrappedProps = this.state.hasAccess ? rest : { ...rest, ...accessDeniedProps };
        return (
          <WrappedComponent {...wrappedProps as TProps} />
        );
      } else if (this.state.hasAccess == null) {
        if (showLoader ?? true) {
          return loaderComponent ?? <Loader />;
        }
      } else if (!this.state.hasAccess) {
        if (showAccessError ?? true) {
          return (
            <div
              style={{
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
                textAlign: "center",
              }}
            >
              <Typography variant="h3">
                Invalid access rights, nothing to see here ;)
              </Typography>
            </div>
          );
        }
      }
      return null;
    }
  };
}


/* Copyright */
import { Mark, Slider, Typography } from "@material-ui/core";
import React from "react";
import { Maybe } from "../../../types/aliases";
// import { ValueLabelComponent } from "./gain-selector-helper";

interface Props {
  currentSliderValue: number;
  onSliderChange: (event: React.ChangeEvent<unknown>, value: number | number[]) => void;
  showSlider: boolean;
  sliderDefaultValue: number;
  sliderMarks: Mark[];
}

class GainSelector extends React.Component <Props> {

  private renderSliderArea(): Maybe<JSX.Element> {
    if (this.props.showSlider) {
      const {
        currentSliderValue,
        onSliderChange,
        sliderDefaultValue,
        sliderMarks,
      } = this.props;
      return (
        <div className="slider-area">
          <Typography variant="body2" className="info-text">Valitse vahvistus</Typography>
          <Slider
            value={this.descaleValue(currentSliderValue)}
            min={Math.min(...sliderMarks.map(mark => mark.value))}
            max={Math.max(...sliderMarks.map(mark => mark.value))}
            defaultValue={sliderDefaultValue}
            getAriaValueText={this.getAriaValueText}
            aria-labelledby="gain-selector-slider"
            step={null}
            scale={(value: any): number => this.scaleValue(value)} // eslint-disable-line @typescript-eslint/no-explicit-any
            valueLabelDisplay="on"
            marks={sliderMarks}
            onChange={(event, value: number | number[]): void => onSliderChange(event, this.scaleValue(value as number))}
            style={{ width: "60%" }}
          />
        </div>
      );
    }
  }

  public render(): JSX.Element {
    return (
      <div className="gain-selector">
        {this.props.children}
        {this.renderSliderArea()}
      </div>
    );
  }

  // transform non-linear values created in scaleValue into linear values to have equal distance between marks on scale
  private descaleValue(value: number): number {
    switch (value) {
      case 4:
        return 3;     
      case 5:
        return 4;       
      case 8:
        return 5;
      case 10:
        return 6;    
      case 16:
        return 7;     
      case 32:
        return 8;     
      default:
        return value;
    }
  }

  // transform linear values created in descaleValue into non-linear values to have correct values in parent component's state
  private scaleValue(value: number): number {
    switch (value) {
      case 3:
        return 4;     
      case 4:
        return 5;     
      case 5:
        return 8;     
      case 6:
        return 10;     
      case 7:
        return 16;     
      case 8:
        return 32;     
      default:
        return value;
    }
  }

  private getAriaValueText = (): string => {
    return `${this.props.currentSliderValue}°C`;
  };
}

export default GainSelector;

/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import { Nullable, Voidable } from "../types/aliases";
import { isDefined } from "./types";

export function isValidEmail(email: string): boolean {
  return /.+@.+\..+/.test(email);
}

// Returns:
//   number, if the input is an int or float
//   null, if the input is empty string or invalid number
export function validateNumber(text: Voidable<string>): Nullable<number> {
  const reg = /^[+-]?[0-9]+(\.[0-9]+)?$/;

  if (!isDefined(text) || text.length === 0) {
    return null;
  } else if (!reg.test(text)) {
    return null;
  } else {
    return parseFloat(text);
  }
}

// Returns true, if the string is the begininging of a valid float number
export function validatePartialNumber(text: Voidable<string>): boolean {
  const reg1 = /^[+-]?[0-9]*$/;
  const reg2 = /^[+-]?[0-9]+\.[0-9]*$/;

  if (!isDefined(text)) {
    return false;
  } else if (text.length === 0) {
    return true;
  } else if (reg1.test(text) || reg2.test(text)) {
    return true;
  } else {
    return false;
  }
}

export function validateStringLength(text: string, maxLength: number): boolean {
  if (text.length <= maxLength) {
    return true;
  } else if (text.length === 0) {
    return true;
  } else {
    return false;
  }
}

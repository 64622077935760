/* Copyright */
import React, { Component } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { Maybe, Nullable } from "../../../../types/aliases";
import DeviceGroup from "../../../../data/device/DeviceGroup";

interface Props {
  currentValue: string;
  label: Nullable<string>;
  labelId: Maybe<string>;
  onChange: (value: Maybe<string>) => void;
  values: Nullable<DeviceGroup[]>;
}

interface State {}


class CompanyAndRegionSelector extends Component<Props, State> {
  public render(): JSX.Element {
    const { currentValue, label, labelId, onChange, values } = this.props;
    return (
      <div className="power-company-selector-container">
        <FormControl variant="outlined" margin="dense" className="power-company-selector">
          <InputLabel id="power-company-region-selector-label" shrink={false} margin="dense">
            {label}
          </InputLabel>
          <Select
            labelId={labelId}
            value={currentValue}
            onChange={({ target }): void => typeof target.value === "string" ? onChange(target.value) : undefined}
          >
            <MenuItem value={undefined}><em>Kaikki</em></MenuItem>
            {values
              ? values.map(value => <MenuItem key={value.getId()} value={value.getId()}> {value.getLabel()}</MenuItem>)
              : null}
          </Select>
        </FormControl>
      </div>
    );
  }
}

export default CompanyAndRegionSelector;

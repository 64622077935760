/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import React, { Component, Fragment } from "react";
import Device from "../../data/device/Device";
import DeviceGroup from "../../data/device/DeviceGroup";
import EventsTable from "../ui/events-table";
import Loader from "../ui/loader";
import PowerCompanySelector from "../ui/power-company-selector";

interface Props {
  groups: DeviceGroup[];
  setHistoryViewEventsTableToMountExpanded: () => void;
  devices?: Device[];
}

interface State {
  isLoading: boolean;
  devices?: Device[];
  onlyEventsActivated: boolean;
}

class EventView extends Component<Props, State> {

  public constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: false,
      onlyEventsActivated: false,
    };
  }

  public async componentDidMount(): Promise<void> {
    if (this.props.devices) {
      this.setState({ devices: this.props.devices });
    }
  }

  public async componentDidUpdate(prevProps: Props, _prevState: State): Promise<void> {
    // this.props.devices === undefined in componentDidMount when application is started with /halytykset in URL -> assign devices to state here
    if (!this.state.devices && (!prevProps.devices && this.props.devices)) {
      this.setState({ devices: this.props.devices });
    }
  }

  private getAcceptedDevices(acceptedIds: string[]): Device[] {
    if (this.props.devices) {
      const acceptedDevices = this.props.devices.filter((device: Device) => {
        if (acceptedIds) {
          if (acceptedIds.includes(device.getId())) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      });
      return acceptedDevices;
    } else {
      return [];
    }
  }

  private onDeviceSelectionsChanged = (deviceIds: string[]): void => {
    console.log(`onDeviceSelectionsChanged ${deviceIds}`);
    this.setState({ devices: this.getAcceptedDevices(deviceIds) });
  };

  private onEventsOnlyCheckedChanged = (checked: boolean): void => {
    console.log(`onEventsOnlyCheckedChanged ${checked}`);

    if (this.state.onlyEventsActivated !== checked) {
      this.setState({ onlyEventsActivated: checked });
    }
  };

  public render(): JSX.Element {
    return (
      <Fragment>
        <div className="toolbar-container">
          {this.props.groups.length ?
            <PowerCompanySelector
              checkboxLabel="Vain aktiiviset"
              onDeviceSelectionsChanged={this.onDeviceSelectionsChanged}
              onEventsOnlyCheckedChanged={this.onEventsOnlyCheckedChanged}
              groups={this.props.groups}
            />
            :
            <div className="power-company-selector-main-container">
              <Loader leftRightPadding="0px" topBottomPadding="0px"/>
            </div>
          }
        </div>
        <div className="events-container">
          <EventsTable
            devices={this.state.devices}
            showOnlyActiveEvents={this.state.onlyEventsActivated}
            hideLatestSensorValueColumn
            redirectOnDeactivate
            setHistoryViewEventsTableToMountExpanded={this.props.setHistoryViewEventsTableToMountExpanded}
          />
        </div>
      </Fragment>
    );
  }
}

export default EventView;
